import styled from 'styled-components';
import { Flex, Icon, Text } from 'ui';

export type SidebarButtonProps = {
  isOpen: boolean;
  label: string;
  onClick: () => void;
};

export const SidebarButton = ({
  isOpen,
  label,
  ...props
}: SidebarButtonProps) => (
  <SidebarButtonWrapper
    pl="s30"
    forwardedAs="button"
    alignItems="center"
    {...props}
  >
    <ButtonIcon isOpen={isOpen} name="20-collapse" />
    {isOpen && <ButtonLabel>{label}</ButtonLabel>}
  </SidebarButtonWrapper>
);

export const SidebarButtonWrapper = styled(Flex)`
  cursor: pointer;
  opacity: 0.5;
`;

export const ButtonIcon = styled(Icon).attrs({
  my: 's025',
  color: 'gray80',
})<{ isOpen: boolean }>`
  transition: transform 0.3s ease-out;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(0deg)' : 'rotate(180deg)')};
  overflow: visible;
`;

export const ButtonLabel = styled(Text).attrs({
  ml: 's10',
  color: 'gray80',
  fontSize: 'fs200',
  lineHeight: 'lh300',
})`
  white-space: nowrap;
`;
