import { useState } from 'react';
import { Flex } from 'ui';
import { Row, Cell, Button } from 'components';
import { ReferenceNumber } from 'components/ReferenceNumber';
import { OrderItem } from 'graphql/types/OrderItem';
import { formatDate } from 'utils';

type RecentCustomersOrdersRowProps = {
  onViewOrder: (id: number[]) => void;
} & OrderItem;

export const RecentCustomersOrdersRow = ({
  order,
  onViewOrder,
}: RecentCustomersOrdersRowProps) => {
  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);

  return (
    <Row onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Cell>{order.documentNumber}</Cell>
      <Cell>{formatDate(order.createdAt)}</Cell>
      <Cell>
        <ReferenceNumber reference={order.reference} />
      </Cell>
      <Cell>
        {order.user.firstName} {order.user.lastName}
      </Cell>
      <Cell>
        {hover && (
          <Flex justifyContent="flex-end">
            <Button
              onClick={() => onViewOrder(order.items.map(({ id }) => id))}
              variant="outline-primary"
              size="small"
            >
              View order
            </Button>
          </Flex>
        )}
      </Cell>
    </Row>
  );
};
