import { useState } from 'react';
import { Button } from 'components';
import styled, { css } from 'styled-components';
import { Flex, Text } from 'ui';
import {
  Cell,
  Row,
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  ExpandRowWrapper,
} from 'components/FlexTable/Table.styled';
import { useGetRole } from 'utils/useGetRole';
import { ERPStatus } from 'components/ERPStatus';
import { BackorderQuantityExpandRow } from './BackorderQuantityExpandRow';
import { OtherSubsidiaryStockExpandRow } from './OtherSubsidiaryStockExpandRow';
import { ReplacementsExpandRow } from './ReplacementsExpandRow';
import { ItemsData } from './types';

type ExpandedRow = {
  id: string;
  type: ExpandedRowType;
};

type ExpandedRowType =
  | 'backorderQuantity'
  | 'otherSubsidiaryStock'
  | 'replacements'
  | 'all';

interface Props {
  data?: ItemsData[];
  onClearCart: () => void;
}

export const ItemsResults = ({ data, onClearCart }: Props) => {
  const [expandedRows, setExpandedRows] = useState<ExpandedRow[]>([]);
  const role = useGetRole();
  const isAdminRole = role === 'ADMIN';

  const handleExpand = (id: string, expandType: ExpandedRowType) => {
    setExpandedRows((prev) => {
      const expandedRow = prev.find((row) => row.id === id);

      if (expandedRow && expandedRow.type === expandType) {
        return prev.filter((row) => row.id !== id);
      }

      if (expandedRow) {
        return prev.map((row) =>
          row.id === id ? { ...row, type: expandType } : row,
        );
      }

      return [...prev, { id, type: expandType }];
    });
  };

  const isExpanded = (id: string, expandType: ExpandedRowType) => {
    return expandedRows.some(
      (row) => row.id === id && (row.type === expandType || row.type === 'all'),
    );
  };

  const hasLabel = (id: string) => {
    return expandedRows.some((row) => row.id === id && row.type === 'all');
  };

  return (
    <Flex pt="s10" flexDirection="column" flex="1">
      <Table>
        <ItemsHead />
        <TableBody>
          {data?.map((item: ItemsData) => (
            <ItemsRow
              handleExpand={handleExpand}
              isExpanded={isExpanded}
              hasLabel={hasLabel}
              key={item.id}
              {...item}
            />
          ))}
        </TableBody>
      </Table>
      <Flex mt="s20" mb="s40" justifyContent="space-between">
        <div>
          {isAdminRole && (
            <LegendStatusErp>
              <ERPStatus label="ERP active" status="success" />
              <ERPStatus label="ERP inactive" status="error" />
              <ERPStatus label="ERP missing" status="warning" />
            </LegendStatusErp>
          )}
        </div>
        <Flex ml="s30">
          <Button onClick={onClearCart} variant="text" ml="s30">
            Clear all
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

const ItemsRow = ({
  boQty,
  description,
  erpStatus,
  handleExpand,
  hasLabel,
  purchases,
  id,
  isExpanded,
  itemCode,
  jceStockStatus,
  otherSubisdiaryStock,
  pcStockStatus,
  replacementStatus,
  replacements,
  subsidiaryStocks,
  commodity,
}: ItemsData & {
  handleExpand: (id: string, type: ExpandedRowType) => void;
  hasLabel: (id: string) => boolean;
  isExpanded: (id: string, expandType: ExpandedRowType) => boolean;
}) => {
  return (
    <ExpandRowWrapper>
      <Row>
        <Cell flex={104}>{itemCode}</Cell>
        <Cell flex={184}>{description}</Cell>
        <Cell flex={56}>
          <ERPStatus status={erpStatus} />
        </Cell>
        <Cell flex={192}>
          {replacements.length > 0 ? (
            <Button
              onClick={() => handleExpand(id, 'replacements')}
              icon={{
                color: 'gray80',
                name: '16-arrowhead',
                rotate: isExpanded(id, 'replacements') ? 0 : 180,
                position: 'right',
              }}
              textColor="black"
              fontWeight="regular"
              variant="text"
            >
              {replacementStatus}
            </Button>
          ) : (
            <Text color="black" fontWeight="regular">
              {replacementStatus}
            </Text>
          )}
        </Cell>
        <Cell flex={96}>{jceStockStatus}</Cell>
        <Cell flex={90}>{pcStockStatus}</Cell>
        <Cell flex={104}>
          <Button
            onClick={() => handleExpand(id, 'backorderQuantity')}
            icon={{
              color: 'gray80',
              name: '16-arrowhead',
              rotate: isExpanded(id, 'backorderQuantity') ? 0 : 180,
              position: 'right',
            }}
            textColor="black"
            fontWeight="regular"
            variant="text"
          >
            {purchases.reduce((n, { quantity }) => n + quantity, 0) ?? '0'}
          </Button>
        </Cell>
        <Cell flex={128}>
          <Button
            onClick={() => handleExpand(id, 'otherSubsidiaryStock')}
            icon={{
              color: 'gray80',
              name: '16-arrowhead',
              rotate: isExpanded(id, 'otherSubsidiaryStock') ? 0 : 180,
              position: 'right',
            }}
            textColor="black"
            fontWeight="regular"
            variant="text"
          >
            {otherSubisdiaryStock}
          </Button>
        </Cell>
        <Cell flex={100} onClick={() => handleExpand(id, 'all')}>
          <Button
            icon={{
              color: 'gray80',
              name: '16-arrowhead',
              rotate: isExpanded(id, 'all') ? 0 : 180,
              position: 'right',
            }}
            textColor="black"
            fontWeight="regular"
            variant="text"
          >
            Expand all
          </Button>
        </Cell>
      </Row>
      {isExpanded(id, 'replacements') && replacements[0]?.items[0] && (
        <>
          {hasLabel(id) && <ExpandRowHeader>Replacements</ExpandRowHeader>}
          <ReplacementsExpandRow
            data={replacements}
            commodity={commodity}
          />
        </>
      )}

      {isExpanded(id, 'backorderQuantity') && (
        <>
          {hasLabel(id) && (
            <ExpandRowHeader>Backorder Quantity</ExpandRowHeader>
          )}
          <BackorderQuantityExpandRow data={purchases ?? []} />
        </>
      )}
      {isExpanded(id, 'otherSubsidiaryStock') && (
        <>
          {hasLabel(id) && (
            <ExpandRowHeader>Other Subsidiary Stock</ExpandRowHeader>
          )}
          {subsidiaryStocks && (
            <OtherSubsidiaryStockExpandRow data={subsidiaryStocks} />
          )}
        </>
      )}
    </ExpandRowWrapper>
  );
};

const ItemsHead = () => (
  <TableHead>
    <TableHeadRow>
      <TableHeadCell flex={104}>Item Co.</TableHeadCell>
      <TableHeadCell flex={184}>Description</TableHeadCell>
      <TableHeadCell flex={56}>ERP Status</TableHeadCell>
      <TableHeadCell flex={192}>Replacement status</TableHeadCell>
      <TableHeadCell flex={96}>JCE Stock status</TableHeadCell>
      <TableHeadCell flex={90}>PC Stock status</TableHeadCell>
      <TableHeadCell flex={104}>BO Qty </TableHeadCell>
      <TableHeadCell flex={128}>Other Subisdiary Stock</TableHeadCell>
      <TableHeadCell flex={100} />
    </TableHeadRow>
  </TableHead>
);

const LegendStatusErp = styled.div`
  display: flex;
  ${({ theme: { space, colors } }) => css`
    column-gap: ${space.s17};
    & span {
      color: ${colors.gray80};
    }
  `};
`;

export const ExpandRowHeader = styled(Flex)`
  ${({ theme: { borders, space, fontSizes, fontWeights, lineHeights } }) => css`
    background: #e3edf6;
    border-bottom: ${borders.thinGray10};
    font-size: ${fontSizes.fs100};
    font-weight: ${fontWeights.bold};
    line-height: ${lineHeights.lh300};
    min-height: ${space.s60};
    padding: ${space.s10} ${space.s10} ${space.s0};
  `};
`;
