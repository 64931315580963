import { TableHead, TableHeadCell, TableHeadRow } from 'components';

export const RecentOrdersHead = () => (
  <TableHead>
    <TableHeadRow>
      <TableHeadCell>Order No.</TableHeadCell>
      <TableHeadCell>Order date</TableHeadCell>
      <TableHeadCell>Reference number</TableHeadCell>
      <TableHeadCell>Order status</TableHeadCell>
      <TableHeadCell />
    </TableHeadRow>
  </TableHead>
);
