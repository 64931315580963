import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { Condition } from 'graphql/types/condition';
import { DocumentFile } from 'graphql/types/DocumentFile';

export const useQueryFiles = (
  options?: QueryHookOptions<FilesData, FilesVariables>,
) => useQuery<FilesData, FilesVariables>(GET_FILES, options);

export const GET_FILES = gql`
  query getFiles($conditions: [ConditionInput!]) {
    files(criteria: { orderBy: "id", orderDir: "DESC" }) {
      items(conditions: $conditions) {
        id
        displayName
      }
    }
  }
`;

type FilesVariables = {
  conditions: Condition[];
};

type FilesData = {
  files: {
    items: DocumentFile[];
  };
};
