import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { AuthLayout, MainLayout } from 'layouts';
import { ApolloProvider, ThemeProvider, ToastProvider } from 'providers';
import { paths } from 'routes';
import {
  CartAndDrafts,
  CreateFirstPassword,
  CreateNewPassword,
  Documents,
  DocumentsPrivileges,
  Faq,
  ForgotPassword,
  Home,
  ItemStatus,
  Login,
  Mailing,
  Messages,
  Register,
  Reports,
  Search,
  SetupNewPassword,
  UploadOrder,
  Users,
  Validation,
  Wishlist,
  YourOrders,
} from 'pages';
import { AuthGuardRoute } from 'routes/AuthGuardRoute';
import { CustomerProfile } from 'pages/CustomerProfile/CustomerProfile';
import { SalesProfile } from 'pages/SalesProfile/SalesProfile';
import { SalesCustomerOrders } from 'pages/SalesCustomerOrders/SalesCustomerOrders';
import { AdminCustomerOrders } from 'pages/AdminCustomerOrders/AdminCustomerOrders';
import './config/sentry';

export const App = () => (
  <ApolloProvider>
    <BrowserRouter>
      <ThemeProvider>
        <ToastProvider>
          <Routes>
            {/* MAIN LAYOUT */}
            <Route path={paths.base} element={<MainLayout />}>
              <Route index element={<Home />} />
              <Route
                path={paths.main.yourOrders}
                element={
                  <AuthGuardRoute allowedRoles={['CLIENT']}>
                    <YourOrders />
                  </AuthGuardRoute>
                }
              />
              <Route
                path={paths.main.uploadOrder}
                element={
                  <AuthGuardRoute allowedRoles={['CLIENT']}>
                    <UploadOrder />
                  </AuthGuardRoute>
                }
              />
              <Route
                path={paths.main.validation}
                element={
                  <AuthGuardRoute allowedRoles={['CLIENT']}>
                    <Validation />
                  </AuthGuardRoute>
                }
              />
              <Route
                path={paths.main.itemStatus}
                element={
                  <AuthGuardRoute allowedRoles={['SALES', 'ADMIN']}>
                    <ItemStatus />
                  </AuthGuardRoute>
                }
              />
              <Route
                path={paths.main.cartAndDrafts}
                element={
                  <AuthGuardRoute allowedRoles={['CLIENT']}>
                    <CartAndDrafts />
                  </AuthGuardRoute>
                }
              />
              <Route
                path={paths.main.customerOrdersSales}
                element={
                  <AuthGuardRoute allowedRoles={['SALES']}>
                    <SalesCustomerOrders />
                  </AuthGuardRoute>
                }
              />
              <Route
                path={paths.main.customerOrdersAdmin}
                element={
                  <AuthGuardRoute allowedRoles={['ADMIN']}>
                    <AdminCustomerOrders />
                  </AuthGuardRoute>
                }
              />
              <Route
                path={paths.main.users}
                element={
                  <AuthGuardRoute allowedRoles={['ADMIN']}>
                    <Users />
                  </AuthGuardRoute>
                }
              />
              <Route
                path={paths.main.usersCustomers}
                element={
                  <AuthGuardRoute allowedRoles={['ADMIN']}>
                    <CustomerProfile />
                  </AuthGuardRoute>
                }
              />
              <Route
                path={paths.main.usersSales}
                element={
                  <AuthGuardRoute allowedRoles={['ADMIN']}>
                    <SalesProfile />
                  </AuthGuardRoute>
                }
              />
              <Route path={paths.main.search} element={<Search />} />
              <Route
                path={paths.main.messages}
                element={
                  <AuthGuardRoute allowedRoles={['CLIENT', 'SALES']}>
                    <Messages />
                  </AuthGuardRoute>
                }
              />
              <Route
                path={paths.main.mailing}
                element={
                  <AuthGuardRoute allowedRoles={['ADMIN']}>
                    <Mailing />
                  </AuthGuardRoute>
                }
              />
              <Route path={paths.main.documents} element={<Documents />} />
              <Route
                path={paths.main.documentsPrivileges}
                element={
                  <AuthGuardRoute allowedRoles={['ADMIN']}>
                    <DocumentsPrivileges />
                  </AuthGuardRoute>
                }
              />
              <Route
                path={paths.main.reports}
                element={
                  <AuthGuardRoute allowedRoles={['ADMIN']}>
                    <Reports />
                  </AuthGuardRoute>
                }
              />
              <Route
                path={paths.main.wishlist}
                element={
                  <AuthGuardRoute allowedRoles={['ADMIN']}>
                    <Wishlist />
                  </AuthGuardRoute>
                }
              />
              <Route path={paths.main.faq} element={<Faq />} />
              <Route
                path={paths.main.notAllowed}
                element={
                  //TODO: temporary solution
                  <div>Sorry, You Are Not Allowed to Access This Page</div>
                }
              />
            </Route>
            {/* AUTH LAYOUT */}
            <Route path={paths.base} element={<AuthLayout />}>
              <Route path={paths.auth.signin} element={<Login />} />
              <Route path={paths.auth.signup} element={<Register />} />
              <Route
                path={paths.auth.forgotPassword}
                element={<ForgotPassword />}
              />
              <Route
                path={paths.auth.createNewPassword}
                element={<CreateNewPassword />}
              />
              <Route
                path={paths.auth.setupNewPassword}
                element={<SetupNewPassword />}
              />
              <Route
                path={paths.auth.createFirstPassword}
                element={<CreateFirstPassword />}
              />
            </Route>

            {/* Not Found */}
            <Route path={paths.all} element={<div>error</div>} />
          </Routes>
        </ToastProvider>
      </ThemeProvider>
    </BrowserRouter>
  </ApolloProvider>
);
