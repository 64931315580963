import React from 'react';

import { DIMENSIONS } from 'constants/dimensions';
import { PAGE_TITLE } from 'constants/texts/general';
import { Flex } from 'ui';
import { Link } from 'react-router-dom';

type LogoJukiProps = {
  width?: number;
  height?: number;
};

export const LogoJuki = ({ width, height }: LogoJukiProps) => (
  <Flex alignItems="center" pl="s30">
    <h1>
      <Link to="/">
        <svg width={width} height={height} viewBox="0 0 200 34">
          <title>{PAGE_TITLE}</title>
          <g
            id="Symbols"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <path
              d="M181.354062,-1.77635684e-14 L168.458317,-1.77635684e-14 C167.122646,-1.77635684e-14 166.041058,1.08158764 166.041058,2.4158697 L166.041058,29.8207181 C166.041058,31.157777 167.122646,32.2393646 168.458317,32.2393646 L181.354062,32.2393646 C182.689733,32.2393646 183.772709,31.157777 183.772709,29.8207181 L183.772709,2.4158697 C183.772709,1.08158764 182.689733,-1.77635684e-14 181.354062,-1.77635684e-14"
              fill="#0071CE"
            />
            <path
              d="M124.931009,-1.77635684e-14 L112.035263,-1.77635684e-14 C110.699593,-1.77635684e-14 109.618005,1.08158764 109.618005,2.4158697 L109.618005,29.8207181 C109.618005,31.157777 110.699593,32.2393646 112.035263,32.2393646 L124.931009,32.2393646 C126.268068,32.2393646 127.349656,31.157777 127.349656,29.8207181 L127.349656,21.9746953 C127.349656,21.82891 127.398251,21.6859016 127.489887,21.5720503 C127.713424,21.2943641 128.118846,21.2485459 128.396532,21.4706949 L140.753567,31.3549342 C141.468609,31.9269677 142.357205,32.2393646 143.272181,32.2393646 L161.716096,32.2393646 C162.428361,32.2393646 163.005949,31.6617774 163.005949,30.9495124 C163.005949,30.5579749 162.828229,30.1872638 162.524163,29.9429 L145.066033,15.9766739 L144.967455,15.8767069 C144.745306,15.6004092 144.788347,15.1949874 145.066033,14.9700616 L160.912195,2.29368779 C161.466179,1.85077834 161.556427,1.04132315 161.112129,0.48317394 C160.870542,0.17771915 160.497054,-1.77635684e-14 160.105517,-1.77635684e-14 L143.272181,-1.77635684e-14 C142.357205,-1.77635684e-14 141.468609,0.311008513 140.753567,0.88443046 L128.396532,10.7686698 C128.281293,10.8589178 128.141061,10.9102897 127.993888,10.9102897 C127.637061,10.9102897 127.349656,10.6187192 127.349656,10.2646694 L127.349656,2.4158697 C127.349656,1.08158764 126.268068,-1.77635684e-14 124.931009,-1.77635684e-14"
              fill="#0071CE"
            />
            <path
              d="M57.2269549,-1.77635684e-14 C55.8912844,-1.77635684e-14 54.8083083,1.08158764 54.8083083,2.4158697 L54.8083083,29.8207181 C54.8083083,31.157777 55.8912844,32.2393646 57.2269549,32.2393646 L102.362065,32.2393646 C103.699124,32.2393646 104.780712,31.157777 104.780712,29.8207181 L104.780712,2.4158697 C104.780712,1.08158764 103.699124,-1.77635684e-14 102.362065,-1.77635684e-14 L89.4677079,-1.77635684e-14 C88.1320374,-1.77635684e-14 87.0490614,1.08158764 87.0490614,2.4158697 L87.0490614,24.5016394 C87.0490614,25.2139045 86.4714741,25.7914917 85.7578207,25.7914917 L73.8311996,25.7914917 C73.1189345,25.7914917 72.5413473,25.2139045 72.5413473,24.5016394 L72.5413473,2.4158697 C72.5413473,1.08158764 71.4583712,-1.77635684e-14 70.1227007,-1.77635684e-14 L57.2269549,-1.77635684e-14 Z"
              fill="#0071CE"
            />
            <path
              d="M49.9724036,2.4158697 L49.9724036,29.8207181 C49.9724036,31.1563886 48.890816,32.2393646 47.5537571,32.2393646 L2.41864656,32.2393646 C1.08158764,32.2393646 -1.38555833e-13,31.1563886 -1.38555833e-13,29.8207181 L-1.38555833e-13,15.3143924 C-1.38555833e-13,13.9759451 1.08158764,12.8957459 2.41864656,12.8957459 L15.3143924,12.8957459 C16.6486745,12.8957459 17.733039,13.9759451 17.733039,15.3143924 L17.733039,24.5016394 C17.733039,25.2139045 18.3092378,25.7914917 19.0215028,25.7914917 L30.9522892,25.7914917 C31.6645543,25.7914917 32.2407531,25.2139045 32.2407531,24.5016394 L32.2407531,2.4158697 C32.2407531,1.08158764 33.3237291,-1.77635684e-14 34.6580112,-1.77635684e-14 L47.5537571,-1.77635684e-14 C48.890816,-1.77635684e-14 49.9724036,1.08158764 49.9724036,2.4158697"
              fill="#0071CE"
            />
            <path
              d="M199.45087,5.72588888 C199.45087,8.69435406 197.046108,11.1005047 194.079031,11.1005047 C191.110566,11.1005047 188.705804,8.69435406 188.705804,5.72588888 C188.705804,2.76020056 191.110566,0.35404987 194.079031,0.35404987 C197.046108,0.35404987 199.45087,2.76020056 199.45087,5.72588888 Z"
              stroke="#0071CE"
              strokeWidth="1.008"
            />
            <path
              d="M191.559029,2.4158697 L194.855164,2.4158697 C196.586537,2.4158697 196.905877,3.69600296 196.905877,4.25970589 C196.905877,4.97613621 196.543496,5.59259952 195.897876,5.81058316 C196.43381,6.04661641 196.723992,6.24655045 196.723992,7.46281589 C196.723992,8.41527946 196.723992,8.65131271 197.015563,8.76099874 L197.015563,8.93316417 L195.509115,8.93316417 C195.417479,8.62354409 195.36333,8.28893225 195.36333,7.61693171 C195.36333,6.72694753 195.309181,6.38261668 194.30118,6.38261668 L192.8947,6.38261668 L192.8947,8.93316417 L191.559029,8.93316417 L191.559029,2.4158697 Z M194.491395,5.28436786 C195.199495,5.28436786 195.545214,5.0386156 195.545214,4.39438368 C195.545214,4.05005283 195.391098,3.54188713 194.591362,3.54188713 L192.8947,3.54188713 L192.8947,5.28436786 L194.491395,5.28436786 Z"
              fill="#0071CE"
            />
          </g>
        </svg>
      </Link>
    </h1>
  </Flex>
);

LogoJuki.defaultProps = {
  width: DIMENSIONS.logoWidth,
  height: DIMENSIONS.logoHeight,
};
