import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { BusinessPartnerShippingAddress } from 'graphql/types/BusinessPartnerShippingAddress';

export const useQueryUserDeliveryAddresses = (
  options?: QueryHookOptions<UserDeliveryAddressesData>,
) => useQuery<UserDeliveryAddressesData>(USER_DELIVERY_ADDRESSES, options);

export const USER_DELIVERY_ADDRESSES = gql`
  query userDeliveryAddresses {
    userDeliveryAddresses {
      identifier
      active
      name
      address
      zipCode
      city
      county
      country
      shipVia
      viaCity
      duty
      id
      createdAt
      updatedAt
    }
  }
`;

export type UserDeliveryAddressesData = {
  userDeliveryAddresses: BusinessPartnerShippingAddress[];
};
