import { gql, QueryHookOptions, useQuery } from '@apollo/client';

import { User } from 'graphql/types/User';

export const useQueryUsers = (
  options?: QueryHookOptions<UsersData, UsersVariables>,
) => useQuery<UsersData, UsersVariables>(USERS, options);

export const USERS = gql`
  query users(
    $phrase: String
    $country: String
    $company: String
    $directoryId: String
    $role: String
    $salesAgent: String
    $status: Int
    $offset: Int
    $orderBy: String!
    $orderDir: String
    $limit: Int
  ) {
    users(
      criteria: {
        offset: $offset
        orderBy: $orderBy
        orderDir: $orderDir
        limit: $limit
      }
    ) {
      items(
        phrase: $phrase
        country: $country
        company: $company
        role: $role
        salesAgent: $salesAgent
        status: $status
        directoryId: $directoryId
      ) {
        id
        email
        roles
        name
        enabled
        directories {
          id
          userVisible
          active
        }
        businessPartner {
          country
          name
          code
        }
        salesUser {
          id
          name
        }
      }
      count
    }
  }
`;

export interface UsersVariables {
  phrase?: string;
  orderBy: string;
  orderDir?: 'ASC' | 'DESC';
  limit?: number;
  offset?: number;
  country?: string;
  company?: string;
  role?: string;
  salesAgent?: string;
  status?: number;
  directoryId?: string;
}

export type UsersData = {
  users: {
    items: User[];
    count: number;
  };
};
