import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { WishlistRegisteredItem } from 'graphql/types/WishlistRegisteredItem';

export const useQueryAdminRegisteredWishlist = (
  options?: QueryHookOptions<AdminRegisteredWishlistData>,
) => useQuery<AdminRegisteredWishlistData>(ADMIN_REGISTERED_WISHLIST, options);

export const ADMIN_REGISTERED_WISHLIST = gql`
  query adminRegisteredWishlist {
    adminRegisteredWishlist {
      quantity
      commodity {
        id
        itemCode
        createdAt
        sellable
        price
      }
      order {
        id
        createdAt
        status
        user {
          name
        }
      }
      stockStatus
      inquireDate
      id
      createdAt
      updatedAt
    }
  }
`;

export type AdminRegisteredWishlistData = {
  adminRegisteredWishlist: WishlistRegisteredItem[];
};
