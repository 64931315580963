import { useQueryBusinessPartners } from 'graphql/query';

export const useCompany = () => {
  const businessPartners = useQueryBusinessPartners();
  const company = businessPartners.data?.businessPartners
    ?.map((item) => ({ name: item?.name, id: item?.id }))
    ?.filter(({ name }) => name);

  return company || [];
};
