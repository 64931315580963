import styled, { css } from 'styled-components';
import { Flex } from 'ui';
import {
  Cell,
  Row,
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  ExpandRow,
} from 'components/FlexTable/Table.styled';
import { ReplacementStatus } from 'components';
import { Replacement } from 'graphql/types/Replacement';
import { Commodity } from 'graphql/types/Commodity';
import { PartsData } from './types';

interface Props {
  data: Replacement[];
  commodity: Commodity;
}

const replacementStatusValue = (commodity: Commodity) => {
  if (commodity?.replacementAvailable) return '1 to 1';
  if (commodity?.replacementAvailableSet) return 'Set';
  if (commodity?.replacementAvailableChoice) return 'Choice';
  if (commodity?.replacementAvailableChoiceWithSet) return 'Choice';
};

export const ReplacementsExpandRow = ({ data, commodity }: Props) => {
  const replacemensItem = data[0]?.items[0];

  return (
    <ExpandRow>
      <Flex flex="1">
        <LabelSubTable>
          <LabelSubTableHead />
          <TableBody>
            <LabelSubTableRow key={replacemensItem.id} {...commodity} />
          </TableBody>
        </LabelSubTable>
        <PartsSubTable>
          <PartsSubTableHead />
          <TableBody>
            {data.map((replacement, index) =>
              replacement.items.map((replacementItem, replacementItemIndex) => (
                <PartsSubTableRow
                  border={replacementItemIndex === replacement.items.length - 1}
                  key={replacementItem.id}
                  id={replacementItem.id}
                  replacementStatus={
                    index === 0 &&
                    replacementItemIndex === 0 &&
                    replacementStatusValue(commodity)
                  }
                  remarks={replacementItem.replacement.remarks}
                  replacedBy={replacementItem.commodity.itemCode}
                  qty={replacementItem.replacement.quantity}
                />
              )),
            )}
          </TableBody>
        </PartsSubTable>
        <PlaceholderSubTable>
          <SubTableHead />
        </PlaceholderSubTable>
      </Flex>
    </ExpandRow>
  );
};

const LabelSubTableHead = () => (
  <SubTableHead>
    <TableHeadRow>
      <SubTableHeadCell flex={136}>Item Co.</SubTableHeadCell>
      <SubTableHeadCell flex={184}>Description</SubTableHeadCell>
    </TableHeadRow>
  </SubTableHead>
);

const LabelSubTableRow = (
  commodity: Pick<Commodity, 'itemCode' | 'description'>,
) => {
  const { itemCode, description } = commodity;
  return (
    <SubRow border>
      <Cell flex={136}>{itemCode}</Cell>
      <Cell flex={184}>{description}</Cell>
    </SubRow>
  );
};

const PartsSubTableHead = () => (
  <SubTableHead>
    <TableHeadRow>
      <SubTableHeadCell flex={120}>Replacement status</SubTableHeadCell>
      <SubTableHeadCell flex={128}>Remarks</SubTableHeadCell>
      <SubTableHeadCell flex={152}>Replaced by</SubTableHeadCell>
      <SubTableHeadCell flex={104}>Qty</SubTableHeadCell>
    </TableHeadRow>
  </SubTableHead>
);

const PartsSubTableRow = ({
  replacementStatus,
  remarks,
  replacedBy,
  qty,
  border,
}: PartsData & { border: boolean }) => (
  <SubRow border={border}>
    <Cell flex={120}>
      {replacementStatus && (
        <ReplacementStatus uppercase status="success">
          {replacementStatus}
        </ReplacementStatus>
      )}
    </Cell>
    <Cell flex={128}>{remarks}</Cell>
    <Cell flex={152}>{replacedBy}</Cell>
    <Cell flex={104}>{qty}</Cell>
  </SubRow>
);

export const LabelSubTable = styled(Table)`
  flex: 296;
`;

export const PartsSubTable = styled(Table)`
  flex: 528;
`;

export const PlaceholderSubTable = styled(Table)`
  flex: 292;
`;

export const SubRow = styled(Row)<{ border: boolean }>`
  ${({ theme: { colors }, border }) => css`
    background: ${colors.lightBlue};

    ${!border &&
    css`
      border-bottom: none;
    `}

    &:last-child {
      border-bottom: none;
    }
  `};
`;

export const SubTableHead = styled(TableHead)`
  ${({ theme: { colors, borders } }) => css`
    background: ${colors.lightBlue};
    border-bottom: ${borders.thinGray10};
  `};
`;

export const SubTableHeadCell = styled(TableHeadCell)`
  ${({ theme: { space } }) => css`
    padding: ${space.s15} ${space.s05} ${space.s0};
  `};
`;
