import { Box, Text } from 'ui';
import { Link } from 'components/Link/Link';
import { paths } from 'routes';
import { Container } from './Container';

export const ExpiredLink = () => {
  return (
    <Container>
      <Text color="gray80" lineHeight="lh300" as="p">
        The link you followed has expired
      </Text>
      <Box mt="s20">
        <Link fontWeight="regular" to={paths.auth.forgotPassword}>
          Please try again
        </Link>
      </Box>
    </Container>
  );
};
