import { useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Checkbox } from 'components';
import { Flex, Text, Icon, MarginProps } from 'ui';
import { useOnClickOutside } from 'utils/useOnClickOutside';
import { paths } from 'routes';
import { Tooltip } from 'components/Tooltip';
import { DocumentDirectory } from 'graphql/types/DocumentDirectory';
import { DocumentDropdown, OptionButton } from './DocumentDropdown';
import { parseDirectories } from '../utils';
import { HandleSelected, Selected } from '../utils/useSelected';
import { downloadZip } from '../utils/downloadZip';

type DocumentProps = MarginProps &
  DocumentDirectory & {
    selected: Selected;
    handleSelected: HandleSelected;
    adminPermission?: boolean;
    isViewPrivileges?: boolean;
  };

export const Document = ({
  id,
  fileCounter,
  name,
  active,
  userVisible,
  handleSelected,
  isViewPrivileges,
  adminPermission,
  selected,
  ...rest
}: DocumentProps) => {
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const [hover, setHover] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const paramDirectory = search.get('directories');
  const directories = parseDirectories(paramDirectory);
  const isSelected = selected?.includes(id);
  const visibleCheckbox = (hover || isSelected) && !isViewPrivileges;
  const documentOptionsRef = useRef<HTMLDivElement>(null);
  const paramDerectories = `?directories=${JSON.stringify([
    ...directories,
    { id, name },
  ])}`;

  const toggleDropdown = () => setDropdownOpen(!isDropdownOpen);
  const closeDropdown = () => setDropdownOpen(false);

  useOnClickOutside(documentOptionsRef, closeDropdown);

  const handleDownload = () => {
    downloadZip({
      directories_ids: `${id}`,
      organization_id: 1,
    });
    closeDropdown();
  };

  const handleSelect = () => {
    handleSelected({ id, type: 'directories' });
    closeDropdown();
  };

  const goToPrivileges = () => {
    navigate(`${paths.main.documentsPrivileges}${paramDerectories}`);
  };

  if (!adminPermission && (!active || !userVisible)) {
    return null;
  }

  return (
    <DocumentContainer
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      flexDirection="column"
      p="s20"
      backgroundColor={hover || isSelected ? 'lightBlue' : 'white'}
      {...rest}
    >
      <ClickableArea
        onClick={() => navigate(`${paths.main.documents}${paramDerectories}`)}
      />
      <Flex justifyContent="space-between" mb="s15">
        <Icon name="20-folder" color="gray05" />
        {visibleCheckbox && (
          <Checkbox checked={isSelected} onChange={handleSelect} />
        )}
      </Flex>

      <Flex mb="s05">
        <Tooltip content={<TooltipText>{name}</TooltipText>}>
          <DisplayName>{name}</DisplayName>
        </Tooltip>
      </Flex>
      <Flex alignItems="center" justifyContent="space-between">
        <Text color="gray80">
          {fileCounter} {fileCounter === 1 ? 'file' : 'files'}
        </Text>

        <DocumentDropdown
          onClickOutside={closeDropdown}
          isDropdownOpen={isDropdownOpen}
          openDropdown={(open) => setDropdownOpen(open)}
          onButtonClick={toggleDropdown}
        >
          {adminPermission && (
            <OptionButton onClick={goToPrivileges}>Privileges</OptionButton>
          )}
          <OptionButton onClick={handleDownload}>Download</OptionButton>
          {!isViewPrivileges && (
            <OptionButton onClick={handleSelect}>
              {isSelected ? 'Unselect' : 'Select'}
            </OptionButton>
          )}
        </DocumentDropdown>
      </Flex>
    </DocumentContainer>
  );
};

const DisplayName = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 140px;
`;

const TooltipText = styled(Text)`
  word-break: break-all;
  display: block;
`;

const DocumentContainer = styled(Flex)`
  ${({ theme: { borders, radii } }) => css`
    border: ${borders.thinGray10};
    width: 212px;
    height: 114px;
    border-radius: ${radii.rounded};
    cursor: pointer;
    transition: background-color 0.2s;
    position: relative;
  `}
`;

const ClickableArea = styled.span`
  position: absolute;
  width: 100%;
  height: 100%;
`;
