/* eslint-disable no-plusplus */
import { Reference } from '@apollo/client';
import {
  FieldPolicy,
  KeyArgsFunction,
  KeySpecifier,
} from '@apollo/client/cache/inmemory/policies';

type KeyArgs = false | KeySpecifier | KeyArgsFunction | undefined;

export const paginationWithAllVariables = () =>
  offsetLimitPagination(['offset'], 'exclude');

export function offsetLimitPagination<T = Reference>(
  keyArgs?: string[],
  type?: 'exclude' | 'include',
): FieldPolicy<T[]> {
    
  return {
    ...getKeyArgsWithVariables(keyArgs, type),
    merge(existing, incoming, { variables }) {
      const { offset = 0 } = variables || {};
      const merged = existing ? existing.slice(0, offset) : [];

      if (incoming) {
        if (variables) {
          for (let i = 0; i < incoming.length; ++i) {
            merged[offset + i] = incoming[i];
          }
        } else {
          merged.push(...incoming);
        }
      }

      return merged;
    },
  };
}

export const getKeyArgsWithVariables = (
  /* If empty, it takes into account all variables in query */
  keys?: string[],
  type?: 'exclude' | 'include',
): { keyArgs: KeyArgs } => {
  const isIncludeKeyArgs = (key: string) =>
    type === 'include' || type === undefined
      ? !keys?.includes(key)
      : keys?.includes(key);

  return {
    keyArgs: (_, { variables, fieldName }) => {
      const result = { ...variables };
      if (result && keys) {
        for (const [key] of Object.entries(result)) {
          if (isIncludeKeyArgs(key)) {
            delete result[key];
          }
        }
      }
      const key = keys ? result : variables;
      return `${fieldName}:${JSON.stringify(key)}`;
    },
  };
};
