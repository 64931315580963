import { ApolloClient, InMemoryCache } from '@apollo/client';

import { createUploadLink } from 'apollo-upload-client';
import {
  getKeyArgsWithVariables,
  offsetLimitPagination,
  paginationWithAllVariables,
} from './utils';

const link = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_HOST,
  credentials: 'include',
});

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          messages: {
            keyArgs: false,
            merge: true,
          },
          users: {
            keyArgs: false,
            merge: true,
          },
          orders: {
            keyArgs: false,
            merge: true,
          },
          contentBlocks: {
            keyArgs: false,
            merge: true,
          },
          deliveryNoteItems: {
            keyArgs: false,
            merge: true,
          },
        },
      },
      DeliveryNoteItemsType: {
        fields: {
          items: paginationWithAllVariables(),
          count: getKeyArgsWithVariables(),
        },
      },
      MessageType: {
        fields: {
          count: getKeyArgsWithVariables(),
          items: paginationWithAllVariables(),
        },
      },
      OrderType: {
        fields: {
          adminOrders: paginationWithAllVariables(),
          salesOrders: paginationWithAllVariables(),
          customerOrders: paginationWithAllVariables(),
          orders: paginationWithAllVariables(),
          count: getKeyArgsWithVariables(),
        },
      },
      UserType: {
        fields: {
          count: getKeyArgsWithVariables(),
          items: paginationWithAllVariables(),
        },
      },
      User: {
        fields: {
          directories: {
            merge(_existing, incoming = []) {
              return incoming;
            },
          },
        },
      },
    },
  }),
  link,
});
