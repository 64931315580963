import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { CartItem } from 'graphql/types/CartItem';

export const useQueryReplacementAvailableChoice = (
  options?: QueryHookOptions<
    ReplacementAvailableChoiceData,
    ReplacementAvailableChoiceVariables
  >,
) =>
  useQuery<ReplacementAvailableChoiceData, ReplacementAvailableChoiceVariables>(
    REPLACEMENT_AVAILABLE_CHOICE,
    options,
  );

export const REPLACEMENT_AVAILABLE_CHOICE = gql`
  query replacementAvailableChoice($section: String) {
    replacementAvailableChoice {
      id
      monitored(section: $section)
      commodity {
        itemCode
        description
        stockStatus
        stocks {
          stock
          source
          id
          location
          status
        }
        replacements {
          setNumber
          quantity
          commodity {
            itemCode
            id
            description
          }
          items {
            id
            quantity
            commodity {
              itemCode
              id
              description
            }
            replacement {
              remarks
            }
          }
          id
          createdAt
          updatedAt
        }
        validate {
          setNumber
          quantity
          commodity
          items {
            id
            commodity {
              itemCode
              id
              description
            }
          }

          id
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export type ReplacementAvailableChoiceVariables = {
  section: string;
};

export type ReplacementAvailableChoiceData = {
  replacementAvailableChoice: CartItem[];
};
