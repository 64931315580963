import styled, { css } from 'styled-components';
import { Flex, Grid, Text } from 'ui';
import { useCreateProfileModalContext } from './CreateProfileModalContext';

export const CreateCustomerProfileSummaryForm = () => {
  const { customerProfileForm } = useCreateProfileModalContext();
  const deliveryAddress = customerProfileForm?.data?.deliveryAddress;
  return (
    <Flex mt={'s20'} mx={'s20'} flexDirection="column">
      <SummaryContainer p={'s30'} flex={1}>
        <Flex flex={1}>
          <Column>
            <Label>User name</Label>
            <Text>{`${customerProfileForm.data.firstName} ${customerProfileForm.data.lastName}`}</Text>

            <Label>Login</Label>
            <Text>{customerProfileForm.data.username}</Text>

            <Label>User email</Label>
            <Text>{customerProfileForm.data.email}</Text>
          </Column>
        </Flex>
        <Flex flex={1}>
          <Column>
            <Label>Company</Label>
            <Text>{customerProfileForm.data.businessPartner?.name}</Text>

            <Label>Default address</Label>
            <div>
              <Text>
                {deliveryAddress?.address} {deliveryAddress?.zipCode}{' '}
                {deliveryAddress?.city}
              </Text>
            </div>
          </Column>
        </Flex>
      </SummaryContainer>
      <Flex mt={'s20'}>
        <SummaryContainer mr={'s20'} p={'s30'} flex={1}>
          <Flex flex={1}>
            <Column>
              <Label>Role</Label>
              <Text>Customer</Text>

              <Label>Account type</Label>
              <Text>{customerProfileForm.data.mainAccount}</Text>

              <Label>Access</Label>
              <MultipleText>
                {customerProfileForm.data.downloadFiles && (
                  <Text>Download files</Text>
                )}
                {customerProfileForm.data.createOrders && (
                  <Text>Create orders</Text>
                )}
                {customerProfileForm.data.createReports && (
                  <Text>Create reports</Text>
                )}
              </MultipleText>
            </Column>
          </Flex>
        </SummaryContainer>
        <SummaryContainer p={'s30'} flex={1}>
          <Flex flex={1}>
            <Column>
              <Label>Sales Support</Label>
              <Text>{customerProfileForm.data?.salesSupport?.name}</Text>
            </Column>
          </Flex>
        </SummaryContainer>
      </Flex>
    </Flex>
  );
};

const Column = styled(Grid).attrs<any>({
  gridTemplateColumns: 'auto auto',
  gridAutoRows: 'max-content',
})`
  gap: ${({ theme: { space } }) => `${space.s15} ${space.s40}`};
`;

const MultipleText = styled(Grid).attrs<any>({
  gridTemplateColumns: 'auto',
  gridAutoRows: 'max-content',
})`
  gap: ${({ theme: { space } }) => `${space.s15} 0`};
`;
const Label = styled(Text).attrs({ fontWeight: 'medium', color: 'gray80' })``;

const SummaryContainer = styled(Flex)`
  height: fit-content;

  ${({ theme: { colors, radii, space } }) => css`
    background: ${colors.gray06};
    border-radius: ${radii.rounded};
    gap: ${space.s15} ${space.s40};
  `};
`;
