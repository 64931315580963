import styled, { css } from 'styled-components';

import { Flex, Grid, Text } from 'ui';
import { formatDate } from 'utils';
import { Attachment } from './Attachment';
import { MessageLabel } from './MessageLabel';
import { getTag } from './utilities';

type Props = {
  id: number;
  title: string;
  updatedAt: string;
  tag: string;
  content: string;
  channel: 'inapp' | 'mail';
  receiver: string;
  attachments: {
    id: string;
    name: string;
  }[];
};

export const MessageContent = ({
  title,
  updatedAt,
  content,
  attachments,
  tag,
}: Props) => {
  const data = formatDate(updatedAt);
  return (
    <Container>
      <MessageHeadMeta mb="s20">
        <MessageLabel backgroundColor={getTag(tag).color}>
          {getTag(tag)?.label}
        </MessageLabel>
        <MessageDate>{data}</MessageDate>
      </MessageHeadMeta>
      <Flex flexDirection="column">
        <Title mb="s30">{title}</Title>

        <SyledMessageContent dangerouslySetInnerHTML={{ __html: content }} />

        <AttachmentsContainer mt="s30">
          {attachments?.map((item) => (
            <Attachment key={item.id} {...item} />
          ))}
        </AttachmentsContainer>
      </Flex>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;
const SyledMessageContent = styled.div`
  img {
    max-width: 100%;
  }
`;

const MessageHeadMeta = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  height: 32px;
`;

const MessageDate = styled(Flex)`
  line-height: 24px;

  font-weight: 500;
  font-size: 12px;
  color: #656565;
`;

const Title = styled(Text)`
  font-size: 24px;
  margin-bottom: 24px;
`;

const AttachmentsContainer = styled(Grid)`
  ${({ theme: { space } }) => css`
    display: grid;
    grid-gap: ${space.s20};
    grid-template-columns: repeat(auto-fill, 212px);
  `}
`;
