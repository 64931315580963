import { gql, QueryHookOptions, useQuery } from '@apollo/client';

export const useQueryProducts = (
  options?: QueryHookOptions<ProductsData, ProductsVariables>,
) => useQuery<ProductsData, ProductsVariables>(PRODUCTS, options);

export const PRODUCTS = gql`
  query products(
    $offset: Int
    $orderBy: String!
    $orderDir: String
    $limit: Int
  ) {
    products(
      criteria: {
        offset: $offset
        orderBy: $orderBy
        orderDir: $orderDir
        limit: $limit
      }
    ) {
      items {
        id
        name
      }
    }
  }
`;

export type ProductsVariables = {
  orderBy: string;
  orderDir?: 'ASC' | 'DESC';
  limit?: number;
  offset?: number;
};

export type ProductItem = {
  id: number;
  name: string;
};

export type ProductsData = {
  products: {
    items: ProductItem[];
  };
};
