import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { Commodity } from 'graphql/types/Commodity';

export const useQueryCommodities = (
  options?: QueryHookOptions<CommoditiesData>,
) => useQuery<CommoditiesData>(COMMODITIES, options);

export const COMMODITIES = gql`
  query commodities {
    commodities {
      items {
        itemCode
        description
        status
        replacements {
          id
          setNumber
          items {
            id
            quantity
            replacement {
              setNumber
            }
            commodity {
              setNumber
            }
          }
        }
      }
      count
    }
  }
`;

export type CommoditiesData = {
  commodities: { items: Commodity[]; count: number };
};
