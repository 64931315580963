import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { Cart } from 'graphql/types/Cart';

export const useQueryCart = (
  options?: QueryHookOptions<CartData, CartVariables>,
) => useQuery<CartData, CartVariables>(GET_CART, options);

export const GET_CART = gql`
  query cart($wishlist: Boolean, $section: String) {
    cart {
      __typename
      monitored(section: $section)
      reference
      user {
        name
      }
      id
      createdAt
      updatedAt
      items(wishlist: $wishlist) {
        monitored(section: $section)
        id
        quantity
        price
        vatRate
        vatValue
        status
        wishlist
        stockStatus
        commodity {
          itemCode
          description
          stockStatus
          createdAt
          sellable
          stocks {
            stock
            source
            id
            location
            status
          }
          replacements {
            setNumber
            quantity
            commodity {
              itemCode
              id
              description
            }
            items {
              id
              quantity
              commodity {
                itemCode
                id
                description
              }
            }
            id
            createdAt
            updatedAt
          }
          validate {
            setNumber
            quantity
            commodity
            items {
              id
              commodity {
                itemCode
                id
                description
              }
            }

            id
            createdAt
            updatedAt
          }
        }
      }
      missingItems {
        itemCode
        quantity
      }
    }
  }
`;

export type CartVariables = {
  wishlist?: boolean;
  section: string;
};

export type CartData = {
  cart: Cart;
};
