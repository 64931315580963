import { Button, RegistrationStatus } from 'components';
import { Flex, Grid, Icon, Text } from 'ui';
import {
  Cell,
  Row,
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TableHeadRow,
} from 'components/FlexTable/Table.styled';
import { formatDate } from 'utils';
import { CartItem } from 'graphql/types/CartItem';

type WishlistStatusTableProps = {
  data: CartItem[];
  onWishlistButtonClick: () => void;
};

export const WishlistStatusTable = ({
  data,
  onWishlistButtonClick,
}: WishlistStatusTableProps) => {
  return (
    <Flex pt="s10" flexDirection="column" flex="1">
      <Table>
        <ItemsHead />
        <TableBody>
          {data.map((item) => (
            <ItemsRow key={item.id} {...item} />
          ))}
        </TableBody>
      </Table>
      <Flex mt="s20" mb="s40" justifyContent="space-between">
        <Grid gridAutoFlow="column" gridGap="s20">
          <RegistrationStatus labelColor={'gray80'} status={'processing'} />
          <RegistrationStatus labelColor={'gray80'} status={'registered'} />
        </Grid>
        <Flex ml="s30">
          <Button onClick={onWishlistButtonClick} variant="outline-primary">
            Wishlist
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

const ItemsRow = ({
  createdAt,
  price,
  quantity,
  movedFromWishlistDate,
  cart: {
    user: { name },
  },
  commodity: { itemCode, sellable, createdAt: registrationDate },
}: CartItem) => {
  return (
    <Row>
      <Cell flex={136}>{itemCode}</Cell>
      <Cell flex={112}>{formatDate(createdAt) ?? '-'}</Cell>
      <Cell flex={136}>{formatDate(registrationDate) ?? '-'}</Cell>
      <Cell flex={100}>
        <RegistrationStatus
          status={sellable ? 'registered' : 'processing'}
          disabledLabel
        />
      </Cell>
      <Cell flex={80}>{price}</Cell>
      <Cell flex={112}>{formatDate(movedFromWishlistDate) ?? '-'}</Cell>
      <Cell flex={176}>{name}</Cell>
      <Cell flex={80}>{quantity}</Cell>
    </Row>
  );
};

const ItemsHead = () => (
  <TableHead>
    <TableHeadRow>
      <TableHeadCell flex={136}>Item code</TableHeadCell>
      <TableHeadCell flex={112}>Inquire date</TableHeadCell>
      <TableHeadCell flex={136}>Registration date</TableHeadCell>
      <TableHeadCell flex={100}>Registration status</TableHeadCell>
      <TableHeadCell flex={80}>List price</TableHeadCell>
      <TableHeadCell flex={112}>Order date</TableHeadCell>
      <TableHeadCell flex={176}>Customer</TableHeadCell>
      <TableHeadCell flex={80}>Qty</TableHeadCell>
    </TableHeadRow>
  </TableHead>
);
