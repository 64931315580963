import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export const useMutationCartMonitorItem = (
  options?: MutationHookOptions<CartMonitorItemData, CartMonitorItemVariables>,
) =>
  useMutation<CartMonitorItemData, CartMonitorItemVariables>(
    CART_MONITOR_ITEM,
    options,
  );

export const CART_MONITOR_ITEM = gql`
  mutation cartMonitorItem($id: Int, $monitored: Boolean) {
    cartMonitorItem(item: { id: $id }, monitored: $monitored) {
      id
      monitored
    }
  }
`;

export type CartMonitorItemVariables = {
  id: number;
  monitored: boolean;
};

export type CartMonitorItemData = {
  cartMonitorItem: {
    id: string;
  };
};
