import styled, { css } from 'styled-components';
import {
  Cell,
  Row,
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  ExpandRow,
} from 'components/FlexTable/Table.styled';

import type { CommodityStock } from 'graphql/types/CommodityStock';

interface Props {
  data: CommodityStock[];
}

export const OtherSubsidiaryStockExpandRow = ({ data }: Props) => (
  <ExpandRow>
    <Table>
      <OtherSubsidiaryStockHead data={data} />
      <TableBody>
        <OtherSubsidiaryStockRow data={data} />
      </TableBody>
    </Table>
  </ExpandRow>
);

const OtherSubsidiaryStockRow = ({ data }: Props) => {
  const total = data.reduce((accumulator, object) => {
    return accumulator + object.stock;
  }, 0);
  const getStock = (name: string) => {
    const item = data?.find(
      (stock: CommodityStock) => stock.locationName === name,
    );
    return item ? item.stock : '-';
  };
  return (
    <SubRow>
      <Cell flex={136}>{total}</Cell>
      <Cell flex={128}>{getStock('JUKI AMERICA')}</Cell>
      <Cell flex={128}>{getStock('JUKI CENTRAL EUROPE')}</Cell>
      <Cell flex={128}>{getStock('JUKI ITALY')}</Cell>
      <Cell flex={128}>{getStock('JUKI SINGAPOURE')}</Cell>
      <Cell flex={128}>{getStock('JUKI CHINA')}</Cell>
      <Cell flex={128}>{getStock('JUKI HONG KONG')}</Cell>
      <Cell flex={128}>{getStock('JUKI JAPAN')}</Cell>
      <Cell flex={128}>{getStock('JUKI VIETNAM')}</Cell>
      <Cell flex={128}>{getStock('JUKI INDIA')}</Cell>
    </SubRow>
  );
};

const OtherSubsidiaryStockHead = ({ data }: Props) => (
  <SubTableHead>
    <TableHeadRow>
      <SubTableHeadCell flex={136}>SC Total Qty</SubTableHeadCell>
      <SubTableHeadCell flex={128}>JUKI America</SubTableHeadCell>
      <SubTableHeadCell flex={128}>JUKI Central Europe</SubTableHeadCell>
      <SubTableHeadCell flex={128}>JUKI Italy</SubTableHeadCell>
      <SubTableHeadCell flex={144}>JUKI Singapoure</SubTableHeadCell>
      <SubTableHeadCell flex={128}>JUKI China</SubTableHeadCell>
      <SubTableHeadCell flex={128}>JUKI Hong Kong</SubTableHeadCell>
      <SubTableHeadCell flex={128}>JUKI Hong Japan</SubTableHeadCell>
      <SubTableHeadCell flex={128}>JUKI Vietnam</SubTableHeadCell>
      <SubTableHeadCell flex={128}>JUKI India</SubTableHeadCell>
    </TableHeadRow>
  </SubTableHead>
);

export const SubRow = styled(Row)`
  ${({ theme: { colors } }) => css`
    background: ${colors.lightBlue};

    &:last-child {
      border-bottom: none;
    }
  `};
`;

export const SubTableHead = styled(TableHead)`
  ${({ theme: { colors, borders } }) => css`
    background: ${colors.lightBlue};
    border-bottom: ${borders.thinGray10};
  `};
`;

export const SubTableHeadCell = styled(TableHeadCell)`
  ${({ theme: { space } }) => css`
    padding: ${space.s15} ${space.s05} ${space.s0};
  `};
`;
