import { Alert, Button, Label, TextInput } from 'components';
import { Link } from 'components/Link/Link';
import { useState } from 'react';
import { paths } from 'routes';
import { Box, Flex, Text } from 'ui';
import { useNavigate } from 'react-router-dom';
import { useMutationLoginByUsername } from 'graphql/mutation';
import { useApolloClient } from '@apollo/client';
import { Container } from './components/Container';

export const Login = () => {
  const [password, setPassword] = useState('');
  const [userName, setUserName] = useState('');
  const [error, setError] = useState(false);

  const navigate = useNavigate();
  const client = useApolloClient();

  const [login] = useMutationLoginByUsername({
    variables: { password, userName },
    onCompleted: () => {
      navigate('/');
    },
    onError: () => {
      setError(true);
    },
  });

  const handleLogin = () => {
    setError(false);
    client.clearStore();
    login();
  };

  const handleEnter = (e?: React.KeyboardEvent<HTMLInputElement>) => {
    if (e?.key === 'Enter') {
      e?.preventDefault();
      handleLogin();
    }
  };

  return (
    <Container>
      <Text color="gray80" fontWeight="bold" lineHeight="lh300" mb="s20" as="p">
        LOGIN TO JUKI CUSTOMER PORTAL
      </Text>
      <Alert visible={!!error}>Login or password incorrect</Alert>
      <Label>Login</Label>
      <TextInput
        placeholder="Insert your login"
        icon={{ name: '20-lock', color: 'gray05' }}
        onChange={(e) => setUserName(e?.currentTarget.value || '')}
        onKeyDown={handleEnter}
      />
      <Box mt="s20">
        <Label>Password</Label>
        <TextInput
          placeholder="Insert your password"
          icon={{ name: '20-user', color: 'gray05' }}
          type="password"
          onChange={(e) => setPassword(e?.currentTarget.value || '')}
          onKeyDown={handleEnter}
        />
      </Box>
      <Box mt="s20">
        <Link fontWeight="regular" to={paths.auth.forgotPassword}>
          Forgot your password?
        </Link>
      </Box>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        mt="s25"
        mb="s10"
      >
        <Text>
          New to Customer Portal?{' '}
          <Link fontWeight="regular" to={paths.auth.signup}>
            Join here
          </Link>
        </Text>
        <Button variant="primary" onClick={handleLogin}>
          Login
        </Button>
      </Flex>
    </Container>
  );
};
