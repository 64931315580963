import styled, { css } from 'styled-components';

import { Flex, FlexProps, Icon, Text } from 'ui';

type UserCardProps = FlexProps & {
  name: string;
  code: string;
};

export const UserCard = ({ name, code, ...rest }: UserCardProps) => {
  return (
    <StyledUserCard {...rest}>
      {/* TODO: icon design */}
      <Icon mr={'s15'} name="20-user" color="gray05" />
      <Flex flexDirection="column">
        <Text color="gray80" fontWeight="medium">
          {name}
        </Text>
        <Text lineHeight="lh300">Code: {code}</Text>
      </Flex>
    </StyledUserCard>
  );
};

export const StyledUserCard = styled(Flex)`
  ${({ theme: { borders, radii, space, colors } }) => css`
    border-radius: ${radii.rounded};
    border: ${borders.thinGray10};
    width: 224px;
    padding: ${space.s17} ${space.s20};
    &:hover {
      cursor: pointer;
      border-color: ${colors.gray40};
    }
  `}
`;
