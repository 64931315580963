import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export const useMutationAddToImport = (
  options?: MutationHookOptions<AddToImportData, AddToImportVariables>,
) => useMutation<AddToImportData, AddToImportVariables>(ADD_TO_IMPORT, options);

export const ADD_TO_IMPORT = gql`
  mutation addToImport($commodityId: Int!) {
    addToImport(commodity: { id: $commodityId }, quantity: 1) {
      id
      commodity {
        itemCode
        description
      }
      quantity
    }
  }
`;

export type AddToImportVariables = {
  commodityId: number;
};

export type AddToImportData = {
  addToImport: {
    id: number;
    commodity: {
      itemCode: string;
      description: string;
    };
    quantity: number;
  };
};
