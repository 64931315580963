import {
  Button,
  ModalProps,
  TextInput,
  Label,
  Modal,
  UploadInput,
  Alert,
} from 'components';
import { useMutationAddFile } from 'graphql/mutation';
import { useState } from 'react';
import { Box, Flex } from 'ui';

interface Props extends Omit<ModalProps, 'children'> {
  directoryId: number;
  refetch: () => void;
}
export const ModalAddFile = ({
  open,
  handleClose: handleCloseModal,
  directoryId,
  refetch,
}: Props) => {
  const [name, setName] = useState('');
  const [file, setFile] = useState<File>();

  const handleClose = () => {
    handleCloseModal();
    setName('');
    setFile(undefined);
    reset();
  };

  const handleInputChange = (e?: React.ChangeEvent<HTMLInputElement>) => {
    setName(e?.currentTarget.value || '');
  };

  const [addFile, { error, loading, reset }] = useMutationAddFile({
    onCompleted: () => {
      handleClose();
      refetch();
    },
  });

  const handleAddFile = () => {
    if (!!name && !!file) {
      addFile({
        variables: { directoryId: directoryId.toString(), name, file },
      });
    }
  };

  return (
    <Modal handleClose={handleClose} open={open} title="ADD FILE">
      <Alert visible={!!error}>File upload error</Alert>
      <Label>File name</Label>
      <TextInput
        autoFocus
        maxLength={255}
        onChange={handleInputChange}
        placeholder="File name"
        type="text"
        value={name}
      />
      <Box mt="s30">
        <UploadInput onChange={(value) => setFile(value?.[0])} />
      </Box>
      <Flex justifyContent="flex-end" mt="s30">
        <Button
          variant="primary"
          onClick={handleAddFile}
          disabled={!(name && file)}
        >
          {loading ? 'Loading...' : 'Add file'}
        </Button>
      </Flex>
    </Modal>
  );
};
