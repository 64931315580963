import styled, { css, keyframes } from 'styled-components';
import { NumericInput, ToggleSwitch, Button, StockStatus } from 'components';
import { Cell, Row, ExpandRowWrapper } from 'components/FlexTable/Table.styled';
import { CartItem } from 'graphql/types/CartItem';
import { Colors } from 'theme';

type OrdersRowProps = {
  onIncrement: (id: number, quantity: number) => void;
  onDecrement: (id: number, quantity: number) => void;
  onMonitorItem: (id: number, currentValue: boolean) => void;
  onDelete: (id: number) => void;
  loading: boolean;
  recentlyAddedIds: number[];
  deletedItemsIds: number[];
} & CartItem;

export const OrdersRow = ({
  commodity,
  quantity,
  price,
  id,
  onDelete,
  onIncrement,
  onDecrement,
  onMonitorItem,
  monitored,
  loading,
  recentlyAddedIds,
  deletedItemsIds,
}: OrdersRowProps) => {
  return (
    <ExpandRowWrapper>
      <StyledRow
        isDeleted={deletedItemsIds.includes(id)}
        fadeAnimation={recentlyAddedIds.includes(id)}
      >
        <Cell flex={104}>{commodity.itemCode}</Cell>
        <CellElipsis flex={168}>{commodity.description}</CellElipsis>
        <Cell flex={240}>
          <NumericInput
            decrementDisabled={quantity <= 1}
            onIncrement={() => onIncrement(id, quantity)}
            onDecrement={() => onDecrement(id, quantity)}
            value={quantity}
          />
        </Cell>
        <Cell flex={110}>{price}</Cell>

        <Cell flex={364}>
          <StockStatus
            status={commodity.stockStatus}
            label={commodity.stockStatus}
          />
        </Cell>
        <CellStyled flex={112}>
          <ToggleSwitch
            onChange={() => onMonitorItem(id, monitored)}
            checked={monitored}
            disabled={loading}
          />
          <ActionWrapper
            icon={{ name: '16-delete', color: 'gray40' }}
            disabled={loading || false}
            variant={'icon'}
            onClick={() => onDelete(id)}
            ml="s20"
          />
        </CellStyled>
      </StyledRow>
    </ExpandRowWrapper>
  );
};

const CellStyled = styled(Cell)`
  ${({ theme: { borders, space } }) => css`
    border-left: ${borders.thinGray10};
    padding-left: ${space.s20};
  `}
`;

const getColorFadeAnimation = (colors: Colors) => keyframes`
  0% {
    background: ${colors.lightBlue};
  }
  100% {
    background: ${colors.white};
  }
`;

const StyledRow = styled(Row)<{ fadeAnimation: boolean }>`
  ${({ theme: { colors }, fadeAnimation }) =>
    fadeAnimation &&
    css`
      animation: ${() => getColorFadeAnimation(colors)} 5s ease-out;
    `}
`;

const CellElipsis = styled(Cell)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  margin: auto 0;
`;

const ActionWrapper = styled(Button)`
  cursor: pointer;
`;
