import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import packageJson from '../../package.json';

const dsn = process.env.REACT_APP_SENTRY_DSN;

Sentry.init({
  dsn,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  enabled: !!dsn,
  release: packageJson?.version,
});
