import { Button, Heading, RegistrationStatus, TextInput } from 'components';
import {
  Cell,
  Row,
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TableHeadRow,
} from 'components/FlexTable/Table.styled';
import { Box, Flex } from 'ui';
import styled, { css } from 'styled-components';
import { formatDate } from 'utils';
import { CartItem } from 'graphql/types/CartItem';

type TableStatusProps = {
  data: CartItem[];
  onSendWishlistByEmail: () => void;
  onRemoveFromWishlist: (id: number) => void;
  email: string;
  onEmailChange: (email: string) => void;
};

export const TableStatus = ({
  data,
  onSendWishlistByEmail,
  onRemoveFromWishlist,
  email,
  onEmailChange,
}: TableStatusProps) => {
  return (
    <Box px="s30">
      <Heading pt="s30">WISHLIST STATUS</Heading>
      <Box p="s15">
        <Table>
          <TableHead>
            <TableHeadRow>
              <TableHeadCell flex={200}>Item code</TableHeadCell>
              <TableHeadCell flex={200}>Inquire date</TableHeadCell>
              <TableHeadCell flex={250}>Customer</TableHeadCell>
              <TableHeadCell flex={300}>Qty</TableHeadCell>
              <TableHeadCell flex={100}>Registration status</TableHeadCell>
              <TableHeadCell flex={40} />
            </TableHeadRow>
          </TableHead>
          <TableBody>
            {data?.map((item) => (
              <Row key={item.id}>
                <Cell flex={200}>{item.commodity.itemCode}</Cell>
                <Cell flex={200}>{formatDate(item.createdAt)}</Cell>
                <Cell flex={250}>{item.cart.user.name}</Cell>
                <Cell flex={300}>{item.quantity}</Cell>
                <Cell flex={100}>
                  <RegistrationStatus
                    disabledLabel
                    status={
                      item.commodity.sellable ? 'registered' : 'processing'
                    }
                  />
                </Cell>
                <Cell flex={40}>
                  <Button
                    icon={{ name: '16-delete', color: 'gray40' }}
                    variant={'icon'}
                    onClick={() => onRemoveFromWishlist(item.id)}
                    ml="s20"
                  />
                </Cell>
              </Row>
            ))}
          </TableBody>
        </Table>
        <ActionBar>
          <ItemBar>
            <RegistrationStatus status="registered" />
            <RegistrationStatus status="processing" />
          </ItemBar>
          {data?.length > 0 && (
            <ItemBar>
              <InputEmail
                value={email}
                onChange={(e) => onEmailChange(e?.target.value ?? '')}
                placeholder="Insert email"
              />
              <Button
                onClick={onSendWishlistByEmail}
                fullWidth
                variant="outline-primary"
              >
                Send for registration
              </Button>
            </ItemBar>
          )}
        </ActionBar>
      </Box>
    </Box>
  );
};

const InputEmail = styled(TextInput)`
  width: 304px;
`;

const ItemBar = styled(Flex)`
  ${({ theme: { space } }) => css`
    column-gap: ${space.s20};
  `}
`;

const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
  ${({ theme: { space } }) => css`
    padding: ${space.s30} 0;
  `}
`;
