import { createRef, RefObject, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

type TableRowScrollProps<T extends { id: number }> = {
  queryParamsKey?: string;
  data: T[];
};

export type Refs = Record<number, RefObject<HTMLDivElement>>;

export const useOrdersTableRowScroll = <T extends { id: number }>({
  data,
  queryParamsKey = 'expandedRowIds',
}: TableRowScrollProps<T>) => {
  const [searchParams] = useSearchParams();

  const expandedRowIds = JSON.parse(
    decodeURIComponent(searchParams.get(queryParamsKey) || '[]'),
  ) as number[];

  const [init, setInit] = useState(true);
  const refs = useMemo(
    () =>
      data?.reduce<Refs>((acc, value) => {
        acc[value.id] = createRef<HTMLDivElement>();
        return acc;
      }, {}),
    [data],
  );

  useEffect(() => {
    if (!init || !expandedRowIds.length || !data?.length) return;

    setInit(false);

    const id = expandedRowIds[Math.floor((expandedRowIds.length - 1) / 2)];
    refs?.[id]?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }, [expandedRowIds, refs, init, data]);

  return { expandedRowIds, refs };
};
