import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export function useMutationClearImport(
  options?: MutationHookOptions<ClearImportData>,
) {
  return useMutation<ClearImportData>(CLEAR_IMPORT, options);
}

export const CLEAR_IMPORT = gql`
  mutation clearImport {
    clearImport
  }
`;

export type ClearImportData = {
  clearImport: boolean;
};
