import { VFC } from 'react';
import styled from 'styled-components';

import { Box } from '../Box';
import { ImageProps } from './props';

export const Image: VFC<ImageProps> = (props) => (
  <StyledImage forwardedAs="img" {...props} />
);

const StyledImage = styled(Box)<ImageProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  max-width: 100%;
`;
