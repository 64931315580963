import { FC } from 'react';
import styled, { css } from 'styled-components';
import { Colors, colors } from 'theme';
import { ColorsType, Flex, Text } from 'ui';

export type DottedStatusProps = {
  color: ColorsType;
  label?: string;
  disabledLabel?: boolean;
  size?: string;
  labelColor?: keyof Colors;
};

export const DottedStatus: FC<DottedStatusProps> = ({
  color,
  label,
  disabledLabel,
  size,
  labelColor,
}) => {
  return (
    <Flex alignItems="center">
      <Circle size={size} color={color} />
      {!disabledLabel && (
        <Text
          color={labelColor ?? color}
          fontSize="fs200"
          lineHeight="lh300"
          fontWeight="medium"
          ml="s10"
        >
          {label}
        </Text>
      )}
    </Flex>
  );
};

DottedStatus.defaultProps = {
  size: '8px',
  color: 'blue',
};

type CircleProps = {
  size?: string;
  color: ColorsType;
};

const Circle = styled.div<CircleProps>`
  ${({ size, color }) => css`
    width: ${size};
    height: ${size};
    background-color: ${colors[color]};
    border-radius: 50%;
    display: inline-block;
  `};
`;
