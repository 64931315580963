import {
  useRef,
  useEffect,
  useState,
  useCallback,
  MutableRefObject,
} from 'react';

interface Props<T> {
  /** Maksymalna liczba elementów do pobrania */
  count: number | undefined;
  /**Parametr zwracany z useQuery lub useLazyQuery */
  loading: boolean | undefined;
  /**Funkcja z useQuery lub useLazyQuery */
  fetchMore?: any;
  /**Zawartośc query którą chcemy łączyć po kolejnym pobraniu */
  resource: (data: T | undefined) => any[] | undefined;
  /**Dane zwrócone z query */
  data?: T;
  /**Domyślnie false (wyłączenie auto pobierania po zjechaniu na dół strony)*/
  disabled?: boolean;
  /** Bierze pod uwage scrolla na który wskazuje ref*/
  listenerRef?: boolean;
  /** Dystans kolejnego załadowania od dołu **/
  distanceBottom?: number;
}

interface Result {
  /** Referencja do górnej częsci strony lub jesli uzyjemy "listenerRef: true" to wtedy do kontenera ze scrollem */
  ref: MutableRefObject<any>;
  /** Ładowanie pobierania */
  loadingFetchMore: boolean;
  /** Funckja  pobierająca kolejne wartości(żeby ją użyć trzeba wyłączyć auto pobieranie useInfiniteScroll({disabled: true}))*/
  handleFetchMore: (recordsNum?: number) => void;
}

export function useInfiniteScroll<T = any>(props: Props<T>): Result {
  const {
    data,
    loading,
    fetchMore,
    count,
    disabled = false,
    resource,
    listenerRef = false,
    distanceBottom = 50,
  } = props;
  const [bottom, setBottom] = useState(false);

  const ref = useRef<any>();

  const loaderMore = (count || 0) <= (resource(data)?.length || 0);
  const [loadingFetchMore, setLoadingFetchMore] = useState(false);

  useEffect(() => {
    const current = ref?.current;
    if (!disabled) {
      const handleScroll = () => {
        if (!loading && !loaderMore) {
          let isBottom = false;

          if (listenerRef) {
            isBottom =
              (current?.scrollTop || 0) +
                (current?.offsetHeight || 0) +
                distanceBottom >
              current?.scrollHeight;
          } else {
            isBottom =
              window.innerHeight +
                document.documentElement.scrollTop +
                distanceBottom >
              (current?.offsetHeight || 0);
          }
          setBottom(isBottom);
        }
      };

      if (listenerRef) {
        current?.addEventListener('scroll', handleScroll);
        return () => {
          current?.removeEventListener('scroll', handleScroll);
        };
      }
      window?.addEventListener('scroll', handleScroll);
      return () => {
        window?.removeEventListener('scroll', handleScroll);
      };
    }
  }, [disabled, distanceBottom, listenerRef, loaderMore, loading]);

  const handleFetchMore = useCallback(
    async (recordsNum?: number) => {
      if (!loading && !loaderMore) {
        setLoadingFetchMore(true);

        fetchMore({
          variables: {
            offset: recordsNum || resource(data)?.length,
          },
        })?.finally(() => {
          setLoadingFetchMore(false);
          return 0;
        });
      }
    },
    [loading, loaderMore, fetchMore, resource, data],
  );

  // Auto FetchMore
  useEffect(() => {
    if (!disabled && !loadingFetchMore && bottom) {
      handleFetchMore();
      setBottom(false);
    }
  }, [handleFetchMore, bottom, disabled, loadingFetchMore]);

  return {
    ref,
    loadingFetchMore,
    handleFetchMore,
  };
}

export default useInfiniteScroll;
