import { useState } from 'react';
import { Box, Flex, Grid } from 'ui';
import { Breadcrumbs, Button, Panel, Heading } from 'components';
import { paths } from 'routes';
import styled from 'styled-components';
import { Carousel } from 'components/Carousel/Carousel';
import { CarouselContextProvider } from 'components/Carousel/CarouselContext';
import { CarouselControls } from 'components/Carousel/CarouselControls';
import { UserCard } from 'components/UserCard/UserCard';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQuerySimilarUsers } from 'graphql/query';
import { CreateProfileModal } from 'pages/Users/components/CreateProfileModal/CreateProfileModal';
import { USER_ROLES } from 'pages/Users/components/CreateProfileModal/CreateProfileModalContext';
import { useQueryUserById } from './graphql/useQueryUserById';
import { SalesBasicInfo } from './components/SalesBasicInfo';
import { ConnectedCustomers } from './components/ConnectedCustomers/ConnectedCustomers';

export const SalesProfile = () => {
  const navigate = useNavigate();
  const [updateProfileModalOpen, setUpdateProfileModalOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const paramId = searchParams.get('id') || '';
  const { data, loading } = useQueryUserById({ variables: { id: paramId } });
  const similarUserQuery = useQuerySimilarUsers({
    variables: { orderBy: 'id', limit: 10, role: 'ROLE_SALES' },
  });

  const {
    name,
    email = '',
    userName = '',
    firstName = '',
    lastName = '',
  } = data?.userById || {};

  const similarUser = similarUserQuery?.data?.users?.items;

  if (loading) {
    return null;
  }

  return (
    <>
      <Grid gridTemplateRows={'40px 1fr'}>
        <Breadcrumbs
          px="s30"
          items={[
            { label: 'Home', to: paths.base },
            { label: 'Users', to: paths.main.users },
            { label: 'Sales', to: paths.main.usersSales },
          ]}
        />

        <StyledWrapper flexDirection="column">
          <Panel
            customHeading={
              <Flex alignItems="center">
                <Heading color="blue" fontSize="fs200" pt="s17" mb="s20">
                  {name}
                </Heading>
                <Button
                  ml="s10"
                  size={'small'}
                  variant={'outline-primary'}
                  onClick={() => setUpdateProfileModalOpen(true)}
                >
                  Edit
                </Button>
              </Flex>
            }
            expandable={false}
            label={''}
          >
            <Flex flex={1} flexWrap="wrap">
              <Box mr={'s30'} mb={'s30'}>
                <SalesBasicInfo data={data?.userById} />
              </Box>
              <ConnectedCustomers />
            </Flex>
          </Panel>
          <CarouselContextProvider>
            <Panel
              expandable={false}
              additionalElements={<CarouselControls />}
              label={'OTHER SALES AGENTS'}
              childrenPx={'s0'}
            >
              <Carousel>
                {similarUser?.map(({ id: key, businessPartner, ...rest }) => (
                  <UserCard
                    key={key}
                    name={rest?.name}
                    code={businessPartner?.code || '-'}
                    onClick={() =>
                      navigate(`${paths.main.usersSales}?id=${key}`)
                    }
                  />
                ))}
              </Carousel>
            </Panel>
          </CarouselContextProvider>
        </StyledWrapper>
      </Grid>

      <CreateProfileModal
        open={updateProfileModalOpen}
        handleClose={() => setUpdateProfileModalOpen(false)}
        initialStep={1}
        initialRole={USER_ROLES[1]}
        initialValuesSales={{
          firstName,
          lastName,
          email,
          username: userName,
          userId: paramId,
        }}
      />
    </>
  );
};

const StyledWrapper = styled(Flex)`
  overflow-x: hidden;
`;
