import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export function useMutationRemoveFromCart(
  options?: MutationHookOptions<RemoveFromCartData, RemoveFromCartVariables>,
) {
  const data = useMutation<RemoveFromCartData, RemoveFromCartVariables>(
    REMOVE_FROM_CART,
    options,
  );
  return data;
}

export const REMOVE_FROM_CART = gql`
  mutation removeFromCart($id: Int, $section: String) {
    removeFromCart(item: { id: $id }) {
      __typename
      items {
        quantity
        price
        vatRate
        vatValue
        commodity
        cart {
          reference
          user
          items {
            id
          }
          id
          createdAt
          updatedAt
          token
          monitored(section: $section)
        }
        wishlist
        validate
        id
        status
        createdAt
        updatedAt
        monitored(section: $section)
      }
    }
  }
`;

export type RemoveFromCartVariables = {
  id: number;
  section: string;
};

export type RemoveFromCartData = {
  removeFromCart: {
    id: number;
  };
};
