import styled, { css } from 'styled-components';
import { Flex, Text } from 'ui';
import { ExpandRow } from 'components/FlexTable/Table.styled';
import { TransformedOrdersData } from 'pages';

export type OrdersExpandRowProps = { data: TransformedOrdersData };

export const OrdersExpandRow = ({
  data: { deliveryNoteItem, order, commodity },
}: OrdersExpandRowProps) => (
  <ExpandRow pt="s10" px="s10" pb="s20">
    <Flex flex="5" flexDirection="column">
      <ExpandRowProduction>
        <Flex flex="1" flexDirection="column">
          <ExpandRowHeader>Expected production date:</ExpandRowHeader>
          <Flex>
            <ExpandRowText>
              {commodity?.purchases?.[0]?.productionDate ?? '-'}
            </ExpandRowText>
          </Flex>
        </Flex>
        <Flex flex="1" flexDirection="column">
          <ExpandRowHeader>Shipped from HQ:</ExpandRowHeader>
          <Flex>
            <ExpandRowText>
              {commodity?.purchases?.[0]?.shippedFrom ?? '-'}
            </ExpandRowText>
          </Flex>
        </Flex>
        <Flex flex="1" flexDirection="column">
          <ExpandRowHeader>Estimated arrival time:</ExpandRowHeader>
          <Flex>
            <ExpandRowText>
              {commodity?.purchases?.[0]?.expectedArrival ?? '-'}
            </ExpandRowText>
          </Flex>
        </Flex>
      </ExpandRowProduction>
    </Flex>
    <Flex flex="1" flexDirection="column">
      <ExpandRowHeader>Tracking number</ExpandRowHeader>
      <Flex>
        <a
          target="_blank"
          rel="noreferrer"
          href={deliveryNoteItem?.deliveryNote?.transports[0]?.trackingUrl}
        >
          <ExpandRowLink>
            {deliveryNoteItem?.deliveryNote?.transports[0]?.carrierNumber ??
              '-'}
          </ExpandRowLink>
        </a>
      </Flex>
    </Flex>
    <Flex flex="1" flexDirection="column">
      <ExpandRowHeader>Invoice</ExpandRowHeader>
      <Flex>
        <ExpandRowLink>
          {deliveryNoteItem?.invoiceItem?.invoice?.documentNumber ?? '-'}
        </ExpandRowLink>
      </Flex>
    </Flex>
    <Flex flex="1" flexDirection="column">
      <ExpandRowHeader>Delivery address</ExpandRowHeader>
      <Flex>
        <ExpandRowLink>
          {order.shipTo ? order.shipTo.address : '-'}
        </ExpandRowLink>
      </Flex>
    </Flex>
  </ExpandRow>
);

const ExpandRowProduction = styled(Flex)`
  gap: 24px;
`;

const ExpandRowText = styled(Text).attrs({ lineHeight: 'lh300' })``;

const ExpandRowLink = styled(ExpandRowText)`
  ${({ theme: { colors } }) => css`
    color: ${colors.blue};
  `}
`;

const ExpandRowHeader = styled(ExpandRowText)`
  padding: 0 0 4px;

  ${({ theme: { colors } }) => css`
    color: ${colors.gray80};
  `}
`;
