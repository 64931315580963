import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export function useMutationSendResetPasswordMail(
  options?: MutationHookOptions<boolean, SendResetPasswordMailVariables>,
) {
  const data = useMutation<boolean, SendResetPasswordMailVariables>(
    SEND_RESET_PASSWORD_MAIL,
    options,
  );
  return data;
}

export const SEND_RESET_PASSWORD_MAIL = gql`
  mutation sendResetPasswordMail($email: String!) {
    sendResetPasswordMail(email: $email)
  }
`;

export type SendResetPasswordMailVariables = {
  email: string;
};
