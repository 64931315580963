import { gql, QueryHookOptions, useQuery } from '@apollo/client';

export function useQueryUser(options?: QueryHookOptions<UserData>) {
  const data = useQuery<UserData>(USER, options);
  return data;
}

export const USER = gql`
  query user {
    user {
      firstName
      lastName
      email
      roles
      businessPartnerShippingAddress {
        id
      }
      businessPartner {
        name
        address
        zipCode
        city
        shippingAddresses {
          id
          name
          address
          city
          zipCode
        }
      }
    }
  }
`;

export interface ShippingAddress {
  id: number;
  name: string;
  address: string;
  city: string;
  zipCode: string;
}

export interface UserData {
  user: {
    firstName: string;
    lastName: string;
    email: string;
    businessPartnerShippingAddress: {
      id: number;
    };
    businessPartner: {
      name: string;
      address: string;
      zipCode: string;
      city: string;
      shippingAddresses: ShippingAddress[];
    };
  };
}
