import { useState } from 'react';
import { Flex, Text } from 'ui';
import {
  ToggleSwitch,
  StockStatus,
  Cell,
  Row,
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  Button,
} from 'components';
import { ReferenceNumber } from 'components/CartAndDraftsList/ReferenceNumber';
import { CartItem } from 'graphql/types/CartItem';

type ReadyToOrderProps = {
  ready?: boolean;
  data: CartItem[];
  reference?: string;
  onDelete: OnDelete;
  onMonitorItem: (id: number, currentValue: boolean, section: string) => void;
  onMonitorAll: (currentValue: boolean, id: number[], section: string) => void;
  onOrderAll: (reference: string) => void;
  monitoredAll: boolean;
  deletedItemsIds: number[];
};

type OnDelete = (id: number, section: string) => void;

export const ReadyToOrder = ({
  ready,
  data,
  reference,
  onDelete,
  onMonitorItem,
  onOrderAll,
  deletedItemsIds,
  onMonitorAll,
  monitoredAll,
}: ReadyToOrderProps) => {
  const [referenceNumber, setReferenceNumber] = useState(reference || '');
  return (
    <div>
      <Table>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell>Item code</TableHeadCell>
            <TableHeadCell>Description</TableHeadCell>
            <TableHeadCell>Qty</TableHeadCell>
            <TableHeadCell>
              List price
              <br />
              (EUR)
            </TableHeadCell>
            <TableHeadCell divider>Stock status</TableHeadCell>
            <TableHeadCell width="160px">Monitor status</TableHeadCell>
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {data?.map((item) => (
            <Row isDeleted={deletedItemsIds.includes(item.id)} key={item.id}>
              <Cell>{item.commodity.itemCode}</Cell>
              <Cell>{item.commodity.description}</Cell>
              <Cell>{item.quantity}</Cell>
              <Cell>{item.price}</Cell>
              <Cell divider>
                <StockStatus
                  status={item.commodity.stockStatus}
                  disabledLabel
                />
              </Cell>
              <Cell>
                <Flex justifyContent="space-between">
                  <ToggleSwitch
                    onChange={() =>
                      onMonitorItem(item.id, item.monitored, 'ReadyToOrder')
                    }
                    checked={item.monitored}
                  />
                  <Button
                    variant="icon"
                    onClick={() => onDelete(item.id, 'ReadyToOrder')}
                    mr="s10"
                    icon={{ color: 'gray', name: '24-delete' }}
                  />
                </Flex>
              </Cell>
            </Row>
          ))}
        </TableBody>
      </Table>
      <Flex
        mt="s20"
        alignItems="center"
        alignSelf="flex-end"
        justifyContent="flex-end"
      >
        <Text mr="s10" lineHeight="lh100">
          Monitor all
        </Text>
        <ToggleSwitch
          onClick={() =>
            onMonitorAll(
              monitoredAll,
              data.map(({ id }) => id),
              'ReadyToOrder',
            )
          }
          defaultChecked={monitoredAll}
        />
        <ReferenceNumber onChange={setReferenceNumber} />
        <Button
          onClick={() => onOrderAll(referenceNumber)}
          disabled={data.length === 0 || !ready}
          ml="s30"
          variant="primary"
        >
          Order all
        </Button>
      </Flex>
    </div>
  );
};
