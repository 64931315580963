import { useState } from 'react';
import { checkPasswordRule } from './checkPasswordRule';

export const usePasswordRules = () => {
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const slicePassword = password.slice(0, confirmPassword?.length);
  const differencePasswords =
    slicePassword === confirmPassword ? slicePassword : '';
  const disabled = !(
    checkPasswordRule(differencePasswords).success &&
    password === confirmPassword
  );

  return {
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    disabled,
    differencePasswords,
  };
};
