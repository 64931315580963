import styled, { css } from 'styled-components';
import { Box, BoxProps, Icon } from 'ui';

type TypesErorr = 'error' | 'success';

type Props = BoxProps & {
  visible?: boolean;
  children: string;
  type?: TypesErorr;
};

export const Alert = ({
  visible = true,
  children,
  type = 'error',
  ...rest
}: Props) => {
  if (!visible) {
    return null;
  }

  return (
    <Container {...rest} type={type}>
      <Icon name={type === 'error' ? '16-alert-solid' : '16-check'} mr="s12" />
      {children}
    </Container>
  );
};

const Container = styled(Box)<{ type?: TypesErorr }>`
  ${({ theme: { space, colors }, type }) => css`
    padding: ${space.s10} ${space.s20};
    margin-bottom: ${space.s20};
    display: flex;
    align-items: center;
    border-radius: 4px;
    ${type === 'error' &&
    css`
      background: #fceeef;
      color: ${colors.alert50};
    `}
    ${type === 'success' &&
    css`
      background: #e8f9e7;
      color: ${colors.success100};
    `}
  `}
`;
