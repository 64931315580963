import { FC } from 'react';
import { Box, MarginProps, PaddingProps } from 'ui';
import { Table, TableBody, Heading } from 'components';
import { OrderItem } from 'graphql/types/OrderItem';
import { RecentOrdersRow } from './RecentOrdersRow';
import { RecentOrdersHead } from './RecentOrdersHead';

type RecentOrdersProps = {
  data: OrderItem[];
  onViewOrder: (id: number[]) => void;
} & MarginProps &
  PaddingProps;

export const RecentOrders: FC<RecentOrdersProps> = ({
  data,
  onViewOrder,
  ...rest
}) => {
  return (
    <Box {...rest}>
      <Heading>Recent orders</Heading>
      <Box m="s40" mb="s50">
        <Table>
          <RecentOrdersHead />
          <TableBody>
            {data.map((order) => (
              <RecentOrdersRow
                onViewOrder={onViewOrder}
                key={order.id}
                {...order}
              />
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};
