import { icons } from 'assets';
import { VFC } from 'react';
import styled from 'styled-components';
import { color, space } from 'styled-system';
import { Box } from '../Box';
import { IconProps } from './props';

export const convertLabel = (string: string): string => {
  const label = string.substr(3).replace('-', ' ');
  return label.charAt(0).toUpperCase() + label.slice(1);
};

export const Icon: VFC<IconProps> = ({ name, ...rest }) => {
  const icon = icons[name];

  if (!icon) {
    throw new Error(`There's no icon named ${name}`);
  }

  const { size, code } = icon;

  return (
    <SVG
      forwardedAs="svg"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      xmlns="http://www.w3.org/2000/svg"
      aria-label={convertLabel(name as string)}
      {...rest}
    >
      {code}
    </SVG>
  );
};

export const SVG = styled(Box)`
  display: inline-flex;
  ${space};
  ${color};
  /* Fix rendering bugs in Chrome for hdpi */
  shape-rendering: 'inherit';
  transform: 'translate3d(0,0,0)';
`;
