import { Alert, Button, Label, TextInput } from 'components';
import { useMutationSendResetPasswordMail } from 'graphql/mutation';
import { useState } from 'react';
import { Flex, Text } from 'ui';
import { validateEmail } from 'utils';
import { Container } from './components/Container';

export const ForgotPassword = () => {
  const [error, setError] = useState(false);
  const [email, setEmail] = useState('');
  const [sendResetMail, { data }] = useMutationSendResetPasswordMail();

  const handleClick = () => {
    setError(false);
    if (validateEmail(email)) {
      sendResetMail({ variables: { email } });
    } else {
      setError(true);
    }
  };
  const handleEnter = (e?: React.KeyboardEvent<HTMLInputElement>) => {
    if (email) {
      if (e?.key === 'Enter') {
        e?.preventDefault();
        handleClick();
      }
    }
  };
  return (
    <Container>
      <Text color="gray80" fontWeight="bold" lineHeight="lh300" mb="s20" as="p">
        FORGOT YOUR PASSWORD?
      </Text>
      {data ? (
        <Alert mt="s25" type="success">
          Check your e-mail please
        </Alert>
      ) : (
        <>
          <Label>E-mail</Label>
          <TextInput
            autoFocus
            placeholder="Insert your email address to reset your password"
            icon={{ name: '20-email', color: 'gray05' }}
            value={email}
            onChange={(e) => setEmail(e?.currentTarget.value || '')}
            onKeyDown={handleEnter}
          />
          {error && <Alert mt="s15">Email incorrect</Alert>}
          <Flex justifyContent="flex-end" mt="s20" mb="s10">
            <Button variant="primary" disabled={!email} onClick={handleClick}>
              Send
            </Button>
          </Flex>
        </>
      )}
    </Container>
  );
};
