import { css } from 'styled-components';

export const fontsCss = css`
  @font-face {
    font-family: 'TT Norms';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: url('/fonts/TTNorms-Regular.woff2') format('woff2'),
      url('/fonts/TTNorms-Regular.woff') format('woff');
  }

  @font-face {
    font-family: 'TT Norms';
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src: url('/fonts/TTNorms-Medium.woff2') format('woff2'),
      url('/fonts/TTNorms-Medium.woff') format('woff');
  }

  @font-face {
    font-family: 'TT Norms';
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: url('/fonts/TTNorms-Bold.woff2') format('woff2'),
      url('/fonts/TTNorms-Bold.woff') format('woff');
  }
`;
