import { gql, QueryHookOptions, useQuery } from '@apollo/client';

export const useQuerySalesUsers = (
  options?: QueryHookOptions<SalesUsersData>,
) => useQuery<SalesUsersData>(SALES_USERS, options);

export const SALES_USERS = gql`
  query salesUsers {
    salesUsers {
      id
      name
    }
  }
`;

export type SalesUsersData = {
  salesUsers: SalesUser[];
};

export type SalesUser = {
  id: number;
  name: string;
};
