import DateRangePicker, {
  DateRangePickerProps,
} from '@wojtekmaj/react-daterange-picker';
import styled, { css } from 'styled-components';
import { Icon } from 'ui';

type DateRangeInputProps<TValue extends string | Date = string | Date> =
  {} & DateRangePickerProps<TValue>;
export type DateRange = [Date | string, Date | string] | string;

export const DateRangeInput = <TValue extends string>(
  props: DateRangeInputProps<TValue>,
) => {
  return (
    <StyledDateRangePicker
      dayPlaceholder={'dd'}
      // @ts-ignore
      monthPlaceholder={'mm'}
      yearPlaceholder={'yyyy'}
      calendarIcon={<Icon name={'20-calendar'} color={'blue'} />}
      clearIcon={<Icon name={'20-close'} color={'blue'} />}
      prevLabel={<Icon name={'20-chevron-left'} color={'blue'} />}
      prev2Label={<Icon name={'20-double-chevron-left'} color={'blue'} />}
      nextLabel={<Icon name={'20-chevron-right'} color={'blue'} />}
      next2Label={<Icon name={'20-double-chevron-right'} color={'blue'} />}
      {...props}
    />
  );
};

const StyledDateRangePicker = styled(DateRangePicker)<{
  error?: boolean;
}>`
  ${({ theme: { radii, space, borders, colors }, error }) => css`
    .react-daterange-picker__wrapper {
      border-radius: ${radii.rounded};
      border: ${borders.thinGray10};
      padding: 0 ${space.s20};
      height: 40px;
      transition: box-shadow 0.2s ease-out;

      &:hover {
        box-shadow: 0 0 0 ${space.s025} ${colors.primary20};
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0 ${space.s025} ${colors.blue};
      }

      ${error &&
      css`
        box-shadow: 0 0 0 ${space.s025} ${colors.alert50};
      `}
    }

    .react-calendar {
      border-radius: ${radii.rounded};
      border: ${borders.thinGray10};
    }

    .react-daterange-picker__calendar {
      inset: calc(100% + 4px) auto auto 0px !important;
    }

    .react-calendar__tile--active {
      background: #0071ce;
    }

    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus {
      background-color: ${colors.gray10};
    }

    .react-calendar--selectRange .react-calendar__tile--hover {
      background-color: ${colors.gray10};
    }

    .react-calendar__tile--now {
      background: ${colors.lightBlue};
    }

    .react-calendar__month-view__weekdays__weekday {
      abbr {
        text-decoration: none;
      }
    }
  `}
`;
