import { SelectInput, TextInput, ToggleSwitch } from 'components';
import { useQueryBusinessPartners, useQuerySalesUsers } from 'graphql/query';
import { useSearchParams } from 'react-router-dom';
import { Flex, Grid, Text } from 'ui';
import { useDebouncedCallback } from 'use-debounce';
import { Filters } from './types';

type UsersFiltersProps = {
  filters: Filters;
  handleFilters: (value: Partial<Filters>) => void;
};
export const UsersFilters = ({ handleFilters, filters }: UsersFiltersProps) => {
  const [searchParams] = useSearchParams();
  const paramId = searchParams.get('id') || '';
  const businessPartners = useQueryBusinessPartners();
  const querySalesUsers = useQuerySalesUsers();
  const salesUsers = querySalesUsers?.data?.salesUsers || [];
  const company =
    businessPartners.data?.businessPartners
      ?.map((item) => ({ name: item?.name, id: item?.id }))
      ?.filter(({ name }) => !!name) || [];
  const role = [
    {
      key: 'ROLE_SALES',
      label: 'Sales',
    },
    { key: 'ROLE_USER', label: 'Customer' },
  ];

  const handleSearch = useDebouncedCallback((value?: string) => {
    handleFilters({ search: value || '' });
  }, 500);

  const handleShowOnlyAssigned = () => {
    if (paramId === filters?.salesAgent?.id?.toString()) {
      handleFilters({ salesAgent: undefined });
    } else {
      handleFilters({ salesAgent: { id: parseInt(paramId, 10), name: '' } });
    }
  };

  return (
    <>
      <Flex flexDirection="column">
        <Flex mb={'s17'} alignItems="center">
          <Text mr={'s10'} fontWeight={'medium'} color={'gray80'}>
            Connected Customers
          </Text>
        </Flex>
        <TextInput
          icon={{ name: '18-search', color: 'blue' }}
          placeholder="Search users by company, name, code or email"
          type="search"
          defaultValue={filters?.search}
          onChange={(e) => handleSearch(e?.target?.value)}
        />
      </Flex>
      <Grid
        mt="s30"
        gridGap="s30"
        gridTemplateColumns={{ xs: '1fr 1fr 1fr 1fr' }}
      >
        <Flex alignItems="flex-end">
          <Flex alignItems="center">
            <ToggleSwitch
              checked={paramId === filters?.salesAgent?.id?.toString()}
              onChange={handleShowOnlyAssigned}
            />
            <Text ml="s15">Show only assigned</Text>
          </Flex>
        </Flex>
        <Flex flexDirection="column">
          <Text lineHeight="lh300" mb="s10">
            Filter by company
          </Text>
          <SelectInput
            value={filters?.company}
            onChange={(option) => handleFilters({ company: option })}
            options={company}
            placeholder="Choose company"
            labelResolver={(option) => option?.name}
            keyResolver={(option) => option?.id}
          />
        </Flex>
        <Flex flexDirection="column">
          <Text lineHeight="lh300" mb="s10">
            Filter by role
          </Text>
          <SelectInput
            value={filters?.role}
            onChange={(option) => handleFilters({ role: option })}
            options={role}
            placeholder="Choose role"
            labelResolver={(option) => option?.label}
            keyResolver={(option) => option?.key}
            disableAutocomplete
          />
        </Flex>
        <Flex flexDirection="column">
          <Text lineHeight="lh300" mb="s10">
            Sales agent
          </Text>
          <SelectInput
            value={filters?.salesAgent}
            onChange={(option) => handleFilters({ salesAgent: option })}
            options={salesUsers}
            placeholder="Choose sales agent"
            labelResolver={(option) => option?.name}
            keyResolver={(option) => option?.name}
          />
        </Flex>
      </Grid>
    </>
  );
};
