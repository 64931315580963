import styled, { css } from 'styled-components';

import { Box } from 'ui';

export const Divider = styled(Box)`
  ${({ theme: { colors, space } }) => css`
    height: ${space.s50}px;
    width: 1px;
    background: ${colors.gray10};
  `}
`;
