import { Breadcrumbs, Button, Divider } from 'components';
import { useState } from 'react';
import { paths } from 'routes';
import { Flex } from 'ui';
import { notify } from 'utils';
import {
  useMutationRemoveFromWishlist,
  useMutationSendWishlistByEmail,
} from 'graphql/mutation';
import {
  ADMIN_WISHLIST,
  useQueryAdminWishlist,
  useQueryAdminRegisteredWishlist,
} from 'graphql/query';
import { TableRegisteredStatus, TableStatus } from './components';
import { ModalAutomation } from './components/ModalAutomation';

const refetchQueries = [{ query: ADMIN_WISHLIST }];

export const Wishlist = () => {
  const [openModal, setOpenModal] = useState(false);
  const [email, setEmail] = useState('');

  const handleClose = () => setOpenModal(false);
  const handleOpen = () => setOpenModal(true);
  const { data: registeredWishlistData } = useQueryAdminRegisteredWishlist();
  const { data } = useQueryAdminWishlist();
  const [sendWishlistByEmail] = useMutationSendWishlistByEmail();
  const [removeFromWishlist] = useMutationRemoveFromWishlist();

  const handleChangeEmail = (value: string) => {
    setEmail(value);
  };

  const handleSendWishlistByEmail = () => {
    sendWishlistByEmail({
      variables: { email },
      refetchQueries,
      onCompleted: () => notify('Email sent successfully'),
      onError: (err) => notify(err.message, { type: 'error' }),
    });
  };

  const handleRemoveFromWishlist = (id: number) => {
    removeFromWishlist({ variables: { id }, refetchQueries });
  };

  return (
    <div>
      <ModalAutomation open={openModal} handleClose={handleClose} />

      <Flex justifyContent="space-between">
        <Breadcrumbs
          alignItems="baseline"
          px="s30"
          items={[
            { label: 'Home', to: '/' },
            { label: 'Wishlist Status', to: paths.main.wishlist },
          ]}
        />
        <Button
          m="s20"
          mb="s25"
          variant="outline-primary"
          onClick={handleOpen}
          icon={{ name: '20-timeline', position: 'left' }}
        >
          Wishlist automation
        </Button>
      </Flex>
      <Divider />
      <TableStatus
        email={email}
        onEmailChange={handleChangeEmail}
        onSendWishlistByEmail={handleSendWishlistByEmail}
        onRemoveFromWishlist={handleRemoveFromWishlist}
        data={data?.adminWishlist ?? []}
      />
      <TableRegisteredStatus
        data={registeredWishlistData?.adminRegisteredWishlist ?? []}
      />
    </div>
  );
};
