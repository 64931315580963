import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export const useMutationOrderItemCreateMonitoring = (
  options?: MutationHookOptions<OrderItemCreateMonitoringData>,
) =>
  useMutation<OrderItemCreateMonitoringData>(
    ORDER_ITEM_CREATE_MONITORING,
    options,
  );

export const ORDER_ITEM_CREATE_MONITORING = gql`
  mutation orderItemCreateMonitoring($id: Int, $section: String) {
    orderItemCreateMonitoring(item: { id: $id }, section: $section) {
      id
    }
  }
`;

export type OrderItemCreateMonitoringVariables = {
  id: number;
  section: string;
};

export type OrderItemCreateMonitoringData = {
  orderItemCreateMonitoring: {
    id: string;
  };
};
