import { ReactElement } from 'react';
import { canNavigate } from 'utils/canNavigate';
import { Navigate } from 'react-router-dom';
import { ROLES } from 'constants/roles';
import { useGetRole } from 'utils/useGetRole';
import { paths } from './paths';

type AuthGuardRouteProps = {
  children: ReactElement;
  allowedRoles: ROLES[];
};

export const AuthGuardRoute = ({
  children,
  allowedRoles,
}: AuthGuardRouteProps) => {
  const role = useGetRole();

  if (!role) {
    return null;
  }

  return canNavigate(role, allowedRoles) ? (
    children
  ) : (
    <Navigate replace to={paths.main.notAllowed} />
  );
};
