import styled from 'styled-components';
import { Flex, Text } from 'ui';
import { MessageLabel } from 'components/Messages/MessageLabel';
import { getTag } from 'components/Messages/utilities';
import { formatDate } from 'utils';
import { MessageDate } from './MessageDate';
import { MessageItem } from '../graphql/useQueryMessage';

interface Props extends MessageItem {
  active: boolean;
  setOpen: (value: number) => void;
}

export const Message = ({
  title,
  tag,
  updatedAt,
  setOpen,
  id,
  active,
  userRead,
}: Props) => {
  return (
    <MessageContainer
      flexDirection="column"
      p="s20"
      onClick={() => setOpen(id)}
      active={active}
    >
      <MessageMeta justifyContent="space-between">
        <MessageLabel backgroundColor={getTag(tag).color}>
          {getTag(tag).label}
        </MessageLabel>
        <MessageDate>{formatDate(updatedAt)}</MessageDate>
      </MessageMeta>
      <Text fontWeight={userRead ? 'regular' : 'bold'}>{title}</Text>
    </MessageContainer>
  );
};

export const MessageContainer = styled(Flex)<{ active?: boolean }>`
  width: 100%;
  height: 88px;
  border-bottom: ${({ theme: { borders } }) => borders.thinGray10};
  background-color: ${({ theme: { colors }, active }) =>
    active ? colors.lightBlue : 'none'};
  &:hover {
    cursor: pointer;
  }
`;

export const MessageMeta = styled(Flex)`
  width: 100%;
  height: 32px;
`;
