import { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Icon, Text } from 'ui';
import { TextInput } from 'components';
import { useOnClickOutside } from 'utils/useOnClickOutside';

type SelectInputProps<T = any> = {
  placeholder?: string;
  options: readonly T[];
  value?: T;
  error?: boolean;
  onChange: (value?: T) => void;
  labelResolver: (value?: T) => string | undefined;
  keyResolver: (value?: T) => string | number | undefined;
  disableClearable?: boolean;
  disabled?: boolean;
  disableAutocomplete?: boolean;
};

export const SelectInput = <T,>({
  placeholder,
  options,
  onChange,
  value,
  error,
  labelResolver,
  keyResolver,
  disableClearable = false,
  disabled = false,
  disableAutocomplete = false,
}: SelectInputProps<T>) => {
  const [valueInput, setValueInput] = useState(labelResolver(value));
  const [showDropdown, setShowDropdown] = useState(false);
  const showDropdownHandler = () => setShowDropdown(!showDropdown);
  const selectContainerRef = useRef(null);

  const clickOutsideHandler = () => {
    setValueInput(labelResolver(value));
    setShowDropdown(false);
  };

  useOnClickOutside(selectContainerRef, clickOutsideHandler);

  const updateSelectedOption = useCallback(
    (option: any) => {
      onChange?.(option);
      setValueInput(labelResolver(option));
      setShowDropdown(false);
    },
    [labelResolver, onChange],
  );

  useEffect(() => {
    updateSelectedOption(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleSearch = useCallback(
    (data: readonly T[]) => {
      const isSelect = valueInput === labelResolver(value);

      if (!disableAutocomplete && !isSelect) {
        try {
          return data.filter(
            (item) =>
              labelResolver(item)
                ?.toLowerCase()
                ?.search(valueInput?.toLowerCase() || '') !== -1,
          );
        } catch {
          return [];
        }
      }
      return data;
    },
    [disableAutocomplete, labelResolver, value, valueInput],
  );

  const selectOptions = useMemo(
    () => handleSearch(options),
    [handleSearch, options],
  );

  return (
    <SelectContainer ref={selectContainerRef} error={error} disabled={disabled}>
      <TextInput
        readOnly={disableAutocomplete}
        placeholder={placeholder}
        value={valueInput ?? ''}
        onClick={showDropdownHandler}
        icon={{
          name: '16-arrowhead',
          color: 'gray80',
          space: 's10',
          rotate: showDropdown ? 0 : 180,
        }}
        onChange={(e) => setValueInput(e?.target?.value)}
      />
      {!disableClearable && showDropdown && value && (
        <ResetButton
          type="button"
          onClick={() => updateSelectedOption(undefined)}
        >
          <Icon name="20-close" color="gray40" />
        </ResetButton>
      )}
      {showDropdown && selectOptions?.length > 0 && (
        <SelectOptions>
          {selectOptions.map((option) => (
            <SelectOption
              className="select-option"
              key={keyResolver(option)}
              onClick={() => updateSelectedOption(option)}
            >
              <Text lineHeight="lh100">{labelResolver(option)}</Text>
            </SelectOption>
          ))}
        </SelectOptions>
      )}
    </SelectContainer>
  );
};

const SelectContainer = styled.div<{ error?: boolean; disabled?: boolean }>`
  ${({ theme: { radii, space, colors }, error, disabled }) => css`
    border-radius: ${radii.rounded};
    position: relative;
    width: 100%;

    input[readonly] {
      cursor: pointer;
    }

    ${
      error &&
      css`
        box-shadow: 0 0 0 ${space.s025} ${colors.alert50};
      `
    }
    ${
      disabled &&
      css`
        opacity: 0.5;
        pointer-events: none;
      `
    }


    }
  `}
`;

const SelectOptions = styled.div`
  max-height: 260px;
  overflow-y: auto;
  position: absolute;
  width: 100%;
  z-index: 999;

  ${({ theme: { colors, space, radii, borders } }) => css`
    background: ${colors.white};
    border-radius: ${radii.rounded};
    border: ${borders.thinGray10};
    top: calc(100% + ${space.s10});
  `};
`;

const SelectOption = styled.div`
  border-bottom: ${({ theme: { borders } }) => borders.thinGray10};
  cursor: pointer;
  list-style-type: none;
  padding: 6px 16px;

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.gray10};
  }

  &:last-child {
    border-bottom: none;
  }
`;

const ResetButton = styled.button`
  position: absolute;
  right: 25px;
  top: 10px;
  &:hover {
    cursor: pointer;
    svg {
      color: ${({ theme: { colors } }) => colors.gray80};
    }
  }
`;
