import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { User } from 'graphql/types/User';

export const useQueryUser = (options?: QueryHookOptions<UserData>) =>
  useQuery<UserData>(USER, options);

export const USER = gql`
  query user {
    user {
      firstName
      lastName
      email
      roles
      id
      createOrdersPermission
      downloadFilesPermission
      businessPartnerShippingAddress {
        id
      }
      businessPartner {
        name
        address
        zipCode
        city
        shippingAddresses {
          id
          name
          address
          city
          zipCode
        }
      }
    }
  }
`;

export type UserData = {
  user: User;
};
