import { Box, Text } from 'ui';
import styled, { css } from 'styled-components';
import { useMutationNewsReadLog } from 'graphql/mutation';
import { useArticles } from './hooks/useArticles';

export const News = () => {
  const { artilces, getPath } = useArticles('news');
  const [newsReadLog] = useMutationNewsReadLog();
  return (
    <Container>
      {artilces?.map((item) => {
        const { slug, title, lead, photoAlt, photoDownloadUrl } = item;
        return (
          <Item
            role="presentation"
            key={slug}
            onClick={() => {
              newsReadLog({ variables: { title } });
              window.open(getPath(slug), '_blank');
            }}
          >
            <Image
              src={photoDownloadUrl}
              alt={photoAlt}
              width="100%"
              height={144}
            />
            <Text lineHeight="lh300" as="p">
              {title}
            </Text>
            <Text fontSize="fs100" lineHeight="lh300" color="gray80" as="p">
              {lead?.slice(0, 50)}
            </Text>
          </Item>
        );
      })}
    </Container>
  );
};

const Image = styled('img')`
  object-fit: cover;

  ${({ theme: { space } }) => css`
    margin-bottom: ${space.s10};
  `};
`;
const Item = styled.div`
  width: 212px;
  cursor: pointer;
`;

const Container = styled(Box)`
  display: inline-flex;
  flex-wrap: wrap;

  ${({ theme: { space } }) => css`
    gap: ${space.s30};
  `};
`;
