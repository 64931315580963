import styled, { css } from 'styled-components';

import { Box } from 'ui';

export const Label = styled(Box).attrs({ as: 'p' })`
  ${({ theme: { space, lineHeights } }) => css`
    margin-bottom: ${space.s10};
    line-height: ${lineHeights.lh300};
  `};
`;
