import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { User } from 'graphql/types/User';

export const useQuerySimilarUsers = (
  options?: QueryHookOptions<SimilarUsersData, SimilarUsersVariables>,
) => useQuery<SimilarUsersData, SimilarUsersVariables>(SIMILAR_USERS, options);

export const SIMILAR_USERS = gql`
  query similarUsers(
    $offset: Int
    $orderBy: String!
    $orderDir: String
    $limit: Int
    $role: String
  ) {
    users {
      items(role: $role) {
        id
        name
        businessPartner {
          code
        }
      }
    }
  }
`;

export type SimilarUsersVariables = {
  orderBy: string;
  orderDir?: 'ASC' | 'DESC';
  limit?: number;
  offset?: number;
  role?: string;
};

export type SimilarUsersData = {
  users: {
    items: User[];
  };
};
