import {
  Button,
  Label,
  TextInput,
  PasswordRules,
  usePasswordRules,
  Alert,
} from 'components';
import { useMutationChangePassword } from 'graphql/mutation';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Flex, Text } from 'ui';
import { useMe } from 'utils';
import { Container } from './components/Container';

export const CreateNewPassword = () => {
  const { email } = useMe() || {};
  const [oldPassword, setOldPassword] = useState('');
  const navigate = useNavigate();
  const {
    disabled,
    differencePasswords,
    confirmPassword,
    password,
    setConfirmPassword,
    setPassword,
  } = usePasswordRules();

  useEffect(() => {
    if (!email) {
      navigate('/');
    }
  }, [email, navigate]);

  const [changePassword, { loading, error }] = useMutationChangePassword({
    onCompleted: () => navigate('/'),
  });

  const handleSave = () => {
    if (email && password && oldPassword) {
      changePassword({
        variables: { email, newPassword: password, oldPassword },
      });
    }
  };

  const handleEnter = (e?: React.KeyboardEvent<HTMLInputElement>) => {
    if (!disabled) {
      if (e?.key === 'Enter') {
        e?.preventDefault();
        handleSave();
      }
    }
  };

  return (
    <Container>
      <Text color="gray80" fontWeight="bold" lineHeight="lh300" mb="s20" as="p">
        CREATE NEW PASSWORD
      </Text>
      <Label>Old password</Label>
      <TextInput
        autoFocus
        placeholder="Insert your password"
        icon={{ name: '20-lock', color: 'gray05' }}
        type="password"
        value={oldPassword}
        onChange={(e) => setOldPassword(e?.currentTarget?.value || '')}
      />
      <Box mt="s20">
        <Label>New password</Label>
        <TextInput
          placeholder="Insert your password"
          icon={{ name: '20-lock', color: 'gray05' }}
          type="password"
          value={password}
          onChange={(e) => setPassword(e?.currentTarget?.value || '')}
        />
      </Box>
      <Box mt="s20">
        <Label>Confirm your new password</Label>
        <TextInput
          placeholder="Insert your password"
          icon={{ name: '20-lock', color: 'gray05' }}
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e?.currentTarget?.value || '')}
          onKeyDown={handleEnter}
        />
      </Box>
      {error && <Alert mt="s25">Old password is incorrect</Alert>}
      <Flex
        justifyContent="space-between"
        alignItems="flex-end"
        mt="s35"
        mb="s10"
      >
        <PasswordRules password={differencePasswords} />
        <Button variant="primary" disabled={disabled} onClick={handleSave}>
          {loading ? 'Saving...' : 'Save'}
        </Button>
      </Flex>
    </Container>
  );
};
