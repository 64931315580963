import { useMemo } from 'react';
import { Flex, Grid } from 'ui';
import { Breadcrumbs, Panel, AddProduct, Upload } from 'components';
import { paths } from 'routes';
import { notify } from 'utils';

import { useQueryImport, useQuerySearchCommodities } from 'graphql/query';
import {
  useMutationImportCsvToImport,
  useMutationAddToImport,
  useMutationClearImport,
} from 'graphql/mutation';
import { Commodity } from 'graphql/types/Commodity';
import type { CommodityStock } from 'graphql/types/CommodityStock';
import { SingleValue } from 'react-select';
import { ERPStatuses } from 'components/ERPStatus/convertStatus';
import { ItemsResults } from './components';

export const ItemStatus = () => {
  const { data: importData, refetch } = useQueryImport();

  const getStock = (stocks: CommodityStock[], source: string) => {
    const data = stocks.find((item) => item.source === source);
    return data?.stock ? data?.stock : 0;
  };

  const getStocks = (stocks: CommodityStock[], source: string) => {
    const data = stocks.filter((item) => item.source === source);
    return data;
  };

  const getErpStatus = (internal: boolean, sellable: boolean) => {
    let status;
    if (internal && sellable) {
      status = 'success';
    } else if (internal) {
      status = 'error';
    } else {
      status = 'warning';
    }
    return status as ERPStatuses;
  };

  const importItemsTransformedData = useMemo(
    () =>
      importData?.import.items.map((item) => ({
        id: item.id.toString(),
        boQty: 0,
        description: item.commodity.description,
        erpStatus: getErpStatus(
          item.commodity.internal,
          item.commodity.sellable,
        ),
        itemCode: item.commodity.itemCode,
        jceStockStatus: getStock(item.commodity.stocks, 'JCE'),
        otherSubisdiaryStock: getStock(item.commodity.stocks, 'SD'),
        pcStockStatus: getStock(item.commodity.stocks, 'PC'),
        replacementStatus:
          item.commodity.replacements.length > 0
            ? 'Replacement available'
            : 'Replacement unavailable',
        replacements: item.commodity.replacements,
        subsidiaryStocks: getStocks(item.commodity.stocks, 'SD'),
        purchases: item.commodity.purchases,
        commodity: item.commodity,
      })),
    [importData],
  );

  const [importCsvToImport, { loading: uploadLoading }] =
    useMutationImportCsvToImport();
  const [addToImport] = useMutationAddToImport();
  const [clearImport] = useMutationClearImport();

  const handleUploadedFileChange = (file: File[]) => {
    importCsvToImport({
      variables: { file: file[0] },
      onCompleted: (data) => {
        // eslint-disable-next-line no-console
        console.log(data);
      },
      onError: () => {
        notify('Cannot import file.', { type: 'error' });
      },
    });
  };

  const [searchCommodities] = useQuerySearchCommodities();

  const handleSubmit = (
    autocompleteData: SingleValue<Commodity> | undefined,
  ) => {
    if (autocompleteData?.id)
      addToImport({
        variables: { commodityId: autocompleteData?.id },
        onCompleted: () => {
          refetch();
        },
      });
  };

  const handleLoad = async (value: string) => {
    const options = await searchCommodities({
      variables: { phrase: value },
    });

    return options.data?.searchCommodities ?? [];
  };

  const handleClearImport = () => {
    clearImport({
      onCompleted: () => {
        refetch();
      },
    });
  };

  return (
    <Grid gridTemplateRows={'40px 1fr'}>
      <Breadcrumbs
        px="s30"
        items={[
          { label: 'Home', to: paths.base },
          { label: 'Item status', to: paths.main.uploadOrder },
        ]}
      />

      <Flex flexDirection="column">
        <Panel label={'Import file'}>
          <Upload onChange={handleUploadedFileChange} loading={uploadLoading} />
        </Panel>
        <Panel label={'Search for items'}>
          <AddProduct onLoad={handleLoad} onSubmit={handleSubmit} mb="s40" />
        </Panel>
        <Panel label={'Import & search results'}>
          <ItemsResults
            data={importItemsTransformedData}
            onClearCart={handleClearImport}
          />
        </Panel>
      </Flex>
    </Grid>
  );
};
