import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export function useMutationMarkAsRead(
  options?: MutationHookOptions<MarkAsReadData, MarkAsReadVariables>,
) {
  const data = useMutation<MarkAsReadData, MarkAsReadVariables>(
    MARK_AS_READ,
    options,
  );
  return data;
}

export const MARK_AS_READ = gql`
  mutation ($messageId: String!) {
    markAsRead(messageId: $messageId) {
      id
      userRead
    }
  }
`;

export type MarkAsReadVariables = {
  messageId: string;
};

export type MarkAsReadData = {
  markAsRead: {
    id: string;
    userRead: boolean;
  };
};
