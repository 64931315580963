import { useNavigate } from 'react-router-dom';

export const useViewOrder = (path: string) => {
  const navigate = useNavigate();

  const getPathValidate = (value: string) => `${path}?expandedRowIds=${value}`;

  const handleViewOrder = (item: any) => {
    const encodedItemsId = encodeURIComponent(JSON.stringify(item));

    navigate(getPathValidate(encodedItemsId));
  };

  return { handleViewOrder };
};
