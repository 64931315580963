import { Button, NumericInput } from 'components';
import { Flex, Icon, Text } from 'ui';
import {
  Cell,
  Row,
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TableHeadRow,
} from 'components/FlexTable/Table.styled';
import { CartItem } from 'graphql/types/CartItem';

type OrdersPreviewProps = {
  data: CartItem[];
  onDelete: (id: number) => void;
  onProceedOrder: () => void;
  onClearCart: () => void;
};

export const OrderPreview = ({
  data,
  onDelete,
  onProceedOrder,
  onClearCart,
}: OrdersPreviewProps) => {
  const proceedOrders = data.find((item) => item.wishlist === false);

  return data.length > 0 ? (
    <Flex mb="s50" alignItems="flex-end" flexDirection="column" flex="1">
      <Flex mb="s30">
        <Button
          onClick={onProceedOrder}
          variant="primary"
          disabled={!proceedOrders}
        >
          Validate
        </Button>
        <Button onClick={onClearCart} variant="text" ml="s30">
          Clear all
        </Button>
      </Flex>
      <Table>
        <YourOrdersHead />
        <TableBody>
          {data.map((order) => (
            <YourOrdersRow onDelete={onDelete} key={order.id} {...order} />
          ))}
        </TableBody>
      </Table>
      <Flex mt="s30">
        <Button
          onClick={onProceedOrder}
          variant="primary"
          disabled={!proceedOrders}
        >
          Validate
        </Button>
        <Button onClick={onClearCart} variant="text" ml="s30">
          Clear all
        </Button>
      </Flex>
    </Flex>
  ) : (
    <EmptyState />
  );
};

export const EmptyState = () => (
  <Flex flex="1" alignItems="center" flexDirection="column">
    <Icon mt="s40" color="gray10" name="40-listUnordered" />
    <Text mt="s20" lineHeight="lh150">
      Your order is empty, add items by uploader or search for single product
    </Text>
  </Flex>
);

type YourOrdersRowProps = { onDelete: (id: number) => void } & OrdersData;

export const YourOrdersRow = ({
  quantity,
  commodity: { itemCode },
  id,
  onDelete,
}: YourOrdersRowProps) => (
  <Row>
    <Cell flex={136}>{itemCode}</Cell>
    <Cell flex={846}>
      <NumericInput
        // eslint-disable-next-line no-console
        onIncrement={(value) => console.log(value)}
        // eslint-disable-next-line no-console
        onDecrement={(value) => console.log(value)}
        value={quantity}
      />
    </Cell>
    <Cell flex={104}>
      <Flex justifyContent="flex-end" flex={1}>
        <Button
          variant="icon"
          mr="s20"
          onClick={() => onDelete(id)}
          icon={{ color: 'gray', name: '24-delete' }}
        />
      </Flex>
    </Cell>
  </Row>
);

export const YourOrdersHead = () => (
  <TableHead>
    <TableHeadRow>
      <TableHeadCell flex={136}>Item code</TableHeadCell>
      <TableHeadCell flex={846}>Quantity</TableHeadCell>
      <TableHeadCell flex={104} />
    </TableHeadRow>
  </TableHead>
);

export type OrdersData = CartItem;
