import { HeaderAuth } from 'components';
import { Outlet } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const AuthLayout = () => (
  <LayoutWrapper>
    <HeaderAuth />
    <Content>
      <Outlet />
    </Content>
  </LayoutWrapper>
);

const LayoutWrapper = styled.div`
  height: 100%;
  min-height: 100vh;
  background: #e3edf6;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  ${({ theme: { space } }) => css`
    padding-top: ${space.s80};
  `}
`;
