import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export const useMutationCartItemRemoveMonitoring = (
  options?: MutationHookOptions<
    CartItemRemoveMonitoringData,
    CartItemRemoveMonitoringVariables
  >,
) =>
  useMutation<CartItemRemoveMonitoringData, CartItemRemoveMonitoringVariables>(
    CART_ITEM_REMOVE_MONITORING,
    options,
  );

export const CART_ITEM_REMOVE_MONITORING = gql`
  mutation cartItemRemoveMonitoring($id: Int, $section: String) {
    cartItemRemoveMonitoring(item: { id: $id }, section: $section) {
      id
    }
  }
`;

export type CartItemRemoveMonitoringVariables = { id: number; section: string };

export type CartItemRemoveMonitoringData = {
  cartItemRemoveMonitoring: {
    id: string;
  };
};
