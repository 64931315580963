import { Button, UploadInput } from 'components';
import { Flex, Text } from 'ui';
import { Link } from 'react-router-dom';

type UploadProps = {
  onChange: (value: File[]) => void;
  loading?: boolean;
  clear?: boolean;
};

export const Upload = (props: UploadProps) => (
  <Flex flex="1" flexDirection="column">
    <UploadInput
      maxFiles={1}
      accept={'.csv, application/vnd.ms-excel, text/csv'}
      {...props}
    />
    <Flex mb="s37" justifyContent="space-between">
      <Text mt="s30" color="black" fontSize="fs200" lineHeight="lh150">
        Use this sample form to make your orders easier and faster
      </Text>
      <Flex mt="s10" alignItems="center">
        <Link to="/sample-order.csv" target="_blank" download>
          <Button
            mr="s50"
            icon={{ position: 'left', color: 'blue', name: '16-document' }}
            variant="text"
          >
            Sample order CSV
          </Button>
        </Link>
        <Link to="/how-to.pdf" target="_blank" download>
          <Button
            mr="s15"
            icon={{ position: 'left', color: 'blue', name: '16-document' }}
            variant="text"
          >
            How to prepare order document PDF
          </Button>
        </Link>
      </Flex>
    </Flex>
  </Flex>
);
