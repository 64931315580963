import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export const useMutationCartMonitorAll = (
  options?: MutationHookOptions<CartMonitorAllData, CartMonitorAllVariables>,
) =>
  useMutation<CartMonitorAllData, CartMonitorAllVariables>(
    CART_MONITOR_ALL,
    options,
  );

export const CART_MONITOR_ALL = gql`
  mutation cartMonitorAll($monitored: Boolean) {
    cartMonitorAll(monitored: $monitored) {
      id
      monitored
      items {
        monitored
        id
      }
    }
  }
`;

export type CartMonitorAllVariables = {
  monitored: boolean;
};

export type CartMonitorAllData = {
  cartMonitorAll: {
    id: string;
  };
};
