import { FormEvent, useState } from 'react';

export type Validation = {
  required?: {
    value: boolean;
    message: string;
  };
  pattern?: {
    value: string;
    message: string;
  };
  custom?: {
    isValid: (value: string) => boolean;
    message: string;
  };
};

export type ErrorRecord<T> = Partial<Record<keyof T, string>>;

type Validations<T extends {}> = Partial<Record<keyof T, Validation>>;

export type Form<T> = {
  data: T;
  errors: ErrorRecord<T>;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => any;
  handleChange: <Key extends keyof T, Value extends T[Key]>(
    key: Key,
    value?: Value,
  ) => void;
};

export const useForm = <T extends Record<keyof T, any> = {}>(options?: {
  validations?: Validations<T>;
  initialValues?: Partial<T>;
  onSubmit?: () => void;
}) => {
  const [data, setData] = useState<T>((options?.initialValues || {}) as T);
  const [errors, setErrors] = useState<ErrorRecord<T>>({});

  const handleChange = <Key extends keyof T, Value extends T[Key]>(
    key: Key,
    value?: Value,
  ) => {
    setData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const reset = () => {
    setData((options?.initialValues || {}) as T);
    setErrors({});
  };

  const handleSubmit = async (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    const validations = options?.validations;
    if (validations) {
      let valid = true;
      const newErrors: ErrorRecord<T> = {};

      for (const key in validations) {
        if (Object.prototype.hasOwnProperty.call(validations, key)) {
          const value = data[key];
          const validation = validations[key];
          if (validation?.required?.value && !value) {
            valid = false;
            newErrors[key] = validation?.required?.message;
          }

          const pattern = validation?.pattern;
          if (pattern?.value && !RegExp(pattern.value).test(value)) {
            valid = false;
            newErrors[key] = pattern.message;
          }

          const custom = validation?.custom;
          if (custom?.isValid && !custom.isValid(value)) {
            valid = false;
            newErrors[key] = custom.message;
          }
        }
      }

      if (!valid) {
        setErrors(newErrors);
        return newErrors;
      }
    }

    setErrors({});

    if (options?.onSubmit) options.onSubmit();
  };

  return {
    data,
    handleChange,
    setData,
    handleSubmit,
    errors,
    reset,
  };
};
