import {
  Alert,
  Button,
  Label,
  SelectInput,
  TextArea,
  TextInput,
} from 'components';
import { SendRegistrationVariables } from 'graphql/mutation';
import useMutationSendRegistration from 'graphql/mutation/useMutationSendRegistration';
import { useQueryCountriesList } from 'graphql/query';
import { useState } from 'react';
import styled, { css } from 'styled-components';
import { Box, Flex, Text } from 'ui';
import { useForm } from 'utils/useForm';

export const Register = () => {
  const [success, setSuccess] = useState<boolean>();
  const countriesData = useQueryCountriesList();
  const countriesList = countriesData.data?.countriesList || [];
  const {
    handleChange,
    handleSubmit,
    reset,
    errors,
    data: { companyName, country, email, message, phoneNumber },
  } = useForm<SendRegistrationVariables>({
    initialValues: {
      companyName: '',
      country: '',
      email: '',
      message: '',
      phoneNumber: '',
    },
    validations: {
      companyName: {
        required: {
          value: true,
          message: 'Company Name is required',
        },
      },
      country: {
        required: {
          value: true,
          message: 'Country is required',
        },
      },
      email: {
        required: {
          value: true,
          message: 'Email is required',
        },
      },
      message: {
        required: {
          value: true,
          message: 'Message is required',
        },
      },
      phoneNumber: {
        required: {
          value: true,
          message: 'Phone number is required',
        },
      },
    },
  });

  const [registration, { loading }] = useMutationSendRegistration({
    onCompleted: () => {
      setSuccess(true);
      reset();
    },
    onError: () => {
      setSuccess(false);
    },
  });

  const handleSend = () => {
    handleSubmit().then((data) => {
      if (!data) {
        registration({
          variables: { companyName, country, email, message, phoneNumber },
        });
      }
    });
  };

  return (
    <Container>
      <Text color="gray80" fontWeight="bold" lineHeight="lh300" mb="s10" as="p">
        JOIN JUKI CUSTOMER PORTAL
      </Text>
      <Text>
        Send your inquire on joining Customer Portal. Our Sales <br />
        Department will confirm your account or contact for details.
      </Text>
      <Grid>
        <div>
          <Label>Company name</Label>
          <TextInput
            placeholder="Insert your company name"
            value={companyName}
            onChange={(e) =>
              handleChange('companyName', e?.currentTarget.value)
            }
            error={!!errors?.companyName}
          />
        </div>
        <div>
          <Label>Country</Label>
          <SelectInput
            value={country}
            onChange={(data) => handleChange('country', data)}
            options={countriesList}
            placeholder="Select country"
            labelResolver={(option) => option}
            keyResolver={(option) => option}
            error={!!errors?.country}
          />
        </div>
        <div>
          <Label>Phone number</Label>
          <TextInput
            placeholder="Insert your phone number"
            value={phoneNumber}
            type="number"
            onChange={(e) =>
              handleChange('phoneNumber', e?.currentTarget.value)
            }
            error={!!errors?.phoneNumber}
          />
        </div>
        <div>
          <Label>E-mail</Label>
          <TextInput
            placeholder="Insert your e-mail"
            type="email"
            value={email}
            onChange={(e) => handleChange('email', e?.currentTarget.value)}
            error={!!errors?.email}
          />
        </div>
      </Grid>
      <div>
        <Label>Message</Label>
        <StyleTextArea
          rows={3}
          placeholder="Insert your message"
          value={message}
          onChange={(e) => handleChange('message', e?.currentTarget.value)}
          error={!!errors?.message}
        />
      </div>

      {Object.keys(errors).length !== 0 && (
        <Box mt="s20">
          <Alert visible={!!errors}>Please fill in the required fields</Alert>
        </Box>
      )}

      {success !== undefined && (
        <Box mt="s20">
          <Alert type={success ? 'success' : 'error'}>
            {success ? 'The request was sent successfully' : 'Error occurred'}
          </Alert>
        </Box>
      )}

      <Flex justifyContent="flex-end" mt="s20" mb="s10">
        <Button variant="primary" onClick={handleSend}>
          {loading ? 'Sending...' : 'Send request'}
        </Button>
      </Flex>
    </Container>
  );
};

const StyleTextArea = styled(TextArea)`
  ${({ theme: { space } }) => css`
    padding: ${space.s10} ${space.s20};
  `}
`;

const Grid = styled.div`
  ${({ theme: { space } }) => css`
    display: grid;
    gap: ${space.s20} ${space.s35};
    grid-template-columns: 1fr 1fr;
    margin-top: ${space.s30};
    margin-bottom: ${space.s20};
  `}
`;

const Container = styled.div`
  ${({ theme: { space } }) => css`
    background: white;
    width: 684px;
    padding: ${space.s30};
    margin: ${space.s20};
  `}
`;
