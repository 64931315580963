import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export function useMutationUpdateCartReference(
  options?: MutationHookOptions<
    UpdateCartReferenceVariables,
    UpdateCartReferenceVariables
  >,
) {
  const data = useMutation<
    UpdateCartReferenceVariables,
    UpdateCartReferenceVariables
  >(UPDATE_CART_REFERENCE, options);
  return data;
}

export const UPDATE_CART_REFERENCE = gql`
  mutation updateCartReference($reference: String!) {
    updateCartReference(reference: $reference) {
      id
    }
  }
`;

export type UpdateCartReferenceVariables = {
  reference: string;
};

export type UpdateCartReferenceData = {
  updateCartReference: {
    id: string;
  };
};
