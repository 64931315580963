import { Button, Cell, Row } from 'components';
import { OrderStatus } from 'components/OrderStatus';
import { ReferenceNumber } from 'components/ReferenceNumber';
import { OrderItem } from 'graphql/types/OrderItem';
import { useState } from 'react';
import { Flex } from 'ui';
import { formatDate } from 'utils';

type RecentOrdersRowProps = {
  onViewOrder: (id: number[]) => void;
} & OrderItem;

export const RecentOrdersRow = ({
  createdAt,
  onViewOrder,
  order,
}: RecentOrdersRowProps) => {
  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);

  return (
    <Row onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Cell>{order.documentNumber}</Cell>
      <Cell>{formatDate(createdAt)}</Cell>
      <Cell>
        <ReferenceNumber reference={order.reference ?? ''} />
      </Cell>
      <Cell>
        <OrderStatus status={order.status} showLabel />
      </Cell>
      <Cell>
        {hover && (
          <Flex justifyContent="flex-end">
            <Button
              onClick={() =>
                onViewOrder(order.items.map(({ id: itemId }) => itemId))
              }
              variant="outline-primary"
              size="small"
            >
              View order
            </Button>
          </Flex>
        )}
      </Cell>
    </Row>
  );
};
