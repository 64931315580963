import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { Roles } from 'graphql/types/Roles';

export function useQueryUserById(
  options?: QueryHookOptions<UserByIdData, UserByIdVariables>,
) {
  const data = useQuery<UserByIdData, UserByIdVariables>(USER_BY_ID, options);
  return data;
}

export const USER_BY_ID = gql`
  query userById($id: String!) {
    userById(id: $id) {
      id
      name
      userName
      email
      roles
      enabled
      firstName
      lastName
    }
  }
`;

export type UserByIdVariables = {
  id: string;
};

export type UserByIdData = {
  userById: {
    id: number;
    name: string;
    userName: string;
    email: string;
    roles: Roles[];
    enabled: boolean;
    firstName: string;
    lastName: string;
  };
};
