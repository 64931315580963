import {
  Button,
  Label,
  Modal,
  SelectInput,
  TextInput,
  UploadInput,
} from 'components';
import { optionsTag } from 'components/Messages/utilities';
import { CloseButton, File } from 'components/UploadInput/UploadInput';
import {
  EditMessageVariables,
  useMutationAddAttachment,
  useMutationAddMessage,
  useMutationEditMessage,
  useMutationRemoveAttachment,
  useMutationRemoveMessage,
} from 'graphql/mutation';
import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import styled, { css } from 'styled-components';
import { Box, Flex, Icon } from 'ui';
import { useForm } from 'utils/useForm';
import { ActionsBar } from './ActionsBar';
import { optionsChannel, optionsReceiver, validations } from './utilities';
import 'react-quill/dist/quill.snow.css';
import { MessageItem } from '../graphql/useQueryMessage';

interface Props {
  open: boolean;
  handleClose: () => void;
  handleCloseAfterDeleted?: () => void;
  refetch?: () => void;
  type: 'create' | 'edit' | null;
  dataEdit?: MessageItem;
}

interface AttachmentsUploaded {
  id: string;
  name: string;
  remove: boolean;
}

export const MailingCreate = ({
  open,
  handleClose,
  refetch,
  type,
  dataEdit,
  handleCloseAfterDeleted,
}: Props) => {
  const [attachments, setAttachments] = useState<File[]>([]);
  const [attachmentsUploaded, setAttachmentsUploaded] = useState<
    AttachmentsUploaded[]
  >([]);

  const {
    handleChange,
    handleSubmit,
    setData,
    reset,
    errors,
    data: { channel, content, receiver, tag, title, id },
  } = useForm<EditMessageVariables>({
    initialValues: {
      channel: '',
      content: '',
      receiver: '',
      tag: '',
      title: '',
      id: '',
    },
    validations,
  });

  // Reset form for create message
  useEffect(() => {
    if (open && type === 'create') {
      reset();
      setAttachments([]);
      setAttachmentsUploaded([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  // Update form for edit message
  useEffect(() => {
    if (dataEdit) {
      setData({ ...(dataEdit as any) });
      setAttachmentsUploaded([
        ...dataEdit.attachments.map((item) => ({ ...item, remove: false })),
      ]);
    }
  }, [dataEdit, setData, type]);

  const handleAttachmentsUploaded = (idAttachment: string) => {
    setAttachmentsUploaded((prev) => {
      const result = [...prev];
      const searchIndex = result.findIndex((item) => item.id === idAttachment);
      result[searchIndex].remove = true;
      return result;
    });
  };

  const [addAttachment, { loading: loadingAddAttachment }] =
    useMutationAddAttachment();

  const [removeAttachment, { loading: loadingRemoveAttachment }] =
    useMutationRemoveAttachment();

  const handleAddAttachments = async (messageId: string) => {
    await Promise.all(
      attachments.map((file) =>
        addAttachment({
          variables: { file, messageId: `${messageId}` },
          update: (cache, addedAttachment) => {
            const normalizedId = cache.identify({
              id: messageId,
              __typename: 'Message',
            });
            cache.modify({
              id: normalizedId,
              fields: {
                attachments(data: any[]) {
                  return [addedAttachment?.data?.addAttachment, ...data];
                },
              },
            });
          },
        }),
      ),
    );
    setAttachments([]);
  };

  const handleRemoveAttachments = async () => {
    await Promise.all(
      attachmentsUploaded
        .filter((item) => item.remove)
        .map((item) =>
          removeAttachment({
            variables: { attachmentId: `${item.id}` },
            update(cache) {
              const normalizedId = cache.identify({
                id: `${item.id}`,
                __typename: 'Attachment',
              });
              cache.evict({ id: normalizedId });
              cache.gc();
            },
          }),
        ),
    );
    setAttachmentsUploaded([]);
  };

  const [addMessage, { loading: loadingAddMessage }] = useMutationAddMessage({
    onCompleted: ({ addMessage: { id: messageId } }) => {
      handleAddAttachments(messageId);
      reset();
      handleClose();
      if (refetch) refetch();
    },
  });

  const [editMessage, { loading: loadingEdit }] = useMutationEditMessage({
    onCompleted: async ({ editMessage: { id: messageId } }) => {
      await handleAddAttachments(messageId);
      await handleRemoveAttachments();
      reset();
      handleClose();
    },
  });

  const [removeMessage, { loading: loadingRemoveMessage }] =
    useMutationRemoveMessage({
      onCompleted() {
        reset();
        if (handleCloseAfterDeleted) handleCloseAfterDeleted();
        if (refetch) refetch();
      },
    });

  const handleSend = () => {
    handleSubmit().then((data) => {
      if (!data) {
        if (type === 'create') {
          addMessage({
            variables: { channel, content, receiver, tag, title },
          });
        } else {
          editMessage({
            variables: { channel, content, receiver, tag, title, id: `${id}` },
          });
        }
      }
    });
  };

  const titleModal = type === 'create' ? 'CREATE MAILING' : 'EDIT MAILING';

  const loading =
    open &&
    (loadingAddMessage ||
      loadingEdit ||
      loadingAddAttachment ||
      loadingRemoveAttachment);

  const filesToRemove = attachmentsUploaded.filter((item) => !item.remove);

  return (
    <Modal
      handleClose={handleClose}
      open={open}
      title={titleModal}
      actions={
        <ActionsBar>
          {type === 'edit' && (
            <Button
              variant="outline-primary"
              disabled={!id}
              loading={loadingRemoveMessage}
              onClick={() => removeMessage({ variables: { id: `${id}` } })}
            >
              {loadingRemoveMessage ? 'Deleting' : 'Delete'}
            </Button>
          )}
          <Button loading={loading} variant="primary" onClick={handleSend}>
            {loading ? 'Sending' : 'Send message'}
          </Button>
        </ActionsBar>
      }
    >
      <Container>
        <ContainerInputs>
          <div>
            <Label>Message title</Label>
            <TextInput
              placeholder="Insert message title"
              value={title}
              onChange={(e) => handleChange('title', e?.currentTarget.value)}
              error={!!errors?.title}
            />
          </div>
          <div>
            <Label>Message tag</Label>
            <SelectInput
              value={optionsTag.filter((item) => item.key === tag)?.[0]}
              onChange={(item) => handleChange('tag', item?.key)}
              options={optionsTag}
              placeholder="Pick tag"
              labelResolver={(option) => option?.label}
              keyResolver={(option) => option?.key}
              error={!!errors?.tag}
              disableAutocomplete
            />
          </div>
          <div>
            <Label>Receivers</Label>
            <SelectInput
              value={
                optionsReceiver.filter((item) => item.key === receiver)?.[0]
              }
              onChange={(item) => handleChange('receiver', item?.key)}
              options={optionsReceiver}
              placeholder="Choose users to receive message"
              labelResolver={(option) => option?.label}
              keyResolver={(option) => option?.key}
              error={!!errors?.receiver}
              disableAutocomplete
            />
          </div>
          <div>
            <Label>Channels</Label>
            <SelectInput
              value={optionsChannel.filter((item) => item.key === channel)?.[0]}
              onChange={(item) => handleChange('channel', item?.key)}
              options={optionsChannel}
              placeholder="Inapp/mail"
              labelResolver={(option) => option?.label}
              keyResolver={(option) => option?.key}
              error={!!errors?.channel}
              disableAutocomplete
            />
          </div>
        </ContainerInputs>
        <Box mt="s20" mb="s10">
          <Label>Message</Label>
          <Quill
            value={content || ''}
            onChange={(value) => handleChange('content', value)}
            modules={{
              toolbar: ['bold', 'italic', 'underline', 'image'],
            }}
          />
        </Box>
        <UploadInput
          type="multiple"
          onChange={(value) => setAttachments(value)}
          childrenFiles={
            filesToRemove?.length
              ? filesToRemove?.map((item) => (
                  <File key={item.id}>
                    <Icon color="blue" name="20-folder" /> {item.name}
                    <CloseButton
                      type="button"
                      onClick={() => handleAttachmentsUploaded(item.id)}
                    >
                      <Icon name="20-close" color="gray40" />
                    </CloseButton>
                  </File>
                ))
              : undefined
          }
        />
      </Container>
    </Modal>
  );
};

const Quill = styled(ReactQuill)`
  .ql-editor {
    min-height: 250px;
  }
  ${({ theme: { radii, borders } }) => css`
    .ql-toolbar.ql-snow {
      border-radius: ${radii.rounded} ${radii.rounded} 0 0;
      border: ${borders.thinGray10};
    }
    .ql-container.ql-snow {
      border-radius: 0 0 ${radii.rounded} ${radii.rounded};
      border: ${borders.thinGray10};
    }
  `}
`;
export const Container = styled(Box)`
  width: 710px;
`;

export const ContainerInputs = styled(Flex)`
  ${({ theme: { space } }) => css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr 1fr;
    column-gap: ${space.s35};
    row-gap: ${space.s20};
  `}
`;
