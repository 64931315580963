import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { CartItem } from 'graphql/types/CartItem';

export const useQueryReplacementAvailable = (
  options?: QueryHookOptions<
    ReplacementAvailableData,
    ReplacementAvailableVariables
  >,
) =>
  useQuery<ReplacementAvailableData, ReplacementAvailableVariables>(
    REPLACEMENT_AVAILABLE,
    options,
  );

export const REPLACEMENT_AVAILABLE = gql`
  query replacementAvailable($section: String) {
    replacementAvailable {
      id
      monitored(section: $section)
      commodity {
        itemCode
        description
        stockStatus
        stocks {
          stock
          source
          id
          location
          status
        }
        replacements {
          setNumber
          quantity
          commodity {
            itemCode
            id
            description
          }
          items {
            id
            quantity
            commodity {
              itemCode
              id
              description
            }
          }
          id
          createdAt
          updatedAt
        }
        validate {
          setNumber
          quantity
          commodity
          items {
            id
            commodity {
              itemCode
              id
              description
            }
          }

          id
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export type ReplacementAvailableVariables = { section: string };

export type ReplacementAvailableData = { replacementAvailable: CartItem[] };
