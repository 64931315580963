interface DimensionsProps {
  logoHeight: number;
  logoWidth: number;
  menuWidth: number;
  headerHeight: number;
  avatarSize: number;
  searchBoxWidth: number;
}

export const DIMENSIONS: DimensionsProps = {
  logoHeight: 23,
  logoWidth: 142,
  menuWidth: 236,
  headerHeight: 78,
  avatarSize: 40,
  searchBoxWidth: 566,
};
