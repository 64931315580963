export const paths = {
  base: '/',
  main: {
    //orders
    yourOrders: '/orders',
    uploadOrder: '/orders/new',
    //Customer orders admin
    customerOrdersAdmin: '/customer-orders/admin',
    //Customer orders sales
    customerOrdersSales: '/customer-orders/sales',
    //Reports
    reports: '/reports',
    //validation
    validation: '/validation',
    // Faq
    faq: '/faq',
    // item status
    itemStatus: '/item-status',
    // messages
    messages: '/messages',
    // mailing
    mailing: '/mailing',
    // Documents
    documents: '/documents',
    // Documents privileges
    documentsPrivileges: '/documents/privileges',
    // cart & drafts
    cartAndDrafts: '/cartAndDrafts',
    // users
    users: '/users',
    // users customers
    usersCustomers: '/users/customers',
    // users sales
    usersSales: '/users/sales',
    // search
    search: '/search/:value',
    // wish list
    wishlist: '/wishlist',
    // orders
    notAllowed: '/not-allowed',
  },
  outside: {
    news: 'https://jukieurope.com/en/ism/articles/news',
    webpartsList: 'https://www.juki.co.jp/partslist/?language=en',
  },
  auth: {
    createFirstPassword: '/create-first-password/:token',
    createNewPassword: '/create-new-password',
    forgotPassword: '/forgot-your-password',
    setupNewPassword: '/setup-new-password/:token',
    signin: '/signin',
    signup: '/signup',
  },
  all: '*',
} as const;
