import { useQueryUser } from 'graphql/query';
import { ReactChild } from 'react';
import { useNavigate } from 'react-router-dom';
import { paths } from 'routes';

type AuthProviderProps = { children: ReactChild };

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const navigate = useNavigate();

  const { loading } = useQueryUser({
    fetchPolicy: 'network-only',
    onCompleted: ({ user }) => {
      const role = user?.roles?.[0];
      if (!role) {
        navigate(paths.auth.signin);
      }
    },
    onError: () => {
      navigate(paths.auth.signin);
    },
  });

  if (loading) {
    return null;
  }

  return <div>{children}</div>;
};
