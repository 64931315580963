import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export default function useMutationSendRegistration(
  options?: MutationHookOptions<any, SendRegistrationVariables>,
) {
  const data = useMutation<any, SendRegistrationVariables>(
    SEND_REGISTRATION_REQUEST_MAIL,
    options,
  );
  return data;
}

export const SEND_REGISTRATION_REQUEST_MAIL = gql`
  mutation sendRegistrationRequestMail(
    $companyName: String!
    $country: String!
    $phoneNumber: String!
    $email: String!
    $message: String!
  ) {
    sendRegistrationRequestMail(
      companyName: $companyName
      country: $country
      phoneNumber: $phoneNumber
      email: $email
      message: $message
    )
  }
`;

export type SendRegistrationVariables = {
  companyName: string;
  country: string;
  phoneNumber: string;
  email: string;
  message: string;
};
