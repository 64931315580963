import { Button, ModalProps, TextInput, Label, Modal, Alert } from 'components';
import { useMutationAddDirectory } from 'graphql/mutation';
import { useState } from 'react';
import { Flex } from 'ui';

interface Props extends Omit<ModalProps, 'children'> {
  parentId: number;
  refetch: () => void;
}
export const ModalCreateFolder = ({
  open,
  handleClose: handleCloseModal,
  parentId,
  refetch,
}: Props) => {
  const [name, setName] = useState('');

  const handleClose = () => {
    handleCloseModal();
    setName('');
  };

  const [addDirectory, { loading, error }] = useMutationAddDirectory({
    onCompleted: () => {
      handleClose();
      refetch();
    },
  });

  const handleCreateFolder = () => {
    if (name) {
      addDirectory({
        variables: { organizationsIds: ['1'], name, parentId },
      });
    }
  };

  const handleEnter = (e?: React.KeyboardEvent<HTMLInputElement>) => {
    if (e?.key === 'Enter') {
      e?.preventDefault();
      handleCreateFolder();
    }
  };

  return (
    <Modal handleClose={handleClose} open={open} title="CREATE FOLDER">
      <Alert visible={!!error}>Folder create error</Alert>
      <Label>Folder name</Label>
      <TextInput
        placeholder="Folder name"
        autoFocus
        value={name}
        onKeyDown={handleEnter}
        onChange={(e) => setName(e?.currentTarget.value || '')}
      />
      <Flex justifyContent="flex-end" mt="s30">
        <Button variant="primary" onClick={handleCreateFolder} disabled={!name}>
          {loading ? 'Loading...' : 'Create folder'}
        </Button>
      </Flex>
    </Modal>
  );
};
