import { useState, useEffect, KeyboardEvent } from 'react';
import { TextInput } from 'components';
import { Flex, Text } from 'ui';
import { notify } from 'utils';

import { GET_CART } from 'graphql/query';
import { useMutationUpdateCartReference } from 'graphql/mutation';

const refetchQueries = [
  { query: GET_CART, variables: { wishlist: false } },
  { query: GET_CART, variables: { wishlist: true } },
];

type ReferenceNumberProps = {
  reference?: string;
  onChange?: (value: string) => void;
};

export const ReferenceNumber = ({
  reference,
  onChange,
}: ReferenceNumberProps) => {
  const [referenceNumber, setReferenceNumber] = useState(reference || '');
  const [updateCartReference] = useMutationUpdateCartReference({
    refetchQueries,
  });

  const handleOnChange = (value: string) => {
    setReferenceNumber(value);
    if (onChange) onChange(value);
  };

  const handleKeyDown = (e?: KeyboardEvent<HTMLInputElement>) => {
    if (e?.key === 'Enter') {
      handleUpdateCartReference();
    }
  };

  const handleUpdateCartReference = () => {
    if (referenceNumber)
      updateCartReference({
        variables: { reference: referenceNumber },
        onCompleted: () =>
          notify(`Reference number changed to: ${referenceNumber}`),
      });
  };

  useEffect(() => {
    if (reference) setReferenceNumber(reference);
  }, [reference]);

  return (
    <Flex ml="s30" flexDirection="row" alignItems="center">
      <Text lineHeight="lh300" mr="s10" whiteSpace="nowrap">
        Reference number:
      </Text>
      <TextInput
        placeholder={reference ? `${reference}` : `Add reference number`}
        autoFocus
        value={referenceNumber}
        onChange={(e) => handleOnChange(e?.currentTarget.value || '')}
        onKeyDown={handleKeyDown}
        onSubmit={() => handleUpdateCartReference()}
        icon={{ name: '12-plus', color: 'blue' }}
      />
    </Flex>
  );
};
