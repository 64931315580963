import { Flex, Grid, Text } from 'ui';
import { SelectInput, TextInput } from 'components';
import { DeliveriesFilters } from 'pages/YourOrders/hooks/useDeliveriesFilters';
import { DateRangeInput } from 'components/DateRangeInput/DateRangeInput';

type DeliveriesFilterProps = DeliveriesFilters;

export const DeliveriesFilter = ({
  handlers,
  filters,
  options,
}: DeliveriesFilterProps) => {
  return (
    <Flex flexDirection="column" mb="s40" flex="1">
      <TextInput
        icon={{ name: '18-search', color: 'blue' }}
        placeholder="Search deliveries by tracking no., order no. or reference no."
        value={filters.searchInputValue}
        onChange={handlers.handleSearchInputChange}
        type="search"
      />
      <Grid
        mt="s20"
        gridGap="s30"
        gridTemplateColumns="212px 212px 212px 212px"
      >
        <Flex flexDirection="column">
          <Text lineHeight="lh300" mb="s10">
            Delivery address
          </Text>
          <SelectInput
            value={filters.selectedDeliveryAddress}
            onChange={(option) =>
              handlers.handleSelectedDeliveryAddressChange(option)
            }
            options={options.deliveryAddressOptions}
            placeholder="Choose address"
            labelResolver={(option) => option?.address}
            keyResolver={(option) => option?.id}
          />
        </Flex>
        <Flex flexDirection="column">
          <Text lineHeight="lh300" mb="s10">
            Date range
          </Text>
          <DateRangeInput
            //@ts-ignore
            onChange={handlers.handleDateRangeInputChange}
            //@ts-ignore
            value={filters.dateRangeInputValue}
          />
        </Flex>
      </Grid>
    </Flex>
  );
};
