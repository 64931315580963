import { Text, TextProps } from 'ui';

export const MessageDate = (props: TextProps) => (
  <Text
    color="gray80"
    lineHeight="lh300"
    fontSize="fs100"
    fontWeight="medium"
    {...props}
  />
);
