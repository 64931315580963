import { Box, Flex, Grid } from 'ui';
import { Divider, Heading } from 'components';
import { useGetRole } from 'utils/useGetRole';
import { HomeAdmin } from 'pages/HomeAdmin/HomeAdmin';
import { useQueryOrders, useQuerySalesOrders } from 'graphql/query';
import { useViewOrder } from 'utils';
import { paths } from 'routes';
import {
  News,
  ProductUpdates,
  YourProfile,
  RecentOrders,
  RecentCustomersOrders,
  UrgentOrders,
} from './components';

export function Home() {
  const role = useGetRole();
  const { data: ordersData } = useQueryOrders({
    skip: role !== 'CLIENT',
    variables: { orderDir: 'DESC', orderBy: 'createdAt' },
  });
  const { data: salesOrdersData } = useQuerySalesOrders({
    skip: role !== 'SALES',
    variables: {
      orderDir: 'DESC',
      orderBy: 'createdAt',
      limit: 6,
    },
  });

  const { handleViewOrder } = useViewOrder(paths.main.yourOrders);
  const { handleViewOrder: handleViewSalesOrder } = useViewOrder(
    paths.main.customerOrdersSales,
  );

  if (role === 'ADMIN') return <HomeAdmin />;
  return (
    <Box>
      {role === 'SALES' ? (
        <Flex px="s30" pb="s40">
          <RecentCustomersOrders
            onViewOrder={handleViewSalesOrder}
            data={salesOrdersData?.orders?.salesOrders ?? []}
          />
          <UrgentOrders />
        </Flex>
      ) : (
        <RecentOrders
          onViewOrder={handleViewOrder}
          data={ordersData?.orders?.customerOrders ?? []}
          px="s30"
        />
      )}

      <Divider />
      <Grid
        gridTemplateColumns={{ lg: '1fr', xl: '1fr 1fr' }}
        gridGap="s35"
        mb="s15"
        px="s30"
      >
        <div>
          <Heading>Product Updates</Heading>
          <ProductUpdates />
        </div>
        <div>
          <Heading>Your profile</Heading>
          <YourProfile />
        </div>
      </Grid>
      <Divider />
      <Box px="s30" pb="s15">
        <Heading>News</Heading>
        <News />
      </Box>
    </Box>
  );
}
