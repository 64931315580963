import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { BusinessPartner } from 'graphql/types/BusinessPartner';

export const useQuerySalesBusinessSaless = (
  options?: QueryHookOptions<SalesBusinessPartnersData>,
) => useQuery<SalesBusinessPartnersData>(SALES_BUSINESS_PARTNERS, options);

export const SALES_BUSINESS_PARTNERS = gql`
  query salesBusinessPartners {
    salesBusinessPartners {
      code
      active
      name
      address
      zipCode
      city
      county
      country
      id
      createdAt
      updatedAt
    }
  }
`;

export type SalesBusinessPartnersData = {
  salesBusinessPartners: BusinessPartner[];
};
