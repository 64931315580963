import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export const useMutationReplaceCartItems = (
  options?: MutationHookOptions<
    ReplaceCartItemsData,
    ReplaceCartItemsVariables
  >,
) =>
  useMutation<ReplaceCartItemsData, ReplaceCartItemsVariables>(
    REPLACE_CART_ITEMS,
    options,
  );

export const REPLACE_CART_ITEMS = gql`
  mutation replaceCartItems(
    $replacements: [ReplaceCartItemInput!]!
    $section: String
  ) {
    replaceCartItems(replacements: $replacements) {
      id
      monitored(section: $section)
    }
  }
`;

export type ReplaceCartItemsVariables = {
  replacements: {
    cartItemId: number;
    commodities: number[];
    quantity: number;
  }[];
  section: string;
};

export type ReplaceCartItemsData = {
  replaceCartItems: {
    id: string;
    monitored: boolean;
  };
};
