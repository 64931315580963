export const openWindowWithPost = (
  url: string,
  data: { [key: string]: any },
) => {
  const form = document.createElement('form');
  form.target = '_blank';
  form.method = 'POST';
  form.action = url;
  form.style.display = 'none';

  Object.entries(data)?.forEach(([key, value]) => {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = value;
    form.appendChild(input);
  });

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
};
