import { TextInput } from 'components';
import styled from 'styled-components';
import { Flex, Text } from 'ui';

import { useCreateProfileModalContext } from './CreateProfileModalContext';

export const CreateSalesProfileForm = () => {
  const { salesProfileForm } = useCreateProfileModalContext();

  return (
    <Flex pb={'s10'} flexDirection="column">
      <Flex mt={'s20'} justifyContent={'space-between'}>
        <InputWrapper>
          <Label>Login</Label>
          <TextInput
            onChange={(e) =>
              salesProfileForm.handleChange('username', e?.target.value)
            }
            error={!!salesProfileForm.errors.username}
            value={salesProfileForm.data.username}
            placeholder="Insert user login"
          />
        </InputWrapper>
        <InputWrapper>
          <Label>E-mail</Label>
          <TextInput
            onChange={(e) =>
              salesProfileForm.handleChange('email', e?.target.value)
            }
            error={!!salesProfileForm.errors.email}
            value={salesProfileForm.data.email}
            placeholder="Insert user e-mail"
          />
        </InputWrapper>
      </Flex>
      <Flex mt={'s20'} justifyContent={'space-between'}>
        <InputWrapper>
          <Label>First name</Label>
          <TextInput
            onChange={(e) =>
              salesProfileForm.handleChange('firstName', e?.target.value)
            }
            error={!!salesProfileForm.errors.firstName}
            value={salesProfileForm.data.firstName}
            placeholder="Inser user first name"
          />
        </InputWrapper>
        <InputWrapper>
          <Label>Last Name</Label>
          <TextInput
            onChange={(e) =>
              salesProfileForm.handleChange('lastName', e?.target.value)
            }
            error={!!salesProfileForm.errors.lastName}
            value={salesProfileForm.data.lastName}
            placeholder="Insert user last name"
          />
        </InputWrapper>
      </Flex>
    </Flex>
  );
};

export const InputWrapper = styled(Flex)`
  flex-direction: column;
  max-width: 304px;
  width: 100%;
`;

export const Label = styled(Text).attrs({
  lineHeight: 'lh300',
  mb: 's10',
})``;
