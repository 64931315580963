import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export function useMutationNewsReadLog(
  options?: MutationHookOptions<any, NewsReadLogVariables>,
) {
  const data = useMutation<any, NewsReadLogVariables>(NEWS_READ_LOG, options);
  return data;
}

export const NEWS_READ_LOG = gql`
  mutation newsReadLog($title: String!) {
    newsReadLog(title: $title)
  }
`;

export type NewsReadLogVariables = {
  title: string;
};

export type NewsReadLogData = {
  newsReadLog: {
    id: string;
  };
};
