import { Box, Flex } from 'ui';
import { Heading, Button, Breadcrumbs, Divider } from 'components';
import { ModalCreateFolder } from './components/ModalCreateFolder';
import { ModalAddFile } from './components/ModalAddFile';
import { File } from './components/File';
import { Document } from './components/Document';
import { useDocuments } from './hooks/useDocuments';
import { Message } from './components/Message';

export const Documents = () => {
  const {
    breadcrumbs,
    directoriesData,
    filesData,
    handleDownload,
    handleSelected,
    adminPermission,
    isEmptyDirectory,
    openModalAddFile,
    openModalCreateFolder,
    setOpenModalAddFile,
    setOpenModalCreateFolder,
    paramNameFolder,
    state,
    loading,
    documents,
    files,
    directoryId,
  } = useDocuments();

  return (
    <>
      <Box>
        <Flex px="s30">
          <Breadcrumbs items={breadcrumbs} />
        </Flex>
        <Divider />
        <Flex mb="s15" px="s30">
          <Flex
            justifyContent="space-between"
            alignItems="center"
            style={{ height: '61px', width: '100%' }}
          >
            <Heading>{paramNameFolder || 'Documents'}</Heading>
            <Flex mt="s10">
              <Button
                size="default"
                variant="outline-primary"
                onClick={handleDownload}
                disabled={
                  state.directories?.length === 0 && state.files?.length === 0
                }
              >
                Download selected
              </Button>
              {adminPermission && (
                <Button
                  size="default"
                  variant="outline-primary"
                  onClick={() => setOpenModalCreateFolder(true)}
                  ml="s20"
                >
                  Create folder
                </Button>
              )}
              {adminPermission && (
                <Button
                  size="default"
                  variant="primary"
                  onClick={() => setOpenModalAddFile(true)}
                  ml="s20"
                >
                  Add new file
                </Button>
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex m="s15" flexWrap="wrap">
          {loading ? (
            <Message mt="s130">Loading files...</Message>
          ) : (
            <>
              {isEmptyDirectory && (
                <Message mt="s130">The folder is empty</Message>
              )}
              <>
                {documents?.map((item) => (
                  <Document
                    handleSelected={handleSelected}
                    selected={state.directories}
                    key={item.id}
                    adminPermission={adminPermission}
                    {...item}
                    m="s15"
                  />
                ))}
                {files?.map((item) => (
                  <File
                    handleSelected={handleSelected}
                    selected={state.files}
                    key={item.id}
                    {...item}
                    m="s15"
                  />
                ))}
              </>
            </>
          )}
        </Flex>
      </Box>
      <ModalCreateFolder
        parentId={directoryId}
        open={openModalCreateFolder}
        handleClose={() => setOpenModalCreateFolder(false)}
        refetch={directoriesData.refetch}
      />
      <ModalAddFile
        open={openModalAddFile}
        handleClose={() => setOpenModalAddFile(false)}
        directoryId={directoryId}
        refetch={filesData.refetch}
      />
    </>
  );
};
