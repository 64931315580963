import styled, { css } from 'styled-components';
import { Text } from 'ui';

export const TextNoData = () => {
  return <StyledText>No results found</StyledText>;
};

export const StyledText = styled(Text)`
  ${({ theme: { space, colors } }) => css`
    margin-top: ${space.s50};
    color: ${colors.gray50};
    width: 100%;
    display: flex;
    justify-content: center;
  `}
`;
