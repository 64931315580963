import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export const useMutationChangeWishlistMailingConfig = (
  options?: MutationHookOptions<
    ChangeWishlistMailingConfigData,
    ChangeWishlistMailingConfigVariables
  >,
) =>
  useMutation<
    ChangeWishlistMailingConfigData,
    ChangeWishlistMailingConfigVariables
  >(CHANGE_WISHLIST_MAILING_CONFIG, options);

export const CHANGE_WISHLIST_MAILING_CONFIG = gql`
  mutation ($userId: String!, $cc: String!, $interval: String!) {
    changeWishlistMailingConfig(userId: $userId, cc: $cc, interval: $interval) {
      id
    }
  }
`;

export type ChangeWishlistMailingConfigVariables = {
  userId: string;
  cc: string;
  interval: string;
};

export type ChangeWishlistMailingConfigData = {
  changeWishlistMailingConfig: {
    id: string;
  };
};
