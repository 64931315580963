import { Dispatch, SetStateAction } from 'react';
import { Button, Label, SelectInput, TextInput } from 'components';
import { optionsTag } from 'components/Messages/utilities';
import { Flex, Grid } from 'ui';
import { useDebouncedCallback } from 'use-debounce';
import { StateFilters } from './utilities';

interface Props {
  handleOpenEdit: () => void;
  filters: StateFilters;
  setFilters: Dispatch<SetStateAction<StateFilters>>;
}

export const Filters = ({ handleOpenEdit, setFilters, filters }: Props) => {
  const { messages, type } = filters;

  const handleSearch = useDebouncedCallback(({ target: { value } }) => {
    setFilters((prev) => ({ ...prev, messages: value || '' }));
  }, 500);

  return (
    <Grid
      gridTemplateColumns={{ md: '1fr', xl: '1.5fr 1fr 2.5fr' }}
      gridGap="s35"
    >
      <div>
        <Label>Search for messages</Label>
        <TextInput
          icon={{ name: '18-search', color: 'blue' }}
          placeholder="Search"
          type="search"
          defaultValue={messages}
          onChange={handleSearch}
        />
      </div>
      <div>
        <Label>Filter by message type</Label>
        <SelectInput
          value={type}
          onChange={(value) => setFilters((prev) => ({ ...prev, type: value }))}
          options={optionsTag}
          placeholder="Choose type"
          labelResolver={(option) => option?.label}
          keyResolver={(option) => option?.key}
          disableAutocomplete
        />
      </div>
      <Flex justifyContent="right" alignItems="flex-end">
        <Button variant="primary" onClick={handleOpenEdit}>
          Create message
        </Button>
      </Flex>
    </Grid>
  );
};
