import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export function useMutationImportCsvToImport(
  options?: MutationHookOptions<
    ImportCsvToImportData,
    ImportCsvToImportVariables
  >,
) {
  const data = useMutation<ImportCsvToImportData, ImportCsvToImportVariables>(
    IMPORT_CSV_TO_IMPOPRT,
    options,
  );
  return data;
}

export const IMPORT_CSV_TO_IMPOPRT = gql`
  mutation importCsvToImport($file: Upload!) {
    importCsvToImport(file: $file) {
      id
      items {
        quantity
        commodity {
          itemCode
          internal
          sellable
          description
          price
          currency
          fixedDiscount
          discount
          stock
          availableStock
          incomingStock
          replacements
          stocks
          validate
          id
          createdAt
          updatedAt
          status
        }
      }
    }
  }
`;

export type ImportCsvToImportVariables = {};

export type ImportCsvToImportData = {
  id: number;
  items: {
    quantity: number;
    commodity: {
      itemCode: string;
    };
  };
};
