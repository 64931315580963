import styled, { css } from 'styled-components';

import { LOGOUT_LINK_TEXT } from 'constants/texts/logoutLink';
import { Button } from 'components/Button/Button';
import { useMutationLogout } from 'graphql/mutation';

export const LogoutLink = () => {
  const [logout] = useMutationLogout();
  const handleLogout = () => {
    logout({ refetchQueries: ['user'] });
  };

  return (
    <StyledLogoutLink
      onClick={handleLogout}
      variant="text"
      textColor="gray50"
      icon={{ name: '8-link-external', color: 'gray50', position: 'right' }}
    >
      {LOGOUT_LINK_TEXT}
    </StyledLogoutLink>
  );
};

const StyledLogoutLink = styled(Button)`
  ${({ theme: { space } }) => css`
    display: flex;
    align-items: center;
    svg {
      margin-left: ${space.s15};
    }
  `}
  &:hover > * {
    color: ${({ theme: { colors } }) => colors.gray80};
  }
`;
