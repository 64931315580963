import { RegisterClientVariables } from 'graphql/mutation/useMutationRegisterClient';
import { BusinessPartner } from 'pages/Users/graphql/useQueryBusinessPartners';
import { SalesUser } from 'graphql/query/useQuerySalesUsers';
import { createContext, ReactChild, useContext, ReactElement } from 'react';
import { Form } from 'utils/useForm';

type CreateProfileModalContextProviderProps = {
  children: ReactChild | ReactChild[];
  value: CreateProfileModalContextProps;
};

export const USER_ROLES = [
  { id: 1, label: 'Customer', value: 'customer' },
  { id: 2, label: 'Sales Support', value: 'salesSupport' },
] as const;

export type UserRoles = typeof USER_ROLES[number];

type CreateProfileModalContextProps = {
  role?: UserRoles;
  steps: CreateProfileModalStep[];
  step: number;
  handleRoleChange: (option?: UserRoles) => void;
  salesProfileForm: Form<SalesProfileFormData>;
  customerProfileForm: Form<CustomerProfileFormData>;
  error: string;
};

export type CreateProfileModalStep = {
  title: string;
  content: ReactElement;
  step: number;
  firstButton: {
    label: string;
    disabled?: boolean;
    onClick: () => void;
  };
  secondButton: {
    label: string;
    disabled?: boolean;
    onClick: () => void;
  };
};

const CreateProfileModalContext = createContext<
  CreateProfileModalContextProps | undefined
>(undefined);

export type CustomerProfileFormData = Omit<
  RegisterClientVariables,
  'businessPartner' | 'salesSupport' | 'salesUser' | 'deliveryAddress'
> & {
  businessPartner: BusinessPartner;
  salesSupport: SalesUser;
  deliveryAddress: BusinessPartner['shippingAddresses'][0];
  id: string | undefined;
};

export type SalesProfileFormData = {
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  userId: string | undefined;
};

export const CreateProfileModalContextProvider = ({
  value,
  children,
}: CreateProfileModalContextProviderProps) => {
  return (
    <CreateProfileModalContext.Provider value={value}>
      {children}
    </CreateProfileModalContext.Provider>
  );
};

export const useCreateProfileModalContext = () => {
  const context = useContext(CreateProfileModalContext);

  if (context === undefined) {
    throw new Error(
      'useCreateProfileModalContext must be used within a CreateProfileModalContextProvider',
    );
  }

  return context;
};
