import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export const useMutationAddAttachment = (
  options?: MutationHookOptions<AddAttachmentData, AddAttachmentVariables>,
) =>
  useMutation<AddAttachmentData, AddAttachmentVariables>(
    ADD_ATTACHMENT,
    options,
  );

export const ADD_ATTACHMENT = gql`
  mutation addAttachment($file: Upload!, $messageId: String!) {
    addAttachment(file: $file, messageId: $messageId) {
      id
      name
    }
  }
`;

export type AddAttachmentVariables = {
  file: File;
  messageId: string;
};

export type AddAttachmentData = {
  addAttachment: {
    id: string;
    name: string;
  };
};
