import { useQueryFiles, useQueryGetDirectories } from 'graphql/query';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetRole } from 'utils';
import { paths } from 'routes';
import { parseDirectories } from '../utils';
import { downloadZip } from '../utils/downloadZip';
import { useDownloadFilesPermission } from '../utils/useDownloadFilesPermission';
import { useSelected } from '../utils/useSelected';

interface Props {
  isViewPrivileges?: boolean;
}

export const useDocuments = (props?: Props) => {
  const { isViewPrivileges = false } = props || {};
  const [openModalCreateFolder, setOpenModalCreateFolder] = useState(false);
  const [openModalAddFile, setOpenModalAddFile] = useState(false);
  const { handleSelected, state, reset } = useSelected();
  const [searchParams] = useSearchParams();
  const role = useGetRole();
  const paramDirectory = searchParams.get('directories');
  const directories = parseDirectories(paramDirectory);
  const paramNameFolder = directories.at(-1)?.name;
  const directoryId = directories.at(-1)?.id || 0;

  useDownloadFilesPermission();

  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramDirectory]);

  const directoriesData = useQueryGetDirectories({
    variables: {
      conditions: [
        {
          column: 'parent',
          operator: directoryId === 0 ? 'is_null' : 'eq',
          value: directoryId === 0 ? '' : `${directoryId}`,
        },
      ],
    },
    fetchPolicy: 'no-cache',
  });

  const filesData = useQueryFiles({
    variables: {
      conditions: [
        {
          column: 'directory',
          operator: 'eq',
          value: `${directoryId}`,
        },
      ],
    },
    fetchPolicy: 'no-cache',
  });

  const documents = directoriesData.data?.directories?.items;
  const files = filesData.data?.files?.items;
  const adminPermission = role === 'ADMIN';
  const breadcrumbs = [
    { label: 'Home', to: '/' },
    { label: 'Documents', to: paths.main.documents },
  ];
  const loading = filesData.loading || directoriesData.loading;
  const isEmptyDirectory = !loading && !(documents?.length || files?.length);

  const handleDownload = () => {
    downloadZip({
      directories_ids: state.directories.join(','),
      files_ids: state.files.join(','),
      organization_id: 1,
    });
    reset();
  };

  if (paramNameFolder) {
    breadcrumbs.push(
      ...directories.map((item, index) => ({
        label: item.name,
        to: `${paths.main.documents}?directories=${JSON.stringify([
          ...directories.slice(0, index + 1),
        ])}`,
      })),
    );
  }

  return {
    handleDownload,
    isEmptyDirectory,
    adminPermission,
    directoriesData,
    filesData,
    breadcrumbs,
    openModalCreateFolder,
    setOpenModalCreateFolder,
    openModalAddFile,
    setOpenModalAddFile,
    handleSelected,
    paramNameFolder,
    state,
    loading,
    documents,
    files,
    directoryId,
    isViewPrivileges,
  };
};
