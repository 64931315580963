import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { ROLES } from 'constants/roles';

export const useMutationRegisterSales = (
  options?: MutationHookOptions<RegisterSalesData, RegisterSalesVariables>,
) => useMutation(REGISTER_SALES, options);

export const REGISTER_SALES = gql`
  mutation registerSales(
    $email: String!
    $firstName: String!
    $lastName: String!
    $username: String!
  ) {
    registerSales(
      email: $email
      firstName: $firstName
      lastName: $lastName
      username: $username
    ) {
      id
      roles
    }
  }
`;

export type RegisterSalesVariables = {
  email: string;
  firstName: string;
  lastName: string;
  username: string;
};

export type RegisterSalesData = {
  registerSales: {
    id: string;
    roles: ROLES[];
  };
};
