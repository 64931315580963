import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export function useMutationResetPassword(
  options?: MutationHookOptions<ResetPasswordData, ResetPasswordVariables>,
) {
  const data = useMutation<ResetPasswordData, ResetPasswordVariables>(
    RESET_PASSWORD,
    options,
  );
  return data;
}

export const RESET_PASSWORD = gql`
  mutation resetPassword($password: String!, $token: String!) {
    resetPassword(password: $password, token: $token) {
      userName
    }
  }
`;

export type ResetPasswordVariables = {
  password: string;
  token: string;
};
export type ResetPasswordData = {
  resetPassword: {
    userName: string;
  };
};
