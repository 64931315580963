import { Fragment, KeyboardEvent, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Flex, Box } from 'ui';
import { DIMENSIONS } from 'constants/dimensions';
import { SearchBox } from 'components';
import { SEARCH_BOX_INPUT_PLACEHOLDER } from 'constants/texts/search';
import { useQuerySearchCommodities } from 'graphql/query';
import { paths } from 'routes';
import { useMutationAddToCart } from 'graphql/mutation';
import { ItemResultSearch } from './ItemResultSearch';
import { LogoJuki } from './LogoJuki';
import { User } from './User';
import { LogoutLink } from './LogoutLink';
import { Divider } from './Divider';
import { Link } from '../Link/Link';
import { StyledHeader } from './StyledHeader';

export const Header = () => {
  const [search, setSearch] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [addToCart] = useMutationAddToCart();
  const [searchCommodity, { data: searchCommoditiesData }] =
    useQuerySearchCommodities({ fetchPolicy: 'no-cache' });

  const navigate = useNavigate();
  const getPathSearch = (value: string) => `/search/${value}`;

  const data = searchCommoditiesData?.searchCommodities;

  const visleShowAllResult = data && data?.length > 3;

  const handleEnter = (e?: KeyboardEvent<HTMLInputElement> | undefined) => {
    if (e?.key === 'Enter') {
      if (e.currentTarget.value) {
        navigate(getPathSearch(e.currentTarget.value));
        setShowDropdown(false);
      }
    }
  };

  const handleValidate = (id: number) => {
    addToCart({ variables: { id, quantity: 1 } });
    navigate(paths.main.validation);
  };

  useEffect(() => {
    searchCommodity({
      variables: {
        phrase: search,
      },
    });
  }, [search, searchCommodity]);

  return (
    <StyledHeader>
      <LogoJuki />
      <Flex justifyContent="space-between" alignItems="center" px="s30">
        <SearchWrapper>
          <SearchBox
            iconName="18-search"
            placeholder={SEARCH_BOX_INPUT_PLACEHOLDER}
            onChange={setSearch}
            onKeyDown={handleEnter}
            setShowDropdown={setShowDropdown}
            showDropdown={showDropdown}
            inputSizeVariant="large"
            searchResults={
              !!search && (
                <Flex alignItems="end" flexDirection="column">
                  {data
                    ?.slice(0, 3)
                    .map(({ itemCode, stockStatus, id, description }) => (
                      <Fragment key={id}>
                        <ItemResultSearch
                          search={search}
                          button={{
                            label: 'Validate',
                            onClick: handleValidate,
                          }}
                          code={itemCode}
                          id={id}
                          description={description}
                          status={stockStatus}
                        />
                      </Fragment>
                    ))}
                  <Box px="s20" py="s20">
                    {visleShowAllResult && (
                      <Link
                        to={getPathSearch(search)}
                        onClick={() => setShowDropdown(false)}
                      >
                        Show all results
                      </Link>
                    )}
                  </Box>
                </Flex>
              )
            }
          />
        </SearchWrapper>
        <Flex>
          <User />
          <Divider mx="s30" />
          <LogoutLink />
        </Flex>
      </Flex>
    </StyledHeader>
  );
};

const SearchWrapper = styled(Flex)`
  width: 100%;
  max-width: ${DIMENSIONS.searchBoxWidth}px;
`;
