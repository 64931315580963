/* eslint-disable import/no-cycle */
import { useState } from 'react';
import styled from 'styled-components';
import {
  Checkbox,
  SelectInput,
  TextInput,
  DateRangeInput,
  AddProduct,
} from 'components';
import { Text, Flex } from 'ui';
import { useQueryUsers, useQuerySearchCommodities } from 'graphql/query';
import { formatDate } from 'utils';
import { Form } from 'utils/useForm';
import { User } from 'graphql/types/User';
import { Commodity } from 'graphql/types/Commodity';
import { SingleValue } from 'react-select';
import { CustomerReportRequestData } from './CreateReportModal';

interface CreateReportFormProps
  extends Pick<
    Form<CustomerReportRequestData>,
    'data' | 'errors' | 'handleChange'
  > {
  handleUserSelect: (user: User) => void;
  handleCommoditySelect: (commodity: Commodity) => void;
}

export const CreateReportForm = ({
  data,
  handleUserSelect,
  handleCommoditySelect,
  handleChange,
}: CreateReportFormProps) => {
  const [all, setAll] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User>();

  const usersData = useQueryUsers({
    variables: {
      orderBy: 'id',
      orderDir: 'DESC',
      limit: 1000,
    },
  });
  const users = usersData.data?.users.items || [];

  const handleDateRangeInputChange = (val: [Date, Date]) => {
    if (val) {
      handleChange('dateFrom', formatDate(val[0]) || '');
      handleChange('dateTo', formatDate(val[1]) || '');
    } else {
      handleChange('dateFrom', '');
      handleChange('dateTo', '');
    }
  };

  const handleSelectAll = (val: boolean) => {
    setAll(val);
    handleChange('login', val);
    handleChange('activityTime', val);
    handleChange('logout', val);
    handleChange('productSearches', val);
    handleChange('filesDownload', val);
    handleChange('newsRead', val);
    handleChange('orderResponseTime', val);
    handleChange('wishlist', val);
  };

  const handleUserChange = (user: User) => {
    handleUserSelect(user);
    setSelectedUser(user);
  };

  const [searchCommodities] = useQuerySearchCommodities();

  const handleSubmit = (commodity: SingleValue<Commodity> | undefined) => {
    if (commodity?.id) {
      handleCommoditySelect(commodity);
    }
  };

  const handleLoad = async (value: string) => {
    const options = await searchCommodities({
      variables: { phrase: value },
    });

    return options.data?.searchCommodities ?? [];
  };

  return (
    <Flex flexDirection="column">
      <SectionWrapper>
        <CheckboxWrapper>
          <Checkbox
            checked={all}
            onChange={(e) => handleSelectAll(e?.currentTarget.checked || false)}
          />
          <SubTitle>Select all available data</SubTitle>
        </CheckboxWrapper>
      </SectionWrapper>
      <SectionWrapper>
        <Label>Choose users from list</Label>
        <InputWrapper>
          <SelectInput
            value={selectedUser}
            onChange={(option) => option && handleUserChange(option)}
            options={users}
            placeholder="Choose from list"
            labelResolver={(option) =>
              option ? `${option?.name} (${option?.id})` : undefined
            }
            keyResolver={(option) => option?.id}
          />
        </InputWrapper>
      </SectionWrapper>
      <SectionWrapper>
        <Label>Choose date range</Label>
        <InputWrapper>
          <DateRangeInput
            //@ts-ignore
            onChange={handleDateRangeInputChange}
            //@ts-ignore
            value={[data.dateFrom, data.dateTo]}
          />
        </InputWrapper>
      </SectionWrapper>
      <SectionWrapper>
        <SubTitle>User behaviours</SubTitle>
        <CheckboxWrapper>
          <Checkbox
            checked={data.login}
            onChange={(e) => handleChange('login', e?.currentTarget.checked)}
          />
          <Label>Login</Label>
        </CheckboxWrapper>
        <CheckboxWrapper>
          <Checkbox
            checked={data.activityTime}
            onChange={(e) =>
              handleChange('activityTime', e?.currentTarget.checked)
            }
          />
          <Label>Activity time</Label>
        </CheckboxWrapper>
        <CheckboxWrapper>
          <Checkbox
            checked={data.logout}
            onChange={(e) => handleChange('logout', e?.currentTarget.checked)}
          />
          <Label>Include logouts</Label>
        </CheckboxWrapper>
      </SectionWrapper>
      <SectionWrapper>
        <SubTitle>Products</SubTitle>
        <CheckboxWrapper>
          <Checkbox
            checked={data.productSearches}
            onChange={(e) =>
              handleChange('productSearches', e?.currentTarget.checked)
            }
          />
          <Label>Select all avilable data of searches</Label>
        </CheckboxWrapper>
        <Label>Single product</Label>
        <InputWrapper>
          <AddProduct onLoad={handleLoad} onSubmit={handleSubmit} />
        </InputWrapper>
      </SectionWrapper>
      <SectionWrapper>
        <SubTitle>Marketing</SubTitle>
        <CheckboxWrapper>
          <Checkbox
            checked={data.filesDownload}
            onChange={(e) =>
              handleChange('filesDownload', e?.currentTarget.checked)
            }
          />
          <Label>Only files download</Label>
        </CheckboxWrapper>
        <CheckboxWrapper>
          <Checkbox
            checked={data.newsRead}
            onChange={(e) => handleChange('newsRead', e?.currentTarget.checked)}
          />
          <Label>Only news read</Label>
        </CheckboxWrapper>
      </SectionWrapper>
      <SectionWrapper>
        <SubTitle>Orders</SubTitle>
        <CheckboxWrapper>
          <Checkbox
            checked={data.orderResponseTime}
            onChange={(e) =>
              handleChange('orderResponseTime', e?.currentTarget.checked)
            }
          />
          <Label>Select all avilable data of orders response time</Label>
        </CheckboxWrapper>
        <Label>Select single order</Label>
        <InputWrapper>
          <TextInput
            value={data.orderId}
            placeholder="Insert order no."
            onChange={(e) => handleChange('orderId', e?.currentTarget.value)}
          />
        </InputWrapper>
      </SectionWrapper>
      <SectionWrapper>
        <SubTitle>Wishlist</SubTitle>
        <CheckboxWrapper>
          <Checkbox
            checked={data.wishlist}
            onChange={(e) => handleChange('wishlist', e?.currentTarget.checked)}
          />
          <Label>Select all avilable data of wishlist response time</Label>
        </CheckboxWrapper>
      </SectionWrapper>
    </Flex>
  );
};

export const InputWrapper = styled(Flex)`
  flex-direction: column;
  max-width: 304px;
  width: 100%;
`;

export const SectionWrapper = styled(Flex)`
  gap: ${({ theme: { space } }) => space.s10};
  flex-direction: column;
  margin-bottom: ${({ theme: { space } }) => space.s20};
`;

export const SubTitle = styled(Text)`
  color: ${({ theme: { colors } }) => colors.gray80};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.medium};
  line-height: ${({ theme: { lineHeights } }) => lineHeights.lh300};
`;

export const Label = styled(Text)`
  line-height: ${({ theme: { lineHeights } }) => lineHeights.lh300};
`;

export const CheckboxWrapper = styled(Flex)`
  align-items: center;
  gap: ${({ theme: { space } }) => space.s10};
`;
