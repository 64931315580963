import { DottedStatus } from 'components';
import { FC } from 'react';
import { convertStatus, ERPStatuses } from './convertStatus';

type ERPStatusProps = {
  status?: ERPStatuses;
  label?: string;
  disabledLabel?: boolean;
};

export const ERPStatus: FC<ERPStatusProps> = ({
  status,
  disabledLabel,
  label,
}) => {
  const data = convertStatus(status ?? 'error');
  if (!data) return null;
  const { color } = data;

  return (
    <DottedStatus color={color} label={label} disabledLabel={disabledLabel} />
  );
};
