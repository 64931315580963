import { Box, Flex, Icon, Text } from 'ui';
import { Breadcrumbs, Divider, Heading } from 'components';
import { paths } from 'routes';
import { useLocation, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ModalAddFile } from './components/ModalAddFile';
import { File } from './components/File';
import { Document } from './components/Document';
import { useDocuments } from './hooks/useDocuments';
import { Message } from './components/Message';
import { ConnectedCustomers } from './components/ConnectedCustomers/ConnectedCustomers';
import { FolderBasicInfo } from './components/FolderBasicInfo';

export const DocumentsPrivileges = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const {
    breadcrumbs,
    filesData,
    handleSelected,
    openModalAddFile,
    setOpenModalAddFile,
    state,
    loading,
    documents,
    files,
    directoryId,
    isViewPrivileges,
    paramNameFolder,
    adminPermission,
  } = useDocuments({ isViewPrivileges: true });

  const goToBack = () => {
    navigate(`${paths.main.documents}${search}`);
  };
  return (
    <>
      <Box>
        <Flex px="s30">
          <Breadcrumbs items={breadcrumbs} />
        </Flex>
        <Divider />
        <Box mb="s15" px="s30">
          <Flex alignItems="baseline" justifyContent="space-between">
            <Heading mr="s10">{paramNameFolder || 'Documents'}</Heading>
            <CloseButton type="button" onClick={goToBack}>
              <Icon name="20-close" color="gray40" />
            </CloseButton>
          </Flex>
          <Flex flex={1} flexWrap="wrap">
            <Box mr={'s30'} mb={'s30'}>
              <FolderBasicInfo directoryId={directoryId} />
            </Box>
            <ConnectedCustomers directoryId={directoryId} />
          </Flex>
        </Box>
        <Divider />
        <Flex mb="s15" px="s30">
          <Heading>{`${paramNameFolder || ''} Documents `}</Heading>
        </Flex>
        <Flex m="s15" flexWrap="wrap">
          {loading ? (
            <Message mb="s60" mt="s30">
              Loading files...
            </Message>
          ) : (
            <>
              <ContainerAddFile
                flexDirection="column"
                p="s20"
                m="s15"
                onClick={() => setOpenModalAddFile(true)}
              >
                <Icon name="20-documents-add" color="blue" />
                <Text color="blue" mt="s15">
                  Add new file
                </Text>
              </ContainerAddFile>
              {documents?.map((item) => (
                <Document
                  handleSelected={handleSelected}
                  selected={state.directories}
                  isViewPrivileges={isViewPrivileges}
                  adminPermission={adminPermission}
                  key={item.id}
                  {...item}
                  m="s15"
                />
              ))}
              {files?.map((item) => (
                <File
                  handleSelected={handleSelected}
                  selected={state.files}
                  isViewPrivileges={isViewPrivileges}
                  key={item.id}
                  {...item}
                  m="s15"
                />
              ))}
            </>
          )}
        </Flex>
      </Box>
      <ModalAddFile
        open={openModalAddFile}
        handleClose={() => setOpenModalAddFile(false)}
        directoryId={directoryId}
        refetch={filesData.refetch}
      />
    </>
  );
};

export const ContainerAddFile = styled(Flex)`
  ${({ theme: { borders, radii, colors, fontWeights } }) => css`
    border: ${borders.thinGray10};
    background: ${colors.lightBlue};
    width: 212px;
    height: 114px;
    border-radius: ${radii.rounded};
    cursor: pointer;
    transition: background-color 0.2s;
    position: relative;
    &:hover {
      font-weight: ${fontWeights.medium};
    }
  `}
`;

const CloseButton = styled.button`
  &:hover {
    cursor: pointer;
    svg {
      color: ${({ theme: { colors } }) => colors.gray80};
    }
  }
`;
