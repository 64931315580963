import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export function useMutationUpdateCart(
  options?: MutationHookOptions<UpdateCartData, UpdateCartVariables>,
) {
  const data = useMutation<UpdateCartData, UpdateCartVariables>(
    UPDATE_CART,
    options,
  );
  return data;
}

export const UPDATE_CART = gql`
  mutation updateCart($id: Int, $quantity: Int, $wishlist: Boolean) {
    updateCart(item: { id: $id }, quantity: $quantity, wishlist: $wishlist) {
      items {
        id
        commodity {
          id
          price
          description
        }
        quantity
      }
    }
  }
`;

export type UpdateCartVariables = {
  id: number;
  quantity: number;
  wishlist?: boolean;
};

export type UpdateCartData = {
  updateCart: {
    id: string;
  };
};
