import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export const useMutationDisableUser = (
  options?: MutationHookOptions<DisableUserData, DisableUserVariables>,
) => useMutation<DisableUserData, DisableUserVariables>(DISABLE_USER, options);

export const DISABLE_USER = gql`
  mutation ($id: String!, $enabled: Boolean!) {
    disableUser(id: $id, enabled: $enabled) {
      id
      enabled
    }
  }
`;

export type DisableUserVariables = {
  id: string;
  enabled: boolean;
};

export type DisableUserData = {
  disableUser: {
    id: string;
    enabled: boolean;
  };
};
