import { useRef } from 'react';
import styled, { css } from 'styled-components';

import { Button } from 'components';
import { Flex } from 'ui';
import { useOnClickOutside } from 'utils/useOnClickOutside';

type DocumentDropdownProps = {
  onClickOutside: () => void;
  isDropdownOpen: boolean;
  openDropdown: (open: boolean) => void;
  onButtonClick: () => void;
  children?: any;
};

export const DocumentDropdown = ({
  onClickOutside,
  isDropdownOpen,
  openDropdown,
  onButtonClick,
  children,
  ...rest
}: DocumentDropdownProps) => {
  const documentOptionsRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(documentOptionsRef, () => openDropdown(false));

  return (
    <DocumentOptions ref={documentOptionsRef} {...rest}>
      <DocumentOptionsButton
        onClick={onButtonClick}
        icon={{ name: '20-more' }}
        variant="icon"
        color={isDropdownOpen ? 'blue' : 'gray60'}
      />
      {isDropdownOpen && (
        <DocumentOptionsDropdown flexDirection="column">
          {children}
        </DocumentOptionsDropdown>
      )}
    </DocumentOptions>
  );
};

export const DocumentOptionsButton = styled(Button)`
  position: relative;
  z-index: 2;
`;

export const DocumentOptions = styled(Flex)`
  position: relative;
`;

export const OptionButton = styled(Button).attrs({
  variant: 'text',
  lineHeight: 'lh300',
})`
  ${({ theme: { space } }) => css`
    width: 100%;
    justify-content: flex-start;
    margin: ${space.s025} 0;
  `}
`;

// TODO: można zrobić delikatne przejście przy otwieraniu/zamykaniu dropdowna

export const DocumentOptionsDropdown = styled(Flex)`
  ${({ theme: { radii, borders } }) => css`
    position: absolute;
    width: 144px;
    border: ${borders.thinGray10};
    background: #fff;
    top: -8px;
    right: -8px;
    padding: 30px 8px 8px 8px;
    border-radius: ${radii.rounded};
    z-index: 5;
  `}
`;
