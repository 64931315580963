import { TextInput, SelectInput, Checkbox } from 'components';
import { RegisterClientVariables } from 'graphql/mutation/useMutationRegisterClient';
import { useQuerySalesUsers } from 'graphql/query';
import { useQueryBusinessPartners } from 'pages/Users/graphql/useQueryBusinessPartners';
import styled from 'styled-components';
import { Flex, Text } from 'ui';
import { useCreateProfileModalContext } from './CreateProfileModalContext';

export const CreateCustomerProfileForm = () => {
  const { customerProfileForm } = useCreateProfileModalContext();
  const queryBusinessPartners = useQueryBusinessPartners();
  const querySalesUsers = useQuerySalesUsers();

  const optionsBusinessPartners =
    queryBusinessPartners?.data?.businessPartners?.filter(
      (item) => item?.name,
    ) || [];

  const optionsSalesUsers = [...(querySalesUsers?.data?.salesUsers || [])].sort(
    (a, b) => a.name.localeCompare(b.name),
  );

  const optionsMainAccount: RegisterClientVariables['mainAccount'][] = [
    'Main account',
    'Subaccount',
  ];

  return (
    <Flex flexDirection="column">
      <Flex mt={'s20'} justifyContent={'space-between'}>
        <InputWrapper>
          <Label>Login</Label>
          <TextInput
            onChange={(e) =>
              customerProfileForm.handleChange('username', e?.target.value)
            }
            error={!!customerProfileForm.errors.username}
            value={customerProfileForm.data.username}
            placeholder="Insert user login"
          />
        </InputWrapper>
        <InputWrapper>
          <Label>E-mail</Label>
          <TextInput
            onChange={(e) =>
              customerProfileForm.handleChange('email', e?.target.value)
            }
            error={!!customerProfileForm.errors.email}
            value={customerProfileForm.data.email}
            placeholder="Insert user e-mail"
          />
        </InputWrapper>
      </Flex>
      <Flex mt={'s20'} justifyContent={'space-between'}>
        <InputWrapper>
          <Label>First name</Label>
          <TextInput
            onChange={(e) =>
              customerProfileForm.handleChange('firstName', e?.target.value)
            }
            error={!!customerProfileForm.errors.firstName}
            value={customerProfileForm.data.firstName}
            placeholder="Inser user first name"
          />
        </InputWrapper>
        <InputWrapper>
          <Label>Last Name</Label>
          <TextInput
            onChange={(e) =>
              customerProfileForm.handleChange('lastName', e?.target.value)
            }
            error={!!customerProfileForm.errors.lastName}
            value={customerProfileForm.data.lastName}
            placeholder="Insert user last name"
          />
        </InputWrapper>
      </Flex>
      <Flex mt={'s20'} justifyContent={'space-between'}>
        <InputWrapper>
          <Label>Choose Business Partner</Label>
          <SelectInput
            value={customerProfileForm.data.businessPartner}
            onChange={(option) => {
              customerProfileForm.handleChange('businessPartner', option);
              customerProfileForm.handleChange('deliveryAddress');
            }}
            options={optionsBusinessPartners}
            placeholder="Pick Business Partner"
            labelResolver={(option) => option?.name}
            keyResolver={(option) => option?.id}
            error={!!customerProfileForm.errors.businessPartner}
          />
        </InputWrapper>
        <InputWrapper>
          <Label>Choose Sales Support</Label>
          <SelectInput
            value={customerProfileForm.data.salesSupport}
            onChange={(option) =>
              customerProfileForm.handleChange('salesSupport', option)
            }
            options={optionsSalesUsers}
            placeholder="Insert Sales Support"
            labelResolver={(option) => option?.name}
            keyResolver={(option) => option?.id}
          />
        </InputWrapper>
      </Flex>
      <Flex mt={'s20'} justifyContent={'space-between'}>
        <InputWrapper>
          <Label>Choose default delivery address</Label>
          <SelectInput
            value={customerProfileForm.data.deliveryAddress}
            onChange={(option) =>
              customerProfileForm.handleChange('deliveryAddress', option)
            }
            options={
              customerProfileForm.data.businessPartner?.shippingAddresses || []
            }
            placeholder="Pick default address"
            labelResolver={(option) => {
              return (
                option &&
                `${option?.address || ''} ${option?.zipCode || ''} ${
                  option?.city || ''
                }`
              );
            }}
            keyResolver={(option) => option?.id}
            disabled={
              (
                customerProfileForm.data.businessPartner?.shippingAddresses ||
                []
              )?.length === 0
            }
          />
        </InputWrapper>
        <InputWrapper>
          <Label>Connect with main account</Label>
          <SelectInput
            value={customerProfileForm.data.mainAccount}
            onChange={(option) =>
              customerProfileForm.handleChange('mainAccount', option)
            }
            options={optionsMainAccount}
            placeholder="Choose account type or main account"
            labelResolver={(option) => option}
            keyResolver={(option) => option}
            disableAutocomplete
          />
        </InputWrapper>
      </Flex>
      <Flex mt="s20" mb="s10" flexDirection="column">
        <Label>Accesses</Label>
        <RadioGroup>
          <Checkbox
            label="Download files"
            checked={customerProfileForm.data.downloadFiles}
            onChange={() =>
              customerProfileForm.handleChange(
                'downloadFiles',
                !customerProfileForm?.data?.downloadFiles,
              )
            }
          />
          <Checkbox
            label="Create orders"
            checked={customerProfileForm.data.createOrders}
            onChange={() =>
              customerProfileForm.handleChange(
                'createOrders',
                !customerProfileForm?.data?.createOrders,
              )
            }
          />
          <Checkbox
            label="Create reports"
            checked={customerProfileForm.data.createReports}
            onChange={() =>
              customerProfileForm.handleChange(
                'createReports',
                !customerProfileForm?.data?.createReports,
              )
            }
          />
        </RadioGroup>
      </Flex>
    </Flex>
  );
};

export const RadioGroup = styled(Flex)`
  gap: ${({ theme: { space } }) => space.s55};
`;

export const InputWrapper = styled(Flex)`
  flex-direction: column;
  max-width: 304px;
  width: 100%;
`;

const Label = styled(Text).attrs({ lineHeight: 'lh300', mb: 's10' })``;
