import { useState } from 'react';
import { Flex, Grid } from 'ui';
import { Breadcrumbs, Panel } from 'components';
import { paths } from 'routes';
import {
  CreateReportModal,
  CustomerReportRequestData,
} from 'pages/Reports/components/CreateReportModal/CreateReportModal';

import { useQueryUsers } from 'graphql/query';
import { User } from 'graphql/types/User';
import { LogsFilter } from './components/LogsFilter/LogsFilter';

export const Logs = () => {
  const [isCreateReportModalOpen, setIsCreateReportModalOpen] = useState(false);
  const [initialData, setInitialData] = useState<CustomerReportRequestData>();
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);

  const usersData = useQueryUsers({
    variables: {
      orderBy: 'id',
      orderDir: 'DESC',
      limit: 1000,
    },
  });
  const users = usersData.data?.users.items || [];

  const handleUpdateArray = (arr: any[], item: any) => {
    let updatedArr = arr;
    if (arr && arr.length > 0) {
      if (!arr.includes(item)) {
        updatedArr = [...arr, item];
      }
    } else {
      updatedArr = [item];
    }
    return updatedArr;
  };

  const handleUserSelect = (user: User) => {
    const updatedUsers = handleUpdateArray(selectedUsers, user);
    setSelectedUsers(updatedUsers);
  };

  const handleUserRemove = (user: User) => {
    setSelectedUsers(selectedUsers.filter((item) => item.id !== user.id));
  };

  const handleUserchange = (usersId: string) => {
    const updatedUsers = users.filter((user) =>
      usersId.split(';').includes(user.id.toString()),
    );
    setSelectedUsers(updatedUsers);
  };

  const handleModalOpen = (data: CustomerReportRequestData) => {
    setInitialData(data);
    setIsCreateReportModalOpen(true);
  };

  return (
    <>
      <Grid gridTemplateRows={'40px 1fr'}>
        <Breadcrumbs
          px="s30"
          items={[
            { label: 'Home', to: paths.base },
            { label: 'Reports', to: paths.main.reports },
          ]}
        />
        <Flex flexDirection="column">
          <Panel expandable={false} label={'System Logs'}>
            <Flex flex={1} flexDirection="column">
              <LogsFilter
                users={users}
                selectedUsers={selectedUsers}
                handleUserSelect={handleUserSelect}
                handleUserRemove={handleUserRemove}
                handleModalOpen={handleModalOpen}
              />
            </Flex>
          </Panel>
        </Flex>
      </Grid>
      <CreateReportModal
        open={isCreateReportModalOpen}
        initialData={initialData}
        handleUserchange={handleUserchange}
        handleClose={() => setIsCreateReportModalOpen(false)}
      />
    </>
  );
};
