import styled from 'styled-components';
import { SelectInput } from 'components';
import { Text, Flex } from 'ui';
import {
  useCreateProfileModalContext,
  UserRoles,
  USER_ROLES,
} from './CreateProfileModalContext';

export const CreateProfileForm = () => {
  const context = useCreateProfileModalContext();

  const handleChange = (option?: UserRoles) => {
    context?.handleRoleChange(option);
  };

  return (
    <InputWrapper>
      <Text lineHeight="lh300" mb="s10">
        Choose role
      </Text>
      <SelectInput
        value={context?.role}
        onChange={handleChange}
        options={USER_ROLES}
        placeholder="Customer / Sales Support"
        labelResolver={(option) => option?.label}
        keyResolver={(option) => option?.id}
        disableAutocomplete
      />
    </InputWrapper>
  );
};

export const InputWrapper = styled(Flex)`
  flex-direction: column;
  max-width: 304px;
  width: 100%;
`;
