import { Button, StockStatus } from 'components';
import {
  Cell,
  ExpandRowWrapper,
  Row,
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TableHeadRow,
} from 'components/FlexTable/Table.styled';
import { OrderStatus, OrderStatusLegend } from 'components/OrderStatus';
import { ReferenceNumber } from 'components/ReferenceNumber';
import { TableSpinner } from 'components/TableSpinner';
import { TextNoData } from 'components/TextNoData';
import { Tooltip } from 'components/Tooltip';
import { OrderItem } from 'graphql/types/OrderItem';
import { Refs } from 'pages/YourOrders/YourOrders';
import { useState } from 'react';
import styled from 'styled-components';
import { Box, Flex, Icon, Text } from 'ui';
import { formatDate } from 'utils';
import { useGetRole } from 'utils/useGetRole';
import { OrdersExpandRow } from './OrdersExpandRow';

type OrdersProps = {
  data: TransformedOrdersData[];
  defaultExpandedIds: number[];
  refs: Refs;
  loading: boolean;
};

export const Orders = ({
  data,
  refs,
  defaultExpandedIds,
  loading,
}: OrdersProps) => {
  const [expandedRows, setExpandedRows] =
    useState<number[]>(defaultExpandedIds);
  const role = useGetRole();
  const isAdminRole = role === 'ADMIN';

  const handleExpand = (id: number) => {
    setExpandedRows((prev) => {
      if (prev.includes(id)) {
        return prev.filter((row) => row !== id);
      }
      return [...prev, id];
    });
  };

  return (
    <Flex pt="s10" mb={'s40'} flexDirection="column" flex="1">
      <Table>
        <OrdersHead />
        <TableBody>
          {data?.length > 0 || loading ? (
            data?.map((order) => (
              <OrdersRow
                handleExpand={handleExpand}
                isExpanded={expandedRows.includes(order.id)}
                key={order.id}
                refs={refs}
                {...order}
              />
            ))
          ) : (
            <Box m="s130">
              <TextNoData />
            </Box>
          )}
          <TableSpinner loading={loading} />
        </TableBody>
      </Table>
      {isAdminRole && (
        <Box my="s30">
          <OrderStatusLegend />
        </Box>
      )}
    </Flex>
  );
};

const OrdersRow = ({
  isExpanded,
  handleExpand,
  refs,
  ...data
}: TransformedOrdersData & {
  isExpanded: boolean;
  refs: Refs;
  handleExpand: (id: number) => void;
}) => {
  const {
    order,
    commodity: { itemCode, description, stockStatus },
    quantity,
    value,
    id,
  } = data;

  return (
    <ExpandRowWrapper ref={refs[id]}>
      <Row isExpanded={isExpanded}>
        <Cell flex={112}>
          <Tooltip content={order.user?.fullName}>
            <Text>{order.user?.id || '-'}</Text>
          </Tooltip>
        </Cell>
        <Cell flex={112}>{order.documentNumber}</Cell>
        <Cell flex={112}>{formatDate(order.createdAt)}</Cell>
        <Cell flex={112}>
          <ReferenceNumber reference={order.reference || '-'} />
        </Cell>
        <Cell flex={112}>{itemCode}</Cell>
        <Cell flex={112}>{description}</Cell>
        <Cell flex={64}>{quantity}</Cell>
        <Cell flex={64}>{value}</Cell>
        <Cell flex={80}>
          <OrderStatus status={order.status} />
        </Cell>
        <Cell flex={80}>
          <StockStatus status={stockStatus} />
        </Cell>

        <Cell flex={80} onClick={() => handleExpand(id)}>
          <Button fontWeight="regular" variant="text">
            View more
          </Button>
          <StyledIcon
            isExpanded={isExpanded}
            color="gray80"
            ml="s10"
            name="16-arrowhead"
          />
        </Cell>
      </Row>
      {isExpanded && <OrdersExpandRow data={data} />}
    </ExpandRowWrapper>
  );
};

const OrdersHead = () => (
  <TableHead>
    <TableHeadRow>
      <TableHeadCell flex={112}>Client ID</TableHeadCell>
      <TableHeadCell flex={112}>Order No.</TableHeadCell>
      <TableHeadCell flex={112}>Order date</TableHeadCell>
      <TableHeadCell flex={112}>Reference number</TableHeadCell>
      <TableHeadCell flex={112}>Item code</TableHeadCell>
      <TableHeadCell flex={112}>Description</TableHeadCell>
      <TableHeadCell flex={64}>Qty</TableHeadCell>
      <TableHeadCell flex={64}>List price</TableHeadCell>
      <TableHeadCell flex={80}>
        Order <br /> status
      </TableHeadCell>
      <TableHeadCell flex={80}>
        Stock <br /> status
      </TableHeadCell>
      <TableHeadCell flex={80}>Production & Delivery</TableHeadCell>
    </TableHeadRow>
  </TableHead>
);

const StyledIcon = styled(Icon)<{ isExpanded: boolean }>`
  transform: ${({ isExpanded }) =>
    isExpanded ? 'rotate(0)' : 'rotate(180deg)'};
`;

export type TransformedOrdersData = OrderItem;
