import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export const useMutationClearCart = (
  options?: MutationHookOptions<ClearCartData>,
) => {
  return useMutation<ClearCartData>(CLEAR_CART, options);
};

export const CLEAR_CART = gql`
  mutation clearCart {
    clearCart
  }
`;

export type ClearCartData = {
  clearCart: boolean;
};
