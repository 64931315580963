import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export function useMutationRemoveFromWishlist(
  options?: MutationHookOptions<
    RemoveFromWishlistData,
    RemoveFromWishlistVariables
  >,
) {
  const data = useMutation<RemoveFromWishlistData, RemoveFromWishlistVariables>(
    REMOVE_FROM_WISHLIST,
    options,
  );
  return data;
}

export const REMOVE_FROM_WISHLIST = gql`
  mutation removeFromWishlist($id: Int) {
    removeFromWishlist(item: { id: $id }) {
      id
    }
  }
`;

export type RemoveFromWishlistVariables = {
  id: number;
};

export type RemoveFromWishlistData = {};
