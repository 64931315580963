import { ReactChild, ReactElement } from 'react';
import { Heading } from 'components';
import { Flex } from 'ui';
import { Accordion } from 'components/Accordion/Accordion';
import { Space } from 'theme';

type PanelProps = {
  label: string;
  expandable?: boolean;
  children: ReactChild;
  additionalElements?: ReactElement;
  childrenPx?: keyof Space;
  customHeading?: ReactElement;
};

export const Panel = ({
  label,
  children,
  customHeading,
  additionalElements,
  expandable,
  childrenPx = 's50',
}: PanelProps) => {
  return (
    <Accordion
      expandable={expandable}
      defaultExpand
      additionalElements={additionalElements}
      heading={
        customHeading || (
          <Heading fontSize="fs200" pt="s17" mb="s20">
            {label}
          </Heading>
        )
      }
    >
      <Flex px={childrenPx}>{children}</Flex>
    </Accordion>
  );
};
