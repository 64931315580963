import { Button } from 'components';
import { Box, Flex } from 'ui';
import {
  Cell,
  Row,
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TableHeadRow,
} from 'components/FlexTable/Table.styled';
import { ReferenceNumber } from 'components/ReferenceNumber';
import { DeliveryNoteItem } from 'graphql/types/DeliveryNoteItem';
import { formatDate } from 'utils';
import { TextNoData } from 'components/TextNoData';
import { TableSpinner } from 'components/TableSpinner';
import styled, { css } from 'styled-components';

type DeliveriesProps = {
  onFetchMore: () => void;
  onFetchAll: () => void;
  data: DeliveryNoteItem[];
  loading: boolean;
  allItemsCount?: number;
};

export const Deliveries = ({
  data,
  loading,
  onFetchAll,
  onFetchMore,
  allItemsCount,
}: DeliveriesProps) => {
  return (
    <Flex pt="s10" mb="s50" flex="1" flexDirection="column">
      <Table>
        <DeliveriesHead />
        <TableBody>
          {data?.length > 0 || loading ? (
            data.map((delivery) => (
              <DeliveriesRow
                key={`${delivery.id}${delivery.commodity?.itemCode}}`}
                {...delivery}
              />
            ))
          ) : (
            <Box m="s130">
              <TextNoData />
            </Box>
          )}
          <TableSpinner loading={loading} />
        </TableBody>
      </Table>
      <Flex mt="s30" alignSelf="flex-end" alignItems="center">
        <Button onClick={onFetchAll} mr="s30" variant="text">
          <>See all ({allItemsCount ?? 0})</>
        </Button>
        <Button onClick={onFetchMore} variant="outline-primary">
          See more (+25)
        </Button>
      </Flex>
    </Flex>
  );
};

const DeliveriesRow = ({
  orderItem,
  commodity,
  deliveryNote: { documentCreatedAt, transports, invoices },
}: DeliveryNoteItem) => {
  return (
    <Row>
      <Cell flex={114}>{orderItem?.order?.documentNumber ?? '-'}</Cell>
      <Cell flex={112}>{formatDate(documentCreatedAt)}</Cell>
      <Cell flex={104}>
        <Link
          target="_blank"
          rel="noreferrer"
          href={transports[0]?.trackingUrl}
        >
          {transports[0]?.carrierNumber ?? '-'}
        </Link>
      </Cell>
      <Cell flex={136}>{invoices[0]?.documentNumber ?? '-'}</Cell>
      <Cell flex={152}>
        <ReferenceNumber reference={orderItem?.order?.reference ?? '-'} />
      </Cell>
      <Cell flex={112}>{commodity?.itemCode ?? '-'}</Cell>
      <Cell flex={64}>{orderItem?.quantity ?? '-'}</Cell>
    </Row>
  );
};

const DeliveriesHead = () => (
  <TableHead>
    <TableHeadRow>
      <TableHeadCell flex={114}>Order No.</TableHeadCell>
      <TableHeadCell flex={112}>Order date</TableHeadCell>
      <TableHeadCell flex={104}>Tracking number </TableHeadCell>
      <TableHeadCell flex={136}>Invoice</TableHeadCell>
      <TableHeadCell flex={152}>Reference number</TableHeadCell>
      <TableHeadCell flex={112}>Item code</TableHeadCell>
      <TableHeadCell flex={64}>Qty</TableHeadCell>
    </TableHeadRow>
  </TableHead>
);

const Link = styled.a<{ href: string }>`
  ${({ theme: { colors }, href }) => css`
    color: ${href ? colors.blue : colors.black};
  `}
`;
