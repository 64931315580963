import { gql, QueryHookOptions, useQuery } from '@apollo/client';

export const useQueryPartnerDeliveryAddresses = (
  options?: QueryHookOptions<PartnerDeliveryAddressesData>,
) =>
  useQuery<PartnerDeliveryAddressesData>(PARTNER_DELIVERY_ADDRESSES, options);

export const PARTNER_DELIVERY_ADDRESSES = gql`
  {
    partnerDeliveryAddresses {
      name
      id
    }
  }
`;

export type PartnerDeliveryAddressesItem = {
  id: number;
  name: string;
};

export type PartnerDeliveryAddressesData = {
  partnerDeliveryAddresses: PartnerDeliveryAddressesItem[];
};
