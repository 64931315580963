import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export const useMutationConnectCustomersToSales = (
  options?: MutationHookOptions<
    ConnectCustomersToSalesData,
    ConnectCustomersToSalesVariables
  >,
) =>
  useMutation<ConnectCustomersToSalesData, ConnectCustomersToSalesVariables>(
    CONNECT_CUSTOMERS_TO_SALES,
    options,
  );

export const CONNECT_CUSTOMERS_TO_SALES = gql`
  mutation connectCustomersToSales(
    $customersIds: [String!]!
    $salesId: String!
    $connect: Boolean!
  ) {
    connectCustomersToSales(
      customersIds: $customersIds
      salesId: $salesId
      connect: $connect
    ) {
      id
      salesUser {
        id
        name
      }
    }
  }
`;

export type ConnectCustomersToSalesVariables = {
  customersIds: string[];
  salesId: string;
  connect: boolean;
};

export type ConnectCustomersToSalesData = {
  connectCustomersToSales: {
    id: string;
    salesUser: {
      id: number;
      name: string;
    };
  }[];
};
