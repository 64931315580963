import { gql, QueryHookOptions, useQuery } from '@apollo/client';

import { Import } from 'graphql/types/Import';

export const useQueryImport = (options?: QueryHookOptions<ImportData>) =>
  useQuery<ImportData>(IMPORT, options);

export const IMPORT = gql`
  query import {
    import {
      id
      items {
        id
        commodity {
          itemCode
          description
          internal
          sellable
          replacementAvailable
          replacementAvailableSet
          replacementAvailableChoice
          replacementAvailableChoiceWithSet
          purchases {
            documentNumber
            documentCreatedAt
            invoice {
              documentNumber
            }
            productionDate
            shippedFrom
            expectedArrival
            quantity
          }
          replacements {
            id
            items {
              id
              commodity {
                itemCode
                description
                replacementAvailable
                replacementAvailableSet
                replacementAvailableChoice
                replacementAvailableChoiceWithSet
              }
              replacement {
                items {
                  id
                  commodity {
                    itemCode
                    description
                    replacementAvailable
                    replacementAvailableSet
                    replacementAvailableChoice
                    replacementAvailableChoiceWithSet
                  }
                  replacement {
                    remarks
                    quantity
                    commodity {
                      itemCode
                      replacementAvailable
                      replacementAvailableSet
                      replacementAvailableChoice
                      replacementAvailableChoiceWithSet
                    }
                  }
                }
              }
            }
          }
          stocks {
            source
            stock
            location
            locationName
          }
          status
        }
        quantity
        status
      }
    }
  }
`;

export type ImportData = { import: Import };
