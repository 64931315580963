import { Button, ToggleSwitch } from 'components';
import { Cell, Row } from 'components/FlexTable/Table.styled';
import { StockStatus } from 'components/StockStatus';
import { CartItem } from 'graphql/types/CartItem';
import { formatDate } from 'utils';
import styled from 'styled-components';

type WishlistRowProps = {
  onMonitorItem: (id: number, currentValue: boolean) => void;
  onOrderRegisteredItem: (id: number, quantity: number) => void;
  onDelete: (id: number) => void;
  loading: boolean;
  recentlyAddedIds: number[];

  deletedItemsIds: number[];
} & CartItem;

export const WishlistRow = ({
  commodity: { itemCode, description, createdAt, sellable, stockStatus },
  price,
  quantity,
  monitored,
  onMonitorItem,
  onDelete,
  loading,
  id,
  onOrderRegisteredItem,
  deletedItemsIds,
}: WishlistRowProps) => {
  return (
    <Row isDeleted={deletedItemsIds.includes(id)}>
      <Cell flex={80}>{itemCode}</Cell>
      <Cell flex={140}>{description}</Cell>
      <Cell flex={70}>{quantity}</Cell>
      <Cell flex={70}>{price || '-'}</Cell>
      <Cell flex={64}>
        <StockStatus status={stockStatus} />
      </Cell>
      <Cell flex={104}>{formatDate(createdAt) || '-'}</Cell>
      <Cell flex={64}>
        <ToggleSwitch
          onChange={() => onMonitorItem(id, monitored)}
          checked={monitored}
          disabled={loading}
        />
        <ActionWrapper
          icon={{ name: '16-delete', color: 'gray40' }}
          disabled={loading || false}
          variant={'icon'}
          onClick={() => onDelete(id)}
          ml="s20"
        />
      </Cell>
      <Cell flex={100}>
        <Button
          onClick={() => onOrderRegisteredItem(id, quantity)}
          disabled={!sellable}
          fontWeight="regular"
          variant="outline-primary"
          size="small"
        >
          Order item
        </Button>
      </Cell>
    </Row>
  );
};

const ActionWrapper = styled(Button)`
  cursor: pointer;
`;
