import { Button, SelectInput } from 'components';
import { Flex, Grid, Text } from 'ui';
import { useState } from 'react';
import { Badge } from 'components/Badge/Badge';
import styled from 'styled-components';
import { User } from 'graphql/types/User';
import { CustomerReportRequestData } from '../CreateReportModal/CreateReportModal';

const DATA_TO_SHOW = [
  { id: 1, label: 'Custom report', value: 'custom' },
  { id: 2, label: 'Loggining', value: 'loggining' },
  { id: 3, label: 'Products', value: 'products' },
  { id: 4, label: 'Marketing', value: 'marketing' },
] as const;

type OrderStatus = typeof DATA_TO_SHOW[number];

interface LogsFilterProps {
  users: User[];
  selectedUsers?: User[];
  handleUserSelect: (user: User) => void;
  handleUserRemove: (user: User) => void;
  handleModalOpen: (data: CustomerReportRequestData) => void;
}

export const LogsFilter = ({
  users,
  selectedUsers,
  handleUserSelect,
  handleUserRemove,
  handleModalOpen,
}: LogsFilterProps) => {
  const [selectedDataToShow, setSelectedDataToShow] = useState<
    OrderStatus | undefined
  >();
  const [selectedUser, setSelectedUser] = useState<User>();

  const usersIds = selectedUsers
    ? selectedUsers.map((item) => item.id).join(';')
    : '';

  const handleButtonClick = () => {
    const value = selectedDataToShow?.value;

    let init;

    switch (value) {
      case 'custom':
        init = {
          usersIds,
          dateFrom: '',
          dateTo: '',
          login: false,
          activityTime: false,
          logout: false,
          productSearches: false,
          productCode: '',
          filesDownload: false,
          newsRead: false,
          orderResponseTime: false,
          orderId: '',
          wishlist: false,
        };
        break;
      case 'loggining':
        init = {
          usersIds,
          dateFrom: '',
          dateTo: '',
          login: true,
          activityTime: true,
          logout: true,
          productSearches: false,
          productCode: '',
          filesDownload: false,
          newsRead: false,
          orderResponseTime: false,
          orderId: '',
          wishlist: false,
        };
        break;
      case 'products':
        init = {
          usersIds,
          dateFrom: '',
          dateTo: '',
          login: false,
          activityTime: false,
          logout: false,
          productSearches: true,
          productCode: '',
          filesDownload: false,
          newsRead: false,
          orderResponseTime: false,
          orderId: '',
          wishlist: false,
        };
        break;
      case 'marketing':
        init = {
          usersIds,
          dateFrom: '',
          dateTo: '',
          login: false,
          activityTime: false,
          logout: false,
          productSearches: false,
          productCode: '',
          filesDownload: true,
          newsRead: true,
          orderResponseTime: false,
          orderId: '',
          wishlist: false,
        };
        break;
      default:
        init = {
          usersIds,
          dateFrom: '',
          dateTo: '',
          login: false,
          activityTime: false,
          logout: false,
          productSearches: false,
          productCode: '',
          filesDownload: false,
          newsRead: false,
          orderResponseTime: false,
          orderId: '',
          wishlist: false,
        };
    }

    handleModalOpen(init);
  };

  const handleUserChange = (user: User) => {
    handleUserSelect(user);
    setSelectedUser(user);
  };

  return (
    <Flex flexDirection="column" mb="s40" flex="1">
      <Grid mt="s10" gridGap="s20" gridTemplateColumns="212px 212px 120px auto">
        <Flex flexDirection="column">
          <Text lineHeight="lh300" mb="s10">
            Choose data to show
          </Text>
          <SelectInput
            value={selectedDataToShow}
            onChange={(option) => setSelectedDataToShow(option)}
            options={DATA_TO_SHOW}
            placeholder="Choose filter to sort data"
            labelResolver={(option) => option?.label}
            keyResolver={(option) => option?.id}
            disableAutocomplete
          />
        </Flex>
        <Flex flexDirection="column">
          <Text lineHeight="lh300" mb="s10">
            Additional filter
          </Text>
          <SelectInput
            value={selectedUser}
            onChange={(option) => option && handleUserChange(option)}
            options={users}
            placeholder="Choose from list"
            labelResolver={(option) =>
              option ? `${option?.name} (${option?.id})` : undefined
            }
            keyResolver={(option) => option?.id}
            disabled={!selectedDataToShow}
          />
        </Flex>
        <Flex alignItems="flex-end">
          <Button variant="primary" onClick={() => handleButtonClick()}>
            Create report
          </Button>
        </Flex>
        <Flex alignItems="flex-end">
          <BadgesWrapper>
            {selectedUsers?.map((user) => (
              <Badge
                key={user.id}
                label={`${user.name}`}
                onClick={() => handleUserRemove(user)}
              />
            ))}
          </BadgesWrapper>
        </Flex>
      </Grid>
    </Flex>
  );
};

const BadgesWrapper = styled(Flex)`
  align-items: center;
  height: 40px;
  gap: ${({ theme: { space } }) => space.s20};
`;
