import { FC, ChangeEvent, FormEvent } from 'react';
import styled, { css } from 'styled-components';
import { BoxProps, Flex, Icon, MarginProps, Text } from 'ui';

export type CheckboxFormProps = {
  id?: string;
  form?: string;
  label?: string;
  name?: string;
  checked?: boolean;
  required?: boolean;
  disabled?: boolean;
  onChange?: (e?: FormEvent<HTMLInputElement>) => void;
  onFocus?: (e?: FormEvent<HTMLInputElement>) => void;
  onBlur?: (e?: FormEvent<HTMLInputElement>) => void;
};

export type CheckboxProps = CheckboxFormProps &
  Pick<BoxProps, 'theme' | 'aria-label' | 'aria-labelledby'> &
  MarginProps & {
    as?: 'label' | 'span';
  };

export const Checkbox: FC<CheckboxProps> = ({
  as,
  checked,
  required,
  disabled,
  onChange,
  id,
  label,
  ...rest
}) => {
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();

    if (onChange) onChange(e);
  };

  return (
    <CheckboxWrapper as={as}>
      <Input
        type="checkbox"
        checked={checked}
        onChange={handleOnChange}
        required={required}
        disabled={disabled}
        id={id}
        {...rest}
      />
      <Square disabled={disabled}>
        <Check name="12-check" />
      </Square>
      {label && <CheckboxLabel>{label}</CheckboxLabel>}
    </CheckboxWrapper>
  );
};

Checkbox.defaultProps = {
  as: 'label',
};

export const CheckboxLabel = styled(Text)`
  line-height: ${({ theme: { lineHeights } }) => lineHeights.lh300};
`;

export const CheckboxWrapper = styled(Flex)`
  display: flex;
  align-items: center;
  gap: ${({ theme: { space } }) => space.s10};
  cursor: pointer;
`;

export const Square = styled(Flex).attrs({
  forwardedAs: 'span',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'white',
  borderRadius: 'rounded',
})<Pick<CheckboxProps, 'disabled'>>`
  position: relative;
  transform: translateZ(0);
  overflow: hidden;
  width: 18px;
  height: 18px;

  ${({ theme: { borders } }) => css`
    border: ${borders.mediumGray10};
  `};
`;

export const Check = styled(Icon).attrs({
  color: 'white',
})`
  position: absolute;
  pointer-events: none;
  opacity: 0;
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  pointer-events: none;
  ${({ theme: { colors } }) => css`
    &:checked + ${Square} {
      background-color: ${colors.blue};
      border-color: ${colors.blue};

      ${Check} {
        opacity: 1;
      }
    }
  `};
`;
