import { useQueryUsers, UsersData } from 'graphql/query';
import { useState } from 'react';
import { Flex } from 'ui';
import useInfiniteScroll from 'utils/useInfiniteScroll';
import { Filters } from './types';
import { UsersFilters } from './UsersFilters';
import { UsersTable } from './UsersTable';

export const ConnectedCustomers = () => {
  const [filters, setFilters] = useState<Filters>({});
  const salesAgent = filters?.salesAgent?.id?.toString();

  const { data, loading, fetchMore, client, updateQuery } = useQueryUsers({
    variables: {
      orderBy: 'id',
      orderDir: 'DESC',
      offset: 0,
      limit: 50,
      phrase: filters?.search,
      company: filters?.company?.id?.toString(),
      role: filters?.role?.key,
      salesAgent,
    },
  });

  const { ref } = useInfiniteScroll<UsersData>({
    count: data?.users?.count,
    loading,
    resource: (item) => item?.users?.items,
    data,
    fetchMore,
    listenerRef: true,
  });

  const users = data?.users?.items || [];

  const handleFilters = async (value: Partial<Filters>) => {
    await client.cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'users',
      broadcast: false,
    });
    setFilters((prev) => ({ ...prev, ...value }));
  };

  const handleInvalidateCache = () => {
    updateQuery((previousQueryResult) => {
      const items = previousQueryResult?.users?.items || [];
      const count = previousQueryResult?.users?.count || 0;

      if (salesAgent === undefined) {
        return previousQueryResult;
      }

      const resultData = items?.filter(
        (item) => item?.salesUser?.id?.toString() === salesAgent,
      );
      const quantityRemoved = (items?.length || 0) - (resultData?.length || 0);

      return {
        ...previousQueryResult,
        users: {
          ...previousQueryResult.users,
          count: count - quantityRemoved,
          items: resultData,
        },
      };
    });
  };

  return (
    <Flex flex={1} flexDirection={'column'}>
      <UsersFilters filters={filters} handleFilters={handleFilters} />
      <UsersTable
        data={users}
        filters={filters}
        refTable={ref}
        handleInvalidateCache={handleInvalidateCache}
        loading={loading}
      />
    </Flex>
  );
};
