import {
  useQueryPartnerDeliveryAddresses,
  useQueryUsers,
  PartnerDeliveryAddressesItem,
} from 'graphql/query';
import { User } from 'graphql/types/User';
import { ChangeEvent, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { sortAlphabeticallyUsersByName, useCompany } from 'utils';

type Filters = {
  searchInputValue: string;
  showOnlyMonitoredValue: boolean;
  dateRangeInputValue?: [string | Date, string | Date];
  selectedClient?: User;
  selectedDeliveryAddress?: PartnerDeliveryAddressesItem;
  selectedCompany?: { id: number; name: string };
};

export type AdminCustomerOrdersFilters = ReturnType<
  typeof useAdminCustomerOrdersFilters
>;

export const useAdminCustomerOrdersFilters = () => {
  const [filters, setFilters] = useState<Filters>({
    searchInputValue: '',
    showOnlyMonitoredValue: false,
  });

  const { data: deliveryAddressData } = useQueryPartnerDeliveryAddresses();
  const company = useCompany();
  const { data: usersData } = useQueryUsers({
    variables: { orderBy: 'id', limit: 1000 },
  });

  const handleSearchInputChange = useDebouncedCallback(
    (e?: ChangeEvent<HTMLInputElement>) => {
      setFilters((prev) => ({
        ...prev,
        searchInputValue: e?.target.value ?? '',
      }));
    },
    500,
  );

  const handleShowOnlyMonitoredChange = () => {
    setFilters((prev) => ({
      ...prev,
      showOnlyMonitoredValue: !prev.showOnlyMonitoredValue,
    }));
  };

  const handleDateRangeInputChange = (val: [string | Date, string | Date]) => {
    setFilters((prev) => ({ ...prev, dateRangeInputValue: val }));
  };

  const handleSelectedClientChange = (val: any) => {
    setFilters((prev) => ({ ...prev, selectedClient: val }));
  };

  const handleSelectedCompanyChange = (val: any) => {
    setFilters((prev) => ({ ...prev, selectedCompany: val }));
  };

  const handleSelectedDeliveryAddressChange = (
    val: PartnerDeliveryAddressesItem | undefined,
  ) => {
    setFilters((prev) => ({ ...prev, selectedDeliveryAddress: val }));
  };

  return {
    handlers: {
      handleSearchInputChange,
      handleShowOnlyMonitoredChange,
      handleDateRangeInputChange,
      handleSelectedClientChange,
      handleSelectedCompanyChange,
      handleSelectedDeliveryAddressChange,
    },
    filters,
    options: {
      clientOptions: sortAlphabeticallyUsersByName(
        usersData?.users.items ?? [],
      ),
      companyOptions: company ?? [],
      deliveryAddressOptions:
        deliveryAddressData?.partnerDeliveryAddresses ?? [],
    },
  };
};
