import { SelectInput, TextInput } from 'components';
import { Flex, Grid, Text } from 'ui';
import {
  useQueryBusinessPartners,
  useQueryCountriesList,
  useQuerySalesUsers,
} from 'graphql/query';
import { Filters } from 'pages/Users/types';
import { useDebouncedCallback } from 'use-debounce';
import { getCountryWithCode, sortCountry } from 'utils';
import { role, status } from './utils';

type UsersFilterProps = {
  handleFilters: (value: Partial<Filters>) => void;
  filters: Filters;
};

export const UsersFilter = ({ filters, handleFilters }: UsersFilterProps) => {
  const countriesData = useQueryCountriesList();
  const businessPartners = useQueryBusinessPartners();
  const querySalesUsers = useQuerySalesUsers();

  const countriesList = sortCountry(countriesData.data?.countriesList);
  const salesUsers = querySalesUsers?.data?.salesUsers || [];
  const company =
    businessPartners.data?.businessPartners
      ?.map((item) => ({ name: item?.name, id: item?.id }))
      ?.filter(({ name }) => !!name) || [];

  const handleSearch = useDebouncedCallback((value?: string) => {
    handleFilters({ search: value || '' });
  }, 500);

  return (
    <Flex flexDirection="column" mb="s50" flex="1">
      <TextInput
        defaultValue={filters?.search}
        onChange={(e) => handleSearch(e?.target?.value)}
        icon={{ name: '18-search', color: 'blue' }}
        placeholder="Search users by company, name, code or email"
        type="search"
      />
      <Grid
        mt="s20"
        gridGap="s20"
        gridTemplateColumns="repeat(5, minmax(160px, 1fr))"
      >
        <Flex flexDirection="column">
          <Text lineHeight="lh300" mb="s10">
            Filter by country
          </Text>
          <SelectInput
            value={filters?.country}
            onChange={(option) => handleFilters({ country: option })}
            options={countriesList}
            placeholder="Choose country"
            labelResolver={(option) => getCountryWithCode(option || '')}
            keyResolver={(option) => option}
          />
        </Flex>
        <Flex flexDirection="column">
          <Text lineHeight="lh300" mb="s10">
            Filter by company
          </Text>
          <SelectInput
            value={filters?.company}
            onChange={(option) => handleFilters({ company: option })}
            options={company}
            placeholder="Choose company"
            labelResolver={(option) => option?.name}
            keyResolver={(option) => option?.id}
          />
        </Flex>
        <Flex flexDirection="column">
          <Text lineHeight="lh300" mb="s10">
            Filter by role
          </Text>
          <SelectInput
            value={filters?.role}
            onChange={(option) => handleFilters({ role: option })}
            options={role}
            placeholder="Choose role"
            labelResolver={(option) => option?.label}
            keyResolver={(option) => option?.key}
            disableAutocomplete
          />
        </Flex>
        <Flex flexDirection="column">
          <Text lineHeight="lh300" mb="s10">
            Sales agent
          </Text>
          <SelectInput
            value={filters?.salesAgent}
            onChange={(option) => handleFilters({ salesAgent: option })}
            options={salesUsers}
            placeholder="Choose sales agent"
            labelResolver={(option) => option?.name}
            keyResolver={(option) => option?.name}
          />
        </Flex>
        <Flex flexDirection="column">
          <Text lineHeight="lh300" mb="s10">
            User status
          </Text>
          <SelectInput
            value={filters?.userStatus}
            onChange={(option) => handleFilters({ userStatus: option })}
            options={status}
            placeholder="Active/Disable"
            labelResolver={(option) => option?.label}
            keyResolver={(option) => option?.key}
            disableAutocomplete
          />
        </Flex>
      </Grid>
    </Flex>
  );
};
