import styled from 'styled-components';

import { Flex, Text } from 'ui';
import { DIMENSIONS } from 'constants/dimensions';
import { useMe } from 'utils/useMe';

export const User = () => {
  const me = useMe();
  const { email, firstName, lastName } = me || {};
  const initials = (firstName?.[0] || '') + (lastName?.[0] || '');
  return (
    <Flex>
      <UserAvatar
        justifyContent="center"
        alignItems="center"
        backgroundColor="blue"
        borderRadius="circle"
      >
        <UserAvatarInitials
          color="white"
          fontWeight="medium"
          textTransform="uppercase"
        >
          {initials}
        </UserAvatarInitials>
      </UserAvatar>
      <Flex flexDirection="column" justifyContent="center" ml="s15">
        <Text lineHeight="lh200" fontWeight="medium" color="black">
          {firstName} {lastName}
        </Text>
        <Text lineHeight="lh200" color="black">
          {email}
        </Text>
      </Flex>
    </Flex>
  );
};

const UserAvatar = styled(Flex)`
  width: ${DIMENSIONS.avatarSize}px;
  height: ${DIMENSIONS.avatarSize}px;
`;

const UserAvatarInitials = styled(Text)`
  opacity: 64%;
`;
