import { GroupBase, OnChangeValue } from 'react-select';
import AsyncSelect, { AsyncProps } from 'react-select/async';
import styled, { css } from 'styled-components';
import { ColorsType, Flex, Icon, SpaceType } from 'ui';

type AutocompleteProps<OptionType, IsMulti extends boolean = false> = {
  placeholder?: string;
  onSubmit?: (value: OnChangeValue<OptionType, IsMulti> | undefined) => void;
  icon?: ButtonIcon;
};

type ButtonIcon = {
  name: string;
  color?: ColorsType;
  space?: SpaceType;
  rotate?: number;
  hoverColor?: ColorsType;
};

export const Autocomplete = <
  OptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>({
  onChange,
  icon,
  onSubmit,
  placeholder,
  styles,
  ...rest
}: AsyncProps<OptionType, IsMulti, GroupType> &
  AutocompleteProps<OptionType, IsMulti>) => {
  const handleChange = (selected: OnChangeValue<OptionType, IsMulti>) => {
    onSubmit?.(selected);
  };

  return (
    <AutocompleteWrapper>
      <AsyncSelect
        {...rest}
        placeholder={placeholder}
        onChange={handleChange}
        value={null}
        styles={{
          container: (defaultStyles) => ({
            ...defaultStyles,
            width: '100%',
          }),
          control: (defaultStyles) => ({ ...defaultStyles, minHeight: '40px' }),
          noOptionsMessage: (defaultStyles) => ({
            ...defaultStyles,
            padding: '0',
          }),
          option: (defaultStyles) => ({ ...defaultStyles, padding: '4px 8px' }),
          loadingMessage: (defaultStyles) => ({
            ...defaultStyles,
            padding: '0',
          }),
          indicatorSeparator: () => ({}),
          dropdownIndicator: (defaultStyles) => ({
            ...defaultStyles,
            '& svg': { display: 'none' },
          }),
          ...styles,
        }}
      />
      {icon && <InputIcon {...icon} />}
    </AutocompleteWrapper>
  );
};

export const AutocompleteWrapper = styled(Flex)`
  width: 100%;
  position: relative;
`;

const InputIcon = styled(Icon)<{
  space?: SpaceType;
  rotate?: number;
  hoverColor?: ColorsType;
}>`
  position: absolute;
  cursor: pointer;
  right: ${({ space, theme }) => theme.space[space ?? 's20']};
  top: 50%;
  transform: translateY(-50%)
    ${({ rotate }) => (rotate ? `rotate(${rotate}deg)` : 'rotate(0deg)')};

  &:hover {
    ${({ theme: { colors }, hoverColor }) =>
      hoverColor &&
      css`
        color: ${colors[hoverColor]};
      `};
  }
`;
