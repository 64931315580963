import { Button, Modal, ModalBody, Content, ModalProps } from 'components';
import { Flex, Grid, Text } from 'ui';
import { YourOrdersReportModal } from 'pages/YourOrders/hooks/useYourOrdersReportModal';
import styled from 'styled-components';
import { Badge } from 'components/Badge/Badge';
import { formatDate } from 'utils';
import { CreateReportForm } from './CreateReportForm';

type CreateProfileModalProps = {
  reportModal: YourOrdersReportModal;
  onGenerateReport: () => void;
} & Pick<ModalProps, 'open' | 'handleClose'>;

export const CreateReportModal = ({
  handleClose,
  open,
  reportModal,
  onGenerateReport,
}: CreateProfileModalProps) => {
  return (
    <StyledModal title={'Create report'} handleClose={handleClose} open={open}>
      <Grid gridTemplateColumns="10fr 9fr">
        <CreateReportForm reportModal={reportModal} />
        <Flex justifyContent="space-between" flexDirection="column">
          <Flex flexDirection="column">
            {(!!reportModal.reportModalData?.selectedOrderStatus ||
              !!reportModal.reportModalData?.selectedAccount?.name ||
              !!reportModal.reportModalData?.selectedDeliveryAddress?.address ||
              !!reportModal.reportModalData?.dateRangeInputValue?.length) && (
              <Text lineHeight="lh300" mb="s10">
                Picked data:
              </Text>
            )}
            <BadgeGroup>
              {!!reportModal.reportModalData?.selectedOrderStatus && (
                <Badge
                  label={reportModal.reportModalData?.selectedOrderStatus}
                  onClick={() =>
                    reportModal.handlers.handleSelectedOrderStatusChange(
                      undefined,
                    )
                  }
                />
              )}
              {!!reportModal.reportModalData?.selectedAccount?.name && (
                <Badge
                  label={reportModal.reportModalData?.selectedAccount?.name}
                  onClick={() =>
                    reportModal.handlers.handleSelectedAccountChange(undefined)
                  }
                />
              )}
              {!!reportModal.reportModalData?.selectedDeliveryAddress
                ?.address && (
                <Badge
                  label={
                    reportModal.reportModalData?.selectedDeliveryAddress
                      ?.address
                  }
                  onClick={() =>
                    reportModal.handlers.handleSelectedDeliveryAddressChange(
                      undefined,
                    )
                  }
                />
              )}
              {!!reportModal.reportModalData?.dateRangeInputValue?.length && (
                <Badge
                  label={`${formatDate(
                    reportModal.reportModalData?.dateRangeInputValue[0],
                  )} - ${formatDate(
                    reportModal.reportModalData?.dateRangeInputValue[1],
                  )}`}
                  onClick={() =>
                    reportModal.handlers.handleDateRangeInputChange(undefined)
                  }
                />
              )}
            </BadgeGroup>
          </Flex>
          <StyledButton variant="primary" onClick={onGenerateReport}>
            Create report
          </StyledButton>
        </Flex>
      </Grid>
    </StyledModal>
  );
};

export const StyledModal = styled(Modal)`
  ${ModalBody} {
    width: 684px;
  }
  ${Content} {
    overflow-y: initial;
    overflow-x: initial;
  }
`;

export const InputWrapper = styled(Flex)`
  flex-direction: column;
  max-width: 304px;
  width: 100%;
`;

export const BadgeGroup = styled(Flex)`
  flex-direction: column;
  gap: ${({ theme: { space } }) => space.s20};
`;

export const StyledButton = styled(Button)`
  align-self: flex-end;
`;
