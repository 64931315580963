import { useState } from 'react';

type TypeSelected = 'files' | 'directories';
type Id = number | string;
export type HandleSelected = (data: { id: Id; type: TypeSelected }) => void;
export type Selected = Id[];

export const useSelected = () => {
  const [state, setState] = useState<{
    files: Selected;
    directories: Selected;
  }>({
    files: [],
    directories: [],
  });

  const reset = () => {
    setState({
      files: [],
      directories: [],
    });
  };
  const arrayRemove = (arr: Selected, value: Id) => {
    return arr.filter((ele) => {
      return ele !== value;
    });
  };
  const idExist = (arr: Selected, value: Id) => arr.includes(value);
  const handleSelected = ({ id, type }: { id: Id; type: TypeSelected }) => {
    const exist = idExist(state[type], id);

    if (exist) {
      const result = arrayRemove(state[type], id);
      setState((prev) => ({ ...prev, [type]: [...result] }));
    } else {
      setState((prev) => ({ ...prev, [type]: [...prev[type], id] }));
    }
  };

  return { handleSelected, state, reset };
};
