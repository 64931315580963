import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { MainAccount } from 'graphql/mutation/useMutationRegisterClient';
import { Roles } from 'graphql/types/Roles';

export const useQueryUserById = (
  options?: QueryHookOptions<UserByIdData, UserByIdVariables>,
) => {
  return useQuery<UserByIdData, UserByIdVariables>(USER_BY_ID, options);
};

export const USER_BY_ID = gql`
  query userById($id: String!) {
    userById(id: $id) {
      name
      firstName
      lastName
      userName
      email
      roles
      accountType
      downloadFilesPermission
      createOrdersPermission
      createReportsPermission
      businessPartnerShippingAddress {
        id
        name
        address
        zipCode
        city
      }
      businessPartner {
        id
        code
        country
        name
        shippingAddresses {
          id
          name
          address
          zipCode
          city
        }
        billingAddresses {
          discount
        }
      }
      salesUser {
        id
        name
        email
      }
    }
  }
`;

export type ShippingAddress = {
  id: number;
  name: string;
  address: string;
  city: string;
  zipCode: string;
};

export type UserByIdVariables = {
  id: string;
};

export type UserByIdData = {
  userById: {
    name: string;
    firstName: string;
    lastName: string;
    userName: string;
    email: string;
    roles: Roles[];
    accountType: MainAccount;
    downloadFilesPermission: boolean;
    createOrdersPermission: boolean;
    createReportsPermission: boolean;
    businessPartnerShippingAddress?: {
      id: number;
      name: string;
      address: string;
      zipCode: string;
      city: string;
    };
    businessPartner?: {
      id: number;
      code: string;
      country: string;
      name: string;
      shippingAddresses: {
        id: number;
        name: string;
        address: string;
        zipCode: string;
        city: string;
      }[];
      billingAddresses: {
        discount: number;
      }[];
    };
    salesUser?: {
      id: number;
      name: string;
      email: string;
    };
  };
};
