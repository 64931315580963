import { ReactChild } from 'react';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

type ToastProviderProps = { children: ReactChild };

export const ToastProvider = ({ children }: ToastProviderProps) => {
  return (
    <>
      <ToastContainer
        transition={Slide}
        position="bottom-left"
        autoClose={5000}
        closeButton={false}
      />
      {children}
    </>
  );
};
