import { Fragment, useMemo } from 'react';
import styled from 'styled-components';
import { Flex, Icon } from 'ui';

type StepProgressProps = {
  steps?: number;
  activeStep: number;
};

export const StepProgress = ({ steps = 3, activeStep }: StepProgressProps) => {
  const stepsArray = useMemo(
    () => Array.from({ length: steps }, (_, i) => i + 1),
    [steps],
  );

  return (
    <StepProgressWrapper>
      {stepsArray.map((step) => (
        <Fragment key={step}>
          {step < activeStep ? (
            <StepCompleted name="16-check" />
          ) : (
            <StepActive active={step === activeStep} />
          )}
          {step < stepsArray.length && (
            <Divider completed={step < activeStep} />
          )}
        </Fragment>
      ))}
    </StepProgressWrapper>
  );
};

export const StepProgressWrapper = styled(Flex)`
  align-items: center;
  gap: 4px;
`;

export const StepCompleted = styled(Icon)`
  color: ${({ theme: { colors } }) => colors.success50};
`;

export const StepActive = styled.div<{ active: boolean }>`
  width: 11px;
  height: 11px;
  border-radius: 50%;
  border: 1px solid #c4c4c4;

  border: ${({ active, theme: { colors } }) =>
    active && `2px solid ${colors.blue}`};
`;

export const Divider = styled.div<{ completed: boolean }>`
  height: 1px;
  width: 93px;

  background: ${({ completed, theme: { colors } }) =>
    completed ? colors.success50 : colors.gray05};
`;
