import styled from 'styled-components';
import { Button } from 'components';
import { Flex } from 'ui';
import { useCarouselContext } from './CarouselContext';

const SCROLL_BY = 300;

export const CarouselControls = () => {
  const ref = useCarouselContext();

  const handleScrollLeft = () => {
    const currentScrollPos = ref?.current?.scrollLeft ?? 0;
    ref?.current?.scrollTo({
      top: 0,
      left: currentScrollPos - SCROLL_BY < 0 ? 0 : currentScrollPos - SCROLL_BY,
      behavior: 'smooth',
    });
  };

  const handleScrollRight = () => {
    const currentScrollPos = ref?.current?.scrollLeft ?? 0;
    const maxScrollWidth =
      (ref?.current?.scrollWidth ?? 0) - (ref?.current?.clientWidth ?? 0);

    ref?.current?.scrollTo({
      top: 0,
      left:
        currentScrollPos + SCROLL_BY > maxScrollWidth
          ? maxScrollWidth
          : currentScrollPos + SCROLL_BY,
      behavior: 'smooth',
    });
  };

  return (
    <ControlsWrapper>
      <Arrow
        onClick={handleScrollLeft}
        variant="outline-primary"
        icon={{ name: '20-chevron-left' }}
      />
      <Arrow
        onClick={handleScrollRight}
        variant="outline-primary"
        icon={{ name: '20-chevron-right' }}
      />
    </ControlsWrapper>
  );
};

const ControlsWrapper = styled(Flex)`
  gap: ${({ theme: { space } }) => space.s10};
`;

const Arrow = styled(Button)`
  width: 32px;
  height: 32px;
  padding: 0;
`;
