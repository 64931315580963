import { Button, Heading, StockStatus } from 'components';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuerySearchCommodities } from 'graphql/query';
import { Box, Flex, Text } from 'ui';
import {
  Cell,
  Row,
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TableHeadRow,
} from 'components/FlexTable/Table.styled';
import { useMutationAddToCart } from 'graphql/mutation';
import { paths } from 'routes';
import { Commodity } from 'graphql/types/Commodity';

export const Search = () => {
  const { value } = useParams<{ value: string }>();
  const navigate = useNavigate();

  const [addToCart] = useMutationAddToCart();
  const [searchCommodity, { data: searchCommoditiesData }] =
    useQuerySearchCommodities({
      variables: { phrase: value ?? '', source: 'JCE' },
      fetchPolicy: 'no-cache',
    });

  const data = searchCommoditiesData?.searchCommodities;

  useEffect(() => {
    searchCommodity({ variables: { phrase: value ?? '' } });
  }, [value, searchCommodity]);

  const handleValidate = (id: number) => {
    addToCart({ variables: { id, quantity: 1 } });
    navigate(paths.main.validation);
  };

  return (
    <div>
      <Box px="s30">
        <Flex>
          <Heading pt="s30">
            <Text color="gray80" fontWeight="bold" lineHeight="lh300">
              SEARCH RESULTS
            </Text>
          </Heading>
          <Text pt="s35" pl="s05" color="gray80" fontWeight="bold">
            FOR:
          </Text>
          <Text
            pt="s35"
            fontWeight="bold"
            pl="s05"
            color="black"
            textTransform="none"
          >
            {value}
          </Text>
        </Flex>
        <Flex alignItems="end" flexDirection="column">
          <Table>
            <TableHead>
              <TableHeadRow>
                <TableHeadCell flex={200}>Item code</TableHeadCell>
                <TableHeadCell flex={400}>Description</TableHeadCell>
                <TableHeadCell flex={200}>Item status</TableHeadCell>
                <TableHeadCell flex={60} />
              </TableHeadRow>
            </TableHead>
            <TableBody>
              {data?.map((item) => (
                <Fragment key={item.id}>
                  <ItemRow onValidate={handleValidate} {...item} />
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </Flex>
      </Box>
    </div>
  );
};

type ItemRowProps = {
  onValidate: (id: number) => void;
};

export const ItemRow = ({
  itemCode,
  description,
  stockStatus,
  onValidate,
  id,
}: Commodity & ItemRowProps) => {
  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);

  return (
    <Row hover onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Cell flex={200}>{itemCode}</Cell>
      <Cell flex={400}>{description}</Cell>
      <Cell flex={200}>
        <StockStatus status={stockStatus} label={stockStatus} />
      </Cell>
      <Cell flex={60}>
        {hover && (
          <Button
            onClick={() => onValidate(id)}
            variant="outline-primary"
            size="small"
          >
            Validate
          </Button>
        )}
      </Cell>
    </Row>
  );
};
