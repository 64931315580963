import { useState } from 'react';
import { Flex } from 'ui';
import { Row, Cell, Button, StockStatus } from 'components';
import { formatDate } from 'utils';
import { TransformedOrdersData } from 'pages/SalesCustomerOrders/components';

type UrgentOrdersRowProps = {
  onViewOrder: (id: number[]) => void;
} & TransformedOrdersData;

export const UrgentOrdersRow = ({
  order,
  commodity: { stockStatus },
  quantity,
  id,
  onViewOrder,
}: UrgentOrdersRowProps) => {
  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => setHover(true);
  const handleMouseLeave = () => setHover(false);

  return (
    <Row onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Cell>{order.documentNumber}</Cell>
      <Cell>{formatDate(order.createdAt)}</Cell>
      <Cell>{quantity}</Cell>
      <Cell>
        <StockStatus status={stockStatus} />
      </Cell>
      <Cell>{order.user.name}</Cell>
      <Cell>
        {hover && (
          <Flex justifyContent="flex-end">
            <Button
              variant="outline-primary"
              size="small"
              onClick={() => onViewOrder([id])}
            >
              View order
            </Button>
          </Flex>
        )}
      </Cell>
    </Row>
  );
};
