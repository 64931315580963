import { useState, useCallback, useMemo } from 'react';
import { ModalProps } from 'components';
import { useForm } from 'utils/useForm';
import { useMutationRegisterSales } from 'graphql/mutation/useMutationRegisterSales';
import { useMutationRegisterClient } from 'graphql/mutation/useMutationRegisterClient';
import { getApolloError } from 'utils';
import {
  useMutationEditClient,
  useMutationUpdateSales,
} from 'graphql/mutation';
import { CreateCustomerProfileForm } from './CreateCustomerProfileForm';
import { CreateCustomerProfileSummaryForm } from './CreateCustomerProfileSummaryForm';
import { CreateProfileForm } from './CreateProfileForm';
import { CreateSalesProfileForm } from './CreateSalesProfileForm';
import { CreateSalesProfileSummaryForm } from './CreateSalesProfileSummaryForm';
import {
  CustomerProfileFormData,
  SalesProfileFormData,
  UserRoles,
} from './CreateProfileModalContext';

type CreateProfileModalLogicProps = Pick<ModalProps, 'handleClose'> & {
  initialValuesSales?: SalesProfileFormData;
  initialValuesCustomer?: Partial<CustomerProfileFormData>;
  initialStep: number;
  initialRole?: UserRoles;
};

export const useCreateProfileModalLogic = ({
  handleClose,
  initialValuesSales = {
    email: '',
    firstName: '',
    lastName: '',
    username: '',
    userId: undefined,
  },
  initialValuesCustomer = {
    email: '',
    firstName: '',
    lastName: '',
    username: '',
    downloadFiles: false,
    createOrders: false,
    createReports: false,
  },
  initialStep,
  initialRole,
}: CreateProfileModalLogicProps) => {
  const [step, setStep] = useState(initialStep);
  const [role, setRole] = useState<UserRoles | undefined>(initialRole);

  const resetState = () => {
    setStep(0);
    setRole(undefined);
  };

  const [registerSalesMutation, { error: errorSalesRegister }] =
    useMutationRegisterSales({
      refetchQueries: ['users'],
      onCompleted: () => {
        salesProfileForm?.reset();
        resetState();
        handleClose();
      },
    });

  const [registerClientMutation, { error: errorClientRegister }] =
    useMutationRegisterClient({
      refetchQueries: ['users'],
      onCompleted: () => {
        customerProfileForm?.reset();
        resetState();
        handleClose();
      },
    });

  const [updateSalesMutation, { error: errorSalesUpdate }] =
    useMutationUpdateSales({
      refetchQueries: ['userById', 'users'],
      onCompleted: () => {
        setStep(1);
        handleClose();
      },
    });

  const [editClientMutation, { error: errorEditClient }] =
    useMutationEditClient({
      refetchQueries: ['userById', 'users'],
      onCompleted: () => {
        setStep(1);
        handleClose();
      },
    });

  const customerProfileForm = useForm<CustomerProfileFormData>({
    initialValues: initialValuesCustomer,
    validations: {
      username: {
        required: {
          value: true,
          message: 'Login is required',
        },
      },
      firstName: {
        required: {
          value: true,
          message: 'First name is required',
        },
      },
      lastName: {
        required: {
          value: true,
          message: 'Last name is required',
        },
      },
      email: {
        required: {
          value: true,
          message: 'Email is required',
        },
      },
      businessPartner: {
        required: {
          value: true,
          message: 'Business partner is required',
        },
      },
    },
    onSubmit: () => {
      setStep(2);
    },
  });

  const salesProfileForm = useForm<SalesProfileFormData>({
    initialValues: initialValuesSales,
    validations: {
      username: {
        required: {
          value: true,
          message: 'Login is required',
        },
      },
      firstName: {
        required: {
          value: true,
          message: 'First name is required',
        },
      },
      lastName: {
        required: {
          value: true,
          message: 'Last name is required',
        },
      },
      email: {
        required: {
          value: true,
          message: 'Email is required',
        },
      },
    },
    onSubmit: () => {
      setStep(2);
    },
  });

  const handleRoleChange = useCallback((option?: UserRoles) => {
    setRole(option);
  }, []);

  const defaultSteps = useMemo(
    () => [
      {
        title: 'Create Profile',
        content: <CreateProfileForm />,
        step: 1,
        firstButton: {
          label: 'Cancel',
          onClick: handleClose,
        },
        secondButton: {
          label: 'Next',
          disabled: !role,
          onClick: () => {
            setStep(1);
          },
        },
      },
    ],
    [handleClose, role],
  );

  const salesSteps = useMemo(
    () => [
      {
        title: `${
          salesProfileForm.data.userId ? 'Update' : 'Create'
        } Profile - Sales`,
        content: <CreateSalesProfileForm />,
        step: 2,
        firstButton: {
          label: 'Previous',
          disabled: !!salesProfileForm.data?.userId,
          onClick: () => {
            setStep(0);
            salesProfileForm.reset();
          },
        },
        secondButton: {
          label: 'Next',
          onClick: () => {
            salesProfileForm.handleSubmit();
          },
        },
      },
      {
        title: `${salesProfileForm.data.userId ? 'Update' : 'Create'}
         Profile - Sales - Summary`,
        content: <CreateSalesProfileSummaryForm />,
        step: 3,
        firstButton: {
          label: 'Previous',
          onClick: () => {
            setStep(1);
          },
        },
        secondButton: {
          label: `${
            salesProfileForm.data.userId ? 'Update profile' : 'Create profile'
          }`,
          onClick: () => {
            if (salesProfileForm.data?.userId) {
              updateSalesMutation({
                variables: {
                  userId: salesProfileForm.data.userId,
                  email: salesProfileForm.data.email,
                  firstName: salesProfileForm.data.firstName,
                  lastName: salesProfileForm.data.lastName,
                  username: salesProfileForm.data.username,
                },
              });
            } else {
              registerSalesMutation({
                variables: {
                  email: salesProfileForm.data.email,
                  firstName: salesProfileForm.data.firstName,
                  lastName: salesProfileForm.data.lastName,
                  username: salesProfileForm.data.username,
                },
              });
            }
          },
        },
      },
    ],
    [salesProfileForm, updateSalesMutation, registerSalesMutation],
  );

  const customerSteps = useMemo(
    () => [
      {
        title: `${
          customerProfileForm.data.id ? 'Update' : 'Create'
        } Profile - Customer`,
        content: <CreateCustomerProfileForm />,
        step: 2,
        firstButton: {
          label: 'Previous',
          onClick: () => {
            setStep(0);
            customerProfileForm.reset();
          },
          disabled: !!customerProfileForm.data?.id,
        },
        secondButton: {
          label: 'Next',
          onClick: () => {
            customerProfileForm.handleSubmit();
          },
        },
      },
      {
        title: 'Create Profile - Customer - Summary',
        content: <CreateCustomerProfileSummaryForm />,
        step: 3,
        firstButton: {
          label: 'Previous',
          onClick: () => {
            setStep(1);
          },
        },
        secondButton: {
          label: `${
            customerProfileForm?.data?.id ? 'Update profile' : 'Create profile'
          }`,
          onClick: () => {
            const {
              id,
              deliveryAddress,
              businessPartner,
              salesSupport,
              createOrders,
              createReports,
              downloadFiles,
              email,
              firstName,
              lastName,
              mainAccount,
              username,
            } = customerProfileForm?.data || {};

            const shippingAddress = deliveryAddress && {
              id: deliveryAddress?.id,
            };
            const partner = businessPartner && {
              id: businessPartner?.id,
            };
            const salesUserValue = salesSupport && {
              id: salesSupport?.id,
            };

            const variables = {
              createOrders,
              createReports,
              downloadFiles,
              email,
              firstName,
              lastName,
              mainAccount,
              username,
              shippingAddress,
              partner,
              salesUser: salesUserValue,
            };

            if (id) {
              editClientMutation({
                variables: { id, ...variables },
              });
            } else {
              registerClientMutation({
                variables,
              });
            }
          },
        },
      },
    ],
    [customerProfileForm, editClientMutation, registerClientMutation],
  );

  const getSteps = useCallback(
    (selectedRole: UserRoles | undefined) => {
      if (selectedRole?.value === 'customer')
        return [...defaultSteps, ...customerSteps];

      if (selectedRole?.value === 'salesSupport') {
        return [...defaultSteps, ...salesSteps];
      }
      return defaultSteps;
    },
    [defaultSteps, customerSteps, salesSteps],
  );

  const steps = useMemo(() => getSteps(role), [role, getSteps]);
  const error =
    getApolloError(errorSalesRegister) ||
    getApolloError(errorClientRegister) ||
    getApolloError(errorSalesUpdate) ||
    getApolloError(errorEditClient);

  const contextValue = useMemo(
    () => ({
      customerProfileForm,
      handleRoleChange,
      role,
      salesProfileForm,
      step,
      steps,
      error,
    }),
    [
      customerProfileForm,
      handleRoleChange,
      role,
      salesProfileForm,
      step,
      steps,
      error,
    ],
  );

  return contextValue;
};
