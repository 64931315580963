import { Box, Flex, Text } from 'ui';
import styled from 'styled-components';
import { useMutationNewsReadLog } from 'graphql/mutation';
import { useArticles } from './hooks/useArticles';

export const ProductUpdates = () => {
  const { artilces, getPath } = useArticles('product_update');
  const [newsReadLog] = useMutationNewsReadLog();
  return (
    <div>
      {artilces?.slice(0, 4).map((item) => {
        const { slug, title, lead, photoAlt, photoDownloadUrl } = item;
        return (
          <Item
            role="presentation"
            key={slug}
            onClick={() => {
              newsReadLog({ variables: { title } });
              window.open(getPath(slug), '_blank');
            }}
          >
            <Flex mb="s20">
              <Image
                src={photoDownloadUrl}
                alt={photoAlt}
                width={94}
                height={64}
              />
              <Box pl="s30">
                <Text as="p" lineHeight="lh300">
                  {title}
                </Text>
                <Text fontSize="fs100" color="gray80" lineHeight="lh300" as="p">
                  {lead?.slice(0, 50)}
                </Text>
              </Box>
            </Flex>
          </Item>
        );
      })}
    </div>
  );
};

const Image = styled('img')`
  object-fit: cover;
`;
const Item = styled.div`
  cursor: pointer;
  width: fit-content;
`;
