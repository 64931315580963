import { createGlobalStyle } from 'styled-components';
import { resetCss } from './reset';
import { fontsCss } from './fonts';

export const GlobalStyle = createGlobalStyle`
  ${resetCss};
  ${fontsCss};
  html{
    padding-right: calc(17px - (100vw - 100%));
  }
  body {
    font-family: 'TT Norms', sans-serif;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-overflow-scrolling: touch;
    
  }
  strong{
    font-weight: bold; ;
  }

  :root{
    --toastify-color-progress-light:#0071CE;
    --toastify-toast-min-height: 52px;
    --toastify-text-color-light: #0071CE;
  }
`;
