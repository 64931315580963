import { ButtonHTMLAttributes, ReactChild } from 'react';
import styled, { css } from 'styled-components';
import { Box, ColorsType, Icon, SpaceProps } from 'ui';
import { space as styledSystemSpace } from 'styled-system';
import { FontSizes, FontWeights, LineHeights } from 'theme';
import { Spinner } from 'components/Spinner';

export type ButtonProps = {
  children?: ReactChild;
  textColor?: ColorsType;
  fontSize?: keyof FontSizes;
  fontWeight?: keyof FontWeights;
  fullWidth?: boolean;
  icon?: ButtonIcon;
  lineHeight?: keyof LineHeights;
  size?: ButtonSize;
  variant: ButtonVariant;
  loading?: boolean;
} & SpaceProps &
  ButtonHTMLAttributes<HTMLButtonElement>;

type ButtonSize = 'small' | 'default' | 'medium';

type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'outline-primary'
  | 'outline-secondary'
  | 'outline-tertiary'
  | 'text'
  | 'icon';

type ButtonIcon = {
  position?: 'left' | 'right';
  name: string;
  color?: ColorsType;
  rotate?: number;
};

export const Button = ({
  children,
  size = 'default',
  icon,
  loading,
  ...rest
}: ButtonProps) => (
  <StyledButton size={size} loading={loading} {...rest}>
    {icon && !icon.position && <StyledIcon {...icon} />}
    {icon && icon.position === 'left' && <StyledIcon {...icon} mr="s10" />}
    {children}
    {loading && (
      <Box ml="s10">
        <Spinner size={size} />
      </Box>
    )}
    {icon && icon.position === 'right' && <StyledIcon {...icon} ml="s10" />}
  </StyledButton>
);

const getVariantCss = (variant: ButtonVariant) => {
  if (variant === 'primary') {
    return css`
      background: ${({ theme: { colors } }) => colors.blue};
      border-radius: ${({ theme: { radii } }) => radii.rounded};
      color: ${({ theme: { colors } }) => colors.white};
    `;
  }
  if (variant === 'secondary') {
    return css`
      border-radius: ${({ theme: { radii } }) => radii.rounded};
      background: ${({ theme: { colors } }) => colors.white};
      color: ${({ theme: { colors } }) => colors.blue};
    `;
  }
  if (variant === 'outline-primary') {
    return css`
      border-radius: ${({ theme: { radii } }) => radii.rounded};
      border: 1px solid ${({ theme: { colors } }) => colors.blue};
      color: ${({ theme: { colors } }) => colors.blue};
      background: ${({ theme: { colors } }) => colors.white};
    `;
  }
  if (variant === 'outline-secondary') {
    return css`
      border-radius: ${({ theme: { radii } }) => radii.rounded};
      border: 1px solid ${({ theme: { colors } }) => colors.gray10};
      color: ${({ theme: { colors } }) => colors.blue};
      background: ${({ theme: { colors } }) => colors.white};
    `;
  }
  if (variant === 'outline-tertiary') {
    return css`
      border-radius: ${({ theme: { radii } }) => radii.rounded};
      border: 1px solid ${({ theme: { colors } }) => colors.gray80};
      color: ${({ theme: { colors } }) => colors.gray80};
      background: ${({ theme: { colors } }) => colors.white};
    `;
  }
  if (variant === 'text' || variant === 'icon') {
    return css`
      color: ${({ theme: { colors } }) => colors.blue};
      padding: ${({ theme: { space } }) => space.s0};
      height: auto;
    `;
  }
  return '';
};

const StyledIcon = styled(Icon)<{ rotate?: number }>`
  transform: ${({ rotate }) => (rotate ? `rotate(${rotate}deg)` : 'rotate(0)')};
`;

const getSizeCss = (variant: ButtonSize) => {
  if (variant === 'small') {
    return css`
      height: 30px;
      padding: 0 ${({ theme: { space } }) => space.s15};
    `;
  }
  if (variant === 'default') {
    return css`
      height: 40px;
      padding: 0 ${({ theme: { space } }) => space.s20};
    `;
  }
  if (variant === 'medium') {
    return css`
      height: 44px;
      padding: 0 ${({ theme: { space } }) => space.s20};
    `;
  }
  return '';
};

const StyledButton = styled.button<{
  fontSize?: keyof FontSizes;
  fontWeight?: keyof FontWeights;
  fullWidth?: boolean;
  lineHeight?: keyof LineHeights;
  size: ButtonSize;
  textColor?: ColorsType;
  variant: ButtonVariant;
  disabled?: boolean;
  loading?: boolean;
}>`
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: center;

  ${({ size }) => getSizeCss(size)}
  ${({ variant }) => getVariantCss(variant)}
  ${styledSystemSpace};

  ${({
    theme: { fontSizes, fontWeights, lineHeights, colors },
    fontWeight,
    fontSize,
    lineHeight,
    fullWidth,
    textColor,
    disabled,
    loading,
  }) => css`
    font-size: ${fontSizes[fontSize ?? 'fs200']};
    line-height: ${lineHeights[lineHeight ?? 'lh100']};
    font-weight: ${fontWeights[fontWeight ?? 'medium']};
    color: ${textColor && colors[textColor]};
    width: ${fullWidth ? '100%' : 'max-content'};
    opacity: ${disabled ? 0.2 : 1};
    cursor: ${disabled || loading ? 'default' : 'pointer'};
    pointer-events: ${loading ? 'none' : 'all'};
  `};
`;
