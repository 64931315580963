import { LogoJuki } from './LogoJuki';
import { StyledHeader } from './StyledHeader';

export const HeaderAuth = () => {
  return (
    <StyledHeader>
      <LogoJuki />
    </StyledHeader>
  );
};
