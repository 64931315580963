import styled, { css } from 'styled-components';
import { FontSizes, LineHeights } from 'theme';

export const TextArea = styled.textarea<{
  fontSize?: FontSizes;
  lineHeight?: LineHeights;
  error?: boolean;
}>`
  ${({
    theme: { radii, space, borders, colors, fontSizes, lineHeights },
    fontSize,
    lineHeight,
    error,
  }) => css`
    padding: ${space.s20};
    box-sizing: border-box;
    border-radius: ${radii.rounded};
    border: ${borders.thinGray10};
    width: 100%;
    font-size: ${fontSize || fontSizes.fs200};
    line-height: ${lineHeight || lineHeights.lh300};
    &:hover {
      box-shadow: 0 0 0 ${space.s025} ${colors.primary20};
    }
    &:focus {
      outline: none;
      box-shadow: 0 0 0 ${space.s025} ${colors.blue};
    }
    ${error &&
    css`
      box-shadow: 0 0 0 ${space.s025} ${colors.alert50};
    `}
  `}
`;
