import { useRef } from 'react';
import styled, { css } from 'styled-components';
import { Button } from 'components';
import { Flex } from 'ui';
import { useOnClickOutside } from 'utils/useOnClickOutside';
import { Colors } from 'theme';

type DropdownProps = {
  isDropdownOpen: boolean;
  openDropdown: (open: boolean) => void;
  children?: JSX.Element | JSX.Element[];
};

export const Dropdown = ({
  isDropdownOpen,
  openDropdown,
  children,
  ...rest
}: DropdownProps) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(dropdownRef, () => openDropdown(false));

  return (
    <DropdownWrapper ref={dropdownRef} {...rest}>
      <DropdownButton
        onClick={() => openDropdown(!isDropdownOpen)}
        icon={{ name: '20-more', color: isDropdownOpen ? 'blue' : 'gray80' }}
        variant="icon"
        isOpen={isDropdownOpen}
      />
      {isDropdownOpen && (
        <DropdownOptions flexDirection="column">{children}</DropdownOptions>
      )}
    </DropdownWrapper>
  );
};

export const DropdownButton = styled(Button)<{ isOpen: boolean }>`
  position: relative;
  z-index: ${({ isOpen }) => (isOpen ? 10 : 0)};
`;

export const DropdownWrapper = styled(Flex)`
  position: relative;
`;

export const DropdownOption = styled(Button).attrs({
  variant: 'text',
  lineHeight: 'lh300',
})<{ color?: keyof Colors }>`
  ${({ theme: { space, colors }, color }) => css`
    width: 100%;
    justify-content: flex-start;
    margin: ${space.s025} 0;
    color: ${colors[color ?? 'blue']};
  `}
`;

export const DropdownOptions = styled(Flex)`
  ${({ theme: { borders, radii } }) => css`
    position: absolute;
    width: 144px;
    z-index: 9;
    border: ${borders.thinGray10};
    background: #fff;
    top: -8px;
    right: -8px;
    padding: 30px 8px 8px 8px;
    border-radius: ${radii.rounded};
  `}
`;
