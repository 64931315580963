import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { Roles } from 'graphql/types/Roles';

export function useMutationLoginByUsername(
  options?: MutationHookOptions<LoginByUsernameData, LoginByUsernameVariables>,
) {
  const data = useMutation<LoginByUsernameData, LoginByUsernameVariables>(
    LOGIN_BY_USERNAME,
    options,
  );
  return data;
}

export const LOGIN_BY_USERNAME = gql`
  mutation loginByUsername($userName: String!, $password: String!) {
    loginByUsername(username: $userName, password: $password) {
      userName
    }
  }
`;

export type LoginByUsernameVariables = {
  userName: string;
  password: string;
};

export type LoginByUsernameData = {
  login: {
    userName: string;
    roles: Roles[];
  };
};
