import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { DeliveryNoteItem } from 'graphql/types/DeliveryNoteItem';

export const useQueryDeliveryNoteItems = (
  options?: QueryHookOptions<DeliveryNoteItemsData, DeliveryNoteItemsVariables>,
) =>
  useQuery<DeliveryNoteItemsData, DeliveryNoteItemsVariables>(
    DELIVERY_NOTES,
    options,
  );

export const DELIVERY_NOTES = gql`
  query deliveryNoteItems(
    $deliveryAddress: String
    $phrase: String
    $dateFrom: String
    $dateTo: String
    $offset: Int
    $limit: Int
  ) {
    deliveryNoteItems(
      criteria: {
        orderDir: "asc"
        orderBy: "id"
        offset: $offset
        limit: $limit
      }
    ) {
      __typename
      items(
        deliveryAddress: $deliveryAddress
        phrase: $phrase
        dateFrom: $dateFrom
        dateTo: $dateTo
      ) {
        __typename
        id
        commodity {
          itemCode
          description
        }
        orderItem {
          quantity
          order {
            reference
            documentNumber
          }
        }
        quantity
        deliveryNote {
          documentNumber
          documentCreatedAt
          businessPartner {
            id
          }
          billTo {
            id
          }
          shipTo {
            id
          }
          transports {
            carrierName
            trackingUrl
            carrierNumber
          }
          pickLists {
            orders {
              id
              documentNumber
              reference
            }
          }
          invoices {
            documentNumber
            createdAt
            status
            items {
              lineNumber
              commodity {
                itemCode
                description
              }
              quantity
              value
              valueAfterDiscount
              grossAfterDiscount
              discount
              vatRate
              vatValue
            }
          }
        }
      }
      count
    }
  }
`;

export type DeliveryNoteItemsVariables = {
  deliveryAddress?: string;
  dateFrom?: string;
  dateTo?: string;
  offset?: number;
  phrase?: string;
  limit?: number;
};

export type DeliveryNoteItemsData = {
  deliveryNoteItems: { items: DeliveryNoteItem[]; count: number };
};
