import { Modal, ModalProps, Checkbox } from 'components';
import { useMutationUserUpdateShippingAddress } from 'pages/home/graphql/useMutationUserUpdateShippingAddress';
import { ShippingAddress } from 'pages/home/graphql/useQueryUser';
import styled, { css } from 'styled-components';
import { Box, Text } from 'ui';
import { getNumberWithOrdinal } from 'utils';

export interface EditDeliveryProps
  extends Pick<ModalProps, 'open' | 'handleClose'> {
  activeAddress?: ShippingAddress;
  setActiveAdresses: (address: ShippingAddress) => void;
  shippingAddresses?: ShippingAddress[];
}

export const EditDelivery = ({
  activeAddress,
  handleClose,
  open,
  shippingAddresses,
  setActiveAdresses,
}: EditDeliveryProps) => {
  const [updateShippingAddress] = useMutationUserUpdateShippingAddress({
    refetchQueries: ['user'],
  });

  const handleChangeAdresses = (address: ShippingAddress) => {
    setActiveAdresses(address);
    updateShippingAddress({ variables: { id: address.id } });
  };

  return (
    <Modal title="DELIVERY ADRESSES" handleClose={handleClose} open={open}>
      {shippingAddresses?.map((item, index) => (
        <Item
          key={`${item?.id}`}
          htmlFor={`${item.id}`}
          active={item.id === activeAddress?.id}
          as="label"
        >
          <Checkbox
            id={`${item.id}`}
            checked={item.id === activeAddress?.id}
            onChange={() => handleChangeAdresses(item)}
          />
          <div>
            <Text as="p" fontWeight="medium" mt="s0">
              {getNumberWithOrdinal(index + 1)} departament
            </Text>
            <Text as="p" mt="s05">
              {item.name}
            </Text>
            <Text as="p" mt="s05">
              {item.address}
            </Text>
            <Text as="p" mt="s05">
              {item.zipCode}
            </Text>
            <Text as="p" mt="s05">
              {item.city}
            </Text>
          </div>
        </Item>
      ))}
    </Modal>
  );
};

const Item = styled(Box)<{ active: boolean; htmlFor: string }>`
  display: flex;
  cursor: pointer;

  ${({ theme: { borders, space } }) => css`
    gap: ${space.s20};
    border: ${borders.mediumGray10};
    padding: ${space.s30};
    margin-bottom: ${space.s20};
  `};

  border-color: ${({ active, theme: { colors } }) =>
    active ? colors.blue : colors.gray10};
`;
