import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export const useMutationCartItemCreateMonitoring = (
  options?: MutationHookOptions<
    CartItemCreateMonitoringData,
    CartItemCreateMonitoringVariables
  >,
) =>
  useMutation<CartItemCreateMonitoringData, CartItemCreateMonitoringVariables>(
    CART_ITEM_CREATE_MONITORING,
    options,
  );

export const CART_ITEM_CREATE_MONITORING = gql`
  mutation cartItemCreateMonitoring($id: Int, $section: String) {
    cartItemCreateMonitoring(item: { id: $id }, section: $section) {
      id
    }
  }
`;

export type CartItemCreateMonitoringVariables = { id: number; section: string };

export type CartItemCreateMonitoringData = {
  cartItemCreateMonitoring: {
    id: string;
  };
};
