import {
  useQueryOrderStatuses,
  useQueryUserDeliveryAddresses,
} from 'graphql/query';
import { BusinessPartnerShippingAddress } from 'graphql/types/BusinessPartnerShippingAddress';
import { ChangeEvent, useState } from 'react';

type Filters = {
  searchInputValue: string;
  dateRangeInputValue?: [string | Date, string | Date];
  selectedDeliveryAddress?: BusinessPartnerShippingAddress;
  selectedOrderStatus?: string;
  selectedStockStatus?: { value: string; label: string };
};

export type YourOrdersFilters = ReturnType<typeof useYourOrdersFilters>;

const JCE_STOCK_STATUSES = [
  { value: 'IN_STOCK', label: 'In Stock' },
  { value: 'PARTIALY_IN_STOCK', label: 'Partialy in Stock' },
  { value: 'AT_HQ', label: 'At HQ' },
  { value: 'AT_OTHER_SUBSIDIARY', label: 'At other subsidiary' },
  { value: 'PRODUCTION_ORDER', label: 'Production Order' },
];

export const useYourOrdersFilters = () => {
  const [filters, setFilters] = useState<Filters>({
    searchInputValue: '',
  });

  const { data: orderStatusesData } = useQueryOrderStatuses();
  const { data: deliveryAddressData } = useQueryUserDeliveryAddresses();

  const handleSearchInputChange = (e?: ChangeEvent<HTMLInputElement>) => {
    setFilters((prev) => ({
      ...prev,
      searchInputValue: e?.target.value ?? '',
    }));
  };

  const handleSelectedOrderStatusChange = (val?: string) => {
    setFilters((prev) => ({ ...prev, selectedOrderStatus: val }));
  };

  const handleDateRangeInputChange = (val: [string | Date, string | Date]) => {
    setFilters((prev) => ({ ...prev, dateRangeInputValue: val }));
  };

  const handleSelectedDeliveryAddressChange = (
    val: BusinessPartnerShippingAddress | undefined,
  ) => {
    setFilters((prev) => ({ ...prev, selectedDeliveryAddress: val }));
  };

  const handleStockStatusChange = (val?: { value: string; label: string }) => {
    setFilters((prev) => ({ ...prev, selectedStockStatus: val }));
  };

  return {
    handlers: {
      handleSearchInputChange,
      handleSelectedOrderStatusChange,
      handleDateRangeInputChange,
      handleSelectedDeliveryAddressChange,
      handleStockStatusChange,
    },
    filters,
    options: {
      deliveryAddressOptions: deliveryAddressData?.userDeliveryAddresses ?? [],
      orderStatusOptions: orderStatusesData?.orderStatuses ?? [],
      stockStatusOptions: JCE_STOCK_STATUSES,
    },
  };
};
