import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export function useMutationUserUpdateShippingAddress(
  options?: MutationHookOptions<
    UserUpdateShippingAddressData,
    UserUpdateShippingAddressVariables
  >,
) {
  const data = useMutation<
    UserUpdateShippingAddressData,
    UserUpdateShippingAddressVariables
  >(USER_UPDATE_SHIPPING_ADDRESS, options);
  return data;
}

export const USER_UPDATE_SHIPPING_ADDRESS = gql`
  mutation userUpdateShippingAddress($id: Int!) {
    userUpdateShippingAddress(shippingAddress: { id: $id }) {
      businessPartnerShippingAddress {
        id
      }
    }
  }
`;

export interface UserUpdateShippingAddressVariables {
  id: number;
}

export interface UserUpdateShippingAddressData {
  businessPartnerShippingAddress: {
    id: number;
  };
}
