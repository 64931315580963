import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { paths } from 'routes';
import { useGetRole, useMe } from 'utils';

export const useDownloadFilesPermission = () => {
  const navigate = useNavigate();
  const me = useMe();
  const role = useGetRole();
  const isPermission = me?.downloadFilesPermission;
  useEffect(() => {
    if (!isPermission && role === 'CLIENT') {
      navigate(paths.main.notAllowed);
    }
  }, [isPermission, navigate, role]);
};
