import { Tooltip } from 'components/Tooltip';
import { Flex, Text } from 'ui';
import { getCountryWithCode, getFlagEmoji } from 'utils';
import { UserByIdData } from '../graphql/useQueryUserById';
import { Column, Label, SummaryContainer } from './common';

type Props = UserByIdData['userById'];

export const CustomerBasicInfo = ({ data }: { data?: Props }) => {
  const { name, userName, businessPartner, email } = data || {};
  const { code, country, name: company } = businessPartner || {};
  const phone = '-';

  return (
    <Flex mt={'s20'} flexDirection="column">
      <SummaryContainer p={'s30'}>
        <Flex flex={1}>
          <Column mr={'s45'}>
            <Label>User name</Label>
            <Text>{name}</Text>

            <Label>Login</Label>
            <Text>{userName}</Text>

            <Label>Code</Label>
            <Text>{code}</Text>

            <Label>Country</Label>
            {country ? (
              <Tooltip
                active={!!country}
                content={getCountryWithCode(country) || ''}
              >
                <Text fontSize="fs400">{getFlagEmoji(country)}</Text>
              </Tooltip>
            ) : (
              '-'
            )}
          </Column>
        </Flex>
        <Flex flex={1}>
          <Column mr={'s45'}>
            <Label>Company</Label>
            <Text>{company}</Text>

            <Label>User email</Label>
            <Text>{email}</Text>

            <Label>Phone</Label>
            <Text>{phone}</Text>
          </Column>
        </Flex>
      </SummaryContainer>
    </Flex>
  );
};
