import { ReactChild, useCallback, useState, useEffect } from 'react';
import { Button, Spinner } from 'components';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import styled, { css } from 'styled-components';
import { Flex, Icon, Text } from 'ui';

export type UploadInputProps = {
  onChange: (value: File[]) => void;
  loading?: boolean;
  clear?: boolean;
  type?: 'single' | 'multiple';
  childrenFiles?: ReactChild | ReactChild[];
} & DropzoneOptions;

export const UploadInput = ({
  onChange,
  loading,
  clear,
  type = 'single',
  childrenFiles,
  ...rest
}: UploadInputProps) => {
  const [value, setValue] = useState<File[]>([]);

  useEffect(() => {
    if (clear) setValue([]);
  }, [clear, onChange]);

  const onDrop = useCallback(
    (files: File[]) => {
      let result: File[] = [];
      if (type === 'single') {
        result = files;
      } else {
        result = [...value];
        files?.forEach((file) => {
          if (!value?.map((item) => item.name)?.includes(file.name)) {
            result = [...result, file];
          }
        });
      }

      onChange(result);
      setValue(result);
    },
    [onChange, type, value],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    ...rest,
  });

  const removeFile = (name: string) => {
    setValue((prev) => prev.filter((item) => item.name !== name));
  };

  return (
    <>
      <UploadInputWrapper {...getRootProps()}>
        {loading ? (
          <Spinner size="medium" />
        ) : (
          <>
            <input {...getInputProps()} />
            <Flex alignItems="center">
              <Button fontSize="fs300" variant="text">
                Drag a file here
              </Button>
              <Text color="gray80" px="s20" fontSize="fs200" lineHeight="lh300">
                or
              </Text>
              <Button
                icon={{ position: 'left', color: 'blue', name: '20-folder' }}
                variant="secondary"
                //TODO: ADD SIZING VARIANT TO BUTTON IF MORE THAN ONE HAS 44px HEIGHT
                style={{ height: '44px' }}
              >
                {value?.length && type === 'single'
                  ? '1 file(s)'
                  : 'Browse files'}
              </Button>
            </Flex>
          </>
        )}
      </UploadInputWrapper>
      {type === 'multiple' && (!!childrenFiles || !!value?.length) && (
        <Files>
          {childrenFiles}
          {value?.map((item) => (
            <File key={item.name}>
              <Icon color="blue" name="20-folder" /> {item.name}
              <CloseButton type="button" onClick={() => removeFile(item.name)}>
                <Icon name="20-close" color="gray40" />
              </CloseButton>
            </File>
          ))}
        </Files>
      )}
    </>
  );
};

export const CloseButton = styled.button`
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
    svg {
      color: ${({ theme: { colors } }) => colors.gray80};
    }
  }
`;

const Files = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${({ theme: { space } }) => space.s25};
  gap: 5px;
`;

export const File = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  gap: 5px;
  border: 1px solid ${({ theme: { colors } }) => colors.blue};
  border-radius: ${({ theme: { radii } }) => radii.rounded};
`;

const UploadInputWrapper = styled(Flex).attrs({
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
})`
  cursor: pointer;
  min-height: 80px;

  ${({ theme: { colors, radii } }) => css`
    background: ${colors.lightBlue};
    border-radius: ${radii.rounded};
  `};
`;
