import { Children, cloneElement, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Flex } from 'ui';
import { useCarouselContext } from './CarouselContext';

type CarouselProps = { children?: any[] };

export const Carousel = ({ children }: CarouselProps) => {
  const ref = useCarouselContext();
  const [mouseDown, setMouseDown] = useState(false);
  const [mouseMove, setMove] = useState(false);
  const [positionStart, setPositionStart] = useState(0);

  useEffect(() => {
    window.addEventListener('mouseup', () => {
      setMouseDown(false);
      setPositionStart(0);
      setMove(false);
    });
  });

  return (
    <CarouselWrapper ref={ref}>
      <div
        onMouseMove={(e) => {
          if (mouseDown) {
            setMove(true);
            if (ref?.current) {
              const scrollLeft = ref?.current?.scrollLeft ?? 0;

              ref.current.scrollTo({
                left:
                  e.movementX < 0
                    ? scrollLeft + (positionStart - e.clientX)
                    : scrollLeft - (e.clientX - positionStart),
              });
              setPositionStart(e.clientX);
            }
          }
        }}
        onMouseDown={(e) => {
          setMouseDown(true);
          setPositionStart(e.clientX);
        }}
      >
        <InnerWrapper>
          {Children.map(children, (child) => {
            return (
              child &&
              cloneElement(
                child,
                mouseMove
                  ? {}
                  : { onMouseUp: child?.props?.onClick, onClick: undefined },
              )
            );
          })}
        </InnerWrapper>
      </div>
    </CarouselWrapper>
  );
};

const CarouselWrapper = styled(Flex)`
  overflow-x: auto;
  padding: ${({ theme: { space } }) =>
    `${space.s10} 0 ${space.s20} ${space.s30}`};

  scrollbar-color: transparent transparent;
  scrollbar-width: 0px;
  -ms-overflow-style: none;

  ::-webkit-scrollbar {
    width: 0;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
    border: none;
  }
`;

const InnerWrapper = styled.div`
  ${({ theme: { space } }) => css`
    display: flex;
    padding-right: ${space.s50};
    gap: ${space.s20};
  `}
`;
