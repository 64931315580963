import styled from 'styled-components';
import { DateRangeInput, SelectInput } from 'components';
import { Text, Flex } from 'ui';
import { YourOrdersReportModal } from 'pages/YourOrders/hooks/useYourOrdersReportModal';

type CreateReportFormProps = { reportModal: YourOrdersReportModal };

export const CreateReportForm = ({
  reportModal: { handlers, reportModalData, options },
}: CreateReportFormProps) => {
  return (
    <CreateReportFormWrapper>
      <InputWrapper>
        <Text lineHeight="lh300" mb="s10">
          Choose order status
        </Text>
        <SelectInput
          value={reportModalData?.selectedOrderStatus}
          onChange={handlers.handleSelectedOrderStatusChange}
          options={options.orderStatusOptions}
          placeholder="Open / Closed"
          labelResolver={(option) => option}
          keyResolver={(option) => option}
        />
      </InputWrapper>
      <InputWrapper>
        <Text lineHeight="lh300" mb="s10">
          Choose account
        </Text>
        <SelectInput
          value={reportModalData?.selectedAccount}
          onChange={handlers.handleSelectedAccountChange}
          options={options.subAccountUsersOptions}
          placeholder="Choose account to create report"
          labelResolver={(option) => option?.name}
          keyResolver={(option) => option?.id}
        />
      </InputWrapper>
      <InputWrapper>
        <Text lineHeight="lh300" mb="s10">
          Choose delivery address
        </Text>
        <SelectInput
          value={reportModalData?.selectedDeliveryAddress}
          onChange={handlers.handleSelectedDeliveryAddressChange}
          options={options.deliveryAddressOptions}
          placeholder="Choose address to create report"
          labelResolver={(option) => option?.address}
          keyResolver={(option) => option?.id}
        />
      </InputWrapper>
      <InputWrapper>
        <Text lineHeight="lh300" mb="s10">
          Date range
        </Text>
        <DateRangeInput
          //@ts-ignore
          onChange={handlers.handleDateRangeInputChange}
          //@ts-ignore
          value={reportModalData?.dateRangeInputValue}
        />
      </InputWrapper>
    </CreateReportFormWrapper>
  );
};

export const CreateReportFormWrapper = styled(Flex)`
  gap: ${({ theme: { space } }) => space.s10};
  flex-direction: column;
`;

export const InputWrapper = styled(Flex)`
  flex-direction: column;
  max-width: 304px;
  width: 100%;
`;

export const SectionWrapper = styled(Flex)`
  gap: ${({ theme: { space } }) => space.s10};
  flex-direction: column;
  margin-bottom: ${({ theme: { space } }) => space.s20};
`;

export const SubTitle = styled(Text)`
  color: ${({ theme: { colors } }) => colors.gray80};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.medium};
  line-height: ${({ theme: { lineHeights } }) => lineHeights.lh300};
`;

export const Label = styled(Text)`
  line-height: ${({ theme: { lineHeights } }) => lineHeights.lh300};
`;

export const CheckboxWrapper = styled(Flex)`
  align-items: center;
  gap: ${({ theme: { space } }) => space.s10};
`;
