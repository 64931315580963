import { gql, QueryHookOptions, useQuery } from '@apollo/client';

export const useQueryUserLoginReport = (
  options?: QueryHookOptions<UserLoginReportData, UserLoginReportVariables>,
) =>
  useQuery<UserLoginReportData, UserLoginReportVariables>(
    USER_LOGIN_REPORT,
    options,
  );

export const USER_LOGIN_REPORT = gql`
  query userLoginReport($userId: String) {
    userLoginReport(userId: $userId) {
      id
      user
      resourceName
      createdAt
    }
  }
`;

export type UserLoginReportVariables = {
  userId?: string;
};

export type UserLoginReportData = {
  userLoginReport: UserLoginReport[];
};

export type UserLoginReport = {
  id: number;
  user: string;
  resourceName: string;
  createdAt: string;
};
