import { useRef, useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalProps } from 'components';
import { Flex, Grid, Text } from 'ui';
import styled from 'styled-components';
import { Badge } from 'components/Badge/Badge';
import { useForm } from 'utils/useForm';
import { formatDate, useReportRestApi } from 'utils';
import { User } from 'graphql/types/User';
import { Commodity } from 'graphql/types/Commodity';
// eslint-disable-next-line import/no-cycle
import { CreateReportForm } from './CreateReportForm';

export type CustomerReportRequestData = {
  usersIds: string;
  dateFrom: string;
  dateTo: string;
  login: boolean;
  activityTime: boolean;
  logout: boolean;
  productSearches: boolean;
  productCode: string;
  filesDownload: boolean;
  newsRead: boolean;
  orderResponseTime: boolean;
  orderId: string;
  wishlist: boolean;
};

const REPORT_SLUG = '/juki/download/generateCustomerReport';

type CreateProfileModalProps = {
  initialData?: CustomerReportRequestData;
  handleUserchange?: (usersIds: string) => void;
} & Pick<ModalProps, 'open' | 'handleClose'>;

export const CreateReportModal = ({
  initialData,
  handleClose,
  open,
}: CreateProfileModalProps) => {
  const { handleChange, handleSubmit, errors, data, setData } =
    useForm<CustomerReportRequestData>({
      initialValues: {
        usersIds: '',
        dateFrom: '',
        dateTo: '',
        login: false,
        activityTime: false,
        logout: false,
        productSearches: false,
        productCode: '',
        filesDownload: false,
        newsRead: false,
        orderResponseTime: false,
        orderId: '',
        wishlist: false,
      },
    });

  const [selectedProducts, setSelectedProducts] = useState<Commodity[]>();
  const [selectedUsers, setSelectedUsers] = useState<User[]>();

  useEffect(() => {
    if (initialData) {
      setData(initialData);
    }
  }, [initialData, setData]);

  const handleUpdateArray = (arr: any[], item: any) => {
    let updatedArr = arr;
    if (arr && arr.length > 0) {
      if (!arr.includes(item)) {
        updatedArr = [...arr, item];
      }
    } else {
      updatedArr = [item];
    }
    return updatedArr;
  };

  const userIdsArr = data.usersIds.split(';').filter((item) => item);
  const productCodeArr = data.productCode.split(';').filter((item) => item);

  const handleUserChange = (user: User, remove: boolean) => {
    let updatedUsersIds;
    let updatedUsers;
    if (remove) {
      updatedUsersIds = userIdsArr
        .filter((item) => user.id.toString() !== item)
        .map((item) => item)
        .join(';');
      updatedUsers = selectedUsers?.filter((item) => item.id !== user.id);
    } else {
      updatedUsersIds = handleUpdateArray(userIdsArr, user.id.toString()).join(
        ';',
      );
      updatedUsers = selectedUsers
        ? handleUpdateArray(selectedUsers, user)
        : [user];
    }
    setSelectedUsers(updatedUsers);
    handleChange('usersIds', updatedUsersIds);
  };

  const handleCommodityChange = (commodity: Commodity, remove: boolean) => {
    let updatedProductCode;
    let updatedProducts;

    if (remove) {
      updatedProductCode = productCodeArr
        .filter((item) => commodity.id.toString() !== item)
        .map((item) => item)
        .join(';');
      updatedProducts = selectedProducts?.filter(
        (item) => item.id !== commodity.id,
      );
    } else {
      updatedProductCode = handleUpdateArray(
        productCodeArr,
        commodity.id.toString(),
      ).join(';');
      updatedProducts = selectedProducts
        ? handleUpdateArray(selectedProducts, commodity)
        : [commodity];
    }

    setSelectedProducts(updatedProducts);
    handleChange('productCode', updatedProductCode);
  };

  const handleClickRemoveDate = () => {
    handleChange('dateFrom', '');
    handleChange('dateTo', '');
  };

  const handleClickRemoveOrder = () => {
    handleChange('orderId', '');
  };

  const downloadAnchorRef = useRef<HTMLAnchorElement>(null);

  const { getAPIData } = useReportRestApi<CustomerReportRequestData>({
    callback: (blob) => {
      const downloadAnchor = downloadAnchorRef.current;

      if (!downloadAnchor) return;
      const today = new Date();
      downloadAnchor.href = URL.createObjectURL(blob);
      downloadAnchor.download = `Report-${formatDate(today)}`;
      downloadAnchor.click();
      handleClose();
    },
    slug: REPORT_SLUG,
  });

  const handleSend = () => {
    handleSubmit().then((formdata) => {
      if (!formdata) {
        getAPIData(data);
      }
    });
  };

  return (
    <StyledModal title={'Create report'} handleClose={handleClose} open={open}>
      <Grid gridTemplateColumns="10fr 9fr">
        <CreateReportForm
          data={data}
          handleUserSelect={(u) => handleUserChange(u, false)}
          handleCommoditySelect={(c) => handleCommodityChange(c, false)}
          handleChange={handleChange}
          errors={errors}
        />
        <Flex justifyContent="space-between" flexDirection="column">
          <Flex flexDirection="column">
            <Text lineHeight="lh300" mb="s10">
              Picked data
            </Text>
            <BadgeGroup>
              {selectedUsers?.map((item) => (
                <Badge
                  key={item.id}
                  label={`${item.name} (${item.id})`}
                  onClick={() => handleUserChange(item, true)}
                />
              ))}
              {data?.dateFrom && data?.dateTo && (
                <Badge
                  label={`${data.dateFrom.toLocaleString()} - ${data.dateTo.toLocaleString()}`}
                  onClick={() => handleClickRemoveDate()}
                />
              )}
              {selectedProducts?.map((item) => (
                <Badge
                  key={item.id}
                  label={item.description}
                  onClick={() => handleCommodityChange(item, true)}
                />
              ))}
              {data?.orderId && (
                <Badge
                  label={data.orderId}
                  onClick={() => handleClickRemoveOrder()}
                />
              )}
              {data?.login && (
                <Badge
                  label="Login"
                  onClick={() => handleChange('login', false)}
                />
              )}
              {data?.activityTime && (
                <Badge
                  label="Activity time"
                  onClick={() => handleChange('activityTime', false)}
                />
              )}
              {data?.logout && (
                <Badge
                  label="Include logouts"
                  onClick={() => handleChange('logout', false)}
                />
              )}
              {data?.productSearches && (
                <Badge
                  label="Select all avilable data of searches"
                  onClick={() => handleChange('productSearches', false)}
                />
              )}
              {data?.filesDownload && (
                <Badge
                  label="Only files download"
                  onClick={() => handleChange('filesDownload', false)}
                />
              )}
              {data?.newsRead && (
                <Badge
                  label="Only news read"
                  onClick={() => handleChange('newsRead', false)}
                />
              )}
              {data?.orderResponseTime && (
                <Badge
                  label="Select all avilable data of orders response time"
                  onClick={() => handleChange('orderResponseTime', false)}
                />
              )}
              {data?.wishlist && (
                <Badge
                  label="Wishlist"
                  onClick={() => handleChange('wishlist', false)}
                />
              )}
            </BadgeGroup>
          </Flex>
          <StyledButton variant="primary" onClick={() => handleSend()}>
            Download report
          </StyledButton>
          <StyledAnchor ref={downloadAnchorRef} />
        </Flex>
      </Grid>
    </StyledModal>
  );
};

export const StyledAnchor = styled.a`
  display: none;
`;

export const StyledModal = styled(Modal)`
  ${ModalBody} {
    width: 684px;
  }
`;

export const InputWrapper = styled(Flex)`
  flex-direction: column;
  max-width: 304px;
  width: 100%;
`;

export const BadgeGroup = styled(Flex)`
  flex-direction: column;
  gap: ${({ theme: { space } }) => space.s20};
`;

export const StyledButton = styled(Button)`
  align-self: flex-end;
`;
