import styled, { css } from 'styled-components';
import { Flex, Text } from 'ui';
import {
  Cell,
  Row,
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  ExpandRow,
} from 'components/FlexTable/Table.styled';
import { Purchase } from 'graphql/types/ImportItem';
import { formatDate } from 'utils';

type BackorderQuantityExpandRowProps = {
  data: Purchase[];
};

export const BackorderQuantityExpandRow = ({
  data,
}: BackorderQuantityExpandRowProps) => (
  <ExpandRow>
    <Flex flex="1" flexDirection="column">
      <Table>
        <BackorderQuantityHead />
        <TableBody>
          {data.map((item) => (
            <BackorderQuantityRow key={item.documentNumber} {...item} />
          ))}
        </TableBody>
      </Table>
      <TableSummary>
        <TableSummaryLabel>Backorder Quantity (total)</TableSummaryLabel>
        <TableSummaryText>
          {data.reduce((n, { quantity }) => n + quantity, 0) ?? '0'}
        </TableSummaryText>
      </TableSummary>
    </Flex>
  </ExpandRow>
);

const BackorderQuantityRow = ({
  documentNumber,
  documentCreatedAt,
  productionDate,
  shippedFrom,
  expectedArrival,
  invoice,
  quantity,
}: Purchase) => (
  <SubRow>
    <Cell flex={136}>{documentNumber ?? '-'}</Cell>
    <Cell flex={184}>{formatDate(documentCreatedAt) ?? '-'}</Cell>
    <Cell flex={192}>{invoice?.documentNumber ?? '-'}</Cell>
    <Cell flex={152}>{formatDate(productionDate) ?? '-'}</Cell>
    <Cell flex={152}>{formatDate(shippedFrom) ?? '-'}</Cell>
    <Cell flex={152}>{expectedArrival ?? '-'}</Cell>
    <Cell flex={128}>{quantity ?? '0'}</Cell>
  </SubRow>
);

const BackorderQuantityHead = () => (
  <SubTableHead>
    <TableHeadRow>
      <SubTableHeadCell flex={136}>PO Number</SubTableHeadCell>
      <SubTableHeadCell flex={184}>PO Date</SubTableHeadCell>
      <SubTableHeadCell flex={192}>INV Number</SubTableHeadCell>
      <SubTableHeadCell flex={152}>Exp. Prod. Date</SubTableHeadCell>
      <SubTableHeadCell flex={152}>Shipped from JP</SubTableHeadCell>
      <SubTableHeadCell flex={152}>Exp. Arrival Time</SubTableHeadCell>
      <SubTableHeadCell flex={128}>Qty</SubTableHeadCell>
    </TableHeadRow>
  </SubTableHead>
);

export const SubRow = styled(Row)`
  ${({ theme: { colors } }) => css`
    background: ${colors.lightBlue};

    &:last-child {
      border-bottom: none;
    }
  `};
`;

export const SubTableHead = styled(TableHead)`
  ${({ theme: { colors, borders } }) => css`
    background: ${colors.lightBlue};
    border-bottom: ${borders.thinGray10};
  `};
`;

export const SubTableHeadCell = styled(TableHeadCell)`
  ${({ theme: { space } }) => css`
    padding: ${space.s15} ${space.s05} ${space.s0};
  `};
`;

export const TableSummary = styled(Flex)`
  align-items: center;
  justify-content: flex-end;

  ${({ theme: { colors, borders, space } }) => css`
    background: ${colors.lightBlue};
    border-top: ${borders.thinGray10};
    min-height: ${space.s60};
    padding: 0 ${space.s05};
  `};
`;

const TableSummaryText = styled(Text).attrs({ lineHeight: 'lh300' })`
  flex: 128;

  ${({ theme: { space } }) => css`
    padding: ${space.s05} ${space.s05};
  `}
`;

const TableSummaryLabel = styled(TableSummaryText)`
  flex: 968;
  text-align: right;

  ${({ theme: { fontWeights, space, fontSizes } }) => css`
    font-weight: ${fontWeights.bold};
    font-size: ${fontSizes.fs100};
    padding-right: ${space.s35};
  `}
`;
