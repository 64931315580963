import { Tooltip } from 'components/Tooltip';
import styled, { css } from 'styled-components';
import { Flex, Icon, Text } from 'ui';
import { measureText } from 'utils';

type Props = {
  id: string;
  name: string;
};

export const Attachment = ({ name, id }: Props) => {
  const extensionFile = name?.match(
    /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/gim,
  )?.[0];
  const nameFile = name?.replace(extensionFile || '', '');
  const href = `${process.env.REACT_APP_PUBLIC_HOST}/juki/attachment/file_download/${id}`;
  const activeTooltip = measureText(nameFile) > 176.4;
  return (
    <StyledAttachment flexDirection="column">
      <Tooltip content={nameFile} active={activeTooltip}>
        <StyledText color="blue" fontWeight="bold">
          {nameFile}
        </StyledText>
      </Tooltip>
      <Flex justifyContent="space-between" mt="s05">
        <Text color="gray80" textTransform="uppercase">
          {extensionFile?.slice(1)}
        </Text>
        <a href={href} download>
          <Icon name="20-download" />
        </a>
      </Flex>
    </StyledAttachment>
  );
};

const StyledText = styled(Text)`
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
`;

const StyledAttachment = styled(Flex)`
  ${({ theme: { radii, borders } }) => css`
    border-radius: ${radii.rounded};
    border: ${borders.thinGray10};
    width: 212px;
    height: 60px;
    padding: 8px 16px;
  `}
`;
