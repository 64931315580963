import {
  Cell,
  Row,
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  RegistrationStatus,
} from 'components';
import { CartItem } from 'graphql/types/CartItem';

type WishlistProps = {
  data: CartItem[];
};

export const Wishlist = ({ data }: WishlistProps) => {
  return (
    <div>
      <Table>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell>Item code</TableHeadCell>
            <TableHeadCell>Description</TableHeadCell>
            <TableHeadCell>Qty</TableHeadCell>
            <TableHeadCell>List price</TableHeadCell>
            <TableHeadCell>Registration status</TableHeadCell>
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {data?.map((item) => (
            <Row key={item.id}>
              <Cell>{item.commodity.itemCode}</Cell>
              <Cell>{item.commodity.description}</Cell>
              <Cell>{item.quantity}</Cell>
              <Cell>{item.price}</Cell>
              <Cell>
                <RegistrationStatus
                  status={item.commodity.sellable ? 'registered' : 'processing'}
                />
              </Cell>
            </Row>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
