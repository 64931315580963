import { getCountryWithCode } from './getCountryWithCode';

export const sortCountry = (data?: string[]) => {
  const result = [...(data || [])]?.sort((a, b) => {
    const nameA = getCountryWithCode(a) || '';
    const nameB = getCountryWithCode(b) || '';
    if (nameA > nameB) {
      return 1;
    }
    if (nameB > nameA) {
      return -1;
    }
    return 0;
  });

  return result || [];
};
