import { useState, useEffect } from 'react';
import { Button, Label, Modal, SelectInput, TextInput } from 'components';
import styled, { css } from 'styled-components';
import { Box, Flex } from 'ui';

import {
  useQueryWishlistMailingConfig,
  useQueryIntervals,
  useQuerySalesAndAdminUsers,
  SalesAndAdminUser,
} from 'graphql/query';
import { useMutationChangeWishlistMailingConfig } from 'graphql/mutation';

type ModalAutomationProps = {
  open: boolean;
  handleClose: () => void;
};

export const ModalAutomation = ({
  handleClose,
  open,
}: ModalAutomationProps) => {
  const wishlistMailingConfigQuery = useQueryWishlistMailingConfig();
  const wishlistMailingConfig =
    wishlistMailingConfigQuery?.data?.wishlistMailingConfig;

  const intervalsQuery = useQueryIntervals();
  const intervals = intervalsQuery?.data?.intervals
    ? intervalsQuery.data.intervals
    : [];

  const [interval, setInterval] = useState<string | undefined>(undefined);

  const salesAndAdminUsersQuery = useQuerySalesAndAdminUsers();
  const salesAndAdminUsers = salesAndAdminUsersQuery?.data?.salesAndAdminUsers
    ? salesAndAdminUsersQuery.data.salesAndAdminUsers
    : [];

  const [reveicerUser, setReveicerUser] = useState<
    SalesAndAdminUser | undefined
  >(undefined);

  const [cCUser, setCCUser] = useState<string>('');

  useEffect(() => {
    if (wishlistMailingConfig) {
      setInterval(wishlistMailingConfig.interval);
      setReveicerUser(wishlistMailingConfig.receiverUser);
      setCCUser(wishlistMailingConfig.cC);
    }
  }, [wishlistMailingConfig]);

  const handleSend = () => {
    changeWishlistMailingConfig({
      variables: {
        userId: reveicerUser?.id.toString() || '',
        cc: cCUser,
        interval: interval || '',
      },
    });
  };

  const [changeWishlistMailingConfig, { loading }] =
    useMutationChangeWishlistMailingConfig({
      onCompleted: () => {
        handleClose();
      },
    });

  return (
    <Modal handleClose={handleClose} open={open} title="WISHLIST AUTOMATION">
      <Box>
        <Label>Receiver</Label>
        <WrapperInput>
          <Label>Choose reveicer for automatic delivery</Label>
          <SelectInput
            value={reveicerUser}
            onChange={setReveicerUser}
            options={salesAndAdminUsers}
            placeholder="Choose from list"
            labelResolver={(option) => option?.name}
            keyResolver={(option) => option?.id}
          />
        </WrapperInput>
        <WrapperInput>
          <Label>Add CC receiver</Label>
          <TextInput
            placeholder="Insert CC receiver email"
            defaultValue={cCUser}
            onChange={(e) => setCCUser(e?.currentTarget.value || '')}
          />
        </WrapperInput>
      </Box>

      <Box mt="s10">
        <Label>Time arragement</Label>
        <WrapperInput>
          <Label>Choose time interval</Label>
          <SelectInput
            value={interval}
            onChange={setInterval}
            options={intervals}
            placeholder="Choose interval"
            labelResolver={(option) => option}
            keyResolver={(option) => option}
          />
        </WrapperInput>
      </Box>
      <Flex justifyContent="flex-end" py="s30">
        <Button variant="primary" onClick={handleSend} disabled={loading}>
          Save
        </Button>
      </Flex>
    </Modal>
  );
};

const WrapperInput = styled.div`
  ${({ theme: { space } }) => css`
    padding: ${space.s10} ${space.s60};
  `}
`;
