import styled, { css } from 'styled-components';
import { Grid, Flex, Text } from 'ui';
import { useCreateProfileModalContext } from './CreateProfileModalContext';

export const CreateSalesProfileSummaryForm = () => {
  const { salesProfileForm, role } = useCreateProfileModalContext();

  return (
    <Flex mt={'s20'} mx={'s20'} flexDirection="column">
      <Flex mt={'s20'}>
        <SummaryContainer mr={'s20'} p={'s30'} flex={1}>
          <Flex flex={1}>
            <Column>
              <Label>User name</Label>
              <Text>
                {`${salesProfileForm.data.firstName} ${salesProfileForm.data.lastName}`}
              </Text>

              <Label>Login</Label>
              <Text>{salesProfileForm.data.username}</Text>

              <Label>User email</Label>
              <Text>{salesProfileForm.data.email}</Text>
            </Column>
          </Flex>
        </SummaryContainer>
        <SummaryContainer p={'s30'} flex={1}>
          <Flex flex={1}>
            <Column>
              <Label>Role</Label>
              <Text>{role?.label}</Text>
            </Column>
          </Flex>
        </SummaryContainer>
      </Flex>
    </Flex>
  );
};

const Column = styled(Grid).attrs({
  gridTemplateColumns: 'auto auto',
  gridAutoRows: 'max-content',
})`
  gap: ${({ theme: { space } }) => `${space.s15} ${space.s40}`};
`;

const Label = styled(Text).attrs({ fontWeight: 'medium', color: 'gray80' })``;

const SummaryContainer = styled(Flex)`
  height: fit-content;

  ${({ theme: { colors, radii } }) => css`
    background: ${colors.gray06};
    border-radius: ${radii.rounded};
  `};
`;
