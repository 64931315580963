import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { ROLES } from 'constants/roles';

export const useMutationUpdateSales = (
  options?: MutationHookOptions<UpdateSalesData, UpdateSalesVariables>,
) => useMutation(UPDATE_SALES, options);

export const UPDATE_SALES = gql`
  mutation updateSales(
    $email: String!
    $firstName: String!
    $lastName: String!
    $username: String!
    $userId: String!
  ) {
    updateSales(
      email: $email
      firstName: $firstName
      lastName: $lastName
      username: $username
      userId: $userId
    ) {
      id
      roles
    }
  }
`;

export type UpdateSalesVariables = {
  email: string;
  firstName: string;
  lastName: string;
  username: string;
  userId: string;
};

export type UpdateSalesData = {
  updateSales: {
    id: string;
    roles: ROLES[];
  };
};
