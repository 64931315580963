import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export const useMutationCartOrderAll = (
  options?: MutationHookOptions<CartOrderAllData, CartOrderAllVariables>,
) =>
  useMutation<CartOrderAllData, CartOrderAllVariables>(CART_ORDER_ALL, options);

export const CART_ORDER_ALL = gql`
  mutation cartOrderAll($reference: String) {
    cartOrderAll(reference: $reference) {
      id
      reference
      items {
        id
        quantity
        commodity {
          id
          itemCode
        }
      }
    }
  }
`;

export type CartOrderAllVariables = {
  reference: string;
};

export type CartOrderAllData = {
  cartOrderAll: {
    id: number;
    reference: string;
    items: {
      id: number;
      quantity: string;
      commodity: {
        id: number;
        itemCode: string;
      };
    }[];
  };
};
