/* eslint-disable react/no-danger */
import { Breadcrumbs, Divider, Heading } from 'components';
import { Accordion } from 'components/Accordion/Accordion';
import { paths } from 'routes';
import styled, { css } from 'styled-components';
import { Box, Text } from 'ui';
import { useQueryFaqAnswer } from './graphql/useQueryFaqAnswer';

export const Faq = () => {
  const { data } = useQueryFaqAnswer();
  return (
    <div>
      <Breadcrumbs
        px="s30"
        items={[
          { label: 'Home', to: '/' },
          { label: 'FAQ', to: paths.main.faq },
        ]}
      />
      <Divider />
      <Box px="s30">
        <Heading>Frequently Asked Questions</Heading>
        <Box my="s50" px="s25">
          {data?.faqAnswers?.items?.map((item, index) => (
            <Accordion
              key={item.id}
              heading={
                <AccordionTitle>
                  {index + 1}
                  {'. '}
                  {item.title}
                </AccordionTitle>
              }
            >
              <AccordionContent>
                <div dangerouslySetInnerHTML={{ __html: item?.text }} />
              </AccordionContent>
            </Accordion>
          ))}
          <Divider />
        </Box>
      </Box>
    </div>
  );
};

const AccordionTitle = styled(Text)`
  ${({ theme: { space } }) => css`
    padding: ${space.s20} 0;
  `}
`;

const AccordionContent = styled.div`
  ${({ theme: { borders, space } }) => css`
    border-top: ${borders.thinGray10};
    border-radius: 4px;
    background: #e3edf6;
    padding: ${space.s20} ${space.s30};
  `}
`;
