import { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Checkbox } from 'components';
import { Flex, Text, Icon, MarginProps, Box } from 'ui';
import { useOnClickOutside } from 'utils/useOnClickOutside';
import { Tooltip } from 'components/Tooltip';
import { DocumentDropdown, OptionButton } from './DocumentDropdown';
import { HandleSelected, Selected } from '../utils/useSelected';
import { downloadFile } from '../utils/downloadFile';

interface Props extends MarginProps {
  id: string;
  displayName: string;
  selected: Selected;
  handleSelected: HandleSelected;
  isViewPrivileges?: boolean;
}

export const File = ({
  displayName,
  id,
  handleSelected,
  selected,
  isViewPrivileges,
  ...rest
}: Props) => {
  const [hover, setHover] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const visibleCheckbox = (hover || selected) && !isViewPrivileges;
  const documentOptionsRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => setDropdownOpen(!isDropdownOpen);
  const closeDropdown = () => setDropdownOpen(false);
  const handleDownload = () => {
    downloadFile({
      id,
      organization_id: 1,
    });
    closeDropdown();
  };
  const isSelected = selected?.includes(id);

  const handleSelect = () => {
    handleSelected({ id, type: 'files' });
    closeDropdown();
  };

  useOnClickOutside(documentOptionsRef, closeDropdown);
  return (
    <FileContainer
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      flexDirection="column"
      justifyContent="space-between"
      px="s20"
      pt="s20"
      pb="s17"
      backgroundColor={hover || isSelected ? 'lightBlue' : 'white'}
      {...rest}
    >
      <ClickableArea onClick={handleSelect} />
      <Flex justifyContent="space-between" mb="s10">
        <Icon name="20-documents" color="gray05" />
        {visibleCheckbox && (
          <Checkbox checked={isSelected} onChange={handleSelect} />
        )}
      </Flex>

      <Flex alignItems="center" justifyContent="space-between">
        <Tooltip content={<TooltipText>{displayName}</TooltipText>}>
          <DisplayName>{displayName}</DisplayName>
        </Tooltip>
        <DocumentDropdown
          onClickOutside={closeDropdown}
          isDropdownOpen={isDropdownOpen}
          openDropdown={(open) => setDropdownOpen(open)}
          onButtonClick={toggleDropdown}
        >
          <OptionButton onClick={handleDownload}>Download</OptionButton>
        </DocumentDropdown>
      </Flex>
    </FileContainer>
  );
};

const DisplayName = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 140px;
`;

const TooltipText = styled(Text)`
  word-break: break-all;
  display: block;
  max-width: 500px;
`;

const FileContainer = styled(Flex)`
  ${({ theme: { radii, borders } }) => css`
    border: ${borders.thinGray10};
    width: 212px;
    height: 90px;
    border-radius: ${radii.rounded}px;
    position: relative;
  `}
`;

export const ClickableArea = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;
