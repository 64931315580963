import styled, { css } from 'styled-components';

import { Text } from 'ui';

export const MessageLabel = styled(Text).attrs({
  px: 's10',
  fontWeight: 'medium',
  borderRadius: 'rounded',
  lineHeight: 'lh300',
})`
  ${({ theme: { space } }) => css`
    display: block;
    font-size: 11px;
    height: ${space.s30};
    white-space: nowrap;
    max-width: ${space.s160};
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;
