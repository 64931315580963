import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export const useMutationOrderRemoveMonitoring = (
  options?: MutationHookOptions<
    OrderRemoveMonitoringData,
    OrderRemoveMonitoringVariables
  >,
) =>
  useMutation<OrderRemoveMonitoringData, OrderRemoveMonitoringVariables>(
    ORDER_REMOVE_MONITORING,
    options,
  );

export const ORDER_REMOVE_MONITORING = gql`
  mutation orderRemoveMonitoring($section: String) {
    orderRemoveMonitoring(section: $section) {
      id
    }
  }
`;

export type OrderRemoveMonitoringVariables = {
  section: string;
};

export type OrderRemoveMonitoringData = {
  orderRemoveMonitoring: {
    id: string;
  };
};
