import { ColorsType } from 'ui';
import { StockStatuses } from 'graphql/types/StockStatuses';

type Result = {
  color: ColorsType;
};

export const convertStatus = (status: StockStatuses): Result | undefined => {
  switch (status) {
    case 'IN_STOCK':
    case 'PARTIALY_IN_STOCK':
      return {
        color: 'success50',
      };
    case 'AT_HQ':
    case 'AT_OTHER_SUBSIDIARY':
    case 'PRODUCTION_ORDER':
      return {
        color: 'alert50',
      };
    default:
      return;
  }
};
