import { Flex } from 'ui';
import { Spinner } from './Spinner';

type TableSpinnerProps = {
  loading: boolean;
};

export const TableSpinner = ({ loading }: TableSpinnerProps) => {
  if (!loading) {
    return null;
  }
  return (
    <Flex justifyContent="center" p="s40" color="gray40">
      <Spinner size="medium" />
    </Flex>
  );
};
