import { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { useDebouncedCallback } from 'use-debounce';

import { Box, MarginProps } from 'ui';
import { TextInput, TextInputProps } from 'components';
import { useOnClickOutside } from 'utils/useOnClickOutside';

export type SearchBoxProps = {
  placeholder: string;
  searchResults?: any;
  onChange?: (value: string) => void;
  showDropdown?: boolean;
  setShowDropdown?: (value: boolean) => void;
  onKeyDown?: (e?: React.KeyboardEvent<HTMLInputElement> | undefined) => void;
  iconName?: string;
  inputSizeVariant?: TextInputProps['sizeVariant'];
} & MarginProps;

export const SearchBox = ({
  placeholder,
  searchResults,
  onChange,
  showDropdown: showDropdownOverwrite,
  setShowDropdown: setShowDropdownOverwrite,
  onKeyDown,
  iconName,
  inputSizeVariant,
  ...props
}: SearchBoxProps) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const containerRef = useRef(null);
  const isOverwriteStateDropdown = !!setShowDropdownOverwrite;

  const showDropdownHandler = () => {
    if (isOverwriteStateDropdown) {
      setShowDropdownOverwrite(true);
    } else {
      setShowDropdown(true);
    }
  };
  const clickOutsideHandler = () => {
    if (isOverwriteStateDropdown) {
      setShowDropdownOverwrite(false);
    } else {
      setShowDropdown(false);
    }
  };

  useOnClickOutside(containerRef, clickOutsideHandler);

  const onSearch = useDebouncedCallback(({ target: { value } }) => {
    onChange?.(value);
  }, 500);

  const visibleResult = isOverwriteStateDropdown
    ? showDropdownOverwrite
    : showDropdown;
  const openDropDown = visibleResult && searchResults;
  return (
    <StyledSearchBox {...props} ref={containerRef}>
      <SearchBoxInputContainer openDropDown={openDropDown}>
        <TextInput
          {...(iconName && { icon: { name: iconName, color: 'blue' } })}
          sizeVariant={inputSizeVariant}
          onChange={onSearch}
          placeholder={placeholder}
          onClick={showDropdownHandler}
          onKeyDown={onKeyDown}
          type="search"
        />
      </SearchBoxInputContainer>
      {openDropDown && <SearchBoxDropdown>{searchResults}</SearchBoxDropdown>}
    </StyledSearchBox>
  );
};

const innerWrapper = css`
  height: 100%;
  position: relative;
  width: 100%;
`;

const StyledSearchBox = styled(Box)`
  ${innerWrapper}
`;

const SearchBoxInputContainer = styled(Box)<{ openDropDown?: boolean }>`
  ${innerWrapper}
  input {
    &:focus {
      outline: none;
      box-shadow: none;
      ${({ theme: { colors }, openDropDown }) => css`
        border: 1px solid ${colors.gray05};
        border-radius: ${!!openDropDown && `4px 4px 0px 0px`};
      `}
    }
  }
`;

const SearchBoxDropdown = styled.div`
  ${({ theme: { colors } }) => css`
    position: absolute;
    border: 1px solid ${colors.gray05};
    border-top: 0px;
    width: 100%;
    background: ${colors.white};
    z-index: 10;
    border-radius: 0px 0px 4px 4px;
    box-sizing: border-box;
  `}
`;
