import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export function useMutationEditMessage(
  options?: MutationHookOptions<EditMessageData, EditMessageVariables>,
) {
  const data = useMutation<EditMessageData, EditMessageVariables>(
    EDIT_MESSAGE,
    options,
  );
  return data;
}

export const EDIT_MESSAGE = gql`
  mutation editMessage(
    $id: String!
    $title: String!
    $tag: String!
    $receiver: String!
    $channel: String!
    $content: String!
  ) {
    editMessage(
      id: $id
      title: $title
      tag: $tag
      receiver: $receiver
      channel: $channel
      content: $content
    ) {
      id
      title
      updatedAt
      tag
      content
      channel
      receiver
      attachments {
        id
        name
      }
    }
  }
`;

export type EditMessageVariables = {
  id: string;
  title: string;
  tag: string;
  receiver: string;
  channel: string;
  content: string;
};

export type EditMessageData = {
  editMessage: {
    id: string;
    title: string;
    updatedAt: string;
    tag: string;
    content: string;
    channel: 'inapp' | 'mail';
    receiver: string;
    attachments: {
      id: string;
      name: string;
    }[];
  };
};
