import { Dispatch, SetStateAction } from 'react';
import { Button, SelectInput, TextInput } from 'components';
import { Grid } from 'ui';
import { useDebouncedCallback } from 'use-debounce';
import { optionsTag } from 'components/Messages/utilities';
import { StateFilters } from '../types';

interface Props {
  filters: StateFilters;
  setFilters: Dispatch<SetStateAction<StateFilters>>;
  setOpenMessageId: Dispatch<React.SetStateAction<number>>;
}

export const Filters = ({ setFilters, filters, setOpenMessageId }: Props) => {
  const { search, type, openFilters } = filters;

  const handleSearch = useDebouncedCallback(({ target: { value } }) => {
    setOpenMessageId(0);
    setFilters((prev) => ({ ...prev, search: value || '' }));
  }, 500);

  return (
    <Grid
      p="s10"
      gridGap="s10"
      gridTemplateColumns="1fr 40px"
      backgroundColor="lightBlue"
    >
      <TextInput
        icon={{ name: '18-search', color: 'blue' }}
        placeholder="Search"
        type="search"
        defaultValue={search}
        onChange={handleSearch}
      />
      <Button
        icon={{ color: 'blue', name: '20-filter' }}
        variant="outline-secondary"
        px="s12"
        onClick={() =>
          setFilters((prev) => ({ ...prev, openFilters: !prev.openFilters }))
        }
      />
      {openFilters && (
        <SelectInput
          value={type}
          onChange={(value) => {
            setOpenMessageId(0);
            setFilters((prev) => ({ ...prev, type: value }));
          }}
          options={optionsTag}
          placeholder="Choose type"
          labelResolver={(option) => option?.label}
          keyResolver={(option) => option?.key}
          disableAutocomplete
        />
      )}
    </Grid>
  );
};
