import { RefObject, useRef, useState } from 'react';
import { Flex, Grid } from 'ui';
import styled from 'styled-components';
import { Breadcrumbs, Panel } from 'components';
import { paths } from 'routes';
import {
  formatDate,
  useOrdersTableRowScroll,
  notify,
  useReportRestApi,
} from 'utils';
import {
  useQueryOrders,
  ORDERS,
  OrdersVariables,
  OrdersData,
} from 'graphql/query';
import { useMutationShipAllItemsMessage } from 'graphql/mutation/useMutationShipAllItemsMessage';
import {
  useMutationOrderCreateMonitoring,
  useMutationOrderItemCreateMonitoring,
  useMutationOrderItemRemoveMonitoring,
  useMutationOrderRemoveMonitoring,
} from 'graphql/mutation';
import { OrderItem } from 'graphql/types/OrderItem';
import useInfiniteScroll from 'utils/useInfiniteScroll';

import {
  DeliveryNoteItemsData,
  useQueryDeliveryNoteItems,
} from 'graphql/query/useQueryDeliveryNoteItems';
import {
  Deliveries,
  DeliveriesFilter,
  Orders,
  OrdersFilter,
} from './components';
import { useYourOrdersFilters } from './hooks/useYourOrdersFilters';
import { useDeliveriesFilters } from './hooks/useDeliveriesFilters';
import { CreateReportModal } from './components/CreateReportModal/CreateReportModal';
import { useYourOrdersReportModal } from './hooks/useYourOrdersReportModal';

export type Refs = Record<number, RefObject<HTMLDivElement>>;

const REPORT_SLUG = '/juki/download/ordersReport';

type ReportRequestData = {
  status?: string;
  accountId?: number;
  deliveryAddressId?: number;
  dateFrom?: string;
  dateTo?: string;
};

export const YourOrders = () => {
  const { handlers, filters, options } = useYourOrdersFilters();
  const deliveriesFilter = useDeliveriesFilters();
  const reportModal = useYourOrdersReportModal();
  const [dateFrom, dateTo] = filters.dateRangeInputValue ?? [];
  const [deliveryDateFrom, deliveryDateTo] =
    deliveriesFilter.filters.dateRangeInputValue ?? [];

  const {
    data: deliveryData,
    loading: deliveriesLoadingData,
    fetchMore: deliveriesFetchMore,
  } = useQueryDeliveryNoteItems({
    variables: {
      limit: 25,
      offset: 0,
      phrase: deliveriesFilter.filters.searchInputValue,
      deliveryAddress: deliveriesFilter.filters.selectedDeliveryAddress?.id
        ? `${deliveriesFilter.filters.selectedDeliveryAddress?.id}`
        : undefined,
      dateFrom: deliveryDateFrom
        ? `${formatDate(deliveryDateFrom)}`
        : undefined,
      dateTo: deliveryDateTo ? `${formatDate(deliveryDateTo)}` : undefined,
    },
  });

  const {
    data,
    loading: loadingData,
    refetch,
    fetchMore,
  } = useQueryOrders({
    variables: {
      limit: 25,
      offset: 0,
      orderDir: 'DESC',
      orderBy: 'createdAt',
      phrase: filters.searchInputValue,
      deliveryAddress: filters.selectedDeliveryAddress?.id
        ? `${filters.selectedDeliveryAddress?.id}`
        : undefined,
      status: filters.selectedOrderStatus,
      dateFrom: dateFrom ? `${formatDate(dateFrom)}` : undefined,
      dateTo: dateTo ? `${formatDate(dateTo)}` : undefined,
      stockStatus: filters.selectedStockStatus?.value,
    },
    fetchPolicy: 'cache-and-network',
  });

  const [isCreateReportModalOpen, setIsCreateReportModalOpen] = useState(false);
  const downloadAnchorRef = useRef<HTMLAnchorElement>(null);

  const { getAPIData } = useReportRestApi<ReportRequestData>({
    callback: (blob) => {
      const downloadAnchor = downloadAnchorRef.current;
      if (!downloadAnchor) return;
      const today = new Date();
      downloadAnchor.href = URL.createObjectURL(blob);
      downloadAnchor.download = `Report-${formatDate(today)}`;
      downloadAnchor.click();
      setIsCreateReportModalOpen(false);
    },
    slug: REPORT_SLUG,
  });

  const handleGenerateReport = () => {
    const [reportDateFrom, reportDateTo] =
      reportModal.reportModalData?.dateRangeInputValue ?? [];
    getAPIData({
      status: reportModal.reportModalData?.selectedOrderStatus ?? undefined,
      accountId: reportModal.reportModalData?.selectedAccount?.id,
      deliveryAddressId:
        reportModal.reportModalData?.selectedDeliveryAddress?.id,
      dateFrom: reportDateFrom ? `${formatDate(reportDateFrom)}` : undefined,
      dateTo: reportDateTo ? `${formatDate(reportDateTo)}` : undefined,
    });
  };

  const ordersTransformedData = data?.orders?.customerOrders ?? [];

  const { expandedRowIds, refs } = useOrdersTableRowScroll({
    data: ordersTransformedData,
    queryParamsKey: 'expandedRowIds',
  });

  const [orderRemoveMonitoring] = useMutationOrderRemoveMonitoring({
    variables: { section: 'Order' },
  });
  const [orderCreateMonitoring] = useMutationOrderCreateMonitoring({
    variables: { section: 'Order' },
  });
  const [orderItemCreateMonitoring] = useMutationOrderItemCreateMonitoring();
  const [orderItemRemoveMonitoring] = useMutationOrderItemRemoveMonitoring();

  const [shipAllItemsMessage, { loading: loadingShipAllItems }] =
    useMutationShipAllItemsMessage({
      onCompleted() {
        notify('Items shipped');
      },
    });

  const handleMonitorItem = (id: number, currentValue: boolean) => {
    if (currentValue)
      orderItemRemoveMonitoring({
        variables: { id, section: 'Order' },
      });
    else
      orderItemCreateMonitoring({
        variables: { id, section: 'Order' },
      });
  };

  const ordersQueryVariables = {
    limit: 25,
    orderDir: 'DESC',
    orderBy: 'createdAt',
    phrase: filters.searchInputValue,
    deliveryAddress: filters.selectedDeliveryAddress?.id
      ? `${filters.selectedDeliveryAddress?.id}`
      : undefined,
    status: filters.selectedOrderStatus,
    dateFrom: dateFrom ? `${formatDate(dateFrom)}` : undefined,
    dateTo: dateTo ? `${formatDate(dateTo)}` : undefined,
    stockStatus: filters.selectedStockStatus?.value,
  };

  const handleMonitorAll = (currentValue: boolean) => {
    if (currentValue)
      orderRemoveMonitoring({
        refetchQueries: [
          {
            query: ORDERS,
            variables: ordersQueryVariables,
          },
        ],
      });
    else
      orderCreateMonitoring({
        refetchQueries: [
          {
            query: ORDERS,
            variables: ordersQueryVariables,
          },
        ],
      });
  };

  const hasMonitoredAllItems = !ordersTransformedData
    ?.map((item) => item.monitored)
    .includes(false);

  const handleGenerateReportModalOpen = () => {
    setIsCreateReportModalOpen(true);
  };

  const handleShippAllAvailableItems = () => {
    shipAllItemsMessage({
      onCompleted: () => {
        refetch(ordersQueryVariables as OrdersVariables);
      },
    });
  };

  const isShipAllButtonDisabled =
    ordersTransformedData.every((order) => order.order.shipAllSent) ||
    loadingShipAllItems ||
    loadingData;

  const [infiniteScrollDisabled, setInfiniteScrollDisabled] = useState(true);
  const [
    deliveriesInfiniteScrollDisabled,
    setDeliveriesInfiniteScrollDisabled,
  ] = useState(true);

  const { ref, loadingFetchMore, handleFetchMore } =
    useInfiniteScroll<OrdersData>({
      count: data?.orders?.count,
      loading: loadingData,
      resource: (item) => item?.orders?.customerOrders,
      data,
      fetchMore,
      disabled: infiniteScrollDisabled,
      distanceBottom: 1500,
    });

  const {
    ref: deliveriesRef,
    loadingFetchMore: deliveriesLoadingFetchMore,
    handleFetchMore: deliveriesHandleFetchMore,
  } = useInfiniteScroll<DeliveryNoteItemsData>({
    count: deliveryData?.deliveryNoteItems?.count,
    loading: deliveriesLoadingData,
    resource: (item) => item?.deliveryNoteItems.items,
    data: deliveryData,
    fetchMore: deliveriesFetchMore,
    disabled: deliveriesInfiniteScrollDisabled,
    distanceBottom: 100,
  });

  return (
    <>
      <Grid gridTemplateRows={'40px 1fr'}>
        <Breadcrumbs
          px="s30"
          items={[
            { label: 'Home', to: paths.base },
            { label: 'Your orders', to: paths.main.yourOrders },
          ]}
        />
        <Flex flexDirection="column">
          <Panel label={'Search & Sort Orders'}>
            <OrdersFilter
              handlers={handlers}
              filters={filters}
              options={options}
            />
          </Panel>
          <Panel expandable={false} label={'Your orders'}>
            <InfiniteScrollWrapper ref={ref}>
              <Orders
                onGenerateReport={handleGenerateReportModalOpen}
                onShippAllAvailableItems={handleShippAllAvailableItems}
                refs={refs || []}
                defaultExpandedIds={expandedRowIds}
                onMonitorAll={handleMonitorAll}
                onMonitorItem={handleMonitorItem}
                data={ordersTransformedData ?? []}
                monitoredAll={hasMonitoredAllItems}
                isShipAllButtonDisabled={isShipAllButtonDisabled}
                loading={loadingData || loadingFetchMore}
                onFetchMore={() => handleFetchMore()}
                onFetchAll={() => setInfiniteScrollDisabled(false)}
                allItemsCount={data?.orders.count}
              />
            </InfiniteScrollWrapper>
          </Panel>
          <Panel label={'Search & Sort Deliveries'}>
            <DeliveriesFilter
              handlers={deliveriesFilter.handlers}
              filters={deliveriesFilter.filters}
              options={deliveriesFilter.options}
            />
          </Panel>
          <Panel expandable={false} label={'Deliveries'}>
            <InfiniteScrollWrapper ref={deliveriesRef}>
              <Deliveries
                allItemsCount={deliveryData?.deliveryNoteItems.count}
                loading={deliveriesLoadingData || deliveriesLoadingFetchMore}
                onFetchMore={() => deliveriesHandleFetchMore()}
                onFetchAll={() => setDeliveriesInfiniteScrollDisabled(false)}
                data={deliveryData?.deliveryNoteItems.items ?? []}
              />
            </InfiniteScrollWrapper>
          </Panel>
        </Flex>
      </Grid>
      <CreateReportModal
        open={isCreateReportModalOpen}
        handleClose={() => setIsCreateReportModalOpen(false)}
        reportModal={reportModal}
        onGenerateReport={handleGenerateReport}
      />
      <StyledAnchor ref={downloadAnchorRef} />
    </>
  );
};

export const InfiniteScrollWrapper = styled.div`
  width: 100%;
`;

export const StyledAnchor = styled.a`
  display: none;
`;

export type TransformedOrdersData = OrderItem;
