import { ReactNode, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Flex, Icon, Text } from 'ui';
import { Portal } from 'components';

export type ModalProps = {
  title?: string;
  children: ReactNode;
  actions?: ReactNode;
  open: boolean;
  handleClose: () => void;
};

export const Modal = ({
  children,
  open,
  handleClose,
  title,
  actions,
  ...rest
}: ModalProps) => {
  useEffect(() => {
    const closeOnEscapeKey = (e: KeyboardEvent) =>
      e.key === 'Escape' ? handleClose() : null;
    document.body.addEventListener('keydown', closeOnEscapeKey);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey);
    };
  }, [handleClose]);

  if (!open) return null;

  return (
    <Portal wrapperId="modal">
      <ContainerModal {...rest}>
        <ModalBody>
          <Flex justifyContent="space-between" p="s25">
            <Text
              textTransform="uppercase"
              fontWeight="bold"
              color="gray80"
              lineHeight="lh300"
              p="s05"
            >
              {title}
            </Text>
            <CloseButton type="button" onClick={handleClose}>
              <Icon name="20-close" color="gray40" />
            </CloseButton>
          </Flex>
          <Content>{children}</Content>
          {actions && <Actions>{actions}</Actions>}
        </ModalBody>
      </ContainerModal>
    </Portal>
  );
};

export default Modal;

const Actions = styled.div`
  ${({ theme: { space } }) => css`
    padding: ${space.s25};
  `};
`;
const ContainerModal = styled.div`
  align-items: flex-start;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 80px 20px 20px;
  position: fixed;
  top: 0;
  transition: all 0.5s ease-in-out;
  width: 100%;
  z-index: 999;
`;

export const Content = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 260px);

  ${({ theme: { space } }) => css`
    padding: ${space.s30};
    padding-top: ${space.s0};
  `};
`;

export const ModalBody = styled.div`
  min-width: 448px;
  background-color: white;
  border-radius: 4px;
`;

const CloseButton = styled.button`
  &:hover {
    cursor: pointer;
    svg {
      color: ${({ theme: { colors } }) => colors.gray80};
    }
  }
`;
