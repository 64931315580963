import { Link as LinkReact, LinkProps } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { FontWeights } from 'theme';

type Props = LinkProps & {
  fontWeight?: keyof FontWeights;
};

export const Link = styled(LinkReact)<Props>`
  ${({ theme: { colors, fontWeights }, fontWeight }) => css`
    font-weight: ${fontWeight || fontWeights.bold};
    color: ${colors.blue};
  `};
`;
