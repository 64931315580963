export type ParamDocuments = { id: number; name: string }[];

export const parseDirectories = (value: string | null): ParamDocuments => {
  if (typeof value === 'string') {
    try {
      return JSON.parse(value);
    } catch {
      return [];
    }
  } else {
    return [];
  }
};
