import styled, { css } from 'styled-components';
import { Flex } from 'ui';

export const Table = styled.div`
  width: 100%;
`;

export const Row = styled.div<{
  isDeleted?: boolean;
  isExpanded?: boolean;
  hover?: boolean;
}>`
  display: flex;
  padding: 0 4px;
  ${({ hover }) => css`
    ${hover &&
    css`
      &:hover {
        transition: background-color 0.2s;
        background-color: ${({ theme: { colors } }) => colors.lightBlue};
      }
    `}
  `}
  ${({ theme: { colors, borders, space }, isExpanded, isDeleted }) => css`
    border-bottom: ${!isExpanded && borders.thinGray10};
    background: ${isExpanded ? colors.lightBlue : colors.white};
    min-height: ${space.s60};

    ${!!isDeleted &&
    css`
      opacity: 0.2;
      cursor: wait;
    `}
  `};
`;

export const ExpandRowWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ExpandRow = styled(Flex)`
  ${({ theme: { borders, colors } }) => css`
    border-bottom: ${borders.thinGray10};
    background: ${colors.lightBlue};
  `}
`;

export const Cell = styled.div<{ flex: number }>`
  align-items: center;
  display: flex;

  ${({ theme: { fontSizes, space }, flex }) => css`
    flex: ${flex};
    font-size: ${fontSizes.fs200};
    padding: ${space.s05} ${space.s05};
    word-break: break-word;
  `}
`;

export const TableBody = styled.div``;

export const TableHead = styled.div`
  ${({ theme: { space, borders } }) => css`
    background: #f4f4f4;
    border-bottom: ${borders.mediumGray10};
    height: ${space.s60};
  `};
`;

export const TableHeadRow = styled.div`
  display: flex;
  padding: 0 4px;
`;

export const TableHeadCell = styled.div<{ flex: number }>`
  text-align: left;

  ${({ theme: { fontSizes, fontWeights, lineHeights, space }, flex }) => css`
    flex: ${flex};
    font-size: ${fontSizes.fs100};
    font-weight: ${fontWeights.bold};
    line-height: ${lineHeights.lh100};
    padding: ${space.s10} ${space.s05} ${space.s0};
  `}
`;
