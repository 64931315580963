import { useState } from 'react';

type UseReportRestApiProps = {
  slug: string;
  callback: (data: Blob) => void;
};

const URL = process.env.REACT_APP_REST_API_HOST;

export const useReportRestApi = <T extends Record<string, unknown>>({
  callback,
  slug,
}: UseReportRestApiProps) => {
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const getAPIData = async (payload: T) => {
    setLoading(true);
    try {
      const apiResponse = await fetch(`${URL}${slug}`, {
        body: JSON.stringify(payload),
        method: 'POST',
        credentials: 'include',
        headers: {
          'content-type': 'text/csv;charset=UTF-8',
        },
      });
      const blob = await apiResponse.blob();
      callback(blob);
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  return { error, loading, getAPIData };
};
