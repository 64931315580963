import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Flex, Grid } from 'ui';
import { Breadcrumbs, Panel, Heading, Button } from 'components';
import { paths } from 'routes';
import { UserCard } from 'components/UserCard/UserCard';
import { Carousel } from 'components/Carousel/Carousel';
import { CarouselContextProvider } from 'components/Carousel/CarouselContext';
import { CarouselControls } from 'components/Carousel/CarouselControls';
import { useQuerySimilarUsers } from 'graphql/query';
import { USER_ROLES } from 'pages/Users/components/CreateProfileModal/CreateProfileModalContext';
import { CreateProfileModal } from 'pages/Users/components/CreateProfileModal/CreateProfileModal';
import { useQueryUserById } from './graphql/useQueryUserById';
import { CustomerBasicInfo } from './components/CustomerBasicInfo';
import { CustomerSalesInfo } from './components/CustomerSalesInfo';
import { StyledWrapper } from './components/common';
import { CustomerAccountInfo } from './components/CustomerAccountInfo';

export const CustomerProfile = () => {
  const [updateProfileModalOpen, setUpdateProfileModalOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const id = searchParams.get('id') || '';
  const { data } = useQueryUserById({ variables: { id } });
  const similarUserQuery = useQuerySimilarUsers({
    variables: { orderBy: 'id', limit: 10, role: 'ROLE_USER' },
  });
  const similarUser = similarUserQuery?.data?.users?.items;
  const user = data?.userById;

  return (
    <>
      <Grid gridTemplateRows={'40px 1fr'}>
        <Breadcrumbs
          px="s30"
          items={[
            { label: 'Home', to: paths.base },
            { label: 'Users', to: paths.main.users },
            { label: 'Customers', to: paths.main.users },
          ]}
        />

        <StyledWrapper flexDirection="column">
          <Panel
            expandable={false}
            label={user?.name ?? ''}
            customHeading={
              <Flex alignItems="center">
                <Heading color="blue" fontSize="fs200" pt="s17" mb="s20">
                  {user?.name ?? ''}
                </Heading>
                <Button
                  ml="s10"
                  size={'small'}
                  variant={'outline-primary'}
                  onClick={() => setUpdateProfileModalOpen(true)}
                >
                  Edit
                </Button>
              </Flex>
            }
          >
            <Flex pb={'s40'} flex={1}>
              <Flex mr={'s30'} style={{ width: '100%' }} flexDirection="column">
                <CustomerBasicInfo data={user} />
                <CustomerSalesInfo data={user} />
              </Flex>
              <CustomerAccountInfo data={user} />
            </Flex>
          </Panel>
          <CarouselContextProvider>
            <Panel
              additionalElements={<CarouselControls />}
              expandable={false}
              label={'SIMILAR USERS'}
              childrenPx={'s0'}
            >
              <Carousel>
                {similarUser?.map(({ id: key, businessPartner, name }) => (
                  <UserCard
                    key={key}
                    name={name}
                    code={businessPartner?.code || '-'}
                    onClick={() =>
                      navigate(`${paths.main.usersCustomers}?id=${key}`)
                    }
                  />
                ))}
              </Carousel>
            </Panel>
          </CarouselContextProvider>
        </StyledWrapper>
      </Grid>

      {user && (
        <CreateProfileModal
          open={updateProfileModalOpen}
          handleClose={() => setUpdateProfileModalOpen(false)}
          initialStep={1}
          initialRole={USER_ROLES[0]}
          initialValuesCustomer={{
            id,
            firstName: user.firstName,
            lastName: user?.lastName,
            username: user?.userName,
            email: user?.email,
            createOrders: user?.createOrdersPermission,
            createReports: user?.createReportsPermission,
            downloadFiles: user?.downloadFilesPermission,
            mainAccount: user?.accountType || undefined,
            businessPartner: user?.businessPartner || undefined,
            salesSupport: user?.salesUser || undefined,
            deliveryAddress: user?.businessPartnerShippingAddress || undefined,
          }}
        />
      )}
    </>
  );
};
