import styled, { css } from 'styled-components';
import { Flex, Grid, Text } from 'ui';

export const StyledWrapper = styled(Flex)`
  overflow-x: hidden;
`;

export const SummaryContainer = styled(Flex)`
  height: fit-content;

  ${({ theme: { colors, radii } }) => css`
    background: ${colors.gray06};
    border-radius: ${radii.rounded};
  `};
`;

export const Column = styled(Grid).attrs({
  gridTemplateColumns: 'auto auto',
  gridAutoRows: 'max-content',
})`
  gap: ${({ theme: { space } }) => `${space.s15} ${space.s70}`};
`;

export const Label = styled(Text).attrs({
  fontWeight: 'medium',
  color: 'gray80',
})``;
