export type FontSizes = typeof fontSizes;

export const fontSizes = {
  fs900: '72px',
  fs800: '56px',
  fs700: '40px',
  fs600: '32px',
  fs500: '24px',
  fs400: '20px',
  fs300: '16px',
  fs200: '14px',
  fs100: '12px',
} as const;
