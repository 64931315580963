import { gql, QueryHookOptions, useQuery } from '@apollo/client';

export const useQueryCountriesList = (
  options?: QueryHookOptions<CountriesListData>,
) => {
  return useQuery<CountriesListData>(GET_COUNTRIES_LIST, options);
};

export const GET_COUNTRIES_LIST = gql`
  query countriesList {
    countriesList
  }
`;

export type CountriesListData = {
  countriesList: string[];
};
