import { UsersData } from 'graphql/query';
import { DocumentDirectory } from 'graphql/types/DocumentDirectory';
import { useQueryUsers } from 'pages/Documents/graphql/useQueryUsers';
import { useState } from 'react';
import { Flex } from 'ui';
import useInfiniteScroll from 'utils/useInfiniteScroll';
import { Filters } from './types';
import { UsersFilters } from './UsersFilters';
import { UsersTable } from './UsersTable';

interface Props {
  directoryId: number;
}

export const ConnectedCustomers = ({ directoryId }: Props) => {
  const [filters, setFilters] = useState<Filters>({});

  const { data, loading, fetchMore, updateQuery, client } = useQueryUsers({
    variables: {
      orderBy: 'id',
      orderDir: 'DESC',
      offset: 0,
      limit: 50,
      phrase: filters?.search,
      company: filters?.company?.id?.toString(),
      country: filters?.country,
      directoryId: filters?.onlyAssigned ? `${directoryId}` : undefined,
    },
  });

  const { ref } = useInfiniteScroll<UsersData>({
    count: data?.users?.count,
    loading,
    resource: (item) => item?.users?.items,
    data,
    fetchMore,
    listenerRef: true,
  });

  const users = data?.users?.items || [];

  const handleFilters = async (value: Partial<Filters>) => {
    await client.cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'users',
      broadcast: false,
    });
    setFilters((prev) => ({ ...prev, ...value }));
  };

  const handleInvalidateCache = (
    usersIds: string[],
    id: string,
    connect: boolean,
  ) => {
    updateQuery((previousQueryResult) => {
      const items = previousQueryResult?.users?.items || [];

      const resultItems = items?.map((item) => {
        if (usersIds.includes(`${item?.id}`)) {
          if (connect) {
            return {
              ...item,
              directories: [
                ...item.directories,
                {
                  id: parseInt(id, 10),
                  userVisible: true,
                  active: true,
                  __typename: 'DocumentDirectory',
                } as unknown as DocumentDirectory,
              ],
            };
          }

          return {
            ...item,
            directories: [
              ...item.directories.filter(
                (directory) => `${directory?.id}` !== id,
              ),
            ],
          };
        }
        return item;
      });

      return {
        ...previousQueryResult,
        users: {
          ...previousQueryResult.users,
          items: [...resultItems],
        },
      };
    });
  };

  return (
    <Flex flex={1} flexDirection={'column'}>
      <UsersFilters filters={filters} handleFilters={handleFilters} />
      <UsersTable
        data={users}
        filters={filters}
        refTable={ref}
        loading={loading}
        directoryId={directoryId}
        handleInvalidateCache={handleInvalidateCache}
      />
    </Flex>
  );
};
