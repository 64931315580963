import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export const useMutationCartRemoveMonitoring = (
  options?: MutationHookOptions<
    CartRemoveMonitoringData,
    CartRemoveMonitoringVariables
  >,
) =>
  useMutation<CartRemoveMonitoringData, CartRemoveMonitoringVariables>(
    CART_REMOVE_MONITORING,
    options,
  );

export const CART_REMOVE_MONITORING = gql`
  mutation cartRemoveMonitoring($section: String) {
    cartRemoveMonitoring(section: $section) {
      id
    }
  }
`;

export type CartRemoveMonitoringVariables = {
  section: string;
};

export type CartRemoveMonitoringData = {
  cartRemoveMonitoring: {
    id: string;
  };
};
