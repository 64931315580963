import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import { Sidebar, Header } from 'components';
import { Grid } from 'ui';
import { AuthProvider } from 'providers';

//TODO: implement overflow
export const MainLayout = () => (
  <AuthProvider>
    <MainLayoutWrapper gridTemplateRows="78px 1fr">
      <Header />
      <Grid gridTemplateColumns="auto 1fr">
        <Sidebar />
        <Outlet />
      </Grid>
    </MainLayoutWrapper>
  </AuthProvider>
);

const MainLayoutWrapper = styled(Grid)`
  height: 100%;
  min-height: 100vh;
`;
