import { gql, QueryHookOptions, useQuery } from '@apollo/client';

export const useQueryOrderStatuses = (
  options?: QueryHookOptions<OrderStatusesData>,
) => useQuery<OrderStatusesData>(ORDER_STATUSES, options);

export const ORDER_STATUSES = gql`
  query orderStatuses {
    orderStatuses
  }
`;

export type OrderStatusesData = {
  orderStatuses: string[];
};
