import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { BusinessPartner } from 'graphql/types/BusinessPartner';

export const useQuerySalesDeliveryAddresses = (
  options?: QueryHookOptions<SalesDeliveryAddressesData>,
) => useQuery<SalesDeliveryAddressesData>(SALES_DELIVERY_ADDRESSES, options);

export const SALES_DELIVERY_ADDRESSES = gql`
  query salesDeliveryAddresses {
    salesDeliveryAddresses {
      identifier
      active
      name
      address
      zipCode
      city
      county
      country
      shipVia
      viaCity
      duty
      id
      createdAt
      updatedAt
    }
  }
`;

export type SalesDeliveryAddressesData = {
  salesDeliveryAddresses: BusinessPartner[];
};
