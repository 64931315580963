import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { Condition } from 'graphql/types/condition';

export function useQueryMessages(
  options?: QueryHookOptions<MessagesData, MessagesVariables>,
) {
  const data = useQuery<MessagesData, MessagesVariables>(MESSAGES, options);
  return data;
}

export const MESSAGES = gql`
  query messages(
    $phrase: String
    $conditions: [ConditionInput!]
    $offset: Int
    $orderBy: String
    $orderDir: String!
    $limit: Int
  ) {
    messages(
      criteria: {
        offset: $offset
        orderBy: $orderBy
        orderDir: $orderDir
        limit: $limit
      }
    ) {
      items(phrase: $phrase, conditions: $conditions) {
        id
        title
        updatedAt
        tag
        content
        channel
        receiver
        userRead
        attachments {
          id
          name
        }
      }
      count
    }
  }
`;

export type MessagesVariables = {
  phrase?: string;
  orderBy: string;
  orderDir?: 'ASC' | 'DESC';
  limit?: number;
  offset?: number;
  conditions?: Condition[];
};

export interface MessageItem {
  id: number;
  title: string;
  updatedAt: string;
  tag: string;
  content: string;
  channel: 'inapp' | 'mail';
  receiver: string;
  userRead: boolean;
  attachments: {
    id: string;
    name: string;
  }[];
}

export interface MessagesData {
  messages: {
    count: number;
    items: MessageItem[];
  };
}
