import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { DocumentDirectory } from 'graphql/types/DocumentDirectory';

export const useQueryGetDirectories = (
  options?: QueryHookOptions<GetDirectoriesData, GetDirectoriesVariables>,
) =>
  useQuery<GetDirectoriesData, GetDirectoriesVariables>(
    GET_DIRECTORIES,
    options,
  );

export const GET_DIRECTORIES = gql`
  query getDirectories($conditions: [ConditionInput!]) {
    directories(criteria: { orderBy: "id", orderDir: "DESC" }) {
      items(conditions: $conditions) {
        id
        name
        fileCounter
        userVisible
        active
        createdAt
      }
    }
  }
`;

export type GetDirectoriesVariables = {
  conditions: {
    column: string;
    value: string;
    operator: string;
  }[];
};

export type GetDirectoriesData = {
  directories: { items: DocumentDirectory[] };
};
