import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export function useMutationRemoveAttachment(
  options?: MutationHookOptions<boolean, RemoveAttachmentVariables>,
) {
  const data = useMutation<boolean, RemoveAttachmentVariables>(
    REMOVE_ATTACHMENT,
    options,
  );
  return data;
}

export const REMOVE_ATTACHMENT = gql`
  mutation removeAttachment($attachmentId: String!) {
    removeAttachment(attachmentId: $attachmentId)
  }
`;

export type RemoveAttachmentVariables = {
  attachmentId: string;
};
