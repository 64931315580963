import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export function useMutationAddToCart(
  options?: MutationHookOptions<AddToCartData, AddToCartVariables>,
) {
  const data = useMutation<AddToCartData, AddToCartVariables>(
    ADD_TO_CART,
    options,
  );
  return data;
}

export const ADD_TO_CART = gql`
  mutation addToCart($id: Int, $quantity: Int) {
    addToCart(commodity: { id: $id }, quantity: $quantity) {
      id
      quantity
      price
      vatRate
      vatValue
      commodity {
        itemCode
        internal
        sellable
        description
        price
        currency
        fixedDiscount
        discount
        stock
        availableStock
        incomingStock
        replacements
        stocks
        validate {
          setNumber
          quantity
          commodity
          items {
            id
            commodity {
              itemCode
              id
              description
            }
          }
          id
          createdAt
          updatedAt
        }
        id
        createdAt
        updatedAt
        status
      }
      cart {
        id
        token
        items {
          quantity
          price
          vatRate
          vatValue
          commodity {
            itemCode
            internal
            sellable
            description
            price
            currency
            fixedDiscount
            discount
            stock
            availableStock
            incomingStock
            replacements
            stocks
            validate {
              setNumber
              quantity
              commodity
              items {
                id
                commodity {
                  itemCode
                }
              }
              id
              createdAt
              updatedAt
            }
            id
            createdAt
            updatedAt
            status
          }
          cart
          wishlist
          validate
          id
          status
          createdAt
          updatedAt
          monitored(section: "Cart")
        }
      }
      wishlist
      validate
      status
      createdAt
      updatedAt
      monitored(section: "Cart")
    }
  }
`;

export type AddToCartVariables = {
  id: number;
  quantity: number;
};

export type AddToCartData = {
  addCart: {
    id: string;
  };
};
