import { forwardRef, Ref } from 'react';

import { Text, TextProps } from '../Text';
import { TypoProps, TypoScale } from './props';

type VariantProps = Pick<TextProps, 'fontSize' | 'lineHeight'>;

const switchVariantSettings = (variant: TypoScale): VariantProps => {
  const scale = variant.substring(1);

  return {
    fontSize: `fs${scale}`,
    lineHeight: `lh${scale}`,
  } as VariantProps;
};

export const Typo = forwardRef((props: TypoProps, ref: Ref<HTMLElement>) => {
  const { as, variant, ...rest } = props;

  return (
    <Text
      ref={ref}
      forwardedAs={as}
      {...switchVariantSettings(variant)}
      {...rest}
    />
  );
});

Typo.defaultProps = {
  variant: 't200',
  as: 'span',
};
