import { useQueryUserDeliveryAddresses } from 'graphql/query';
import { BusinessPartnerShippingAddress } from 'graphql/types/BusinessPartnerShippingAddress';
import { ChangeEvent, useState } from 'react';

type Filters = {
  searchInputValue: string;
  dateRangeInputValue?: [string | Date, string | Date];
  selectedDeliveryAddress?: BusinessPartnerShippingAddress;
};

export type DeliveriesFilters = ReturnType<typeof useDeliveriesFilters>;

export const useDeliveriesFilters = () => {
  const [filters, setFilters] = useState<Filters>({
    searchInputValue: '',
  });

  const { data: deliveryAddressData } = useQueryUserDeliveryAddresses();

  const handleSearchInputChange = (e?: ChangeEvent<HTMLInputElement>) => {
    setFilters((prev) => ({
      ...prev,
      searchInputValue: e?.target.value ?? '',
    }));
  };

  const handleDateRangeInputChange = (val: [string | Date, string | Date]) => {
    setFilters((prev) => ({ ...prev, dateRangeInputValue: val }));
  };

  const handleSelectedDeliveryAddressChange = (
    val: BusinessPartnerShippingAddress | undefined,
  ) => {
    setFilters((prev) => ({ ...prev, selectedDeliveryAddress: val }));
  };

  return {
    handlers: {
      handleSearchInputChange,
      handleDateRangeInputChange,
      handleSelectedDeliveryAddressChange,
    },
    filters,
    options: {
      deliveryAddressOptions: deliveryAddressData?.userDeliveryAddresses ?? [],
    },
  };
};
