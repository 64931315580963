import { gql, QueryHookOptions, useQuery } from '@apollo/client';

export const useQuerySalesAndAdminUsers = (
  options?: QueryHookOptions<SalesAndAdminUsersData>,
) => {
  return useQuery<SalesAndAdminUsersData>(SALES_AND_ADMIN_USERS, options);
};

export const SALES_AND_ADMIN_USERS = gql`
  query salesAndAdminUsers {
    salesAndAdminUsers {
      id
      name
    }
  }
`;

export type SalesAndAdminUser = {
  id: number;
  name: string;
};

export type SalesAndAdminUsersData = {
  salesAndAdminUsers: SalesAndAdminUser[];
};
