import { gql, QueryHookOptions, useQuery } from '@apollo/client';

export const useQueryBusinessPartners = (
  options?: QueryHookOptions<BusinessPartnersData>,
) => useQuery<BusinessPartnersData>(BUSINESS_PARTNERS, options);

export const BUSINESS_PARTNERS = gql`
  query businessPartners {
    businessPartners {
      id
      name
    }
  }
`;

export type BusinessPartnersData = {
  businessPartners: {
    id: number;
    name: string;
  }[];
};
