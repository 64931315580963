import { gql, QueryHookOptions, useQuery } from '@apollo/client';

export const useQuerySubAccountUsers = (
  options?: QueryHookOptions<SubAccountUsersData>,
) => useQuery<SubAccountUsersData>(SUB_ACCOUNT_USERS, options);

export const SUB_ACCOUNT_USERS = gql`
  query subAccountUsers {
    subAccountUsers {
      name
      id
    }
  }
`;

export type SubAccountUsersData = {
  subAccountUsers: SubAccountUser[];
};

export type SubAccountUser = { name: string; id: number };
