import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export const useMutationReplaceCartItem = (
  options?: MutationHookOptions<ReplaceCartItemData, ReplaceCartItemVariables>,
) =>
  useMutation<ReplaceCartItemData, ReplaceCartItemVariables>(
    REPLACE_CART_ITEM,
    options,
  );

export const REPLACE_CART_ITEM = gql`
  mutation replaceCartItem(
    $cartItemId: Int
    $commodities: [Int]
    $quantity: Int
    $section: String
  ) {
    replaceCartItem(
      cartItem: { id: $cartItemId }
      commodities: $commodities
      quantity: $quantity
    ) {
      id
      monitored(section: $section)
    }
  }
`;

export type ReplaceCartItemVariables = {
  cartItemId: number;
  commodities: number[];
  quantity: number;
  section: string;
};

export type ReplaceCartItemData = {
  replaceCartItem: {
    id: string;
    monitored: boolean;
  };
};
