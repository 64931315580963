import { Flex, Text } from 'ui';
import { Dropdown, DropdownOption } from 'components/Dropdown/Dropdown';
import { useState } from 'react';
import { User } from 'graphql/types/User';
import { getRoleLabel } from 'utils/getRoleLabel';
import { useNavigate } from 'react-router-dom';
import { paths } from 'routes';
import { getCountryWithCode, getFlagEmoji } from 'utils';
import { useMutationDisableUser } from 'graphql/mutation';
import {
  Cell,
  Row,
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TableHeadRow,
} from './FlexTable/Table.styled';
import { Tooltip } from './Tooltip';
import { TextNoData } from './TextNoData';
import { Checkbox } from './Checkbox';

type UsersTableProps = {
  data: User[];
  handleInvalidateCache?: () => void;
  loading: boolean;
  onItemChecked?: (id: number) => void;
  selectedIds?: number[];
  onExportUser?: (id: number) => void;
};

export const UsersTable = ({
  data,
  handleInvalidateCache,
  loading,
  onItemChecked,
  selectedIds,
  onExportUser,
}: UsersTableProps) => {
  return (
    <Flex pb={'s15'} pt="s30" flexDirection="column" flex="1">
      <Table>
        <Head hasCheckboxes={!!onItemChecked} />
        <TableBody>
          {data?.length > 0 || loading ? (
            data.map((item) => (
              <ItemsRow
                handleInvalidateCache={handleInvalidateCache}
                key={item.id}
                onItemChecked={onItemChecked}
                selectedIds={selectedIds}
                onExportUser={onExportUser}
                {...item}
              />
            ))
          ) : (
            <TextNoData />
          )}
        </TableBody>
      </Table>
    </Flex>
  );
};

type PropsItemsRow = User & {
  handleInvalidateCache?: () => void;
  onItemChecked?: (id: number) => void;
  selectedIds?: number[];
  onExportUser?: (id: number) => void;
};

const ItemsRow = ({
  name,
  email,
  roles,
  businessPartner,
  salesUser,
  id,
  enabled,
  handleInvalidateCache,
  onItemChecked,
  selectedIds,
  onExportUser,
}: PropsItemsRow) => {
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [disabledUser] = useMutationDisableUser({
    onCompleted: () => handleInvalidateCache?.(),
  });
  const role = getRoleLabel(roles?.[0]);
  const { code = '-', country } = businessPartner || {};
  const salesAgent = salesUser?.name || '-';
  const company = businessPartner?.name || '-';

  const handleGoToProfile = () => {
    const key = roles?.[0];
    switch (key) {
      case 'ROLE_SALES': {
        return navigate(`${paths.main.usersSales}?id=${id}`);
      }
      default:
        return navigate(`${paths.main.usersCustomers}?id=${id}`);
    }
  };

  const handleDisableUser = () => {
    disabledUser({ variables: { id: id?.toString(), enabled: !enabled } });
    setDropdownOpen(false);
  };

  return (
    <Row>
      {!!onItemChecked && (
        <Cell flex={24}>
          <Checkbox
            onChange={() => onItemChecked?.(id)}
            checked={selectedIds?.includes(id)}
          />
        </Cell>
      )}
      <Cell flex={64}>
        {country ? (
          <Tooltip
            active={!!country}
            content={getCountryWithCode(country) || ''}
          >
            <Text fontSize="fs400">{getFlagEmoji(country)}</Text>
          </Tooltip>
        ) : (
          '-'
        )}
      </Cell>
      <Cell flex={136}>{code}</Cell>
      <Cell flex={192}>{company}</Cell>
      <Cell flex={120}>{name}</Cell>
      <Cell flex={168}>{email}</Cell>
      <Cell flex={112}>{role}</Cell>
      <Cell flex={112}>{salesAgent}</Cell>
      <Cell flex={112}>
        <Flex flex={1} justifyContent="flex-end">
          <Dropdown
            isDropdownOpen={dropdownOpen}
            openDropdown={(open) => setDropdownOpen(open)}
          >
            <>
              <DropdownOption onClick={handleGoToProfile}>
                Go to profile
              </DropdownOption>
              {!!onExportUser && (
                <DropdownOption onClick={() => onExportUser(id)}>
                  Export
                </DropdownOption>
              )}
              <DropdownOption color={'alert50'} onClick={handleDisableUser}>
                {enabled ? 'Disable user' : 'Enabled user'}
              </DropdownOption>
            </>
          </Dropdown>
        </Flex>
      </Cell>
    </Row>
  );
};

type HeadProps = { hasCheckboxes: boolean };

const Head = ({ hasCheckboxes }: HeadProps) => (
  <TableHead>
    <TableHeadRow>
      {hasCheckboxes && <TableHeadCell flex={24} />}
      <TableHeadCell flex={64}>Country</TableHeadCell>
      <TableHeadCell flex={136}>Code</TableHeadCell>
      <TableHeadCell flex={192}>Company</TableHeadCell>
      <TableHeadCell flex={120}>Name</TableHeadCell>
      <TableHeadCell flex={168}>E-mail</TableHeadCell>
      <TableHeadCell flex={112}>Role</TableHeadCell>
      <TableHeadCell flex={112}>Sales agent</TableHeadCell>
      <TableHeadCell flex={112} />
    </TableHeadRow>
  </TableHead>
);
