import { gql, QueryHookOptions, useLazyQuery } from '@apollo/client';
import { Commodity } from 'graphql/types/Commodity';

export const useQuerySearchCommodities = (
  options?: QueryHookOptions<SearchCommoditiesData, SearchCommoditiesVariables>,
) =>
  useLazyQuery<SearchCommoditiesData, SearchCommoditiesVariables>(
    SEARCH_COMMODITIES,
    options,
  );

export const SEARCH_COMMODITIES = gql`
  query search($phrase: String!, $source: String) {
    searchCommodities(phrase: $phrase, source: $source) {
      id
      itemCode
      description
      price
      currency
      discount
      stock
      availableStock
      incomingStock
      createdAt
      stockStatus
      stocks(source: $source) {
        stock
        source
        id
        location
        status
      }
    }
  }
`;

export type SearchCommoditiesVariables = {
  phrase: string;
  source?: string;
};

export type SearchCommoditiesData = {
  searchCommodities: Commodity[];
};
