import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { OrderItem } from 'graphql/types/OrderItem';

export const useQueryAdminOrders = (
  options?: QueryHookOptions<AdminOrders, AdminOrdersVariables>,
) => useQuery<AdminOrders, AdminOrdersVariables>(ADMIN_ORDERS, options);

export const ADMIN_ORDERS = gql`
  query adminOrders(
    $offset: Int
    $orderBy: String
    $orderDir: String!
    $limit: Int
    $monitored: String
    $phrase: String
    $client: String
    $company: String
    $deliveryAddress: String
    $dateFrom: String
    $dateTo: String
  ) {
    orders(
      criteria: {
        offset: $offset
        orderBy: $orderBy
        orderDir: $orderDir
        limit: $limit
      }
    ) {
      adminOrders(
        monitored: $monitored
        phrase: $phrase
        client: $client
        company: $company
        deliveryAddress: $deliveryAddress
        dateFrom: $dateFrom
        dateTo: $dateTo
      ) {
        order {
          id
          documentNumber
          createdAt
          reference
          token
          status
          user {
            email
            username
            name
            fullName
            firstName
            lastName
            id
            accountType
          }
          shipTo {
            identifier
            active
            name
            address
            zipCode
            city
            country
            shipVia
            id
          }
        }
        id
        monitored(section: "Order")
        commodity {
          itemCode
          stockStatus
          description
          stock
          purchases {
            documentNumber
            documentCreatedAt
            invoice {
              documentNumber
            }
            productionDate
            shippedFrom
            expectedArrival
            quantity
          }
        }
        quantity
        value
        deliveryNoteItem {
          id
          invoiceItem {
            invoice {
              documentNumber
              documentCreatedAt
              documentUpdatedAt
              status
            }
          }
          deliveryNote {
            documentNumber
            createdAt
            updatedAt
            status
            transports {
              carrierId
              carrierName
              carrierNumber
              trackingUrl
              createdAt
              updatedAt
            }
            pickLists {
              documentNumber
              documentCreatedAt
              documentUpdatedAt
            }
          }
        }
      }
      count
    }
  }
`;

export type AdminOrdersVariables = {
  client?: string;
  company?: string;
  dateFrom?: string;
  dateTo?: string;
  deliveryAddress?: string;
  limit?: number;
  monitored?: string;
  offset?: number;
  orderBy?: string;
  orderDir: 'ASC' | 'DESC';
  phrase?: string;
};

export type AdminOrders = {
  orders: { adminOrders: OrderItem[]; count: number };
};
