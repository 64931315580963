import { FC } from 'react';
import { DottedStatus } from 'components';
import { Colors } from 'theme';
import { convertStatus, RegistrationStatuses } from './convertStatus';

type RegistrationStatusProps = {
  status: RegistrationStatuses;
  disabledLabel?: boolean;
  labelColor?: keyof Colors;
};

export const RegistrationStatus: FC<RegistrationStatusProps> = ({
  status,
  disabledLabel,
  labelColor,
}) => {
  const data = convertStatus(status);
  if (!data) return null;
  const { color, label } = data;

  return (
    <DottedStatus
      color={color}
      labelColor={labelColor ?? color}
      label={label}
      disabledLabel={disabledLabel}
    />
  );
};
