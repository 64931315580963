import { ColorsType } from 'ui';

export type ERPStatuses = 'progress' | 'warning' | 'success' | 'error';

type Result = {
  color: ColorsType;
};

export const convertStatus = (status: ERPStatuses): Result | undefined => {
  switch (status) {
    case 'progress':
      return {
        color: 'primary50',
      };
    case 'warning':
      return {
        color: 'warning50',
      };
    case 'error':
      return {
        color: 'alert50',
      };
    case 'success':
      return {
        color: 'success50',
      };
    default:
      return;
  }
};
