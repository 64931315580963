import { openWindowWithPost } from 'utils';

interface Props {
  organization_id: number;
  id: string;
}
export const downloadFile = (props: Props) => {
  const endpoint = `${process.env.REACT_APP_PUBLIC_HOST}/juki/documents/file_download`;

  openWindowWithPost(endpoint, props);
};
