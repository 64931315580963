import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export function useMutationShipAllItemsMessage(options?: MutationHookOptions) {
  const data = useMutation(SHIP_ALL_ITEMS_MESSAGE, options);
  return data;
}

export const SHIP_ALL_ITEMS_MESSAGE = gql`
  mutation shipAllItemsMessage {
    shipAllItemsMessage {
      id
    }
  }
`;
