import { gql, MutationHookOptions, useMutation } from '@apollo/client';
import { ROLES } from 'constants/roles';

export const useMutationRegisterClient = (
  options?: MutationHookOptions<RegisterClientData, RegisterClientVariables>,
) => useMutation(REGISTER_CLIENT, options);

export const REGISTER_CLIENT = gql`
  mutation registerClient(
    $email: String!
    $firstName: String!
    $lastName: String!
    $username: String!
    $partner: BusinessPartnerInput
    $salesUser: UserInput
    $shippingAddress: BusinessPartnerShippingAddressInput
    $mainAccount: String
    $downloadFiles: Boolean
    $createOrders: Boolean
    $createReports: Boolean
  ) {
    registerClient(
      email: $email
      firstName: $firstName
      lastName: $lastName
      username: $username
      partner: $partner
      salesUser: $salesUser
      shippingAddress: $shippingAddress
      mainAccount: $mainAccount
      downloadFiles: $downloadFiles
      createOrders: $createOrders
      createReports: $createReports
    ) {
      id
      roles
    }
  }
`;

export type MainAccount = 'Main account' | 'Subaccount' | undefined;

export type RegisterClientVariables = {
  email: string;
  firstName: string;
  lastName: string;
  username: string;
  partner: { id: number } | undefined;
  salesUser: { id: number } | undefined;
  shippingAddress: { id: number } | undefined;
  mainAccount: MainAccount;
  downloadFiles: boolean;
  createOrders: boolean;
  createReports: boolean;
};

export type RegisterClientData = {
  registerClient: {
    id: string;
    roles: ROLES[];
  };
};
