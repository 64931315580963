import { gql, QueryHookOptions, useQuery } from '@apollo/client';

export const useQueryValidateToken = (
  options?: QueryHookOptions<ValidateTokenData, ValidateTokenVariables>,
) =>
  useQuery<ValidateTokenData, ValidateTokenVariables>(VALIDATE_TOKEN, options);

export const VALIDATE_TOKEN = gql`
  query validateToken($token: String!) {
    validateToken(token: $token)
  }
`;

export type ValidateTokenVariables = {
  token?: string;
};

export type ValidateTokenData = {
  validateToken: boolean;
};
