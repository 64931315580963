export type LineHeights = typeof lineHeights;

export const lineHeights = {
  lh900: '106px',
  lh800: '84px',
  lh700: '60px',
  lh600: '48px',
  lh500: '36px',
  lh400: '32px',
  lh300: '24px',
  lh200: '20px',
  lh150: '18px',
  lh100: '16px',
} as const;
