/* eslint-disable react/no-danger */
import { Breadcrumbs, Divider, Heading } from 'components';
import { Accordion } from 'components/Accordion/Accordion';
import { paths } from 'routes';
import styled, { css } from 'styled-components';
import { Box, Text } from 'ui';
import { useGetRole } from 'utils/useGetRole';
import data from './data.json';
import { Logs } from './Logs';

export const Reports = () => {
  const role = useGetRole();
  if (role === 'ADMIN') return <Logs />;
  return (
    <div>
      <Breadcrumbs
        px="s30"
        items={[
          { label: 'Home', to: '/' },
          { label: 'Reports', to: paths.main.reports },
        ]}
      />
      <Divider />
      <Box px="s30">
        <Heading>Frequently Asked Questions</Heading>
        <Box my="s50" px="s25">
          {data.map((item, index) => (
            <Accordion
              key={item.id}
              heading={
                <AccordionTitle>
                  {index + 1}
                  {'. '}
                  {item.name}
                </AccordionTitle>
              }
            >
              <AccordionContent>
                <div dangerouslySetInnerHTML={{ __html: item?.content }} />
              </AccordionContent>
            </Accordion>
          ))}
          <Divider />
        </Box>
      </Box>
    </div>
  );
};

const AccordionTitle = styled(Text)`
  ${({ theme: { space } }) => css`
    padding: ${space.s20} 0;
  `}
`;

const AccordionContent = styled.div`
  ${({ theme: { borders, space } }) => css`
    border-top: ${borders.thinGray10};
    border-radius: 4px;
    background: #e3edf6;
    padding: ${space.s20} ${space.s30};
  `}
`;
