import { Condition } from 'graphql/types/condition';

type Props = {
  column: string;
  value?: string;
  operator?: string;
}[];

export const filterEmptyConditions = (data: Props) => {
  const result: Condition[] = [];

  data?.forEach((item) => {
    const { column, operator = 'eq', value } = item;
    if (value) {
      result.push({
        column,
        operator,
        value: value || '',
      });
    }
    return null;
  });

  return result;
};
