import styled, { css } from 'styled-components';

type Size = 'small' | 'default' | 'medium';

type SpinnerProps = {
  size?: Size;
};

export const Spinner = ({ size }: SpinnerProps) => (
  <StyledSpinner viewBox="0 0 50 50" size={size}>
    <circle
      className="path"
      cx="25"
      cy="25"
      r="20"
      stroke="currentColor"
      fill="none"
      strokeWidth="4"
    />
  </StyledSpinner>
);

const StyledSpinner = styled.svg<{ size?: Size }>`
  animation: rotate 2s linear infinite;
  height: auto;
  ${({ size }) => getSizeCss(size)}

  & .path {
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

const getSizeCss = (variant?: Size) => {
  if (variant === 'small') {
    return css`
      width: 15px;
    `;
  }
  if (variant === 'medium') {
    return css`
      width: 30px;
    `;
  }
  return css`
    width: 20px;
  `;
};
