import { useState, useEffect } from 'react';
import { Button } from 'components';
import { Flex } from 'ui';
import { CartItem } from 'graphql/types/CartItem';
import {
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TableHeadRow,
} from 'components/FlexTable/Table.styled';
import { WishlistRow } from './Row';

const wishlistHead: { label: string; size: number; name: string }[] = [
  { label: 'Item code', size: 80, name: 'item-code' },
  { label: 'Description', size: 140, name: 'description' },
  { label: 'Qty', size: 70, name: 'quantity' },
  { label: 'List price', size: 70, name: 'list-price' },
  { label: 'Stock status', size: 64, name: 'stock-status' },
  { label: 'Register date', size: 104, name: 'register-date' },
  { label: 'Monitor status', size: 64, name: 'monitor-status' },
  { label: '', size: 100, name: 'cta' },
];

type WishlistProps = {
  loading: boolean;
  monitoredAll: boolean;
  onDelete: (id: number) => void;
  onMonitorItem: (id: number, currentValue: boolean) => void;
  onOrderAll: () => void;
  onOrderAllRegisteredItems: () => void;
  onOrderRegisteredItem: (id: number, quantity: number) => void;
  onClearCart: () => void;
  recentlyAddedIds: number[];
  deletedItemsIds: number[];
  tableData: CartItem[];
};

export const Wishlist = ({
  loading,
  onDelete,
  onMonitorItem,
  onOrderAllRegisteredItems,
  onOrderRegisteredItem,
  onClearCart,
  recentlyAddedIds,
  tableData,
  deletedItemsIds,
}: WishlistProps) => {
  const [orderEnabled, setOrderEnabled] = useState(false);

  useEffect(() => {
    const enable = tableData.find((item) => item.commodity.sellable);
    setOrderEnabled(!enable);
  }, [tableData]);

  return (
    <Flex pt="s10" flexDirection="column" flex="1">
      <Table>
        <TableHead>
          <TableHeadRow>
            {wishlistHead.map((head) => (
              <TableHeadCell key={head.name} flex={head.size}>
                {head.label}
              </TableHeadCell>
            ))}
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {tableData.map((data) => (
            <WishlistRow
              deletedItemsIds={deletedItemsIds}
              key={data.id}
              loading={loading}
              onDelete={onDelete}
              onMonitorItem={onMonitorItem}
              onOrderRegisteredItem={onOrderRegisteredItem}
              recentlyAddedIds={recentlyAddedIds}
              {...data}
            />
          ))}
        </TableBody>
      </Table>
      <Flex mt="s20" mb="s40" justifyContent="flex-end">
        <Flex ml="s30">
          <Button
            variant="primary"
            disabled={orderEnabled}
            onClick={onOrderAllRegisteredItems}
          >
            Order all registered items
          </Button>
          <Button onClick={onClearCart} variant="text" ml="s30">
            Clear all
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};
