import { Flex, Icon, Text } from 'ui';

export const PasswordRules = ({ password }: { password?: string }) => {
  function containsSpecialChars(value: string) {
    const specialChars = /[`!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/;
    return specialChars.test(value);
  }

  const checkPasswordRule = (value: string) => {
    const minValueMarks = value?.length >= 8;
    const oneSpecialMark = containsSpecialChars(value);
    const differentLetters =
      value?.search(/[A-Z]/) >= 0 && value?.search(/[a-z]/) >= 0;
    const success = minValueMarks && oneSpecialMark && differentLetters;

    return { minValueMarks, oneSpecialMark, differentLetters, success };
  };

  const rules = checkPasswordRule(password || '');

  return (
    <div>
      <Rules name="Use at least 8 characters" succes={rules.minValueMarks} />
      <Rules name="Use at least 1 special mark" succes={rules.oneSpecialMark} />
      <Rules
        name="Use different type of letters"
        succes={rules.differentLetters}
      />
    </div>
  );
};

const Rules = ({ name, succes }: { name: string; succes: boolean }) => {
  const color = succes ? 'success100' : 'gray05';
  return (
    <Flex alignItems="center" mb="s10">
      <Icon name="16-check" color={color} mr="s12" />
      <Text>{name}</Text>
    </Flex>
  );
};
