import { Heading, Autocomplete, Button } from 'components';
import styled, { css } from 'styled-components';
import { Box, Grid, Text, Flex } from 'ui';
import { Commodity } from 'graphql/types/Commodity';
import { SingleValue } from 'react-select';

type AddProductProps = {
  onLoad: (value: string) => Promise<Commodity[]>;
  onSubmit: (autocompleteData?: SingleValue<Commodity>) => void;
};

export const AddItem = ({ onLoad, onSubmit }: AddProductProps) => {
  return (
    <Container>
      <Heading>Validation</Heading>
      <Grid gridGap="s20" gridTemplateColumns="max-content 542px" pl="s30">
        <Flex alignItems="center">
          <Text lineHeight="lh300" color="gray80">
            Add item by item code
          </Text>
        </Flex>
        <Autocomplete<Commodity>
          icon={{
            name: '18-search',
            color: 'blue',
            space: 's10',
          }}
          styles={{
            option: (defaultStyles, state) => ({
              ...defaultStyles,
              padding: '4px 8px',
              '& svg': state.isFocused
                ? { visibility: 'visible' }
                : { visibility: 'hidden' },
            }),
          }}
          noOptionsMessage={() => null}
          formatOptionLabel={CartAndDraftsAutocompleteOption}
          placeholder="Search for products to add by item code"
          getOptionLabel={(option) => option?.itemCode ?? ''}
          getOptionValue={(option) => option?.itemCode ?? ''}
          loadOptions={onLoad}
          onSubmit={onSubmit}
        />
      </Grid>
    </Container>
  );
};

export const Container = styled(Box)`
  ${({ theme: { borders, colors, space } }) => css`
    background: ${colors.gray0};
    padding: 0 ${space.s30};
    padding-bottom: ${space.s30};
    border-top: ${borders.thinGray10};
  `};
`;

const CartAndDraftsAutocompleteOption = ({
  itemCode,
  description,
}: Commodity) => {
  return (
    <Grid gridTemplateColumns={'1fr 3fr auto'}>
      <OptionText mr="s20">{itemCode}</OptionText>
      <OptionText>{description}</OptionText>
      <Button variant="icon" icon={{ name: '20-plus', color: 'blue' }} />
    </Grid>
  );
};

const OptionText = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  flex: 1;
`;
