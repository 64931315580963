import { Flex, Grid } from 'ui';
import { useState } from 'react';
import { Button, Panel, TextInput } from 'components';
import { useQueryAdminWishlist, useQueryUsers, UsersData } from 'graphql/query';
import { CreateProfileModal } from 'pages/Users/components/CreateProfileModal/CreateProfileModal';
import { UsersTable } from 'components/UsersTable';
import { useDebouncedCallback } from 'use-debounce';
import styled from 'styled-components';
import useInfiniteScroll from 'utils/useInfiniteScroll';
import { useNavigate } from 'react-router-dom';
import { paths } from 'routes';
import { CreateReportModal } from 'pages/Reports/components/CreateReportModal/CreateReportModal';
import { MailingCreate } from 'pages/Mailing/components';
import { WishlistStatusTable } from './components/WishlistStatusTable/WishlistStatusTable';

export const HomeAdmin = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [isCreateProfileModalOpen, setIsCreateProfileModalOpen] =
    useState(false);
  const [isCreateReportModalOpen, setIsCreateReportModalOpen] = useState(false);
  const [isCreateMailingModalOpen, setIsCreateMailingModalOpen] =
    useState(false);
  const { data: wishlistData } = useQueryAdminWishlist();

  const { data, loading, fetchMore } = useQueryUsers({
    variables: {
      orderBy: 'updatedAt',
      orderDir: 'DESC',
      phrase: search,
      limit: 20,
      offset: 0,
    },
  });

  const count = data?.users?.count || 0;

  const handleNavigateWishlist = () => {
    navigate(paths.main.wishlist);
  };

  const { handleFetchMore } = useInfiniteScroll<UsersData>({
    data,
    count,
    loading,
    resource: (item) => item?.users?.items,
    fetchMore,
    disabled: true,
  });

  const users = data?.users?.items || [];

  const handleSearch = useDebouncedCallback((value?: string) => {
    setSearch(value || '');
  }, 500);

  return (
    <>
      <Grid gridTemplateRows={'75px 1fr'} mb="s30">
        <Flex px="s20" alignItems="center" justifyContent="flex-end">
          <Button
            icon={{ position: 'left', name: '20-email' }}
            mr="s20"
            variant="primary"
            size="medium"
            onClick={() => setIsCreateMailingModalOpen(true)}
          >
            Create message
          </Button>
          <Button
            icon={{ position: 'left', name: '20-reports' }}
            variant="primary"
            size="medium"
            onClick={() => setIsCreateReportModalOpen(true)}
          >
            Create report
          </Button>
        </Flex>

        <Flex flexDirection="column">
          <Panel expandable={false} label={'WISHLIST STATUS'}>
            <Flex flex={1} flexDirection="column">
              <WishlistStatusTable
                onWishlistButtonClick={handleNavigateWishlist}
                data={wishlistData?.adminWishlist ?? []}
              />
            </Flex>
          </Panel>
          <Panel expandable={false} label={'USERS'}>
            <Flex flex={1} flexDirection="column">
              <Grid gridTemplateColumns="1fr 148px" gridGap={'s20'}>
                <TextInput
                  defaultValue={search}
                  onChange={(e) => handleSearch(e?.target?.value)}
                  icon={{ name: '18-search', color: 'blue' }}
                  placeholder="Search user by code, name or company"
                  type="search"
                />
                <Button
                  variant={'outline-primary'}
                  onClick={() => setIsCreateProfileModalOpen(true)}
                  icon={{
                    name: '20-createUser',
                    color: 'blue',
                    position: 'left',
                  }}
                >
                  Create profile
                </Button>
              </Grid>
              <WrapperUsersTable>
                <UsersTable data={users} loading={loading} />
                <Flex justifyContent="flex-end">
                  {users?.length < count && !search && (
                    <Button
                      mr="s30"
                      variant="text"
                      onClick={() => navigate(paths.main.users)}
                    >
                      <>See all ({count})</>
                    </Button>
                  )}
                  {users?.length < count && (
                    <Button
                      variant="outline-primary"
                      onClick={() => handleFetchMore()}
                    >
                      See more (+20)
                    </Button>
                  )}
                </Flex>
              </WrapperUsersTable>
            </Flex>
          </Panel>
        </Flex>
      </Grid>
      <CreateProfileModal
        open={isCreateProfileModalOpen}
        handleClose={() => setIsCreateProfileModalOpen(false)}
      />
      <CreateReportModal
        open={isCreateReportModalOpen}
        handleClose={() => setIsCreateReportModalOpen(false)}
      />
      <MailingCreate
        handleClose={() => {
          setIsCreateMailingModalOpen(false);
        }}
        open={isCreateMailingModalOpen}
        type="create"
      />
    </>
  );
};

const WrapperUsersTable = styled.div`
  min-height: 340px;
`;
