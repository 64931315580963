import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { CartItem } from 'graphql/types/CartItem';

export const useQueryAdminWishlist = (
  options?: QueryHookOptions<AdminWishlistData>,
) => useQuery<AdminWishlistData>(ADMIN_WISHLIST, options);

export const ADMIN_WISHLIST = gql`
  query adminWishlist {
    adminWishlist {
      quantity
      id
      price
      vatRate
      vatValue
      commodity {
        id
        itemCode
        sellable
      }
      movedFromWishlistDate
      cart {
        id
        user {
          name
        }
      }
      wishlist
      status
      createdAt
      updatedAt
      monitored(section: "Cart")
    }
  }
`;

export type AdminWishlistData = {
  adminWishlist: CartItem[];
};
