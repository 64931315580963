export const role = [
  {
    key: 'ROLE_SALES',
    label: 'Sales',
  },
  { key: 'ROLE_USER', label: 'Customer' },
];

export const status = [
  {
    key: 1,
    label: 'Active',
  },
  { key: 0, label: 'Disabled' },
];
