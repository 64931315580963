import { Flex, Icon, Text } from 'ui';
import { ORDER_STATUSES } from './OrderStatus';

export const OrderStatusLegend = () => (
  <Flex alignItems="center">
    {ORDER_STATUSES.map((status) => (
      <Flex key={status.status} alignItems="center" mr="s20">
        <Icon color={status.color} mr="s10" name={status.icon} />
        <Text lineHeight="lh300" textTransform="capitalize">
          {status.label}
        </Text>
      </Flex>
    ))}
  </Flex>
);
