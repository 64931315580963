import { ToggleSwitch } from 'components';
import { useMutationDisableUser } from 'graphql/mutation';
import styled, { css } from 'styled-components';
import { Flex, Grid, Text } from 'ui';
import { getRoleLabel } from 'utils/getRoleLabel';
import { UserByIdData, USER_BY_ID } from '../graphql/useQueryUserById';

type Props = UserByIdData['userById'];

export const SalesBasicInfo = ({ data }: { data?: Props }) => {
  const { email, enabled, name, roles, userName, id } = data || {};

  const [disabledUser] = useMutationDisableUser({
    refetchQueries: [{ query: USER_BY_ID, variables: { id: `${id}` } }],
  });

  const handleChangeStatus = (status: boolean) => {
    if (id) {
      disabledUser({ variables: { id: `${id}`, enabled: status } });
    }
  };

  return (
    <Flex mt={'s20'} flexDirection="column">
      <SummaryContainer p={'s30'}>
        <Column mr={'s45'}>
          <Label>User</Label>
          <Text>{name}</Text>

          <Label>Role</Label>
          <Text>{roles && getRoleLabel(roles[0])}</Text>

          <Label>E-mail</Label>
          <Text>{email}</Text>

          <Label>Login</Label>
          <Text>{userName}</Text>

          <Label>User status</Label>
          <Text>
            <ToggleSwitch
              defaultChecked={enabled}
              onChange={(e) => handleChangeStatus(e.target.checked)}
            />
          </Text>
        </Column>
      </SummaryContainer>
    </Flex>
  );
};

const SummaryContainer = styled(Flex)`
  height: fit-content;
  min-width: 450px;

  ${({ theme: { colors, radii } }) => css`
    background: ${colors.gray06};
    border-radius: ${radii.rounded};
  `};
`;
const Column = styled(Grid).attrs({
  gridTemplateColumns: 'auto auto',
  gridAutoRows: 'max-content',
})`
  gap: ${({ theme: { space } }) => `${space.s15} ${space.s70}`};
`;

const Label = styled(Text).attrs({ fontWeight: 'medium', color: 'gray80' })``;
