import { useState, useLayoutEffect, ReactNode } from 'react';
import { createPortal } from 'react-dom';

export type PortalProps = {
  children: ReactNode;
  wrapperId: string;
};

export const Portal = ({
  children,
  wrapperId = 'react-portal-wrapper',
}: PortalProps) => {
  const [wrapper, setWrapper] = useState<HTMLElement | null>(null);

  const createWrapperAndAppendToBody = (id: string) => {
    const wrapperElement = document.createElement('div');
    wrapperElement.setAttribute('id', id);
    document.body.appendChild(wrapperElement);
    return wrapperElement;
  };

  useLayoutEffect(() => {
    let element = document.getElementById(wrapperId);
    const systemCreated = false;

    if (!element) {
      element = createWrapperAndAppendToBody(wrapperId);
    }
    setWrapper(element);

    return () => {
      if (systemCreated && element?.parentNode) {
        element.parentNode.removeChild(element);
      }
    };
  }, [wrapperId]);

  if (wrapper === null) return null;

  return createPortal(children, wrapper);
};
