import styled, { css } from 'styled-components';
import { Flex, Text } from 'ui';
import { ExpandRow } from 'components/FlexTable/Table.styled';
import { useGetRole } from 'utils/useGetRole';
import { TransformedOrdersData } from 'pages/SalesCustomerOrders/components/Orders';

export type OrdersExpandRowProps = { data: TransformedOrdersData };

export const OrdersExpandRow = ({
  data: { deliveryNoteItem, order, commodity },
}: OrdersExpandRowProps) => {
  const role = useGetRole();
  const isAdminRole = role === 'ADMIN';
  return (
    <ExpandRow p="s10">
      <Flex flex="6" flexDirection="column">
        <ExpandRowHeader>Production</ExpandRowHeader>
        <ExpandRowProduction>
          <ItemRow isFlex={isAdminRole}>
            <ExpandRowLabel>Expected production date: </ExpandRowLabel>
            <ExpandRowText>
              {commodity?.purchases?.[0]?.productionDate ?? '-'}
            </ExpandRowText>
          </ItemRow>
          <ItemRow isFlex={isAdminRole}>
            <ExpandRowLabel>Shipped from HQ: </ExpandRowLabel>
            <ExpandRowText>
              {commodity?.purchases?.[0]?.shippedFrom ?? '-'}
            </ExpandRowText>
          </ItemRow>
          <ItemRow isFlex={isAdminRole}>
            <ExpandRowLabel>Estimated arrival time: </ExpandRowLabel>
            <ExpandRowText>
              {commodity?.purchases?.[0]?.expectedArrival ?? '-'}
            </ExpandRowText>
          </ItemRow>
        </ExpandRowProduction>
      </Flex>
      <Flex flex="1" flexDirection="column">
        <ExpandRowHeader>Tracking number</ExpandRowHeader>
        <Flex>
          <ExpandRowLink>
            {deliveryNoteItem?.deliveryNote?.transports[0]?.carrierNumber ??
              '-'}
          </ExpandRowLink>
        </Flex>
      </Flex>
      <Flex flex="1" flexDirection="column">
        <ExpandRowHeader>Invoice</ExpandRowHeader>
        <Flex>
          <ExpandRowLink>
            {deliveryNoteItem?.invoiceItem?.invoice?.documentNumber ?? '-'}
          </ExpandRowLink>
        </Flex>
      </Flex>
      {role === 'SALES' && (
        <Flex flex="1" flexDirection="column">
          <ExpandRowHeader>Delivery Address</ExpandRowHeader>
          <Flex>
            <ExpandRowLink>
              {order.shipTo ? order.shipTo.address : '-'}
            </ExpandRowLink>
          </Flex>
        </Flex>
      )}
    </ExpandRow>
  );
};

const ItemRow = styled.div<{ isFlex: boolean }>`
  ${({ isFlex }) => css`
    display: ${isFlex ? 'flex' : 'block'};
  `}
`;

const ExpandRowProduction = styled(Flex)`
  gap: 65px;
`;

const ExpandRowText = styled(Text).attrs({ lineHeight: 'lh300', as: 'p' })``;

const ExpandRowLink = styled(ExpandRowText)`
  ${({ theme: { colors } }) => css`
    color: ${colors.blue};
  `}
`;

const ExpandRowLabel = styled(ExpandRowText).attrs({ as: 'p' })`
  ${({ theme: { fontWeights, space } }) => css`
    font-weight: ${fontWeights.bold};
    margin-right: ${space.s05};
  `}
`;

const ExpandRowHeader = styled(ExpandRowText)`
  padding: 0 0 4px;

  ${({ theme: { colors } }) => css`
    color: ${colors.gray80};
  `}
`;
