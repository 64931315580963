import { SelectInput, TextInput } from 'components';
import { Flex, Grid, Text } from 'ui';
import { DateRangeInput } from 'components/DateRangeInput/DateRangeInput';
import { YourOrdersFilters } from 'pages/YourOrders/hooks/useYourOrdersFilters';

type OrdersFilterProps = YourOrdersFilters;

export const OrdersFilter = ({
  handlers,
  filters,
  options,
}: OrdersFilterProps) => (
  <Flex flexDirection="column" mb="s50" flex="1">
    <TextInput
      icon={{ name: '18-search', color: 'blue' }}
      value={filters.searchInputValue}
      onChange={handlers.handleSearchInputChange}
      placeholder="Search order by reference number, item code, order number"
      type="search"
    />
    <Grid mt="s20" gridGap="s30" gridTemplateColumns="212px 212px 212px 212px">
      <Flex flexDirection="column">
        <Text lineHeight="lh300" mb="s10">
          Filter by order status
        </Text>
        <SelectInput
          value={filters.selectedOrderStatus}
          onChange={(option) =>
            handlers.handleSelectedOrderStatusChange(option)
          }
          options={options.orderStatusOptions}
          placeholder="Choose status"
          labelResolver={(option) => option}
          keyResolver={(option) => option}
        />
      </Flex>
      <Flex flexDirection="column">
        <Text lineHeight="lh300" mb="s10">
          Filter by JCE Stock Status
        </Text>
        <SelectInput
          value={filters.selectedStockStatus}
          onChange={handlers.handleStockStatusChange}
          options={options.stockStatusOptions}
          placeholder="Choose status"
          labelResolver={(option) => option?.label}
          keyResolver={(option) => option?.value}
        />
      </Flex>
      <Flex flexDirection="column">
        <Text lineHeight="lh300" mb="s10">
          Delivery address
        </Text>
        <SelectInput
          value={filters.selectedDeliveryAddress}
          onChange={(option) =>
            handlers.handleSelectedDeliveryAddressChange(option)
          }
          options={options.deliveryAddressOptions}
          placeholder="Choose address"
          labelResolver={(option) => option?.address}
          keyResolver={(option) => option?.id}
        />
      </Flex>
      <Flex flexDirection="column">
        <Text lineHeight="lh300" mb="s10">
          Date range
        </Text>
        <DateRangeInput
          //@ts-ignore
          onChange={handlers.handleDateRangeInputChange}
          //@ts-ignore
          value={filters.dateRangeInputValue}
        />
      </Flex>
    </Grid>
  </Flex>
);
