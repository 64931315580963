import { Button } from 'components';
import { useMutationLogout } from 'graphql/mutation';
import { ShippingAddress, useQueryUser } from 'pages/home/graphql/useQueryUser';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { paths } from 'routes';
import styled from 'styled-components';
import { Box, Flex, Icon, Text } from 'ui';
import { useGetRole } from 'utils';
import { EditDelivery } from './EditDelivery';

export const YourProfile = () => {
  const [activeAddress, setActiveAddress] = useState<ShippingAddress>();
  const [openEditDeliver, setOpenEditDelivery] = useState(false);
  const [logout] = useMutationLogout();
  const navigate = useNavigate();
  const role = useGetRole();

  const { data } = useQueryUser({
    onCompleted: ({ user }) => {
      const id = user?.businessPartnerShippingAddress?.id;
      const address = user?.businessPartner?.shippingAddresses.find(
        (item) => item.id === id,
      );

      if (address) {
        setActiveAddress(address);
      }
    },
  });

  const handleLogout = () => {
    logout({ refetchQueries: ['user'] });
  };

  const { businessPartner, email, firstName, lastName } = data?.user || {};
  const { address, city, name, shippingAddresses, zipCode } =
    businessPartner || {};
  return (
    <>
      <div>
        <Item>
          <Icon name="20-user" color="gray40" mr="s15" />
          <div>
            <Text fontWeight="medium" color="gray80" mb="s10" as="p">
              User:
            </Text>
            <Text lineHeight="lh300" as="p">
              {firstName} {lastName}
            </Text>
            <Text mb="s10" lineHeight="lh300" as="p">
              {email}
            </Text>
            <Button
              fontWeight="bold"
              variant="text"
              onClick={() => navigate(paths.auth.createNewPassword)}
            >
              Change password
            </Button>
            {role === 'SALES' && (
              <Button
                fontWeight="bold"
                mt="s12"
                variant="text"
                onClick={handleLogout}
              >
                Log out
              </Button>
            )}
          </div>
        </Item>
        {role === 'CLIENT' && (
          <Flex mt="s40">
            <Item>
              <Icon name="20-company" color="gray40" mr="s15" />
              <div>
                <Text fontWeight="medium" color="gray80" as="p" mb="s10">
                  Company:
                </Text>
                <Text as="p">{name}</Text>
                <Text as="p">{address}</Text>
                <Text as="p">{zipCode}</Text>
                <Text as="p">{city}</Text>
              </div>
            </Item>
            <Flex ml="s60">
              <Icon name="20-bookmark" color="gray40" mr="s15" />
              <div>
                <Text fontWeight="medium" color="gray80" mb="s10" as="p">
                  Delivery addresses:
                </Text>
                {activeAddress && (
                  <Box mb="s10">
                    <Text as="p">{activeAddress.name}</Text>
                    <Text as="p">{activeAddress.address}</Text>
                    <Text as="p">{activeAddress.zipCode}</Text>
                    <Text as="p">{activeAddress.city}</Text>
                  </Box>
                )}
                <Button
                  fontWeight="bold"
                  onClick={() => setOpenEditDelivery(true)}
                  variant="text"
                >
                  Edit
                </Button>
              </div>
            </Flex>
          </Flex>
        )}
      </div>
      <EditDelivery
        shippingAddresses={shippingAddresses}
        open={openEditDeliver}
        handleClose={() => setOpenEditDelivery(false)}
        activeAddress={activeAddress}
        setActiveAdresses={setActiveAddress}
      />
    </>
  );
};

const Item = styled(Box)`
  display: flex;
`;
