/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

type HighlightedProps = {
  text?: string;
  search?: string;
  elipsis?: boolean;
};

export const Highlighted = ({
  elipsis,
  text = '',
  search = '',
}: HighlightedProps) => {
  const re = useMemo(() => {
    const SPECIAL_CHAR_RE = /([.?*+^$[\]\\(){}|-])/g;
    const escapedSearch = search.replace(SPECIAL_CHAR_RE, '\\$1');
    return new RegExp(`(${escapedSearch})`, 'i');
  }, [search]);

  return (
    <HighlightedWrapper elipsis={elipsis}>
      {search === ''
        ? text
        : text
            .split(re)
            .filter((part) => part !== '')
            .map((part, i) =>
              re.test(part) ? (
                <MarkedText key={part + i}>{part}</MarkedText>
              ) : (
                part
              ),
            )}
    </HighlightedWrapper>
  );
};

const MarkedText = styled.mark`
  ${({ theme: { fontWeights } }) => css`
    font-weight: ${fontWeights.bold};
  `};
`;

const HighlightedWrapper = styled.span<{ elipsis?: boolean }>`
  ${({ elipsis }) =>
    elipsis &&
    css`
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    `}
`;
