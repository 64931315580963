import { Alert, Button, Modal, ModalBody, ModalProps } from 'components';
import { Flex } from 'ui';
import styled from 'styled-components';
import { StepProgress } from 'components/StepProgress/StepProgress';
import {
  CreateProfileModalContextProvider,
  CustomerProfileFormData,
  SalesProfileFormData,
  useCreateProfileModalContext,
  UserRoles,
} from './CreateProfileModalContext';
import { useCreateProfileModalLogic } from './useCreateProfileModalLogic';

type CreateProfileModalProps = Pick<ModalProps, 'open' | 'handleClose'> & {
  initialValuesSales?: SalesProfileFormData;
  initialValuesCustomer?: Partial<CustomerProfileFormData>;
  initialStep?: number;
  initialRole?: UserRoles;
};

const CreateProfileModalInner = ({
  handleClose,
  open,
}: CreateProfileModalProps) => {
  const context = useCreateProfileModalContext();
  const { error } = context;
  return (
    <StyledModal
      title={context?.steps[context.step].title}
      handleClose={handleClose}
      open={open}
    >
      {context?.steps[context.step].content}
      <Alert mt="s20" visible={!!error}>
        {error}
      </Alert>
      <Flex mt={'s40'} mb={'s10'} justifyContent={'space-between'}>
        <Button
          onClick={context?.steps[context.step].firstButton.onClick}
          disabled={context?.steps[context.step].firstButton?.disabled}
          variant={'outline-primary'}
        >
          {context?.steps[context.step].firstButton.label}
        </Button>
        <StepProgress activeStep={context?.steps[context.step].step ?? 0} />
        <Button
          onClick={context?.steps[context.step].secondButton.onClick}
          disabled={context?.steps[context.step].secondButton.disabled}
          variant={'primary'}
        >
          {context?.steps[context.step].secondButton.label}
        </Button>
      </Flex>
    </StyledModal>
  );
};

export const CreateProfileModal = ({
  handleClose,
  open,
  initialValuesSales,
  initialValuesCustomer,
  initialStep = 0,
  initialRole,
}: CreateProfileModalProps) => {
  const value = useCreateProfileModalLogic({
    handleClose,
    initialValuesSales,
    initialValuesCustomer,
    initialStep,
    initialRole,
  });
  return (
    <CreateProfileModalContextProvider value={value}>
      <CreateProfileModalInner handleClose={handleClose} open={open} />
    </CreateProfileModalContextProvider>
  );
};

export const StyledModal = styled(Modal)`
  ${ModalBody} {
    width: 684px;
  }
`;

export const InputWrapper = styled(Flex)`
  flex-direction: column;
  max-width: 304px;
  width: 100%;
`;
