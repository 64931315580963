import { forwardRef, InputHTMLAttributes } from 'react';
import { space as styledSystemSpace } from 'styled-system';
import styled, { css } from 'styled-components';
import { MarginProps } from 'ui';
import { removeEmptyValues } from 'utils/removeEmptyValues';

type ToggleSwitchProps = {} & MarginProps &
  InputHTMLAttributes<HTMLInputElement>;

export const ToggleSwitch = forwardRef<HTMLInputElement, ToggleSwitchProps>(
  ({ m, mt, mr, mb, ml, mx, my, ...rest }, ref) => {
    const space = { m, mt, mr, mb, ml, mx, my };

    return (
      <Label {...removeEmptyValues(space)}>
        <Input ref={ref} {...rest} type="checkbox" />
        <Switch />
      </Label>
    );
  },
);

const Label = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  ${styledSystemSpace}
`;

const Switch = styled.div`
  border-radius: 20px;
  box-sizing: border-box;
  position: relative;
  transition: 300ms all;

  ${({ theme: { colors, space } }) => css`
    background: ${colors.gray};
    height: ${space.s17};
    padding: ${space.s025};
    width: ${space.s30};
  `};

  &:before {
    content: '';
    height: 10px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    transition: transform 300ms;
    width: 10px;

    ${({ theme: { radii, space, colors } }) => css`
      background: ${colors.white};
      border-radius: ${radii.circle};
      left: ${space.s025};
    `};
  }
`;

const Input = styled.input`
  opacity: 0;
  position: absolute;

  &:checked + ${Switch} {
    background: ${({ theme: { colors } }) => colors.blue};

    &:before {
      transform: translate(10px, -50%);
    }
  }
`;
