import { DottedStatus } from 'components';
import { Tooltip } from 'components/Tooltip';
import { StockStatuses } from 'graphql/types/StockStatuses';
import { convertStatus } from './convertStatus';

type StockStatusProps = {
  status: StockStatuses;
  label?: StockStatuses;
  disabledLabel?: boolean;
};

const Labels: Record<StockStatuses, string> = {
  IN_STOCK: 'In Stock',
  PARTIALY_IN_STOCK: 'Partialy in Stock',
  AT_HQ: 'At HQ',
  AT_OTHER_SUBSIDIARY: 'At other subsidiary',
  PRODUCTION_ORDER: 'Production Order',
};

export const StockStatus = ({
  status,
  disabledLabel,
  label,
}: StockStatusProps) => {
  const data = convertStatus(status);
  if (!data) return null;
  const { color } = data;

  return (
    <Tooltip
      active={!label}
      content={status ? Labels[status] : ''}
    >
      <DottedStatus
        color={color}
        label={label ? Labels[label] : undefined}
        disabledLabel={disabledLabel}
      />
    </Tooltip>
  );
};
