import styled, { css } from 'styled-components';

import { Text, TextProps } from 'ui';

export const Heading = (props: TextProps) => (
  <TextStyled
    color="gray80"
    fontSize="fs300"
    fontWeight="bold"
    forwardedAs="p"
    lineHeight="lh300"
    mb="s30"
    pt="s15"
    textTransform="uppercase"
    {...props}
  />
);

const TextStyled = styled(Text)`
  width: fit-content;

  ${({ theme: { borders } }) => css`
    border-top: ${borders.mediumGray10};
  `};
`;
