import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export function useMutationSendWishlistByEmail(
  options?: MutationHookOptions<
    SendWishlistByEmailData,
    SendWishlistByEmailVariables
  >,
) {
  const data = useMutation<
    SendWishlistByEmailData,
    SendWishlistByEmailVariables
  >(SEND_WISHLIST_BY_EMAIL, options);
  return data;
}

export const SEND_WISHLIST_BY_EMAIL = gql`
  mutation sendWishlistByEmail($email: String!) {
    sendWishlistByEmail(email: $email)
  }
`;

export type SendWishlistByEmailVariables = {
  email: string;
};

export type SendWishlistByEmailData = {};
