import { gql, QueryHookOptions, useQuery } from '@apollo/client';

export const useQueryBusinessPartners = (
  options?: QueryHookOptions<BusinessPartnersData>,
) => useQuery<BusinessPartnersData>(BUSINESS_PARTNERS, options);

export const BUSINESS_PARTNERS = gql`
  query businessPartners {
    businessPartners {
      id
      name
      shippingAddresses {
        id
        name
        address
        zipCode
        city
      }
    }
  }
`;

export type BusinessPartnersData = {
  businessPartners: BusinessPartner[];
};

export type BusinessPartner = {
  id: number;
  name: string;
  shippingAddresses: {
    id: number;
    name: string;
    address: string;
    zipCode: string;
    city: string;
  }[];
};
