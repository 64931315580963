import { Flex, Text } from 'ui';
import { getRoleLabel } from 'utils/getRoleLabel';
import { UserByIdData } from '../graphql/useQueryUserById';
import { Column, Label, SummaryContainer } from './common';

type Props = UserByIdData['userById'];

export const CustomerAccountInfo = ({ data }: { data?: Props }) => {
  const {
    roles,
    accountType,
    createOrdersPermission,
    createReportsPermission,
    downloadFilesPermission,
  } = data || {};

  return (
    <Flex mt={'s20'} style={{ minWidth: '314px' }} flexDirection="column">
      <SummaryContainer p={'s30'}>
        <Column>
          <Label>Role</Label>
          <Text>{roles && getRoleLabel(roles[0])}</Text>

          <Label>Account type</Label>
          <Text>{accountType || '-'}</Text>

          <Label>Access</Label>
          <Flex style={{ gap: '12px' }} flexDirection="column">
            {downloadFilesPermission && <Text>Download files</Text>}
            {createOrdersPermission && <Text>Create orders</Text>}
            {createReportsPermission && <Text>Create reports</Text>}
          </Flex>
        </Column>
      </SummaryContainer>
    </Flex>
  );
};
