import { SelectInput, TextInput, ToggleSwitch } from 'components';
import { Flex, Grid, Text } from 'ui';
import { DateRangeInput } from 'components/DateRangeInput/DateRangeInput';
import { AdminCustomerOrdersFilters } from '../hooks/useAdminCustomerOrdersFilters';

type OrdersFilterProps = {} & AdminCustomerOrdersFilters;

export const OrdersFilter = ({
  options,
  handlers,
  filters,
}: OrdersFilterProps) => {
  return (
    <Flex flexDirection="column" mb="s50" flex="1">
      <TextInput
        icon={{ name: '18-search', color: 'blue' }}
        placeholder="Search order by reference number, item code, order number"
        type="search"
        defaultValue={filters.searchInputValue}
        onChange={handlers.handleSearchInputChange}
      />
      <Grid mt="s20" gridGap="s30" gridTemplateColumns="1fr 1fr 1fr 280px 1fr">
        <Flex flexDirection="column">
          <Text lineHeight="lh300" mb="s10">
            Filter by client
          </Text>
          <SelectInput
            value={filters.selectedClient}
            onChange={(option) => handlers.handleSelectedClientChange(option)}
            options={options.clientOptions}
            placeholder="Choose client"
            labelResolver={(option) =>
              option ? `${option.name} (${option.id})` : ''
            }
            keyResolver={(option) => option?.id}
          />
        </Flex>
        <Flex flexDirection="column">
          <Text lineHeight="lh300" mb="s10">
            Filter by company
          </Text>
          <SelectInput
            value={filters.selectedCompany}
            onChange={(option) => handlers.handleSelectedCompanyChange(option)}
            options={options.companyOptions}
            placeholder="Choose company"
            labelResolver={(option) => option?.name}
            keyResolver={(option) => option?.id}
          />
        </Flex>
        <Flex flexDirection="column">
          <Text lineHeight="lh300" mb="s10">
            Delivery address
          </Text>
          <SelectInput
            value={filters.selectedDeliveryAddress}
            onChange={(option) =>
              handlers.handleSelectedDeliveryAddressChange(option)
            }
            options={options.deliveryAddressOptions}
            placeholder="Choose address"
            labelResolver={(option) => option?.name}
            keyResolver={(option) => option?.id}
          />
        </Flex>
        <Flex flexDirection="column">
          <Text lineHeight="lh300" mb="s10">
            Date range
          </Text>
          <DateRangeInput
            //@ts-ignore
            onChange={handlers.handleDateRangeInputChange}
            //@ts-ignore
            value={filters.dateRangeInputValue}
          />
        </Flex>
        <Flex alignItems="end" pb="s15">
          <Text mr="s10" lineHeight="lh100">
            Show only monitored
          </Text>
          <ToggleSwitch
            checked={filters.showOnlyMonitoredValue}
            onChange={handlers.handleShowOnlyMonitoredChange}
          />
        </Flex>
      </Grid>
    </Flex>
  );
};
