import { Box, MarginProps, PaddingProps } from 'ui';
import { Table, TableBody, Heading } from 'components';
import { useViewOrder } from 'utils';
import { useQuerySalesOrders } from 'graphql/query';
import { paths } from 'routes';
import { UrgentOrdersHead } from './UrgentOrdersHead';
import { UrgentOrdersRow } from './UrgentOrdersRow';

type UrgentOrdersProps = MarginProps & PaddingProps;

export const UrgentOrders = (props: UrgentOrdersProps) => {
  const { handleViewOrder } = useViewOrder(paths.main.customerOrdersSales);

  const { data } = useQuerySalesOrders({
    variables: {
      limit: 6,
      monitored: undefined,
      orderDir: 'DESC',
      orderBy: 'createdAt',
    },
  });

  return (
    <Box {...props}>
      <Heading>URGENT ORDERS</Heading>
      <Box>
        <Table>
          <UrgentOrdersHead />
          <TableBody>
            {data?.orders.salesOrders?.map((item) => (
              <UrgentOrdersRow
                onViewOrder={handleViewOrder}
                key={item.id}
                {...item}
              />
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};
