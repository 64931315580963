import { Flex, Text, Grid, FlexProps } from 'ui';
import { Autocomplete, Button } from 'components';
import { Commodity } from 'graphql/types/Commodity';
import styled from 'styled-components';
import { SingleValue } from 'react-select';

type AddProductProps = {
  onLoad: (value: string) => Promise<Commodity[]>;
  onSubmit: (autocompleteData?: SingleValue<Commodity>) => void;
} & FlexProps;

export const AddProduct = ({ onLoad, onSubmit, ...rest }: AddProductProps) => (
  <Flex flex="1" {...rest}>
    <Autocomplete<Commodity>
      icon={{
        name: '18-search',
        color: 'blue',
        space: 's10',
      }}
      styles={{
        option: (defaultStyles, state) => ({
          ...defaultStyles,
          padding: '4px 8px',
          '& svg': state.isFocused
            ? { visibility: 'visible' }
            : { visibility: 'hidden' },
        }),
      }}
      noOptionsMessage={() => null}
      formatOptionLabel={CartAndDraftsAutocompleteOption}
      placeholder="Search for products to add by item code"
      getOptionLabel={(option) => option?.itemCode ?? ''}
      getOptionValue={(option) => option?.itemCode ?? ''}
      loadOptions={onLoad}
      onSubmit={onSubmit}
    />
  </Flex>
);

const CartAndDraftsAutocompleteOption = ({
  itemCode,
  description,
}: Commodity) => {
  return (
    <Grid gridTemplateColumns={'1fr 3fr auto'}>
      <OptionText mr="s20">{itemCode}</OptionText>
      <OptionText>{description}</OptionText>
      <Button variant="icon" icon={{ name: '20-plus', color: 'blue' }} />
    </Grid>
  );
};

const OptionText = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  flex: 1;
`;
