import { useState } from 'react';
import styled from 'styled-components';

import { Flex, Text, TextProps } from 'ui';
import { Tooltip } from './Tooltip';

type ReferenceNumberProps = TextProps & {
  reference?: string;
};

const maxLengthReference = 10;

export const ReferenceNumber = ({
  reference = '',
  ...rest
}: ReferenceNumberProps) => {
  const trimmedReference =
    reference && `${reference.substring(0, maxLengthReference)}...`;

  return reference?.length > maxLengthReference ? (
    <Tooltip content={reference}>
      <Text {...rest}>{trimmedReference}</Text>
    </Tooltip>
  ) : (
    <Text {...rest}>{reference}</Text>
  );
};

export const TrimmedNumber = ({
  reference = '',
  ...rest
}: ReferenceNumberProps) => {
  const [hover, setHover] = useState(false);
  const trimmedReference = `${reference.substring(0, maxLengthReference)}...`;

  return (
    <TrimmedNumberContainer
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...rest}
    >
      <Text>{trimmedReference}</Text>
      {hover && (
        <TooltipText
          py="s10"
          px="s20"
          mb="s05"
          color="white"
          backgroundColor="gray100"
        >
          {reference}
        </TooltipText>
      )}
    </TrimmedNumberContainer>
  );
};

const TrimmedNumberContainer = styled(Flex)`
  position: relative;
  cursor: default;
  display: inline-flex;
`;

const TooltipText = styled(Text)`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  letter-spacing: 0.5px;
`;
