import { gql, useMutation } from '@apollo/client';

export function useMutationLogout() {
  const data = useMutation(LOGOUT);
  return data;
}

export const LOGOUT = gql`
  mutation logoutUser {
    logoutUser
  }
`;
