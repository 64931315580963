//TODO:  implement useGetRole hook when backend done

import { ROLES } from 'constants/roles';
import { useMe } from './useMe';

export const useGetRole = (): ROLES => {
  const data = useMe();
  const role = data?.roles[0];

  switch (role) {
    case 'ROLE_SUPER_ADMIN': {
      return 'ADMIN';
    }
    case 'ROLE_ADMIN': {
      return 'ADMIN';
    }
    case 'ROLE_SALES': {
      return 'SALES';
    }
    case 'ROLE_USER': {
      return 'CLIENT';
    }
    default:
      return;
  }
};
