import { gql, QueryHookOptions, useQuery } from '@apollo/client';

export const useQueryIntervals = (options?: QueryHookOptions<IntervalsData>) =>
  useQuery<IntervalsData>(INTERVALS, options);

export const INTERVALS = gql`
  query intervals {
    intervals
  }
`;

export type IntervalsData = {
  intervals: string[];
};
