import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export function useMutationResendMessage(
  options?: MutationHookOptions<boolean, ResendMessageVariables>,
) {
  const data = useMutation<boolean, ResendMessageVariables>(
    RESEND_MESSAGE,
    options,
  );
  return data;
}

export const RESEND_MESSAGE = gql`
  mutation resendMessage($id: String!) {
    resendMessage(id: $id) {
      id
    }
  }
`;

export type ResendMessageData = {
  resendMessage: {
    id: string;
  };
};

export type ResendMessageVariables = {
  id: string;
};
