import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export function useMutationAddMessage(
  options?: MutationHookOptions<AddMessageData, AddMessageVariables>,
) {
  const data = useMutation<AddMessageData, AddMessageVariables>(
    ADD_MESSAGE,
    options,
  );
  return data;
}

export const ADD_MESSAGE = gql`
  mutation addMessage(
    $title: String!
    $tag: String!
    $receiver: String!
    $channel: String!
    $content: String!
  ) {
    addMessage(
      title: $title
      tag: $tag
      receiver: $receiver
      channel: $channel
      content: $content
    ) {
      id
      title
      updatedAt
      tag
      content
      channel
      receiver
      attachments {
        id
        name
      }
    }
  }
`;

export type AddMessageVariables = {
  title: string;
  tag: string;
  receiver: string;
  channel: string;
  content: string;
};

export type AddMessageData = {
  addMessage: {
    id: string;
    title: string;
    updatedAt: string;
    tag: string;
    content: string;
    channel: 'inapp' | 'mail';
    receiver: string;
    attachments: {
      id: string;
      name: string;
    }[];
  };
};
