import styled, { css } from 'styled-components';
import { Breadcrumbs, Divider } from 'components';
import { Box, Flex, Icon, Text } from 'ui';
import { paths } from 'routes';
import {
  useMutationAddToCart,
  useMutationCartCreateMonitoring,
  useMutationCartItemCreateMonitoring,
  useMutationCartItemRemoveMonitoring,
  useMutationCartOrderAll,
  useMutationCartRemoveMonitoring,
  useMutationRemoveFromCart,
  useMutationReplaceCartItem,
  useMutationReplaceCartItems,
} from 'graphql/mutation';
import {
  GET_CART,
  REPLACEMENT_AVAILABLE,
  REPLACEMENT_AVAILABLE_CHOICE,
  REPLACEMENT_AVAILABLE_CHOICE_WITH_SET,
  REPLACEMENT_AVAILABLE_SET,
  useQueryCart,
  useQueryReplacementAvailable,
  useQueryReplacementAvailableChoice,
  useQueryReplacementAvailableChoiceWithSet,
  useQueryReplacementAvailableSet,
  useQuerySearchCommodities,
} from 'graphql/query';
import { useState } from 'react';
import { CartItem } from 'graphql/types/CartItem';
import { SingleValue } from 'react-select';
import { notify } from 'utils';
import { Commodity } from 'graphql/types/Commodity';
import { AddItem, ReadyToOrder } from './components';
import {
  ReplacamentBasic,
  ReplacamentChoiceWithSet,
  ReplacamentChoice,
  ReplacamentSet,
} from './components/ReplacamentAvailable/ReplacamentTypes';
import { Wishlist } from './components/Wishlist';
import { ItemNotExist } from './components/ReplacamentAvailable/ReplacamentTypes/ItemNotExist';
import { OrderStatusModal } from '../UploadOrder/components/OrderStatusModal/OrderStatusModal';
import { ReplaceCartItem } from './types';

const refetchQueries = [
  { query: GET_CART, variables: { section: 'ReadyToOrder' } },
  {
    query: REPLACEMENT_AVAILABLE,
    variables: { section: 'ReplacementAvailable' },
  },
  {
    query: REPLACEMENT_AVAILABLE_CHOICE,
    variables: { section: 'ReplacementAvailableChoice' },
  },
  {
    query: REPLACEMENT_AVAILABLE_CHOICE_WITH_SET,
    variables: { section: 'ReplacementAvailableChoiceWithSet' },
  },
  {
    query: REPLACEMENT_AVAILABLE_SET,
    variables: { section: 'ReplacementAvailableSet' },
  },
];

const getWishlistData = (items: CartItem[]) =>
  items.filter((item) => {
    return !item.commodity.replacements.length && item.wishlist;
  });

const getReadyToOrderData = (items: CartItem[]) =>
  items.filter((item) => {
    return !item.commodity.replacements.length && !item.wishlist;
  });

export function Validation() {
  const [wishlistData, setWishlistData] = useState<CartItem[]>([]);
  const [readyToOrderData, setReadyToOrderData] = useState<CartItem[]>([]);
  const [deletedItemsIds, setDeletedItemsIds] = useState<number[]>([]);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);

  const { data } = useQueryCart({
    variables: { section: 'ReadyToOrder' },
    onCompleted: (response) => {
      setWishlistData(getWishlistData(response?.cart.items ?? []));
      setReadyToOrderData(getReadyToOrderData(response?.cart.items ?? []));
    },
    fetchPolicy: 'cache-and-network',
  });

  const [addToCart] = useMutationAddToCart({ refetchQueries });
  const [cartOrderAll] = useMutationCartOrderAll({ refetchQueries });
  const [removeFromCart] = useMutationRemoveFromCart({ refetchQueries });
  const [replaceCartItem] = useMutationReplaceCartItem({ refetchQueries });
  const [replaceCartItems] = useMutationReplaceCartItems({ refetchQueries });
  const [searchCommodities] = useQuerySearchCommodities();

  const [cartItemCreateMonitoring] = useMutationCartItemCreateMonitoring({
    refetchQueries,
  });
  const [cartItemRemoveMonitoring] = useMutationCartItemRemoveMonitoring({
    refetchQueries,
  });
  const [cartCreateMonitoring] = useMutationCartCreateMonitoring({
    refetchQueries,
  });
  const [cartRemoveMonitoring] = useMutationCartRemoveMonitoring({
    refetchQueries,
  });

  const { data: replacementAvailable } = useQueryReplacementAvailable({
    variables: { section: 'ReplacementAvailable' },
    fetchPolicy: 'cache-and-network',
  });

  const replacementAvailableData =
    replacementAvailable?.replacementAvailable ?? [];

  const { data: replacementAvailableSet } = useQueryReplacementAvailableSet({
    variables: { section: 'ReplacementAvailableSet' },
    fetchPolicy: 'cache-and-network',
  });
  const replacementAvailableSetData =
    replacementAvailableSet?.replacementAvailableSet ?? [];

  const { data: replacementAvailableChoiceWithSet } =
    useQueryReplacementAvailableChoiceWithSet({
      variables: { section: 'ReplacementAvailableChoiceWithSet' },
      fetchPolicy: 'cache-and-network',
    });
  const replacementAvailableChoiceWithSetData =
    replacementAvailableChoiceWithSet?.replacementAvailableChoiceWithSet ?? [];

  const { data: replacementAvailableChoice } =
    useQueryReplacementAvailableChoice({
      variables: { section: 'ReplacementAvailableChoice' },
      fetchPolicy: 'cache-and-network',
    });
  const replacementAvailableChoiceData =
    replacementAvailableChoice?.replacementAvailableChoice ?? [];

  const handleReplaceAllCartItems = (
    items: ReplaceCartItem[],
    section: string,
  ) => {
    if (items)
      replaceCartItems({ variables: { replacements: items, section } });
  };

  const handleOrderAll = (reference: string) => {
    cartOrderAll({
      variables: { reference },
      onCompleted: () => {
        setIsStatusModalOpen(true);
      },
      onError: () => {
        notify('Something went wrong. Please contact the administrator', {
          type: 'error',
        });
      },
    });
  };

  const handleSubmit = (
    autocompleteData: SingleValue<Commodity> | undefined,
  ) => {
    if (autocompleteData?.id)
      addToCart({ variables: { id: autocompleteData?.id, quantity: 1 } });
  };

  const handleMonitorItem = (
    id: number,
    currentValue: boolean,
    section: string,
  ) => {
    if (currentValue) cartItemRemoveMonitoring({ variables: { id, section } });
    else cartItemCreateMonitoring({ variables: { id, section } });
  };

  const handleMonitorAll = (
    currentValue: boolean,
    _ids: number[],
    section: string,
  ) => {
    if (currentValue) cartRemoveMonitoring({ variables: { section } });
    else cartCreateMonitoring({ variables: { section } });
  };

  const handleLoad = async (value: string) => {
    const options = await searchCommodities({
      variables: { phrase: value },
    });

    return options.data?.searchCommodities ?? [];
  };

  const removeIdFromList = (id: number) => {
    setDeletedItemsIds((prev) => prev.filter((i) => i !== id));
  };

  const handleDelete = (id: number, section: string) => {
    setDeletedItemsIds((prev) => [...prev, id]);
    removeFromCart({
      variables: { id, section },
      // onCompleted: () => {
      //   removeIdFromList(id);
      // },
      onError: () => {
        removeIdFromList(id);
      },
    });
  };

  const handleReplaceCartItem = (
    cartItemId: number,
    commodities: number[],
    quantity: number,
    section: string,
  ) => {
    replaceCartItem({
      variables: { cartItemId, commodities, quantity, section },
      refetchQueries,
    });
  };

  return (
    <div>
      <Breadcrumbs
        px="s30"
        items={[
          { label: 'Home', to: paths.base },
          { label: 'Your orders', to: paths.main.yourOrders },
          { label: 'Validation', to: paths.main.validation },
        ]}
      />
      <AddItem onLoad={handleLoad} onSubmit={handleSubmit} />
      <Divider />

      <Section>
        <Title>Ready to order</Title>
        {readyToOrderData.length ? (
          <ReadyToOrder
            onMonitorItem={handleMonitorItem}
            onMonitorAll={handleMonitorAll}
            onDelete={handleDelete}
            onOrderAll={handleOrderAll}
            deletedItemsIds={deletedItemsIds}
            data={readyToOrderData}
            monitoredAll={!!data?.cart.monitored}
            ready={
              !replacementAvailableData.length &&
              !replacementAvailableSetData.length &&
              !replacementAvailableChoiceData.length &&
              !replacementAvailableChoiceWithSetData.length
            }
            reference={data?.cart.reference}
          />
        ) : (
          <EmptyState />
        )}
      </Section>
      {!!replacementAvailableData.length && (
        <>
          <Divider />
          <Section>
            <Title>Replacement available</Title>
            <ReplacamentBasic
              onMonitorItem={handleMonitorItem}
              onMonitorAll={handleMonitorAll}
              data={replacementAvailableData}
              onDelete={handleDelete}
              deletedItemsIds={deletedItemsIds}
              onReplaceCartItem={handleReplaceCartItem}
              onReplaceAllCartItems={handleReplaceAllCartItems}
            />
          </Section>
        </>
      )}
      {!!replacementAvailableSetData.length && (
        <>
          <Divider />
          <Section>
            <Title>Replacement available SET</Title>
            <ReplacamentSet
              onMonitorItem={handleMonitorItem}
              onMonitorAll={handleMonitorAll}
              onDelete={handleDelete}
              deletedItemsIds={deletedItemsIds}
              data={replacementAvailableSetData}
              onReplaceCartItem={handleReplaceCartItem}
              onReplaceAllCartItems={handleReplaceAllCartItems}
            />
          </Section>
        </>
      )}
      {!!replacementAvailableChoiceData.length && (
        <>
          <Divider />
          <Section>
            <Title>Replacement available choice</Title>
            <ReplacamentChoice
              onMonitorItem={handleMonitorItem}
              //   onMonitorAll={handleMonitorAll}
              onDelete={handleDelete}
              deletedItemsIds={deletedItemsIds}
              data={replacementAvailableChoiceData}
              onReplaceCartItem={handleReplaceCartItem}
            />
          </Section>
        </>
      )}
      {!!replacementAvailableChoiceWithSetData.length && (
        <>
          <Divider />
          <Section>
            <Title>Replacement available choice with set</Title>
            <ReplacamentChoiceWithSet
              onMonitorItem={handleMonitorItem}
              //   onMonitorAll={handleMonitorAll}
              onDelete={handleDelete}
              deletedItemsIds={deletedItemsIds}
              data={replacementAvailableChoiceWithSetData}
              onReplaceCartItem={handleReplaceCartItem}
            />
          </Section>
        </>
      )}
      {!!data?.cart.missingItems.length && (
        <>
          <Divider />
          <Section>
            <Title>Wrong item code</Title>
            <ItemNotExist data={data?.cart.missingItems} />
          </Section>
        </>
      )}
      {!!wishlistData?.length && (
        <>
          <Divider />
          <Section>
            <Title>Saved to wishlist</Title>
            <Wishlist data={wishlistData} />
          </Section>
        </>
      )}
      <OrderStatusModal
        open={isStatusModalOpen}
        handleClose={() => setIsStatusModalOpen(false)}
      />
    </div>
  );
}

export const Title = styled(Text).attrs({ as: 'p' })`
  ${({ theme: { colors, lineHeights, space } }) => css`
    color: ${colors.gray80};
    line-height: ${lineHeights.lh300};
    margin-bottom: ${space.s35};
  `};
`;

export const Section = styled(Box)`
  ${({ theme: { space } }) => css`
    margin: ${space.s20} ${space.s60};
    margin-bottom: ${space.s40};
  `};
`;

export const EmptyState = () => (
  <Flex flex="1" alignItems="center" flexDirection="column">
    <Icon color="gray10" name="40-listUnordered" />
    <Text mt="s20" color="gray80" lineHeight="lh150">
      Ready to order list is empty
    </Text>
  </Flex>
);
