import {
  Alert,
  Button,
  Label,
  PasswordRules,
  TextInput,
  usePasswordRules,
} from 'components';
import {
  useMutationLoginByUsername,
  useMutationResetPassword,
} from 'graphql/mutation';
import { useQueryValidateToken } from 'graphql/query';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Flex, Text } from 'ui';
import { Container } from './components/Container';
import { ExpiredLink } from './components/ExpiredLink';

export const SetupNewPassword = () => {
  const { token } = useParams();
  const validateToken = useQueryValidateToken({
    variables: { token },
  });
  const navigate = useNavigate();

  const {
    disabled,
    differencePasswords,
    confirmPassword,
    password,
    setConfirmPassword,
    setPassword,
  } = usePasswordRules();

  const [login] = useMutationLoginByUsername({
    refetchQueries: ['user'],
    onCompleted: () => {
      navigate('/');
    },
    onError: () => {
      navigate('/');
    },
  });

  const [resetPassword, { loading, error }] = useMutationResetPassword({
    onCompleted: ({ resetPassword: { userName } }) =>
      login({ variables: { password, userName } }),
  });

  const handleSave = () => {
    if (token && password) {
      resetPassword({ variables: { password, token } });
    }
  };

  const handleEnter = (e?: React.KeyboardEvent<HTMLInputElement>) => {
    if (!disabled) {
      if (e?.key === 'Enter') {
        e?.preventDefault();
        handleSave();
      }
    }
  };

  if (validateToken?.loading) {
    return null;
  }

  if (validateToken?.data?.validateToken === false) {
    return <ExpiredLink />;
  }

  return (
    <Container>
      <Text color="gray80" fontWeight="bold" lineHeight="lh300" mb="s20" as="p">
        SETUP NEW PASSWORD
      </Text>
      <Label>Password</Label>
      <TextInput
        autoFocus
        placeholder="Insert your password"
        icon={{ name: '20-lock', color: 'gray05' }}
        type="password"
        value={password}
        onChange={(e) => setPassword(e?.currentTarget?.value || '')}
      />
      <Box mt="s20">
        <Label>Confirm your password</Label>
        <TextInput
          placeholder="Insert your password"
          icon={{ name: '20-lock', color: 'gray05' }}
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e?.currentTarget?.value || '')}
          onKeyDown={handleEnter}
        />
      </Box>
      {error && <Alert mt="s25">Error occurred</Alert>}
      <Flex
        justifyContent="space-between"
        alignItems="flex-end"
        mt="s35"
        mb="s10"
      >
        <PasswordRules password={differencePasswords} />
        <Button variant="primary" disabled={disabled} onClick={handleSave}>
          {loading ? 'Saving...' : 'Save'}
        </Button>
      </Flex>
    </Container>
  );
};
