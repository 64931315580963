import { useQuerySalesBusinessSaless } from 'graphql/query';

export const useSalesCompany = () => {
  const businessPartners = useQuerySalesBusinessSaless();
  const company = businessPartners.data?.salesBusinessPartners
    ?.map((item) => ({ name: item?.name, id: item?.id }))
    ?.filter(({ name }) => name);

  return company || [];
};
