import styled, { css } from 'styled-components';
import { Text } from 'ui';

type StatusProps = 'success' | 'warning' | 'error';

export const ReplacementStatus = styled(Text)<{
  status: StatusProps;
  fullWidth?: boolean;
  uppercase?: boolean;
}>`
  border-radius: 24px;
  text-align: center;
  display: block;
  ${({
    theme: { space, fontSizes, colors, fontWeights },
    status,
    fullWidth,
    uppercase,
  }) => css`
    height: ${space.s30};
    padding: 0 ${space.s25};
    width: ${fullWidth ? '100%' : 'fit-content'};
    text-transform: ${uppercase && 'uppercase'};
    font-weight: ${fontWeights.medium};
    font-size: ${fontSizes.fs100};

    ${status === 'success' &&
    css`
      color: ${colors.success100};
      background: ${colors.success60};
    `}
    ${status === 'warning' &&
    css`
      color: ${colors.warning50};
      background: ${colors.warning05};
    `}
      ${status === 'error' &&
    css`
      color: ${colors.alert50};
      background: ${colors.alert20};
    `};
  `};
`;
