import {
  createContext,
  ReactChild,
  RefObject,
  useRef,
  useContext,
} from 'react';

type CarouselContextProviderProps = { children: ReactChild | ReactChild[] };

const CarouselContext = createContext<RefObject<HTMLDivElement> | null>(null);

export const CarouselContextProvider = ({
  children,
}: CarouselContextProviderProps) => {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <CarouselContext.Provider value={ref}>{children}</CarouselContext.Provider>
  );
};

export const useCarouselContext = () => {
  const context = useContext(CarouselContext);
  if (context === undefined) {
    throw new Error('useCount must be used within a CountProvider');
  }
  return context;
};
