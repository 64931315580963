import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export const useMutationCartCreateMonitoring = (
  options?: MutationHookOptions<
    CartCreateMonitoringData,
    CartCreateMonitoringVariables
  >,
) =>
  useMutation<CartCreateMonitoringData, CartCreateMonitoringVariables>(
    CART_CREATE_MONITORING,
    options,
  );

export const CART_CREATE_MONITORING = gql`
  mutation cartCreateMonitoring($section: String) {
    cartCreateMonitoring(section: $section) {
      id
    }
  }
`;

export type CartCreateMonitoringVariables = {
  section: string;
};

export type CartCreateMonitoringData = {
  cartCreateMonitoring: {
    id: string;
  };
};
