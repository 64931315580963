import { openWindowWithPost } from 'utils';

interface Props {
  organization_id: number;
  files_ids?: string;
  directories_ids?: string;
}
export const downloadZip = (props: Props) => {
  const endpoint = `${process.env.REACT_APP_PUBLIC_HOST}/juki/documents/zip_download`;

  openWindowWithPost(endpoint, props);
};
