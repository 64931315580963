import styled, { css } from 'styled-components';
import {
  Autocomplete,
  Breadcrumbs,
  Button,
  Divider,
  Heading,
} from 'components';
import { Box, Flex, Grid, Text } from 'ui';
import { SingleValue } from 'react-select';
import { CartsAndDraftsList } from 'components/CartAndDraftsList/CartAndDraftsList';
import { OrderStatusModal } from 'pages/UploadOrder/components/OrderStatusModal/OrderStatusModal';
import { Wishlist } from 'components/Wishlist/Wishlist';
import {
  useMutationAddToCart,
  useMutationCartMonitorItem,
  useMutationCartOrderAll,
  useMutationRemoveFromCart,
  useMutationUpdateCart,
  useMutationUpdateCartReference,
  useMutationClearWishlist,
  useMutationCartItemCreateMonitoring,
  useMutationCartItemRemoveMonitoring,
  useMutationCartCreateMonitoring,
  useMutationCartRemoveMonitoring,
} from 'graphql/mutation';
import {
  GET_CART,
  useQueryCart,
  useQuerySearchCommodities,
} from 'graphql/query';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { paths } from 'routes';
import { CartItem } from 'graphql/types/CartItem';
import { Commodity } from 'graphql/types/Commodity';

import { useCreateOrdersPermission } from './utils/useCreateOrdersPremission';

const refetchQueries = [
  { query: GET_CART, variables: { wishlist: false, section: 'Cart' } },
  { query: GET_CART, variables: { wishlist: true, section: 'Cart' } },
];

const checkIsValidationNeeded = (items: CartItem[]) =>
  items.some((item) => item.commodity.replacements.length > 0);

export const CartAndDrafts = () => {
  useCreateOrdersPermission();
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [deletedItemsIds, setDeletedItemsIds] = useState<number[]>([]);
  const navigate = useNavigate();
  const [addToCart] = useMutationAddToCart({ refetchQueries });
  const [updateCart] = useMutationUpdateCart({ refetchQueries });
  const [cartItemCreateMonitoring] = useMutationCartItemCreateMonitoring({
    refetchQueries,
  });
  const [cartItemRemoveMonitoring] = useMutationCartItemRemoveMonitoring({
    refetchQueries,
  });
  const [cartOrderAll] = useMutationCartOrderAll({ refetchQueries });
  const [cartCreateMonitoring] = useMutationCartCreateMonitoring({
    refetchQueries,
    variables: { section: 'Cart' },
  });
  const [cartRemoveMonitoring] = useMutationCartRemoveMonitoring({
    refetchQueries,
    variables: { section: 'Cart' },
  });
  const [updateCartReference] = useMutationUpdateCartReference({
    refetchQueries,
  });
  const [clearWishlist] = useMutationClearWishlist({
    refetchQueries,
  });

  const [removeFromCart, { loading: removeFromCartLoading }] =
    useMutationRemoveFromCart({ refetchQueries });

  const { data, previousData } = useQueryCart({
    variables: { wishlist: false, section: 'Cart' },
  });

  const { data: wishlistData } = useQueryCart({
    variables: { wishlist: true, section: 'Cart' },
  });

  const [searchCommodities] = useQuerySearchCommodities();

  const handleDecrement = (id: number, quantity: number) => {
    updateCart({ variables: { id, quantity: quantity - 1 } });
  };

  const handleIncrement = (id: number, quantity: number) => {
    updateCart({ variables: { id, quantity: quantity + 1 } });
  };

  const removeIdFromList = (id: number) => {
    setDeletedItemsIds((prev) => prev.filter((i) => i !== id));
  };

  const handleDelete = (id: number) => {
    setDeletedItemsIds((prev) => [...prev, id]);
    removeFromCart({
      variables: { id, section: 'Cart' },
      // onCompleted: () => {
      //   removeIdFromList(id);
      // },
      onError: () => {
        removeIdFromList(id);
      },
    });
  };

  const handleOrderAll = (reference?: string) => {
    if (
      checkIsValidationNeeded([
        ...(data?.cart.items ?? []),
        ...(wishlistData?.cart.items ?? []),
      ])
    ) {
      navigate(paths.main.validation);
      return;
    }

    cartOrderAll({
      variables: { reference: reference || '' },
      onCompleted: () => setIsStatusModalOpen(true),
    });
  };

  const handleSubmit = (
    autocompleteData: SingleValue<Commodity> | undefined,
  ) => {
    if (autocompleteData?.id)
      addToCart({ variables: { id: autocompleteData?.id, quantity: 1 } });
  };

  const handleMonitorItem = (id: number, currentValue: boolean) => {
    if (currentValue)
      cartItemRemoveMonitoring({ variables: { id, section: 'Cart' } });
    else cartItemCreateMonitoring({ variables: { id, section: 'Cart' } });
  };

  const handleMonitorAll = (currentValue: boolean) => {
    if (currentValue) cartRemoveMonitoring();
    else cartCreateMonitoring();
  };

  const handleUpdateCartReference = (reference: string) => {
    if (reference) updateCartReference({ variables: { reference } });
  };

  const handleLoad = async (value: string) => {
    const options = await searchCommodities({
      variables: { phrase: value },
    });

    return options.data?.searchCommodities ?? [];
  };

  const transformedWithlistData = wishlistData?.cart.items ?? [];

  const handleOrderAllRegisteredItems = () => {
    transformedWithlistData.forEach((item) => {
      if (item.commodity.stockStatus === 'IN_STOCK') {
        updateCart({
          variables: { id: item.id, quantity: item.quantity, wishlist: true },
        });
      }
    });
  };

  const handleOrderRegisteredItem = (id: number, quantity: number) => {
    updateCart({ variables: { id, quantity, wishlist: true } });
  };

  const recentlyAddedIds = useMemo(() => {
    if (!previousData) return [];

    const currentDataIds = data?.cart.items.map((item) => item.id);
    const previousDataIds = previousData?.cart.items.map((item) => item.id);

    return (
      currentDataIds?.filter(
        (currentId) => !previousDataIds.includes(currentId),
      ) ?? []
    );
  }, [data, previousData]);

  const handleClearCart = () => {
    clearWishlist();
  };

  return (
    <Box>
      <Flex px="s30">
        <Breadcrumbs
          items={[
            { label: 'Home', to: '/' },
            { label: 'Cart & drafts', to: '/cartAndDrafts' },
          ]}
        />
      </Flex>
      <CartAndDraftsSearch px="s30">
        <Heading>Cart & drafts</Heading>
        <Flex pb="s30" px="s30" alignItems="center" justifyContent="flex-start">
          <Text mr="s20">Add item by item code</Text>
          <SearchBoxStyled>
            <Autocomplete<Commodity>
              icon={{
                name: '18-search',
                color: 'blue',
                space: 's10',
              }}
              styles={{
                option: (defaultStyles, state) => ({
                  ...defaultStyles,
                  padding: '4px 8px',
                  '& svg': state.isFocused
                    ? { visibility: 'visible' }
                    : { visibility: 'hidden' },
                }),
              }}
              noOptionsMessage={() => null}
              formatOptionLabel={CartAndDraftsAutocompleteOption}
              placeholder="Search for products to add by item code"
              getOptionLabel={(option) => option?.itemCode ?? ''}
              getOptionValue={(option) => option?.itemCode ?? ''}
              loadOptions={handleLoad}
              onSubmit={handleSubmit}
            />
          </SearchBoxStyled>
        </Flex>
      </CartAndDraftsSearch>
      {!!data?.cart.items.length && (
        <Box px="s60" py="s40">
          <CartsAndDraftsList
            loading={removeFromCartLoading}
            monitoredAll={data?.cart?.monitored ?? false}
            onDecrement={handleDecrement}
            onDelete={handleDelete}
            onIncrement={handleIncrement}
            onMonitorAll={handleMonitorAll}
            onMonitorItem={handleMonitorItem}
            deletedItemsIds={deletedItemsIds}
            onOrderAll={handleOrderAll}
            onUpdateCartReference={handleUpdateCartReference}
            recentlyAddedIds={recentlyAddedIds}
            tableData={data?.cart.items}
            reference={data?.cart.reference}
          />
        </Box>
      )}
      {!!transformedWithlistData.length && (
        <>
          <Divider />
          <Box px="s60" pb="s40">
            <Heading>Wishlist</Heading>
            <Wishlist
              loading={removeFromCartLoading}
              monitoredAll={data?.cart?.monitored ?? false}
              onDelete={handleDelete}
              onMonitorItem={handleMonitorItem}
              deletedItemsIds={deletedItemsIds}
              onOrderAll={handleOrderAll}
              onOrderAllRegisteredItems={handleOrderAllRegisteredItems}
              onOrderRegisteredItem={handleOrderRegisteredItem}
              onClearCart={handleClearCart}
              recentlyAddedIds={recentlyAddedIds}
              tableData={transformedWithlistData}
            />
          </Box>
        </>
      )}
      <OrderStatusModal
        open={isStatusModalOpen}
        handleClose={() => setIsStatusModalOpen(false)}
      />
    </Box>
  );
};

const CartAndDraftsAutocompleteOption = ({
  itemCode,
  description,
}: Commodity) => {
  return (
    <Grid gridTemplateColumns={'1fr 3fr auto'}>
      <OptionText mr="s20">{itemCode}</OptionText>
      <OptionText>{description}</OptionText>
      <Button variant="icon" icon={{ name: '20-plus', color: 'blue' }} />
    </Grid>
  );
};

const CartAndDraftsSearch = styled(Flex)`
  ${({ theme: { colors, borders } }) => css`
    background: ${colors.gray0};
    border-top: ${borders.thinGray10};
    border-bottom: ${borders.thinGray10};
    flex-direction: column;
  `}
`;

const SearchBoxStyled = styled(Flex)`
  width: 542px;
`;

const OptionText = styled(Text)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  flex: 1;
`;
