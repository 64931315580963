import { Button } from 'components/Button/Button';
import styled, { css } from 'styled-components';
import { Flex, Text } from 'ui';

export type NumericInputProps = {
  value: number;
  onIncrement: (value: number) => void;
  onDecrement: (value: number) => void;
  incrementDisabled?: boolean;
  decrementDisabled?: boolean;
};

export const NumericInput = ({
  value,
  onDecrement,
  onIncrement,
  decrementDisabled,
  incrementDisabled,
}: NumericInputProps) => (
  <Flex alignItems="center">
    <NumericButton
      disabled={decrementDisabled}
      variant="icon"
      icon={{ color: 'blue', name: '12-minus' }}
      onClick={() => onDecrement(value)}
      p="s05"
    />

    <Text style={{ width: '36px', textAlign: 'center' }} lineHeight="lh300">
      {value}
    </Text>
    <NumericButton
      disabled={incrementDisabled}
      variant="icon"
      icon={{ color: 'blue', name: '12-plus' }}
      onClick={() => onIncrement(value)}
      p="s05"
    />
  </Flex>
);

const NumericButton = styled(Button)<{ onClick: (value: number) => void }>`
  cursor: pointer;

  ${({ theme: { colors, radii } }) => css`
    background: ${colors.lightBlue};
    border-radius: ${radii.rounded};
  `};
`;
