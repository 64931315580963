import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export const useMutationAddFile = (
  options?: MutationHookOptions<any, AddFileVariables>,
) => useMutation<any, AddFileVariables>(ADD_FILE, options);

export const ADD_FILE = gql`
  mutation addFile($file: Upload!, $name: String!, $directoryId: String!) {
    addFile(file: $file, name: $name, directoryId: $directoryId) {
      id
    }
  }
`;

export type AddFileVariables = {
  file: File;
  name: string;
  directoryId: string;
};

export type AddFileData = {
  addDirectory: {
    id: string;
  };
};
