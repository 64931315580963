import { Flex, Icon, Text } from 'ui';
import {
  Cell,
  Row,
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  Button,
  ToggleSwitch,
  ReplacementStatus,
} from 'components';
import { CartItem } from 'graphql/types/CartItem';
import { tableCellNames } from './constants/tableCellNames';

type ReplacamentChoiceProps = {
  data: CartItem[];
  onDelete: (id: number, section: string) => void;
  onReplaceCartItem: OnReplaceCartItem;
  deletedItemsIds: number[];
  onMonitorItem: (id: number, currentValue: boolean, section: string) => void;
};

type OnReplaceCartItem = (
  cartItemId: number,
  commodities: number[],
  quantity: number,
  section: string,
) => void;

export const ReplacamentChoice = ({
  data,
  onDelete,
  onReplaceCartItem,
  onMonitorItem,
  deletedItemsIds,
}: ReplacamentChoiceProps) => {
  return (
    <div>
      <Table>
        <Head />
        <TableBody>
          {data?.map((item) => (
            <RowSet
              onMonitorItem={onMonitorItem}
              onReplaceCartItem={onReplaceCartItem}
              key={`ReplacamentChoice-${item.id}`}
              onDelete={onDelete}
              deletedItemsIds={deletedItemsIds}
              item={item}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

type RowSetProps = {
  item: CartItem;
  onDelete: (id: number, section: string) => void;
  onReplaceCartItem: OnReplaceCartItem;
  onMonitorItem: (id: number, currentValue: boolean, section: string) => void;
  deletedItemsIds: number[];
};

const RowSet = ({
  item,
  onDelete,
  onReplaceCartItem,
  onMonitorItem,
  deletedItemsIds,
}: RowSetProps) => {
  return (
    <Row disabledBorder isDeleted={deletedItemsIds.includes(item.id)}>
      <td colSpan={9}>
        <Table>
          <TableBody>
            <RowBasic
              onMonitorItem={onMonitorItem}
              onReplaceCartItem={onReplaceCartItem}
              onDelete={onDelete}
              item={item}
            />
            {item.commodity.replacements?.map((replacement, index) => {
              const nextChild = item.commodity.replacements?.[index + 1];

              const isSetNextItem =
                nextChild?.setNumber === replacement?.setNumber;

              return (
                <>
                  {replacement.items.map((replacementItem, itemIndex) =>
                    itemIndex === 0 && index === 0 ? null : (
                      <Row
                        key={`${replacement.id}-${replacementItem.id}`}
                        disabledBorder
                      >
                        <Cell />
                        <Cell />
                        <Cell divider />
                        <Cell border={!isSetNextItem} />
                        <Cell border={!isSetNextItem}>
                          {replacementItem.replacement.remarks}
                        </Cell>
                        <Cell border={!isSetNextItem}>
                          {replacementItem.commodity.itemCode}
                        </Cell>
                        <Cell border={!isSetNextItem}>
                          {replacementItem.quantity}
                        </Cell>
                        <Cell border={!isSetNextItem} divider width="60px">
                          <Button
                            onClick={() =>
                              onReplaceCartItem(
                                item.id,
                                [replacementItem.commodity.id],
                                replacementItem.quantity,
                                'ReplacementAvailableChoice',
                              )
                            }
                            variant="outline-primary"
                            size="small"
                            icon={{
                              name: '16-check',
                              color: 'blue',
                            }}
                          />
                        </Cell>
                      </Row>
                    ),
                  )}
                </>
              );
            })}
          </TableBody>
        </Table>
      </td>
    </Row>
  );
};

type RowBasicProps = {
  item: CartItem;
  onDelete: (id: number, section: string) => void;
  onReplaceCartItem: OnReplaceCartItem;
  onMonitorItem: (id: number, currentValue: boolean, section: string) => void;
};

const RowBasic = ({
  item,
  onDelete,
  onReplaceCartItem,
  onMonitorItem,
}: RowBasicProps) => (
  <Row>
    <Cell>{item.commodity.itemCode}</Cell>
    <Cell>{item.commodity.description}</Cell>
    <Cell divider width="70px">
      <Flex justifyContent="space-between">
        <Text>{item.quantity}</Text>
        <Icon mr="s10" color="gray80" name="20-arrow-right" />
      </Flex>
    </Cell>
    <Cell width="120px">
      <ReplacementStatus status="success" uppercase>
        Choice
      </ReplacementStatus>
    </Cell>
    <Cell>{item.commodity.replacements[0]?.items[0].replacement.remarks}</Cell>
    <Cell>{item.commodity.replacements[0]?.items[0].commodity.itemCode}</Cell>
    <Cell width="70px">
      {item.commodity.replacements[0]?.items[0].quantity}
    </Cell>
    <Cell divider width="60px">
      <Button
        onClick={() =>
          onReplaceCartItem(
            item.id,
            [item.commodity.replacements[0]?.items[0].commodity.id],
            item.commodity.replacements[0]?.items[0].quantity,
            'ReplacementAvailableChoice',
          )
        }
        variant="outline-primary"
        size="small"
        icon={{
          name: '16-check',
          color: 'blue',
        }}
      />
    </Cell>
    <Cell width="80px">
      <Flex justifyContent="space-between">
        <ToggleSwitch
          onChange={() =>
            onMonitorItem(item.id, item.monitored, 'ReplacementAvailableChoice')
          }
          defaultChecked={item.monitored}
        />
        <Button
          mr="s10"
          variant="icon"
          onClick={() => onDelete(item.id, 'ReplacementAvailableChoice')}
          icon={{ name: '16-delete', color: 'gray05' }}
        />
      </Flex>
    </Cell>
  </Row>
);

const Head = () => (
  <TableHead>
    <TableHeadRow>
      <TableHeadCell>{tableCellNames.itemCode}</TableHeadCell>
      <TableHeadCell>{tableCellNames.description}</TableHeadCell>
      <TableHeadCell divider width="70px">
        {tableCellNames.qty}
      </TableHeadCell>
      <TableHeadCell width="120px">
        {tableCellNames.replacementStatus}
      </TableHeadCell>
      <TableHeadCell>{tableCellNames.remarks}</TableHeadCell>
      <TableHeadCell>{tableCellNames.replacementBy}</TableHeadCell>
      <TableHeadCell width="70px">{tableCellNames.qty}</TableHeadCell>
      <TableHeadCell width="60px" divider>
        {tableCellNames.acceptReplace}
      </TableHeadCell>
      <TableHeadCell width="80px">{tableCellNames.monitorStatus}</TableHeadCell>
    </TableHeadRow>
  </TableHead>
);
