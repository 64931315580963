import { SelectInput, TextInput, ToggleSwitch } from 'components';
import { useQueryBusinessPartners, useQueryCountriesList } from 'graphql/query';
import { Flex, Grid, Text } from 'ui';
import { useDebouncedCallback } from 'use-debounce';
import { getCountryWithCode, sortCountry } from 'utils';
import { Filters } from './types';

interface Props {
  filters: Filters;
  handleFilters: (value: Partial<Filters>) => void;
}
export const UsersFilters = ({ handleFilters, filters }: Props) => {
  const businessPartners = useQueryBusinessPartners();
  const countriesData = useQueryCountriesList();

  const countriesList = sortCountry(countriesData.data?.countriesList);

  const company =
    businessPartners.data?.businessPartners
      ?.map((item) => ({ name: item?.name, id: item?.id }))
      ?.filter(({ name }) => !!name) || [];

  const handleSearch = useDebouncedCallback((value?: string) => {
    handleFilters({ search: value || '' });
  }, 500);

  return (
    <>
      <Flex flexDirection="column">
        <Flex mb={'s17'} alignItems="center">
          <Text mr={'s10'} fontWeight={'medium'} color={'gray80'}>
            Connected Customers
          </Text>
        </Flex>
        <TextInput
          icon={{ name: '18-search', color: 'blue' }}
          placeholder="Search users by company, name, code or email"
          type="search"
          defaultValue={filters?.search}
          onChange={(e) => handleSearch(e?.target?.value)}
        />
      </Flex>
      <Grid
        mt="s30"
        gridGap="s30"
        gridTemplateColumns={{ xs: '1fr 1fr 1fr 1fr' }}
      >
        <Flex alignItems="flex-end">
          <Flex alignItems="center">
            <ToggleSwitch
              checked={filters?.onlyAssigned || false}
              onChange={(e) =>
                handleFilters({ onlyAssigned: e.target.checked })
              }
            />
            <Text ml="s15">Show only assigned</Text>
          </Flex>
        </Flex>
        <Flex flexDirection="column">
          <Text lineHeight="lh300" mb="s10">
            Filter by country
          </Text>
          <SelectInput
            value={filters?.country}
            onChange={(option) => handleFilters({ country: option })}
            options={countriesList}
            placeholder="Choose country"
            labelResolver={(option) => getCountryWithCode(option || '')}
            keyResolver={(option) => option}
          />
        </Flex>
        <Flex flexDirection="column">
          <Text lineHeight="lh300" mb="s10">
            Filter by company
          </Text>
          <SelectInput
            value={filters?.company}
            onChange={(option) => handleFilters({ company: option })}
            options={company}
            placeholder="Choose company"
            labelResolver={(option) => option?.name}
            keyResolver={(option) => option?.id}
          />
        </Flex>
      </Grid>
    </>
  );
};
