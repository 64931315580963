import { Flex, Text } from 'ui';
import { UserByIdData } from '../graphql/useQueryUserById';
import { Column, Label, SummaryContainer } from './common';

type Props = UserByIdData['userById'];

export const CustomerSalesInfo = ({ data }: { data?: Props }) => {
  const { email = '-', name = '-' } = data?.salesUser || {};
  const discount = data?.businessPartner?.billingAddresses?.[0]?.discount;
  const ccMailFirst = '-';
  const ccMailSecond = '';
  return (
    <Flex mt={'s20'} flexDirection="column">
      <SummaryContainer p={'s30'}>
        <Flex flex={1}>
          <Column mr={'s45'}>
            <Label>Sales support</Label>
            <Text>{name}</Text>

            <Label>Sales support email</Label>
            <Text>{email}</Text>

            <Label>CC email</Label>
            <Flex flexDirection="column">
              <Text>{ccMailFirst}</Text>
              <Text>{ccMailSecond}</Text>
            </Flex>
          </Column>
        </Flex>
        <Flex flex={1}>
          <Column>
            <Label>Discount</Label>
            <Text>{discount}%</Text>
          </Column>
        </Flex>
      </SummaryContainer>
    </Flex>
  );
};
