import styled, { css } from 'styled-components';

export const Table = styled('table')`
  table-layout: fixed;
  width: 100%;
`;

const activeRowCSS = css`
  background-color: ${({ theme: { colors } }) => colors.lightBlue};
`;

export const Row = styled('tr')<{
  expanded?: boolean;
  isDeleted?: boolean;

  disabledBorder?: boolean;
}>`
  ${({ theme: { space, borders }, expanded, disabledBorder, isDeleted }) => css`
    height: ${space.s60};
    border-bottom: ${!disabledBorder && borders.thinGray10};

    &:hover {
      transition: background-color 0.2s;
      ${activeRowCSS}
    }

    ${expanded && activeRowCSS}

    ${!!isDeleted &&
    css`
      opacity: 0.2;
      cursor: wait;
    `}
  `};
`;

export const Cell = styled('td')<{
  divider?: boolean;
  border?: boolean;
  width?: string;
}>`
  ${({
    theme: { space, borders, lineHeights, fontSizes, colors, fontWeights },
    divider,
    border,
    width,
  }) => css`
    padding: ${space.s05} ${space.s10};
    text-align: left;
    width: ${width};
    line-height: ${lineHeights.lh300};
    font-size: ${fontSizes.fs200};
    vertical-align: middle;
    color: ${colors.black};
    font-weight: ${fontWeights.regular};
    border-bottom: ${border && borders.thinGray10};
    border-right: ${divider && borders.thinGray10};
    /*TODO: compare with project - is letter spacing set? */
  `}
`;

export const TableBody = styled('tbody')``;

export const TableHead = styled('thead')`
  ${({ theme: { colors, space } }) => css`
    background: ${colors.gray0};
    height: ${space.s60};
  `};
`;

export const TableHeadRow = styled('tr')<{ divider?: boolean }>`
  ${({ theme: { borders } }) => css`
    border-bottom: ${borders.thinGray10};
  `}
`;

export const TableHeadCell = styled('th')<{
  divider?: boolean;
  width?: string;
}>`
  ${({ theme: { borders, space }, divider, width }) => css`
    border-right: ${divider && borders.thinGray10};
    padding: ${space.s05} ${space.s10};
    text-align: left;
    width: ${width};
  `}
`;
