import { ChangeEvent, forwardRef, FormEvent, KeyboardEvent } from 'react';
import styled, { css } from 'styled-components';
import { space as styledSystemSpace } from 'styled-system';
import { Flex, MarginProps, ColorsType, Icon, SpaceType } from 'ui';
import { removeEmptyValues } from 'utils/removeEmptyValues';

export type TextInputProps = {
  sizeVariant?: InputSizes;
  type?:
    | 'text'
    | 'password'
    | 'file'
    | 'image'
    | 'color'
    | 'date'
    | 'datetime'
    | 'email'
    | 'month'
    | 'number'
    | 'url'
    | 'week'
    | 'search';
  id?: string;
  form?: string;
  name?: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  value?: string;
  defaultValue?: string;
  min?: number;
  max?: number;
  pattern?: string;
  autoComplete?: string;
  autoFocus?: boolean;
  onClick?: (e?: FormEvent<HTMLInputElement>) => void;
  onChange?: (e?: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e?: FormEvent<HTMLInputElement>) => void;
  onBlur?: (e?: FormEvent<HTMLInputElement>) => void;
  onPaste?: (e?: FormEvent<HTMLInputElement>) => void;
  onKeyDown?: (e?: KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (e?: KeyboardEvent<HTMLInputElement>) => void;
  onSubmit?: (e?: ChangeEvent<HTMLInputElement>) => void;
  icon?: ButtonIcon;
  readOnly?: boolean;
  error?: boolean;
  maxLength?: number;
} & MarginProps;

type InputSizes = 'default' | 'large';

type ButtonIcon = {
  name: string;
  color?: ColorsType;
  space?: SpaceType;
  rotate?: number;
};

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      m,
      mt,
      mr,
      mb,
      ml,
      mx,
      my,
      icon,
      sizeVariant = 'default',
      onSubmit,
      ...rest
    },
    ref,
  ) => {
    const space = { m, mt, mr, mb, ml, mx, my };

    return (
      <InputWrapper {...removeEmptyValues(space)}>
        <Input sizeVariant={sizeVariant} ref={ref} {...rest} />
        {icon && <InputIcon {...icon} onClick={onSubmit} />}
      </InputWrapper>
    );
  },
);

const getSizeVariantCss = (sizeVariant: InputSizes) => {
  if (sizeVariant === 'default') {
    return css`
      font-size: ${({ theme: { fontSizes } }) => fontSizes.fs200};
      height: 40px;
    `;
  }
  if (sizeVariant === 'large') {
    return css`
      font-size: ${({ theme: { fontSizes } }) => fontSizes.fs300};
      height: 48px;
    `;
  }
  return '';
};

const Input = styled.input<{ sizeVariant: InputSizes; error?: boolean }>`
  ${({ theme: { radii, space, borders, colors }, sizeVariant, error }) => css`
    padding: 0;
    box-sizing: border-box;
    border-radius: ${radii.rounded};
    border: ${borders.thinGray10};
    padding-left: ${space.s20};
    padding-right: ${space.s55};
    width: 100%;
    transition: box-shadow 0.2s ease-out;

    ${getSizeVariantCss(sizeVariant)}

    &:hover {
      box-shadow: 0 0 0 ${space.s025} ${colors.primary20};
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 ${space.s025} ${colors.blue};
    }

    ${error &&
    css`
      box-shadow: 0 0 0 ${space.s025} ${colors.alert50};
    `}
  `}
`;

const InputWrapper = styled(Flex)`
  position: relative;
  width: 100%;
  ${styledSystemSpace}
`;

const InputIcon = styled(Icon)<{
  space?: SpaceType;
  rotate?: number;
  onClick?: () => void;
}>`
  position: absolute;
  right: ${({ space, theme }) => theme.space[space ?? 's20']};
  top: 50%;
  transform: translateY(-50%)
    ${({ rotate }) => (rotate ? `rotate(${rotate}deg)` : 'rotate(0deg)')};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'defalut')};
`;
