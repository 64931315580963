import styled, { css } from 'styled-components';
import { Colors } from 'theme';
import { Icon, Flex, Text } from 'ui';
import { IconNames } from 'ui/Icon/props';

type BadgeProps = {
  label: string;
  onClick: (label: string) => void;
  icon?: { name?: IconNames; color?: keyof Colors };
};

export const Badge = ({ label, onClick, icon }: BadgeProps) => {
  return (
    <BadgeWrapper>
      <Text lineHeight="lh300">{label}</Text>
      <BaggeIcon
        name={icon?.name ?? '16-close'}
        color={icon?.color ?? 'blue'}
        onClick={() => onClick(label)}
      />
    </BadgeWrapper>
  );
};

const BadgeWrapper = styled(Flex)`
  align-items: center;
  max-width: max-content;
  ${({ theme: { colors, radii, space } }) => css`
    background: ${colors.lightBlue};
    border-radius: ${radii.large};
    gap: ${space.s10};
    padding: ${space.s05} ${space.s20};
  `}
`;

const BaggeIcon = styled(Icon)<{ onClick: () => void }>`
  cursor: pointer;
`;
