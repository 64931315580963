import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { paths } from 'routes';
import { useGetRole, useMe } from 'utils';

export const useCreateOrdersPermission = () => {
  const navigate = useNavigate();
  const me = useMe();
  const role = useGetRole();
  const isPermission = me?.createOrdersPermission;

  useEffect(() => {
    if (!isPermission) {
      navigate(paths.main.notAllowed);
    }
  }, [isPermission, navigate, role]);
};
