import { useRef, useState } from 'react';
import { Flex, Grid } from 'ui';
import { Breadcrumbs, Button, Panel } from 'components';
import { paths } from 'routes';
import { useQueryUsers, UsersData } from 'graphql/query';
import { UsersTable } from 'components/UsersTable';
import useInfiniteScroll from 'utils/useInfiniteScroll';
import { formatDate, useReportRestApi } from 'utils';
import styled from 'styled-components';
import { UsersFilter } from './components/UsersFilter/UsersFilter';
import { CreateProfileModal } from './components/CreateProfileModal/CreateProfileModal';
import { Filters } from './types';

type UsersReportRequestData = {
  usersIds: string;
};

const REPORT_SLUG = '/juki/download/usersData';

export const Users = () => {
  const [isCreateProfileModalOpen, setIsCreateProfileModalOpen] =
    useState(false);
  const [filters, setFilters] = useState<Filters>({
    country: '',
    search: '',
  });
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const downloadAnchorRef = useRef<HTMLAnchorElement>(null);

  const status = filters?.userStatus?.key;

  const { data, loading, fetchMore, client, updateQuery } = useQueryUsers({
    notifyOnNetworkStatusChange: true,
    variables: {
      limit: 100,
      offset: 0,
      orderBy: 'id',
      orderDir: 'DESC',
      phrase: filters?.search,
      country: filters?.country,
      company: filters?.company?.id?.toString(),
      role: filters?.role?.key,
      salesAgent: filters?.salesAgent?.id?.toString(),
      status,
    },
  });

  const { ref, loadingFetchMore } = useInfiniteScroll<UsersData>({
    data,
    count: data?.users?.count,
    loading,
    resource: (item) => item?.users?.items,
    fetchMore,
    distanceBottom: 200,
  });

  const handleInvalidateCache = () => {
    updateQuery((previousQueryResult) => {
      const items = previousQueryResult?.users?.items || [];
      const count = previousQueryResult?.users?.count || 0;

      if (status === undefined) {
        return previousQueryResult;
      }

      const resultData = items?.filter(
        (item) => item?.enabled === Boolean(status),
      );
      const quantityRemoved = (items?.length || 0) - (resultData?.length || 0);

      return {
        ...previousQueryResult,
        users: {
          ...previousQueryResult.users,
          count: count - quantityRemoved,
          items: resultData,
        },
      };
    });
  };

  const users = data?.users?.items || [];

  const { getAPIData } = useReportRestApi<UsersReportRequestData>({
    callback: (blob) => {
      const downloadAnchor = downloadAnchorRef.current;
      if (!downloadAnchor) return;
      const today = new Date();
      downloadAnchor.href = URL.createObjectURL(blob);
      downloadAnchor.download = `Report-${formatDate(today)}`;
      downloadAnchor.click();
    },
    slug: REPORT_SLUG,
  });

  const handleFilters = async (value: Partial<Filters>) => {
    await client.cache.evict({
      id: 'ROOT_QUERY',
      fieldName: 'users',
      broadcast: false,
    });
    setFilters((prev) => ({ ...prev, ...value }));
  };

  const handleExportUsersData = () => {
    getAPIData({ usersIds: selectedIds.join(';') });
  };

  const handleExportUserData = (id: number) => {
    getAPIData({ usersIds: `${id}` });
  };

  const handleAllItemsChecked = () => {
    if (users.length === selectedIds.length) return setSelectedIds([]);
    setSelectedIds(users.map((user) => user.id));
  };

  const handleItemChecked = (id: number) => {
    setSelectedIds((prev) => {
      if (prev.includes(id))
        return prev.filter((selectedId) => selectedId !== id);
      return [...prev, id];
    });
  };

  return (
    <>
      <StyledAnchor ref={downloadAnchorRef} />
      <Grid gridTemplateRows={'60px 1fr'}>
        <Flex alignItems="center" justifyContent="space-between">
          <Breadcrumbs
            px="s30"
            items={[
              { label: 'Home', to: paths.base },
              { label: 'Users', to: paths.main.users },
            ]}
          />
          <Button
            mr="s50"
            variant={'outline-primary'}
            onClick={() => setIsCreateProfileModalOpen(true)}
            icon={{
              name: '20-createUser',
              color: 'blue',
              position: 'left',
            }}
          >
            Create profile
          </Button>
        </Flex>

        <Flex flexDirection="column">
          <Panel expandable={false} label={'SEARCH & FILTER USERS'}>
            <Flex flex={1} flexDirection="column">
              <UsersFilter filters={filters} handleFilters={handleFilters} />
              <div ref={ref}>
                <UsersTable
                  data={users}
                  onExportUser={handleExportUserData}
                  handleInvalidateCache={handleInvalidateCache}
                  loading={loading}
                  selectedIds={selectedIds}
                  onItemChecked={handleItemChecked}
                />
              </div>
              <Flex pb="s30" justifyContent="flex-end">
                <Button
                  onClick={handleAllItemsChecked}
                  disabled={loadingFetchMore}
                  variant="text"
                >
                  {users.length === selectedIds.length
                    ? 'Deselect all'
                    : 'Select all'}
                </Button>
                <Button
                  onClick={handleExportUsersData}
                  variant="primary"
                  disabled={!selectedIds.length}
                  ml="s30"
                >
                  Export user data
                </Button>
              </Flex>
            </Flex>
          </Panel>
        </Flex>
      </Grid>
      <CreateProfileModal
        open={isCreateProfileModalOpen}
        handleClose={() => setIsCreateProfileModalOpen(false)}
      />
    </>
  );
};

const StyledAnchor = styled.a`
  display: none;
`;
