import { useState } from 'react';
import { Breadcrumbs, Button, Divider, Heading, Modal } from 'components';
import { MessageContent } from 'components/Messages/MessageContent';
import { paths } from 'routes';
import { Box } from 'ui';
import styled from 'styled-components';
import { filterEmptyConditions } from 'graphql/utils';
import { useMutationResendMessage } from 'graphql/mutation';
import useInfiniteScroll from 'utils/useInfiniteScroll';
import {
  Filters,
  TableMessages,
  MailingCreate,
  ActionsBar,
} from './components';
import { StateFilters } from './components/utilities';
import { useQueryMessages, MessagesData } from './graphql/useQueryMessage';

export const Mailing = () => {
  const [previewMessageId, setPreviewMessageId] = useState(0);
  const [open, setOpen] = useState<'create' | 'edit' | null>(null);
  const [filters, setFilters] = useState<StateFilters>({
    messages: '',
  });

  const { data, refetch, loading, fetchMore } = useQueryMessages({
    notifyOnNetworkStatusChange: true,
    variables: {
      phrase: filters?.messages,
      conditions: filterEmptyConditions([
        { column: 'tag', value: filters?.type?.key },
      ]),
      orderBy: 'updatedAt',
      orderDir: 'DESC',
      limit: 50,
      offset: 0,
    },
  });

  useInfiniteScroll<MessagesData>({
    count: data?.messages?.count,
    loading,
    resource: (item) => item?.messages?.items,
    data,
    fetchMore,
  });

  const [resendMessage, { loading: loadingResend }] = useMutationResendMessage({
    awaitRefetchQueries: true,
    onCompleted: () => {
      setOpen(null);
      setPreviewMessageId(0);
      refetch();
    },
  });

  const onClickEdit = () => {
    setOpen('edit');
  };

  const messageActive = data?.messages?.items?.filter(
    (item) => item.id === previewMessageId,
  )?.[0];

  const handleCloseAfterDeleted = () => {
    setOpen(null);
    setPreviewMessageId(0);
  };

  const visibleEdit = messageActive?.channel === 'inapp';

  return (
    <>
      <div>
        <Breadcrumbs
          px="s30"
          items={[
            { label: 'Home', to: '/' },
            { label: 'Messages', to: paths.main.mailing },
          ]}
        />
        <Divider />
        <Box px="s30">
          <Heading pt="s25">MESSAGES</Heading>
        </Box>
        <Box m="s60" mt="s0">
          <Filters
            handleOpenEdit={() => setOpen('create')}
            filters={filters}
            setFilters={setFilters}
          />
          <TableMessages handleOpenMessage={setPreviewMessageId} data={data} />
        </Box>
      </div>

      <Modal
        handleClose={() => setPreviewMessageId(0)}
        open={!!previewMessageId && !open}
        actions={
          <ActionsBar>
            {visibleEdit && (
              <Button variant="outline-primary" onClick={onClickEdit}>
                Edit
              </Button>
            )}
            <Button
              variant="primary"
              loading={loadingResend}
              onClick={() =>
                resendMessage({ variables: { id: `${messageActive?.id}` } })
              }
            >
              {loadingResend ? 'Sending' : 'Send again'}
            </Button>
          </ActionsBar>
        }
      >
        <ContainerModal>
          {messageActive && <MessageContent {...messageActive} />}
        </ContainerModal>
      </Modal>

      <MailingCreate
        handleClose={() => {
          setOpen(null);
        }}
        handleCloseAfterDeleted={handleCloseAfterDeleted}
        open={!!open}
        type={open}
        dataEdit={messageActive}
        refetch={refetch}
      />
    </>
  );
};

const ContainerModal = styled.div`
  min-width: 50vw;
`;
