import React, { useState, ReactChild, ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { Flex, Icon } from 'ui';

type Props = {
  heading: ReactChild;
  expandable?: boolean;
  children: ReactChild;
  defaultExpand?: boolean;
  additionalElements?: ReactElement;
};

export const Accordion = ({
  heading,
  expandable = true,
  children,
  defaultExpand = false,
  additionalElements,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpand);
  const handleToggle = () => expandable && setIsExpanded((prev) => !prev);

  return (
    <PanelWrapper flexDirection="column">
      <PanelHeader px="s30" onClick={handleToggle}>
        {heading}
        {expandable && (
          <ExpandIcon
            color="gray80"
            isExpanded={isExpanded}
            name="16-arrowhead"
          />
        )}
        {additionalElements && <Flex mr="s20">{additionalElements}</Flex>}
      </PanelHeader>
      {isExpanded && children}
    </PanelWrapper>
  );
};

const PanelWrapper = styled(Flex)``;

const ExpandIcon = styled(Icon)<{ isExpanded: boolean }>`
  transform: ${({ isExpanded }) =>
    isExpanded ? 'rotate(0deg)' : 'rotate(180deg)'};
`;

const PanelHeader = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'space-between',
})<{ onClick: () => void }>`
  cursor: pointer;

  ${({ theme: { borders } }) => css`
    border-top: ${borders.thinGray10};
  `}
`;
