import { gql, QueryHookOptions, useQuery } from '@apollo/client';

export function useQueryFaqAnswer(options?: QueryHookOptions<FaqAnswerData>) {
  const data = useQuery<FaqAnswerData>(FAQ_ANSWER, options);
  return data;
}

export const FAQ_ANSWER = gql`
  query faqAnswers {
    faqAnswers {
      items {
        id
        title
        text
      }
    }
  }
`;

export interface FaqAnswerData {
  faqAnswers: {
    items: {
      id: number;
      title: string;
      text: string;
    }[];
  };
}
