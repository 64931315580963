import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export const useMutationOrderItemRemoveMonitoring = (
  options?: MutationHookOptions<OrderItemRemoveMonitoringData>,
) =>
  useMutation<OrderItemRemoveMonitoringData>(
    ORDER_ITEM_REMOVE_MONITORING,
    options,
  );

export const ORDER_ITEM_REMOVE_MONITORING = gql`
  mutation orderItemRemoveMonitoring($id: Int, $section: String) {
    orderItemRemoveMonitoring(item: { id: $id }, section: $section) {
      id
    }
  }
`;

export type OrderItemRemoveMonitoringVariables = {
  id: number;
  section: string;
};

export type OrderItemRemoveMonitoringData = {
  orderItemRemoveMonitoring: {
    id: string;
  };
};
