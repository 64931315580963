import {
    Divider,
    Heading, RegistrationStatus
} from 'components';
import {
    Cell,
    Row,
    Table,
    TableBody,
    TableHead,
    TableHeadCell,
    TableHeadRow
} from 'components/FlexTable/Table.styled';
import { OrderStatus } from 'components/OrderStatus';
import { WishlistRegisteredItem } from 'graphql/types/WishlistRegisteredItem';
import styled, { css } from 'styled-components';
import { Box, Flex } from 'ui';
import { formatDate } from 'utils';

type TableRegisteredStatusProps = { data: WishlistRegisteredItem[] };

export const TableRegisteredStatus = ({ data }: TableRegisteredStatusProps) => {
  return (
    <>
      <Divider />
      <Box px="s30">
        <Heading pt="s30">REGISTERED WISHLIST STATUS</Heading>
        <Box p="s15">
          <Table>
            <TableHead>
              <TableHeadRow>
                <TableHeadCell flex={120}>Item code</TableHeadCell>
                <TableHeadCell flex={100}>Inquire date</TableHeadCell>
                <TableHeadCell flex={100}>Registration date</TableHeadCell>
                <TableHeadCell flex={80}>Registration status</TableHeadCell>
                <TableHeadCell flex={80}>List price</TableHeadCell>
                <TableHeadCell flex={120}>Order date</TableHeadCell>
                <TableHeadCell flex={180}>Customer</TableHeadCell>
                <TableHeadCell flex={80}>Qty</TableHeadCell>
                <TableHeadCell flex={80}>Order status</TableHeadCell>
              </TableHeadRow>
            </TableHead>
            <TableBody>
              {data?.map((item) => (
                <Row key={item.id}>
                  <Cell flex={120}>{item.commodity.itemCode}</Cell>
                  <Cell flex={100}>{formatDate(item.inquireDate) || '-'}</Cell>
                  <Cell flex={100}>
                    {formatDate(item.commodity.createdAt) || '-'}
                  </Cell>
                  <Cell flex={80}>
                    <RegistrationStatus
                      disabledLabel
                      status={
                        item.commodity.sellable ? 'registered' : 'processing'
                      }
                    />
                  </Cell>
                  <Cell flex={80}>{item.commodity.price ?? 0}</Cell>
                  <Cell flex={120}>
                    {formatDate(item.order.createdAt) || '-'}
                  </Cell>
                  <Cell flex={180}>{item.order.user.name}</Cell>
                  <Cell flex={80}>{item.quantity}</Cell>
                  <Cell flex={80}>
                    <OrderStatus status={item.order.status} showLabel />
                  </Cell>
                </Row>
              ))}
            </TableBody>
          </Table>
          <ItemBar>
            <RegistrationStatus status="registered" />
            <RegistrationStatus status="processing" />
          </ItemBar>
        </Box>
      </Box>
    </>
  );
};

const ItemBar = styled(Flex)`
  ${({ theme: { space } }) => css`
    column-gap: ${space.s20};
    padding: ${space.s30} 0;
  `}
`;
