import { ColorsType } from 'ui';

export type OptionsTag = { key: string; label: string; color: ColorsType }[];

export const optionsTag: OptionsTag = [
  { key: 'orders', label: 'Orders', color: 'lightGreen' },
  { key: 'news', label: 'News', color: 'lightPink' },
  { key: 'system', label: 'System', color: 'lightViolet' },
];

export const getTag = (key: string) =>
  optionsTag.filter((item) => item.key === key)?.[0];
