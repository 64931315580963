export const formatDate = (
  inputDate?: Date | string,
  inputFormat = 'yyyy-MM-dd',
  showTime?: boolean,
) => {
  if (!inputDate) {
    return null;
  }
  const date = new Date(inputDate);

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const hours = date.getUTCHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  //replace the month
  let format = inputFormat.replace('MM', month.toString().padStart(2, '0'));

  //replace the year
  if (format.indexOf('yyyy') > -1) {
    format = format.replace('yyyy', year.toString());
  } else if (format.indexOf('yy') > -1) {
    format = format.replace('yy', year.toString().substr(2, 2));
  }

  //replace the day
  format = format.replace('dd', day.toString().padStart(2, '0'));

  const time = `, ${hours}:${minutes}:${seconds}`;

  return `${format}${showTime ? time : ''}`;
};
