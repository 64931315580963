import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export function useMutationImportCsvToCart(
  options?: MutationHookOptions<ImportCsvToCartData, ImportCsvToCartVariables>,
) {
  const data = useMutation<ImportCsvToCartData, ImportCsvToCartVariables>(
    IMPORT_CSV_TO_CART,
    options,
  );
  return data;
}

export const IMPORT_CSV_TO_CART = gql`
  mutation importCsvToCart($file: Upload!) {
    importCsvToCart(file: $file) {
      id
      missingItems {
        itemCode
        quantity
      }
    }
  }
`;

export type ImportCsvToCartVariables = {};

export type ImportCsvToCartData = {
  importCsvToCart: {
    id: number;
    missingItems: {
      itemCode: string;
      quantity: number;
    }[];
  };
};
