import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export function useMutationSetDirectoryActive(
  options?: MutationHookOptions<
    SetDirectoryActiveData,
    SetDirectoryActiveVariables
  >,
) {
  const data = useMutation<SetDirectoryActiveData, SetDirectoryActiveVariables>(
    SET_DIRECTORY_ACTIVE,
    options,
  );
  return data;
}

export const SET_DIRECTORY_ACTIVE = gql`
  mutation SetDirectoryActive($directoryId: String!, $active: Boolean!) {
    setDirectoryActive(directoryId: $directoryId, active: $active) {
      id
    }
  }
`;

export type SetDirectoryActiveVariables = {
  directoryId: string;
  active: boolean;
};

export type SetDirectoryActiveData = {
  setDirectoryActive: {
    id: number;
  };
};
