import styled, { css } from 'styled-components';

import { useEffect, useState } from 'react';
import { Box, Flex, Grid } from 'ui';
import { Breadcrumbs } from 'components';
import { MessageContent } from 'components/Messages/MessageContent';
import useInfiniteScroll from 'utils/useInfiniteScroll';
import { filterEmptyConditions } from 'graphql/utils';
import { useGetRole } from 'utils';
import { ROLES } from 'constants/roles';
import { useMutationMarkAsRead } from 'graphql/mutation/useMutationMarkAsRead';
import { TextNoData } from 'components/TextNoData';
import { Message } from './components/Message';
import { useQueryMessages, MessagesData } from './graphql/useQueryMessage';
import { StateFilters } from './types';
import { Filters } from './components/Filters';

export const Messages = () => {
  const [openMessageId, setOpenMessageId] = useState(0);

  const role = useGetRole();

  const [filters, setFilters] = useState<StateFilters>({
    search: '',
    openFilters: false,
  });

  const [markAsRead] = useMutationMarkAsRead();

  const getReceiver = (value: ROLES) => {
    switch (value) {
      case 'SALES': {
        return 'sales_agent|all';
      }
      case 'CLIENT': {
        return 'customers|all';
      }
      default:
        return;
    }
  };

  const { data, fetchMore, loading } = useQueryMessages({
    notifyOnNetworkStatusChange: true,
    variables: {
      limit: 50,
      offset: 0,
      orderBy: 'updatedAt',
      orderDir: 'DESC',
      phrase: filters?.search,
      conditions: filterEmptyConditions([
        { column: 'tag', value: filters?.type?.key },
        { column: 'receiver', operator: 'in', value: getReceiver(role) },
      ]),
    },
    onCompleted: (dataQuery) => {
      const idFirstMessage = dataQuery?.messages?.items?.[0]?.id;
      if (!openMessageId) {
        setOpenMessageId(idFirstMessage);
      }
    },
  });

  const { ref } = useInfiniteScroll<MessagesData>({
    count: data?.messages?.count,
    loading,
    resource: (item) => item?.messages?.items,
    data,
    fetchMore,
    listenerRef: true,
  });

  const getMessageActive = (messages?: MessagesData) => {
    return messages?.messages?.items?.filter(
      (item) => item.id === openMessageId,
    )?.[0];
  };

  useEffect(() => {
    if (openMessageId) {
      const messageActive = getMessageActive(data);

      if (!messageActive?.userRead) {
        markAsRead({
          variables: { messageId: `${openMessageId}` },
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openMessageId, data]);

  const messageActive = getMessageActive(data);
  const messages = data?.messages?.items || [];

  return (
    <Box>
      <Flex px="s30" style={{ borderBottom: '1px solid #ddd' }}>
        <Breadcrumbs
          items={[
            { label: 'Home', to: '/' },
            { label: 'Messages', to: '/messages' },
          ]}
        />
      </Flex>

      <MessagesClient gridTemplateColumns={{ xs: '1fr', m: '354px 1fr' }}>
        <MenuMessages>
          <Filters
            filters={filters}
            setFilters={setFilters}
            setOpenMessageId={setOpenMessageId}
          />
          <WrapperMessages ref={ref} openFilters={filters.openFilters}>
            {messages?.length > 0 || loading ? (
              messages?.map((item) => (
                <Message
                  key={item.id}
                  {...item}
                  setOpen={setOpenMessageId}
                  active={openMessageId === item.id}
                />
              ))
            ) : (
              <TextNoData />
            )}
          </WrapperMessages>
        </MenuMessages>
        <MessagePreviewContainer
          alignItems="flex-start"
          justifyContent="center"
          px="s50"
          py="s30"
        >
          {messageActive && <MessageContent {...messageActive} />}
        </MessagePreviewContainer>
      </MessagesClient>
    </Box>
  );
};

const WrapperMessages = styled.div<{ openFilters: boolean }>`
  ${({ openFilters }) => css`
    max-height: calc(100vh - (175px + ${openFilters ? '50px' : '0px'}));
    overflow: auto;
  `}
`;

const MessagesClient = styled(Grid)`
  width: 100%;
`;

const MenuMessages = styled.div`
  ${({ theme: { borders } }) => css`
    border-right: ${borders.thinGray10};
    min-height: calc(100vh - 120px);
    position: relative;
  `}
`;

const MessagePreviewContainer = styled(Flex)`
  max-height: calc(100vh - 120px);
  overflow: auto;
  width: 100%;
  height: 100%;
`;
