import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export const useMutationClearWishlist = (
  options?: MutationHookOptions<ClearWishlistData>,
) => {
  return useMutation<ClearWishlistData>(CLEAR_WISHLIST, options);
};

export const CLEAR_WISHLIST = gql`
  mutation clearWishlist {
    clearWishlist
  }
`;

export type ClearWishlistData = {
  clearWishlist: boolean;
};
