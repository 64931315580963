import { gql, QueryHookOptions, useQuery } from '@apollo/client';

export const useQueryProductSearchReport = (
  options?: QueryHookOptions<
    ProductSearchReportData,
    ProductSearchReportVariables
  >,
) =>
  useQuery<ProductSearchReportData, ProductSearchReportVariables>(
    PRODUCT_SEARCH_REPORT,
    options,
  );

export const PRODUCT_SEARCH_REPORT = gql`
  query productSearchReport($userId: String) {
    productSearchReport(userId: $userId) {
      id
    }
  }
`;

export type ProductSearchReportVariables = {
  userId?: string;
};

export type ProductSearchReportData = {
  productSearchReport: {
    id: string;
  };
};
