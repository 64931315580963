import {
  useQueryUserDeliveryAddresses,
  useQuerySubAccountUsers,
  SubAccountUser,
} from 'graphql/query';
import { useState } from 'react';
import { BusinessPartnerShippingAddress } from 'graphql/types/BusinessPartnerShippingAddress';

type ReportModalData = {
  dateRangeInputValue?: [string | Date, string | Date];
  selectedDeliveryAddress?: BusinessPartnerShippingAddress;
  selectedOrderStatus?: string;
  selectedAccount?: SubAccountUser;
};

export type YourOrdersReportModal = ReturnType<typeof useYourOrdersReportModal>;

export const useYourOrdersReportModal = () => {
  const [reportModalData, setReportModalData] = useState<ReportModalData>();

  const { data: deliveryAddressData } = useQueryUserDeliveryAddresses();
  const { data: subAccountUsersData } = useQuerySubAccountUsers();

  const handleSelectedAccountChange = (val?: SubAccountUser) => {
    setReportModalData((prev) => ({ ...prev, selectedAccount: val }));
  };

  const handleSelectedOrderStatusChange = (val?: string) => {
    setReportModalData((prev) => ({ ...prev, selectedOrderStatus: val }));
  };

  const handleDateRangeInputChange = (val?: [string | Date, string | Date]) => {
    setReportModalData((prev) => ({ ...prev, dateRangeInputValue: val }));
  };

  const handleSelectedDeliveryAddressChange = (
    val?: BusinessPartnerShippingAddress,
  ) => {
    setReportModalData((prev) => ({ ...prev, selectedDeliveryAddress: val }));
  };

  return {
    handlers: {
      handleSelectedAccountChange,
      handleSelectedOrderStatusChange,
      handleDateRangeInputChange,
      handleSelectedDeliveryAddressChange,
    },
    reportModalData,
    options: {
      deliveryAddressOptions: deliveryAddressData?.userDeliveryAddresses ?? [],
      orderStatusOptions: ['open', 'closed'],
      subAccountUsersOptions: subAccountUsersData?.subAccountUsers ?? [],
    },
  };
};
