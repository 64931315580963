import { Button, StockStatus } from 'components';
import { Highlighted } from 'components/Highlighted';
import { StockStatuses } from 'graphql/types/StockStatuses';
import styled, { css } from 'styled-components';
import { Box, Flex } from 'ui';

type ItemResultSearchProps = {
  code: string;
  description: string;
  status: StockStatuses;
  search?: string;
  id: number;
  button: {
    label: string;
    onClick: (id: number) => void;
  };
};

export const ItemResultSearch = ({
  code,
  description,
  status,
  search,
  button,
  id,
}: ItemResultSearchProps) => {
  return (
    <Container px="s20" py="s15">
      <Highlighted text={code} search={search} />
      <Highlighted elipsis text={description} search={search} />
      <StockStatus status={status} label={status} />
      {button && (
        <Flex justifyContent="right">
          <Button
            onClick={() => button.onClick(id)}
            variant="outline-primary"
            size="small"
          >
            {button.label}
          </Button>
        </Flex>
      )}
    </Container>
  );
};

export const Container = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${({ theme: { space, colors } }) => css`
    gap: ${space.s20};
    padding-bottom: ${space.s15};
    background-color: ${colors.gray06};
    border-bottom: 1px solid ${colors.gray05};
  `};
  > * {
    flex: 1;
  }
`;
