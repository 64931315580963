import { useQueryContentBlocks } from 'graphql/query/useQueryContentBlocks';

export const useArticles = (type: string) => {
  const host = process.env.REACT_APP_REST_HOST;
  const { data } = useQueryContentBlocks({ variables: { type } });
  const getPath = (slug: string) => `${host}/ism/articles/${type}/${slug}`;
  const artilces = data?.contentBlocks?.items || [];

  return { getPath, artilces };
};
