import { Portal } from 'components';
import { ReactChild, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Flex, Text } from 'ui';

type Props = {
  children: ReactChild;
  content: ReactChild;
  active?: boolean;
};

export const Tooltip = ({
  children,
  content,
  active = true,
  ...rest
}: Props) => {
  const key = 'tooltip';
  const [hover, setHover] = useState(false);
  const [position, setPosition] = useState({
    left: 0,
    top: 0,
    width: 0,
  });
  const boxRef = useRef<HTMLDivElement>(null);

  const getPosition = () => {
    const box = boxRef?.current?.getBoundingClientRect();
    if (box) {
      const { left, top, width = 0 } = box;
      setPosition({ left, top, width: width / 2 });
    }
  };

  return (
    <Container
      onMouseEnter={() => {
        setHover(true);
        getPosition();
      }}
      onMouseLeave={() => setHover(false)}
      {...rest}
      ref={boxRef}
    >
      {children}
      {active && hover && (
        <Portal wrapperId={key}>
          <TooltipContent
            style={{
              top: position?.top,
              left: position.left,
            }}
            fontSize="fs100"
          >
            {content}
          </TooltipContent>
        </Portal>
      )}
    </Container>
  );
};

const Container = styled(Flex)`
  position: relative;
  cursor: default;
  display: inline-flex;
  width: 100%;
`;

const TooltipContent = styled(Text)`
  ${({ theme: { space, colors } }) => css`
    padding: ${space.s10} ${space.s20};
    margin: ${space.s05};
    color: ${colors.white};
    background-color: ${colors.gray100};
    position: fixed;
    letter-spacing: 0.5px;
    z-index: 10000000000;
    transform: translateY(-140%) translateX(-17px);
    width: fit-content;
  `}
`;
