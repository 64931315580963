export type Colors = typeof colors;

export const colors = {
  blue: '#0071CE',
  lightBlue: '#EDF3F9',
  black: '#000000' /* TODO: check if this color is correct (User.tsx) */,
  gray: '#C4C4C4', //TODO: check color
  gray100: '#202532',
  gray80: '#656565',
  gray60: '#838791',
  gray50: '#919191' /* TODO: check if this color is correct (logoutLink.tsx) */,
  gray40: '#BEC0C5',
  gray10: '#DDE0E7',
  gray06: '#F9F9F9',
  gray05: '#C4C4C4',
  gray0: '#f4f4f4',
  white: '#FFFFFF',
  primary100: '#2648A4',
  primary50: '#2C5DE5',
  primary20: '#91ADFA',
  primary10: '#BFD0FD',
  primary05: '#D7E1FE',
  primary0: '#EEF3FF',
  success100: '#238500',
  success60: '#E8F9E7',
  success50: '#008556',
  success05: '#D6F3E2',
  warning100: '#A64F21',
  warning50: '#E86825',
  warning05: '#FCE9DB',
  alert100: '#9F1B1F',
  alert50: '#DE1C22',
  alert20: '#FAE4E3',
  alert05: '#FFD6D7',
  lightPink: '#FFD6D7',
  lightViolet: '#D9DDF6',
  lightGreen: '#D7EDCF',
} as const;
