export const checkPasswordRule = (value = '') => {
  const minValueMarks = value?.length >= 8;
  const oneSpecialMark = containsSpecialChars(value);
  const differentLetters =
    value?.search(/[A-Z]/) >= 0 && value?.search(/[a-z]/) >= 0;
  const success = minValueMarks && oneSpecialMark && differentLetters;

  return { minValueMarks, oneSpecialMark, differentLetters, success };
};

const containsSpecialChars = (value: string) => {
  const specialChars = /[`!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/;
  return specialChars.test(value);
};
