import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { OrderItem } from 'graphql/types/OrderItem';

export const useQueryOrders = (
  options?: QueryHookOptions<OrdersData, OrdersVariables>,
) => useQuery<OrdersData, OrdersVariables>(ORDERS, options);

export const ORDERS = gql`
  query customerOrders(
    $offset: Int
    $orderBy: String!
    $orderDir: String!
    $limit: Int
    $monitored: String
    $phrase: String
    $status: String
    $stockStatus: String
    $deliveryAddress: String
    $dateFrom: String
    $dateTo: String
    $source: String
  ) {
    orders(
      criteria: {
        offset: $offset
        orderBy: $orderBy
        orderDir: $orderDir
        limit: $limit
      }
    ) {
      customerOrders(
        monitored: $monitored
        phrase: $phrase
        stockStatus: $stockStatus
        status: $status
        deliveryAddress: $deliveryAddress
        dateFrom: $dateFrom
        dateTo: $dateTo
      ) {
        order {
          id
          documentNumber
          createdAt
          reference
          token
          status
          shipAllSent
          shipTo {
            identifier
            active
            name
            address
            zipCode
            city
            country
            shipVia
            id
          }
        }
        id
        monitored(section: "Order")
        commodity {
          stockStatus
          itemCode
          description
          stock
          purchases {
            documentNumber
            documentCreatedAt
            invoice {
              documentNumber
            }
            productionDate
            shippedFrom
            expectedArrival
            quantity
          }
          stocks(source: $source) {
            id
            stock
            source
            status
            location
          }
        }
        quantity
        value
        deliveryNoteItem {
          id
          invoiceItem {
            invoice {
              documentNumber
              documentCreatedAt
              documentUpdatedAt
              status
            }
          }
          deliveryNote {
            documentNumber
            createdAt
            updatedAt
            status
            transports {
              carrierId
              carrierName
              carrierNumber
              trackingUrl
              createdAt
              updatedAt
            }
            pickLists {
              documentNumber
              documentCreatedAt
              documentUpdatedAt
            }
          }
        }
      }
      count
    }
  }
`;

export type OrdersVariables = {
  dateFrom?: string;
  dateTo?: string;
  deliveryAddress?: string;
  limit?: number;
  monitored?: string;
  offset?: number;
  orderBy: string;
  orderDir: 'ASC' | 'DESC';
  phrase?: string;
  source?: string;
  status?: string;
  stockStatus?: string;
};

export type OrdersData = {
  orders: { customerOrders: OrderItem[]; count: number };
};
