import { Button, UploadInput } from 'components';
import { Flex, Text } from 'ui';
import { Link } from 'react-router-dom';

interface Props {
  handleUpload: (files: File[]) => void;
}

export const Upload = ({ handleUpload }: Props) => (
  <Flex flex="1" flexDirection="column">
    <UploadInput onChange={(value) => handleUpload(value)} />
    <Flex mb="s37" justifyContent="space-between">
      <Text mt="s30" color="black" fontSize="fs200" lineHeight="lh150">
        Use this sample form to make your orders easier and faster
      </Text>
      <Flex mt="s10" alignItems="center">
        <Link to="/sample-order.csv" target="blank" download>
          <Flex>
            <Button
              mr="s50"
              icon={{ position: 'left', color: 'blue', name: '16-document' }}
              variant="text"
            >
              Sample order CSV
            </Button>
          </Flex>
        </Link>
        <Link to="/how-to.pdf" target="_blank" download>
          <Button
            mr="s15"
            icon={{ position: 'left', color: 'blue', name: '16-document' }}
            variant="text"
          >
            How to prepare order document PDF
          </Button>
        </Link>
      </Flex>
    </Flex>
  </Flex>
);
