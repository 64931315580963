import { Fragment, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Flex, FlexProps, Text } from 'ui';

type Breadcrumb = { label: string; to: string };

export type BreadcrumbsProps = { items: Breadcrumb[] } & FlexProps;

// TODO: ostatni breadcrumb nie powinien być linkiem, bo wskazuje na stronę, która jest aktualnie otwarta,
//  props 'to' powinien być opcjonalny i wtedy zrobić okruszek nieklikalny (np. span)

export const Breadcrumbs = ({ items, ...rest }: BreadcrumbsProps) => {
  useEffect(() => {
    document.title = `JUKI Customer Portal - ${items
      .slice(1)
      .map((item) => item.label)
      .join(' - ')}`;
  }, [items]);

  const breadcrumbs = useMemo(
    () =>
      items.map(({ label, to }, index) => {
        const isLastItem = items.length - 1 === index;
        return (
          <Fragment key={label}>
            <LinkWrapper style={{ display: 'flex' }} to={to}>
              <BreadcrumbLabel fontSize="fs100" isLastItem={isLastItem}>
                {label}
              </BreadcrumbLabel>
            </LinkWrapper>
            {!isLastItem && <BreadcrumbLabel mx="s10">→</BreadcrumbLabel>}
          </Fragment>
        );
      }),
    [items],
  );

  return (
    <Flex py="s10" alignItems="center" {...rest}>
      {breadcrumbs}
    </Flex>
  );
};

export const LinkWrapper = styled(Link)`
  display: flex;
`;

export const BreadcrumbLabel = styled(Text).attrs({
  color: 'gray80',
  lineHeight: 'lh300',
})<{
  isLastItem?: boolean;
}>`
  opacity: ${({ isLastItem }) => (isLastItem ? '1' : '0.5')};
`;
