import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export function useMutationAddDirectory(
  options?: MutationHookOptions<AddDirectoryData, AddDirectoryVariables>,
) {
  const data = useMutation<AddDirectoryData, AddDirectoryVariables>(
    ADD_DIRECTORY,
    options,
  );
  return data;
}

export const ADD_DIRECTORY = gql`
  mutation addDirectory(
    $name: String!
    $parentId: Int!
    $organizationsIds: [String!]
  ) {
    addDirectory(
      name: $name
      parentId: $parentId
      organizationsIds: $organizationsIds
    ) {
      id
    }
  }
`;

export type AddDirectoryVariables = {
  name: string;
  parentId: number;
  organizationsIds: string[];
};

export type AddDirectoryData = {
  addDirectory: {
    id: string;
  };
};
