import { DIMENSIONS } from 'constants/dimensions';
import styled, { css } from 'styled-components';
import { Grid } from 'ui';

export const StyledHeader = styled(Grid)`
  ${({ theme: { borders } }) => css`
    align-items: center;
    border-bottom: ${borders.thinGray10};
    height: ${DIMENSIONS.headerHeight}px;
    grid-template-columns: 236px auto;
    width: 100%;
    background: white;
    position: sticky;
    top: 0;
    z-index: 2;
  `}
`;
