import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export const useMutationOrderCreateMonitoring = (
  options?: MutationHookOptions<
    OrderCreateMonitoringData,
    OrderCreateMonitoringVariables
  >,
) =>
  useMutation<OrderCreateMonitoringData, OrderCreateMonitoringVariables>(
    ORDER_CREATE_MONITORING,
    options,
  );

export const ORDER_CREATE_MONITORING = gql`
  mutation orderCreateMonitoring($section: String) {
    orderCreateMonitoring(section: $section) {
      id
    }
  }
`;

export type OrderCreateMonitoringVariables = {
  section: string;
};

export type OrderCreateMonitoringData = {
  orderCreateMonitoring: {
    id: string;
  };
};
