import { ReactChild } from 'react';
import { ThemeProvider as Provider } from 'styled-components';

import { GlobalStyle } from 'style';
import { theme } from 'theme';

type ThemeProviderProps = { children: ReactChild };

export const ThemeProvider = ({ children }: ThemeProviderProps) => (
  <Provider theme={theme}>
    <GlobalStyle />
    {children}
  </Provider>
);
