import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export function useMutationRemoveMessage(
  options?: MutationHookOptions<boolean, RemoveMessageVariables>,
) {
  const data = useMutation<boolean, RemoveMessageVariables>(
    REMOVE_MESSAGE,
    options,
  );
  return data;
}

export const REMOVE_MESSAGE = gql`
  mutation removeMessage($id: String!) {
    removeMessage(id: $id)
  }
`;

export type RemoveMessageVariables = {
  id: string;
};
