import { ReactChild } from 'react';
import { ApolloProvider as Provider } from '@apollo/client';

import { apolloClient } from 'config';

type ApolloProviderProps = { children: ReactChild };

export const ApolloProvider = ({ children }: ApolloProviderProps) => (
  <Provider client={apolloClient}>{children}</Provider>
);
