import { OptionsTag } from 'components/Messages/utilities';
import { EditMessageVariables } from 'graphql/mutation';
import { Validation } from 'utils/useForm';

export const optionsReceiver = [
  { key: 'all', label: 'All' },
  { key: 'customers', label: 'Customers' },
  { key: 'sales_agent', label: 'Sales Agent' },
];

export const optionsChannel = [
  { key: 'inapp', label: 'Inapp' },
  { key: 'mail', label: 'Mail' },
];

export const validations:
  | Partial<Record<keyof EditMessageVariables, Validation>>
  | undefined = {
  id: {
    required: {
      value: false,
      message: 'ID is required',
    },
  },
  channel: {
    required: {
      value: true,
      message: 'Channel is required',
    },
  },
  content: {
    required: {
      value: false,
      message: 'Content is required',
    },
  },
  receiver: {
    required: {
      value: true,
      message: 'Receiver is required',
    },
  },
  tag: {
    required: {
      value: true,
      message: 'Tag is required',
    },
  },
  title: {
    required: {
      value: true,
      message: 'Title number is required',
    },
  },
};

export interface StateFilters {
  messages: string;
  type?: OptionsTag[0];
}
