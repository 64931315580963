import { gql, QueryHookOptions, useQuery } from '@apollo/client';

export const useQueryWishlistMailingConfig = (
  options?: QueryHookOptions<WishlistMailingConfigData>,
) => useQuery<WishlistMailingConfigData>(WISHLIST_MAILING_CONFIG, options);

export const WISHLIST_MAILING_CONFIG = gql`
  query wishlistMailingConfig {
    wishlistMailingConfig {
      cC
      interval
      receiverUser {
        id
        name
      }
    }
  }
`;

export type WishlistMailingConfigData = {
  wishlistMailingConfig: {
    cC: string;
    interval: string;
    receiverUser: {
      id: number;
      name: string;
    };
  };
};
