import { Box, MarginProps, PaddingProps } from 'ui';
import { Table, TableBody, Heading } from 'components';
import { OrderItem } from 'graphql/types/OrderItem';
import { RecentCustomersOrdersHead } from './RecentCustomersOrdersHead';
import { RecentCustomersOrdersRow } from './RecentCustomersOrdersRow';

type RecentCustomersOrdersProps = {
  data: OrderItem[];
  onViewOrder: (id: number[]) => void;
} & MarginProps &
  PaddingProps;

export const RecentCustomersOrders = ({
  data,
  onViewOrder,
  ...rest
}: RecentCustomersOrdersProps) => {
  return (
    <Box {...rest}>
      <Heading>RECENT CUSTOMERS’ ORDERS</Heading>
      <Box mr="s35">
        <Table>
          <RecentCustomersOrdersHead />
          <TableBody>
            {data.map((orders) => (
              <RecentCustomersOrdersRow
                onViewOrder={onViewOrder}
                key={orders.id}
                {...orders}
              />
            ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};
