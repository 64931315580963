import { Checkbox, DottedStatus } from 'components';
import {
  Cell,
  Row,
  Table,
  TableHead,
  TableHeadCell,
  TableHeadRow,
} from 'components/FlexTable/Table.styled';
import { TextNoData } from 'components/TextNoData';
import { Tooltip } from 'components/Tooltip';
import { useMutationConnectCustomersToSales } from 'graphql/mutation';
import { User } from 'graphql/types/User';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { Flex, Text } from 'ui';
import { getCountryWithCode, getFlagEmoji } from 'utils';
import { Filters } from './types';

type UsersTableProps = {
  data: User[];
  filters: Filters;
  refTable: React.MutableRefObject<any>;
  handleInvalidateCache: () => void;
  loading: boolean;
};

export const UsersTable = ({
  data,
  filters,
  refTable,
  handleInvalidateCache,
  loading,
}: UsersTableProps) => {
  const [searchParams] = useSearchParams();
  const [allChecked, setAllChecked] = useState<boolean | null>(null);
  const paramId = searchParams.get('id') || '';
  const [connectCustomersToSales] = useMutationConnectCustomersToSales({
    onCompleted: () => handleInvalidateCache(),
  });

  useEffect(() => {
    setAllChecked(null);
  }, [filters]);

  const handleConnectCustomersToSales = async (
    customersIds: string[],
    connect: boolean,
  ) => {
    connectCustomersToSales({
      variables: { salesId: paramId, customersIds, connect },
    });
  };

  return (
    <Container py="s30" flexDirection="column" flex="1">
      <TableStyled ref={refTable}>
        <TableHeadStyled>
          <TableHeadRow>
            <TableHeadCell flex={24}>
              <Flex mt={'s10'}>
                <Checkbox
                  checked={!!allChecked}
                  onChange={(e) => {
                    handleConnectCustomersToSales(
                      data?.map(({ id }) => `${id}`),
                      !!e?.currentTarget?.checked,
                    );
                    setAllChecked((prev) => !prev);
                  }}
                />
              </Flex>
            </TableHeadCell>
            <TableHeadCell flex={56}>Country</TableHeadCell>
            <TableHeadCell flex={112}>Code</TableHeadCell>
            <TableHeadCell flex={156}>Company</TableHeadCell>
            <TableHeadCell flex={88}>Name</TableHeadCell>
            <TableHeadCell flex={152}>E-mail</TableHeadCell>
            <TableHeadCell flex={128}>Sales agent</TableHeadCell>
            <TableHeadCell flex={62}>User status</TableHeadCell>
          </TableHeadRow>
        </TableHeadStyled>
        <TableBody>
          {data?.length > 0 || loading ? (
            data.map((item) => (
              <UserRow
                key={item?.id}
                {...item}
                handleConnectCustomersToSales={handleConnectCustomersToSales}
                paramId={paramId}
              />
            ))
          ) : (
            <TextNoData />
          )}
        </TableBody>
      </TableStyled>
    </Container>
  );
};

type PropsRow = User & {
  handleConnectCustomersToSales: (
    customersIds: string[],
    connect: boolean,
  ) => void;
  paramId: string;
};

const UserRow = ({
  name,
  email,
  businessPartner,
  salesUser,
  id,
  enabled,
  handleConnectCustomersToSales,
  paramId,
}: PropsRow) => {
  const country = businessPartner?.country;
  return (
    <Row>
      <Cell flex={24}>
        <Checkbox
          checked={paramId === salesUser?.id?.toString()}
          onChange={(e) => {
            handleConnectCustomersToSales(
              [`${id}`],
              !!e?.currentTarget?.checked,
            );
          }}
        />
      </Cell>
      <Cell flex={56}>
        <Cell flex={64}>
          {country ? (
            <Tooltip
              active={!!country}
              content={getCountryWithCode(country) || ''}
            >
              <Text fontSize="fs400">{getFlagEmoji(country)}</Text>
            </Tooltip>
          ) : (
            '-'
          )}
        </Cell>
      </Cell>
      <Cell flex={112}>{businessPartner?.code || '-'}</Cell>
      <Cell flex={156}>{businessPartner?.name || '-'}</Cell>
      <Cell flex={88}>{name}</Cell>
      <Cell flex={152}>{email}</Cell>
      <Cell flex={128}>{salesUser?.name}</Cell>
      <Cell flex={62}>
        <DottedStatus color={enabled ? 'success50' : 'alert50'} />
      </Cell>
    </Row>
  );
};

const Container = styled(Flex)`
  min-height: 348px;
`;

const TableHeadStyled = styled(TableHead)`
  position: sticky;
  z-index: 2;
  top: 0;
`;

const TableStyled = styled(Table)`
  overflow: auto;
  position: relative;
`;

const TableBody = styled.div`
  height: 300px;
  position: relative;
`;
