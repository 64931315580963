import {
  Cell,
  Row,
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  ReplacementStatus,
} from 'components';
import { CartMissingItem } from 'graphql/types/CartMissingItem';
import { tableCellNames } from './constants/tableCellNames';

type ItemNotExistProps = {
  data: CartMissingItem[];
};

export const ItemNotExist = ({ data }: ItemNotExistProps) => {
  return (
    <div>
      <Table>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell width="150px" divider>
              {tableCellNames.itemCode}
            </TableHeadCell>
            <TableHeadCell>{tableCellNames.description}</TableHeadCell>
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <Row key={`ItemNotExist-${item.itemCode + index}`}>
              <Cell divider>{item.itemCode}</Cell>
              <Cell>
                <ReplacementStatus status="error" fullWidth>
                  Can not find product in database. Check item code or contact
                  Sales Support
                </ReplacementStatus>
              </Cell>
            </Row>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};
