import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { ContentBlock } from 'graphql/types/ContentBlock';

export const useQueryContentBlocks = (
  options?: QueryHookOptions<ContentBlocksData, ContentBlocksVariables>,
) =>
  useQuery<ContentBlocksData, ContentBlocksVariables>(CONTENT_BLOCKS, options);

export const CONTENT_BLOCKS = gql`
  query contentBlocks($type: String!) {
    contentBlocks {
      items(conditions: [{ column: "type", value: $type, operator: "eq" }]) {
        id
        title
        slug
        photoUrl
        photoDownloadUrl
        photoAlt
        type
        lead
      }
    }
  }
`;

export type ContentBlocksVariables = {
  type: string;
};

export type ContentBlocksData = {
  contentBlocks: { items: ContentBlock[] };
};
