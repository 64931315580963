export type Space = typeof space;

export const space = {
  s0: '0px',
  s025: '2px',
  s05: '4px',
  s075: '6px',
  s10: '8px',
  s12: '10px',
  s15: '12px',
  //TODO: Redesign space config
  s17: '14px',
  s20: '16px',
  s25: '20px',
  s30: '24px',
  s35: '28px',
  //TODO: Redesign space config
  s37: '30px',
  s40: '32px',
  s45: '36px',
  s50: '40px',
  s55: '44px',
  s60: '48px',
  s65: '52px',
  s70: '56px',
  s80: '64px',
  s100: '80px',
  s120: '96px',
  s130: '104px',
  s160: '128px',
} as const;
