//TODO:  implement useGetRole hook when backend done

import { Roles } from 'graphql/types/Roles';

export const getRoleLabel = (role: Roles) => {
  switch (role) {
    case 'ROLE_SUPER_ADMIN': {
      return 'Super Admin';
    }
    case 'ROLE_ADMIN': {
      return 'Admin';
    }
    case 'ROLE_SALES': {
      return 'Sales';
    }
    case 'ROLE_USER': {
      return 'Customer';
    }
    default:
      return role;
  }
};
