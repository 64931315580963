import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export const useMutationAssignUsersToDirectory = (
  options?: MutationHookOptions<
    AssignUsersToDirectoryData,
    AssignUsersToDirectoryVariables
  >,
) =>
  useMutation<AssignUsersToDirectoryData, AssignUsersToDirectoryVariables>(
    ASSIGN_USERS_TO_DIRECTORY,
    options,
  );

export const ASSIGN_USERS_TO_DIRECTORY = gql`
  mutation AssignUsersToDirectory(
    $usersIds: [String!]!
    $directoryId: String!
    $connect: Boolean!
  ) {
    assignUsersToDirectory(
      usersIds: $usersIds
      directoryId: $directoryId
      connect: $connect
    ) {
      id
    }
  }
`;

export type AssignUsersToDirectoryVariables = {
  usersIds: string[];
  directoryId: string;
  connect: boolean;
};

export type AssignUsersToDirectoryData = {
  assignUsersToDirectory: {
    id: number;
  };
};
