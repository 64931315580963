import { Button, Modal, ModalProps } from 'components';
import { CartItem } from 'graphql/types/CartItem';
import { useNavigate } from 'react-router-dom';
import { paths } from 'routes';
import { Flex, Text } from 'ui';

type OrderStatusModalProps = { wishlistData?: CartItem[] } & Pick<
  ModalProps,
  'open' | 'handleClose'
>;

export const OrderStatusModal = ({
  handleClose,
  wishlistData,
  open,
}: OrderStatusModalProps) => {
  const navigate = useNavigate();
  return (
    <Modal title="Order status" handleClose={handleClose} open={open}>
      <Text fontSize="fs300" lineHeight="lh300">
        Your order has been succesfully proceeded.
      </Text>
      <Flex mt="s20">
        <Button
          onClick={() => navigate(paths.base)}
          mr="s20"
          fullWidth
          size="small"
          variant="outline-primary"
        >
          Home
        </Button>
        <Button
          onClick={() => navigate(paths.main.yourOrders)}
          fullWidth
          size="small"
          variant="primary"
        >
          Your orders
        </Button>
      </Flex>
      {wishlistData && wishlistData?.length > 0 && (
        <>
          <Flex mt="s30">
            <Text fontSize="fs200" lineHeight="lh300">
              Some items were saved at
            </Text>
            <Button
              onClick={() => navigate('/cartAndDrafts')}
              fontWeight="regular"
              lineHeight="lh300"
              ml="s025"
              variant="text"
            >
              Wishlist:
            </Button>
          </Flex>
          <Flex mt="s10" flexDirection="column">
            {wishlistData?.map((item) => (
              <Text mb="s05" key={item.id} fontSize="fs200" lineHeight="lh300">
                {item.commodity.itemCode}
              </Text>
            ))}
          </Flex>
        </>
      )}
    </Modal>
  );
};
