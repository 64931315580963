import {
  Table,
  TableHeadCell,
  TableHeadRow,
  TableHead,
  TableBody,
  Row,
  Cell,
} from 'components/FlexTable/Table.styled';
import { MessageLabel } from 'components/Messages/MessageLabel';
import { getTag } from 'components/Messages/utilities';
import { Box, Text } from 'ui';
import { formatDate } from 'utils';
import { MessagesData } from '../graphql/useQueryMessage';

interface Props {
  handleOpenMessage: (id: number) => void;
  data?: MessagesData;
}

export const TableMessages = ({ handleOpenMessage, data }: Props) => {
  const messages = data?.messages?.items || [];

  return (
    <Box mt="s50">
      <Table>
        <TableHead>
          <TableHeadRow>
            <TableHeadCell flex={170}>Type</TableHeadCell>
            <TableHeadCell flex={600}>Title</TableHeadCell>
            <TableHeadCell flex={120}>
              <Text textAlign="right" as="p">
                Date
              </Text>
            </TableHeadCell>
          </TableHeadRow>
        </TableHead>
        <TableBody>
          {messages?.map(({ id, tag, title, updatedAt }) => (
            <Row key={id} onClick={() => handleOpenMessage(id)} hover>
              <Cell flex={170}>
                <MessageLabel backgroundColor={getTag(tag).color}>
                  {getTag(tag)?.label}
                </MessageLabel>
              </Cell>
              <Cell flex={600}>{title}</Cell>
              <Cell flex={120} style={{ display: 'block' }}>
                <Text textAlign="right" color="gray80" as="p">
                  {formatDate(updatedAt)}
                </Text>
              </Cell>
            </Row>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
