import { Flex, Grid } from 'ui';
import { Breadcrumbs, Panel } from 'components';
import { paths } from 'routes';
import { useOrdersTableRowScroll, formatDate } from 'utils';
import {
  AdminOrders,
  useQueryAdminOrders,
} from 'graphql/query/useQueryAdminOrders';
import useInfiniteScroll from 'utils/useInfiniteScroll';
import { Orders, OrdersFilter } from './components';
import { useAdminCustomerOrdersFilters } from './hooks/useAdminCustomerOrdersFilters';

export const AdminCustomerOrders = () => {
  const { handlers, filters, options } = useAdminCustomerOrdersFilters();
  const [dateFrom, dateTo] = filters.dateRangeInputValue ?? [];
  const { data, loading, fetchMore } = useQueryAdminOrders({
    variables: {
      orderDir: 'DESC',
      orderBy: 'createdAt',
      limit: 100,
      offset: 0,
      phrase: filters.searchInputValue,
      deliveryAddress: filters.selectedDeliveryAddress?.id
        ? `${filters.selectedDeliveryAddress?.id}`
        : undefined,
      dateFrom: dateFrom ? `${formatDate(dateFrom)}` : undefined,
      dateTo: dateTo ? `${formatDate(dateTo)}` : undefined,
      client: filters.selectedClient?.id
        ? `${filters.selectedClient?.id}`
        : undefined,
      company: filters.selectedCompany?.id
        ? `${filters.selectedCompany?.id}`
        : undefined,
      monitored: filters.showOnlyMonitoredValue ? 'Order' : undefined,
    },
  });

  const { ref, loadingFetchMore } = useInfiniteScroll<AdminOrders>({
    count: data?.orders?.count,
    loading,
    resource: (item) => item?.orders?.adminOrders,
    data,
    fetchMore,
    distanceBottom: 1500,
  });

  const ordersTransformedData = data?.orders?.adminOrders;

  const { expandedRowIds, refs } = useOrdersTableRowScroll({
    data: ordersTransformedData ?? [],
    queryParamsKey: 'expandedRowIds',
  });

  return (
    <Grid gridTemplateRows={'40px 1fr'}>
      <Breadcrumbs
        px="s30"
        items={[
          { label: 'Home', to: paths.base },
          { label: 'Customer Orders', to: paths.main.yourOrders },
        ]}
      />

      <Flex flexDirection="column">
        <Panel label={'Search & Sort Orders'}>
          <OrdersFilter
            handlers={handlers}
            filters={filters}
            options={options}
          />
        </Panel>
        <div ref={ref}>
          <Panel expandable={false} label={'CUSTOMER ORDERS'}>
            <Orders
              defaultExpandedIds={expandedRowIds}
              refs={refs || []}
              data={ordersTransformedData ?? []}
              loading={loading || loadingFetchMore}
            />
          </Panel>
        </div>
      </Flex>
    </Grid>
  );
};
