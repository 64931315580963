import { ToggleSwitch } from 'components';
import { useMutationSetDirectoryActive } from 'graphql/mutation/useMutationSetDirectoryActive';
import { GET_DIRECTORIES, useQueryGetDirectories } from 'graphql/query';
import styled, { css } from 'styled-components';
import { Flex, Grid, Text } from 'ui';
import { formatDate } from 'utils';

interface Props {
  directoryId: number;
}

export const FolderBasicInfo = ({ directoryId }: Props) => {
  const { data } = useQueryGetDirectories({
    variables: {
      conditions: [
        {
          column: 'id',
          operator: 'eq',
          value: `${directoryId}`,
        },
      ],
    },
    fetchPolicy: 'network-only',
  });

  const [setDirectoryActive] = useMutationSetDirectoryActive({
    refetchQueries: [{ query: GET_DIRECTORIES }],
  });

  const handleChangeStatus = (active: boolean) => {
    setDirectoryActive({
      variables: { directoryId: directoryId.toString(), active },
    });
  };

  const activeDirectory = data?.directories?.items?.[0];

  return (
    <Flex mt={'s20'} flexDirection="column">
      <SummaryContainer p={'s30'}>
        <Column mr={'s45'}>
          <Label>Folder name</Label>
          <Text>{activeDirectory?.name}</Text>

          <Label>Folder status</Label>

          <ToggleSwitch
            onChange={() => handleChangeStatus(!activeDirectory?.active)}
            checked={!!activeDirectory?.active}
          />

          <Label>Date created</Label>
          <Text>{formatDate(activeDirectory?.createdAt)}</Text>
        </Column>
      </SummaryContainer>
    </Flex>
  );
};

const SummaryContainer = styled(Flex)`
  height: fit-content;
  min-width: 315px;
  min-height: 200px;

  ${({ theme: { colors, radii } }) => css`
    background: ${colors.gray06};
    border-radius: ${radii.rounded};
  `};
`;
const Column = styled(Grid).attrs({
  gridTemplateColumns: 'auto auto',
  gridAutoRows: 'max-content',
})`
  align-items: center;
  gap: ${({ theme: { space } }) => `${space.s15} ${space.s30}`};
`;

const Label = styled(Text).attrs({ fontWeight: 'medium', color: 'gray80' })``;
