import styled from 'styled-components';
import { useState } from 'react';
import { Box, Flex, Icon, Text } from 'ui';
import { Button, ToggleSwitch, StockStatus } from 'components';
import {
  Cell,
  Row,
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  ExpandRowWrapper,
} from 'components/FlexTable/Table.styled';
import { formatDate } from 'utils';
import { ReferenceNumber } from 'components/ReferenceNumber';
import { OrderStatus, OrderStatusLegend } from 'components/OrderStatus';
import { Refs, TransformedOrdersData } from 'pages';
import { TableSpinner } from 'components/TableSpinner';
import { TextNoData } from 'components/TextNoData';
import { OrdersExpandRow } from './OrdersExpandRow';

type OrdersProps = {
  data: TransformedOrdersData[];
  onMonitorItem: (id: number, currentValue: boolean) => void;
  onMonitorAll: (currentValue: boolean) => void;
  defaultExpandedIds: number[];
  monitoredAll: boolean;
  refs: Refs;
  onGenerateReport: () => void;
  onFetchMore: () => void;
  onFetchAll: () => void;
  onShippAllAvailableItems: () => void;
  isShipAllButtonDisabled: boolean;
  loading: boolean;
  allItemsCount?: number;
};

export const Orders = ({
  data,
  onMonitorItem,
  onMonitorAll,
  monitoredAll,
  defaultExpandedIds,
  refs,
  onGenerateReport,
  onShippAllAvailableItems,
  isShipAllButtonDisabled,
  loading,
  onFetchMore,
  onFetchAll,
  allItemsCount,
}: OrdersProps) => {
  const [expandedRows, setExpandedRows] =
    useState<number[]>(defaultExpandedIds);

  const handleExpand = (id: number) => {
    setExpandedRows((prev) => {
      if (prev.includes(id)) {
        return prev.filter((row) => row !== id);
      }
      return [...prev, id];
    });
  };

  return (
    <Flex pt="s10" flexDirection="column" flex="1">
      <Table>
        <OrdersHead />
        <TableBody>
          {data?.length > 0 || loading ? (
            data.map((order) => (
              <OrdersRow
                onMonitorItem={onMonitorItem}
                handleExpand={handleExpand}
                isExpanded={expandedRows.includes(order.id)}
                key={order.id}
                refs={refs}
                {...order}
              />
            ))
          ) : (
            <Box m="s130">
              <TextNoData />
            </Box>
          )}
          <TableSpinner loading={loading} />
        </TableBody>
      </Table>
      <Flex mt="s30" alignSelf="flex-end" alignItems="center">
        <Button onClick={onFetchAll} mr="s30" variant="text">
          <>See all ({allItemsCount ?? 0})</>
        </Button>
        <Button onClick={onFetchMore} variant="outline-primary">
          See more (+25)
        </Button>
      </Flex>
      <Flex mt="s20" mb="s40" justifyContent="space-between">
        <OrderStatusLegend />
        <Flex alignItems="center">
          <Text mr="s10" lineHeight="lh100">
            Monitor all
          </Text>
          <ToggleSwitch
            checked={monitoredAll}
            onChange={() => onMonitorAll(monitoredAll)}
          />
          <Flex ml="s30">
            <Button onClick={onGenerateReport} variant="primary">
              Generate report
            </Button>
            <Button
              disabled={isShipAllButtonDisabled}
              onClick={onShippAllAvailableItems}
              variant="primary"
              ml="s30"
            >
              Shipp all available items
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

const OrdersRow = ({
  isExpanded,
  handleExpand,
  onMonitorItem,
  refs,
  ...data
}: TransformedOrdersData & {
  isExpanded: boolean;
  onMonitorItem: (id: number, currentValue: boolean) => void;
  handleExpand: (id: number) => void;
  refs: Refs;
}) => {
  const {
    order,
    commodity: { itemCode, description, stockStatus },
    quantity,
    value,
    id,
    monitored,
  } = data;
  return (
    <ExpandRowWrapper ref={refs[id]}>
      <Row isExpanded={isExpanded}>
        <Cell flex={112}>{order.documentNumber}</Cell>
        <Cell flex={104}>{formatDate(order.createdAt)}</Cell>
        <Cell flex={136}>
          <ReferenceNumber reference={order.reference} />
        </Cell>
        <Cell flex={112}>{itemCode}</Cell>
        <Cell flex={112}>{description}</Cell>
        <Cell flex={64}>{quantity}</Cell>
        <Cell flex={64}>{value}</Cell>
        <Cell flex={64}>
          <OrderStatus status={order.status} />
        </Cell>
        <Cell flex={64}>
          <StockStatus status={stockStatus} />
        </Cell>
        <Cell flex={64}>
          <ToggleSwitch
            defaultChecked={monitored}
            onChange={(e) => {
              onMonitorItem(id, !e.currentTarget.checked);
            }}
          />
        </Cell>
        <Cell flex={112} onClick={() => handleExpand(id)}>
          <Button fontWeight="regular" variant="text">
            View more
          </Button>
          <StyledIcon
            isExpanded={isExpanded}
            color="gray80"
            ml="s10"
            name="16-arrowhead"
          />
        </Cell>
      </Row>
      {isExpanded && <OrdersExpandRow data={data} />}
    </ExpandRowWrapper>
  );
};

const OrdersHead = () => (
  <TableHead>
    <TableHeadRow>
      <TableHeadCell flex={112}>Order No.</TableHeadCell>
      <TableHeadCell flex={104}>Order date</TableHeadCell>
      <TableHeadCell flex={136}>Reference number</TableHeadCell>
      <TableHeadCell flex={112}>Item code</TableHeadCell>
      <TableHeadCell flex={112}>Description</TableHeadCell>
      <TableHeadCell flex={64}>Qty</TableHeadCell>
      <TableHeadCell flex={64}>List price</TableHeadCell>
      <TableHeadCell flex={64}>Order status</TableHeadCell>
      <TableHeadCell flex={64}>Stock status</TableHeadCell>
      <TableHeadCell flex={64}>Monitor status</TableHeadCell>
      <TableHeadCell flex={112}>Production & Delivery</TableHeadCell>
    </TableHeadRow>
  </TableHead>
);

const StyledIcon = styled(Icon)<{ isExpanded: boolean }>`
  transform: ${({ isExpanded }) =>
    isExpanded ? 'rotate(0)' : 'rotate(180deg)'};
`;
