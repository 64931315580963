import { gql, MutationHookOptions, useMutation } from '@apollo/client';

export const useMutationChangePassword = (
  options?: MutationHookOptions<ChangePasswordData, ChangePasswordVariables>,
) =>
  useMutation<ChangePasswordData, ChangePasswordVariables>(
    CHANGE_PASSWORD,
    options,
  );

export const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $oldPassword: String!
    $newPassword: String!
    $email: String!
  ) {
    changePassword(
      oldPassword: $oldPassword
      newPassword: $newPassword
      email: $email
    ) {
      userName
    }
  }
`;

export type ChangePasswordVariables = {
  oldPassword: string;
  newPassword: string;
  email: string;
};
export type ChangePasswordData = {
  changePassword: {
    userName: string;
  };
};
