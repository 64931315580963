import { ColorsType } from 'ui';
// TODO: add the rest as will api
export type RegistrationStatuses = 'processing' | 'registered';

type Result = {
  color: ColorsType;
  label: string;
};

export const convertStatus = (
  status: RegistrationStatuses,
): Result | undefined => {
  switch (status) {
    case 'processing':
      return {
        label: 'Processing',
        color: 'warning50',
      };
    case 'registered':
      return {
        label: 'Registered',
        color: 'success50',
      };

    default:
      return;
  }
};
