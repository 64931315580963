import { TableHead, TableHeadCell, TableHeadRow } from 'components';

export const UrgentOrdersHead = () => (
  <TableHead>
    <TableHeadRow>
      <TableHeadCell>Order No.</TableHeadCell>
      <TableHeadCell>Order date</TableHeadCell>
      <TableHeadCell width="45px">Qty</TableHeadCell>
      <TableHeadCell width="65px">Stock status</TableHeadCell>
      <TableHeadCell>Customer</TableHeadCell>
      <TableHeadCell />
    </TableHeadRow>
  </TableHead>
);
